import { Button, Space, Tag } from 'antd';
import { Link } from 'react-router-dom';

import {
  identificationStatusColors,
  securityAccountStatusColors,
  securityMutualAccountStatusColors,
  securityMutualAccountStatusApprove,
} from 'apps/investment/constants/user';
import {
  fundAccountESignStatusFinished,
  esignMutualFundStatusColors,
  fundAccountStatusColors,
  fundAccountStatusApprove,
} from 'apps/investment/constants/fundAccount';
import { formatDateTime } from 'utils/formatDate';

import VerificationMark from 'common/ui/VerificationMark';

export const columnsGenerator = props => [
  {
    align: 'center',
    render: record => <Link to={`/investment/users/${record.id}`}>View</Link>,
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    sorter: true,
    render: value => formatDateTime(value),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (value, record) => (
      <Space direction="vertical">
        {value ? <div>{value}</div> : null}
        <VerificationMark
          value={record.email}
          isVerified={record.email_verified}
        />
      </Space>
    ),
  },
  {
    title: 'Phone',
    dataIndex: 'email',
    render: (value, record) => (
      <Space direction="vertical">
        <VerificationMark
          value={record.phone}
          isVerified={record.phone_verified}
        />
      </Space>
    ),
  },
  {
    title: 'KYC',
    dataIndex: 'identification',
    align: 'center',
    render: o =>
      o && (
        <Tag color={identificationStatusColors[o.verification_status]}>
          {o.verification_status_label}
        </Tag>
      ),
  },
  {
    title: 'Account Type',
    dataIndex: 'account_type',
    align: 'center',
    sorter: true,
    render: value =>
      value == 'business' ? (
        <Tag color={'blue'}>{value}</Tag>
      ) : (
        <Tag color={'green'}>{value}</Tag>
      ),
  },
  {
    title: 'Trading Accounts',
    align: 'center',
    render: obj => {
      if (!obj) return '-';

      return (
        <Space direction="vertical">
          {obj.fund_account && (
            <Tag
              color={obj.fund_account.status != 'approved' ? 'orange' : 'blue'}
            >
              {obj.fund_account.fund_manager_name}{' '}
              {obj.fund_account.trading_code}{' '}
              {obj.fund_account.enabled_b2b ? <>(FutureFlex)</> : ''}
              {obj.fund_account.status != 'approved' ? (
                <>({obj.fund_account.status})</>
              ) : (
                ''
              )}
            </Tag>
          )}
          {obj.stock_account && (
              <Tag color={securityAccountStatusColors[obj.stock_account.status]}>
                NHSV {obj.stock_account.account_number}{' '}
                {obj.stock_account.status !== 1007 ? (
                  <>({obj.stock_account.status_label})</>
                ) : (
                  ''
                )}
              </Tag>
        )}
        </Space>
      );
    },
  },
  {
    title: 'NHSV eContract',
    dataIndex: 'is_sign_contract',
    key: 'is_sign_contract',
    render: (value, record) => {
      if (record.stock_account && record.stock_account.is_sign_contract) {
        return <Tag color={'green'}>Đã ký</Tag>;
      }

      return (
        <>
        <Tag color={'yellow'}>Chưa ký</Tag><br/>
        <br/>
        {
          record.stock_account && record.stock_account.is_econtract_link_created ? (
            <Tag color={'green'}>Đã có eContract</Tag>
          ) : (
            <Tag color={'grey'}>Chưa có eContract</Tag>
          )
        }
        </>
      );
    },
  },
  {
    title: 'Priority Trading Account',
    dataIndex: 'priority_trading_account',
    align: 'center',
    sorter: true,
    render: value =>
      value == 'nhsv' ? (
        <Tag color={'blue'}>NHSV</Tag>
      ) : (
        <Tag color={'green'}>VCAM</Tag>
      ),
  },
  {
    title: 'Action',
    key: 'action',
    align: 'center',
    render: (text, record) => (
      <>
        {record && record.ekyc_verified == true && (
          <Link to={`/investment/portfolios?user_id=${record.id}`}>
            View Portfolio
          </Link>
        )}{' '}
      </>
    ),
  },
];

export const columnsFundAccount = ({
  userID,
  createVcamB2BAccount,
  isCreatingVcamB2BAccount,
  resyncStatus,
  isResynchronizing,
}) => [
  {
    title: '',
    dataIndex: 'id',
    align: 'center',
    render: id => <Link to={`/investment/fund-accounts/${id}`}>View</Link>,
  },
  {
    title: 'Trading Code',
    dataIndex: 'trading_code',
    key: 'trading_code',
  },
  {
    title: 'Approved At',
    dataIndex: 'approved_at',
    key: 'approved_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: value => (
      <Tag color={fundAccountStatusColors[value]}>{value}</Tag>
    ),
  },
  {
    title: 'eSign',
    dataIndex: 'sign_contract_status',
    key: 'sign_contract_status',
    render: (value, record) => {
      if (value == fundAccountESignStatusFinished) {
        return <Tag color={'green'}>Đã ký</Tag>;
      }

      return <Tag color={'yellow'}>Chưa ký</Tag>;
    },
  },
  {
    title: 'VSD',
    dataIndex: 'status',
    key: 'status',
    render: (value, record) => {
      const isVSDApprove = record.status == fundAccountStatusApprove;
      if (isVSDApprove) {
        return <Tag color={'green'}>Duyệt</Tag>;
      }

      return <Tag color={'yellow'}>Chưa duyệt</Tag>;
    },
  },
  {
    title: 'Fund Name',
    dataIndex: 'fund_manager_code',
    render: value => value?.toUpperCase(),
  },
  {
    title: 'Fund Account Id',
    dataIndex: 'account_id',
    key: 'account_id',
  },
  {
    title: 'Enable B2B',
    key: 'enable_b2b_action',
    render: (text, record) => {
      if (record.enabled_b2b) {
        return <Tag color="green">Đã kích hoạt</Tag>;
      }

      if (record.status != 'approved') {
        return <Tag color="red">VCAM B2C Account cần approve</Tag>;
      } else {
        return <Tag color="yellow">Chưa kích hoạt</Tag>;
      }
    },
  },
];

export const columnsStockAccount = ({
  userID,
  createStockAccount,
  isCreatingStockAccount,
  enableMutualFundAccount,
  isEnableMutualFundAccount,
}) => [
  {
    title: '',
    dataIndex: 'id',
    align: 'center',
    render: id => <Link to={`/investment/stock-accounts/${id}`}>View</Link>,
  },
  {
    title: 'Account Number',
    dataIndex: 'account_number',
    key: 'account_number',
  },
  {
    title: 'VSD CK',
    dataIndex: 'is_vsd_approved',
    key: 'is_vsd_approved',
    render: (value, record) => {
      if (value) {
        return <Tag color={'green'}>Đã duyệt</Tag>;
      }

      return <Tag color={'yellow'}>Chưa duyệt</Tag>;
    },
  },
  {
    title: 'eContract',
    dataIndex: 'is_sign_contract',
    key: 'is_sign_contract',
    render: (value, record) => {
      if (value) {
        return <Tag color={'green'}>Đã ký</Tag>;
      }

      return (
        <>
        <Tag color={'yellow'}>Chưa ký</Tag><br/>
        <br/>
        {
          record.is_econtract_link_created ? (
            <Tag color={'green'}>Đã có eContract</Tag>
          ) : (
            <Tag color={'grey'}>Chưa có eContract</Tag>
          )
        }
        </>
      );
    },
  },
  {
    title: 'VSD CCQ',
    dataIndex: 'mutual_fund_account_status',
    key: 'mutual_fund_account_status',
    render: (value, record) => {
      if (record.mutual_fund_account_status != securityMutualAccountStatusApprove) {
        return (
          <Button
            disabled={isEnableMutualFundAccount}
            onClick={() => {
              let warningMessage = `Confirm to enable mutual fund account? Please key in the user account number:`;
              let confirmCode = prompt(warningMessage);

              if (confirmCode == record.account_number) {
                enableMutualFundAccount({ id: userID })
                return;
              } else {
                alert('account number was not match');
              }
            }}
            type="primary"
            style={{
              margin: 10,
              backgroundColor: 'green',
              borderColor: 'green',
            }}
          >
            Approve MF
          </Button>
        )
      }

      return (
        <>
        <Tag color={securityMutualAccountStatusColors[record.mutual_fund_account_status]}>
          {record.mutual_fund_account_status_label}
        </Tag>
        <br/>
        {record.vcam_market_customer_id && (
          <Tag color={'green'}>
          VCAM (Customer ID: {record.vcam_market_customer_id})
        </Tag>
        )}
        </>
      );
    },
  },
  {
    title: 'eSign NHSV',
    dataIndex: 'is_esign_contract_approve',
    key: 'is_esign_contract_approve',
    render: (value, record) => {
      if (value) {
        return <Tag color={'green'}>Đã ký</Tag>;
      }

      return <Tag color={'yellow'}>Chưa ký</Tag>;
    },
  },
  {
    title: 'eSign Mutual Fund',
    dataIndex: 'esign_contract_mutual_funds',
    key: 'esign_contract_mutual_funds',
    render: (value, record) => {
      return value && value.map(item => (
        <div key={item.fund_manager} style={{ margin: 10 }}>
          <Tag color={esignMutualFundStatusColors[item.status]}>
            {item.fund_manager.toUpperCase()}: {item.status_label}
          </Tag>
        </div>
      ));
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => {
      if (record && record.account_number != '') {
        return '-'
      }

      return (
        <Button
          disabled={isCreatingStockAccount}
          onClick={() =>
            confirm('Confirm to submit NHSV to create stock account?') &&
            createStockAccount({ id: userID })
          }
          type="primary"
          style={{
            margin: 10,
            backgroundColor: 'green',
            borderColor: 'green',
          }}
        >
          Send to NHSV
        </Button>
      );
    },
  },
  {
    title: '',
    dataIndex: 'id',
    align: 'center',
    render: (text, record) => {
      if (record.mutual_fund_account_status == securityMutualAccountStatusApprove) {
        return (
          <Link to={`/investment/stock-accounts/${record.id}/esign/edit`}>Edit</Link>
        );
      }
    },
  },
];
