import apiCall from 'apps/investment/apiCalls/userIdentification';
import { CreatePage, EditPage } from 'common/ui/AdminCRUD/page';
import { useParams } from 'react-router-dom';
import ItemBusinessForm from './BusinessForm';
import ItemIndividualForm from './IndividualForm';

export const InvestmentUserIdentificationEditPage = props => {
  const params = useParams();

  return (
    <EditPage
      title="User"
      apiCall={apiCall}
      listUrl={`/investment/users`}
      ItemForm={
        params.accountType == 'individual'
          ? ItemIndividualForm
          : ItemBusinessForm
      }
      options={{ title: 'Edit User Identification' }}
      resourceName="data"
      {...props}
    />
  );
};

export const InvestmentUserIdentificationCreatePage = props => {
  const params = useParams();

  return (
    <CreatePage
      title="User Identification"
      apiCall={apiCall}
      listUrl={`/investment/users`}
      ItemForm={
        params.accountType == 'individual'
          ? ItemIndividualForm
          : ItemBusinessForm
      }
      options={{ title: 'Create User Identification' }}
      resourceName="data"
      {...props}
    />
  );
};
