import { InputNumber } from 'antd';

const FormattedNumberInput = props => {
  return (
    <InputNumber
      style={{
        width: '100%',
      }}
      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={value => value?.replace(/\$\s?|(,*)/g, '')}
      {...props}
    />
  );
};

export default FormattedNumberInput;
