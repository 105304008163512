import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';

const FETCH_INVESTCORE_USER_SURVEY_HISTORY_DETAIL_API_ID =
  'DASHBOARD/FETCH_INVESTCORE_USER_SURVEY_HISTORY_DETAIL_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_INVESTCORE_USER_SURVEY_HISTORY_DETAIL_API_ID,
  ({ id }) => ({
    endpoint: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/user-survey-histories/${id}`,
    method: 'GET',
  })
);

export default apiCall;
