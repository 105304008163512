import { isBoolean, isObject, isString } from 'lodash';
import { formatDateTime, formatISODate } from 'utils/formatDate';

function isISOString(str) {
  // Define a regular expression pattern for ISO strings
  const isoStringPattern = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d+)?(Z|[+-]\d{2}:\d{2})?$/;

  return isoStringPattern.test(str);
}

const isContainString = (mainString, searchString) => {
  const regex = new RegExp(`\\b${searchString}\\b`);
  return regex.test(mainString);
};

const formatNumberList = ['amount', 'quantity', 'price'];

function handleValue(value, label) {
  if (label && formatNumberList.some(t => label.toLowerCase().includes(t))) {
    return new Intl.NumberFormat('en-US').format(value);
  }
  if (isBoolean(value)) {
    return JSON.stringify(value);
  }

  if (isISOString(value)) {
    if (isContainString(label.toLowerCase(), 'at')) {
      return formatDateTime(value);
    }
    return formatISODate(value);
  }
  if (isString(value)) {
    return value;
  }

  return JSON.stringify(value);
}

function convertKeyToLabel(key) {
  return key
    ?.split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

function convertLabelToKey(label) {
  return label
    ?.split(' ')
    .join('_')
    .toLowerCase();
}

function convertObjectToArrayData(data) {
  if (!isObject(data)) {
    return [];
  }
  return Object.entries(data).map(([key, value]) => {
    let label = convertKeyToLabel(key);
    let text = handleValue(value, label);
    return { label, text };
  });
}

export default convertObjectToArrayData;

export const removeKeyList = (object, list) => {
  list.map(e => delete object[e]);
  return object;
};

export function sortByOrder(inputArray, orderArray) {
  function customSort(a, b) {
    const valueA = convertLabelToKey(a.label);
    const valueB = convertLabelToKey(b.label);

    const indexA = orderArray.indexOf(valueA);
    const indexB = orderArray.indexOf(valueB);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    if (indexA !== -1) {
      return -1;
    }

    if (indexB !== -1) {
      return 1;
    }

    return 0;
  }

  return [...inputArray].sort(customSort);
}
