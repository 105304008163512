import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_MUTUAL_FUND_LIST_API_ID = 'DASHBOARD/FETCH_MUTUAL_FUND_LIST_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_MUTUAL_FUND_LIST_API_ID,
  params => ({
    endpoint: generateEndpoint({
      host: `${API_BASE_URL}/marketdb-api/admin/v1/funds`,
      params,
    }),
    method: 'GET',
  })
);

export default apiCall;
