import { Alert, Button, Form, List, Table } from 'antd';

import apiCompaniesCall from 'apps/investment/apiCalls/company/list';

import { useFetchApiList, usePostApi } from '@stagapps/redux-utils';
import MetaDecorator from 'apps/home/components/MetaDecorator';
import PageTitle from 'apps/home/components/PageTitle';
import { programOrderStatusCanceled } from 'apps/investment/constants/programOrder';
import SelectListData from 'common/form/SelectListData';
import { formatTableColumns } from 'common/ui/DataTable/config';
import ErrorMessage from 'common/ui/ErrorMessage';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { useEffect } from 'react';
import { rebundleColumns } from './config';

const title = 'Rebundle Program Order';

const RebundleForm = ({ apiCall }) => {
  const [form] = Form.useForm();

  const {
    post: submitItem,
    isLoading: submiting,
  } = usePostApi(apiCall.rebundle, { resourceName: 'data' });

  const { company_id } = Form.useWatch([], form) || {};

  const onFinish = values => {
    if (confirm('Accept to rebundle selected PO')) {
      submitItem({ payload: values });
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <MetaDecorator title={title} />
      <PageTitle title={title} backUrl={`/investment/program-orders`} />
      <Alert
        type="info"
        description={
          <List
            size="small"
            dataSource={[
              'Retry bundle" là nghiệp vụ tính lại các FO mới dựa trên các PO đã bị huỷ.',
              'Khi phát hiện sai sót trong việc đóng góp và Doanh nghiệp chưa thanh toán, các FO trong kỳ sẽ được huỷ, khi đó các PO tương ứng sẽ được huỷ theo.',
              'Tuy nhiên, một số PO trong kỳ đó có thể đã được tái phân phối sai.',
              'Để hệ thống tính toán lại việc tái phân phối và tạo được FO mới đúng nhất. Admin cần:',
              <List
                dataSource={[
                  '1- Kiểm tra và huỷ TÂT CẢ các PO đã được tái phân phối (nếu có) trong kỳ hiện tại - kỳ xảy ra sai sót.',
                  '2- Thực hiện retry bundle ở trang này. Cross check lại các lệnh PO (bao gồm lệnh đã được Admin huỷ và cả những lệnh đã tự động huỷ trước đó theo FO cũ)',
                ]}
                renderItem={item => <List.Item>{item}</List.Item>}
              />,
            ]}
            renderItem={item => <List.Item>{item}</List.Item>}
          />
        }
      />

      <Form
        form={form}
        name={'rebundle_form'}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item name="company_id" label="Company">
          <SelectListData
            apiCall={apiCompaniesCall}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            placeholder="Search Email / Full company name"
            callback={({ id, email, name }) => ({
              value: id,
              label: name + ' / ' + email,
            })}
          />
        </Form.Item>

        {!!company_id ? (
          <Form.Item
            labelCol={{ span: 24 }}
            name="program_order_ids"
            label="Select rebundle program order :"
          >
            <SelectTable
              apiCall={apiCall.list}
              params={{
                company_id,
                status: programOrderStatusCanceled,
                order_type: 'buy',
              }}
            />
          </Form.Item>
        ) : null}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={submiting}>
            Rebundle
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RebundleForm;

function removeDuplicates(arr) {
  return Array.from(new Set(arr));
}

export const SelectTable = ({ apiCall, params, ...props }) => {
  const { onChange, value } = props;
  const { data, load, isLoading, refresh, refreshing, error, paging } =
    useFetchApiList(apiCall, { resourceName: 'data' }) || {};

  const onSelectChange = newSelectedRowKeys => {
    const selected = removeDuplicates([...value, ...newSelectedRowKeys]);
    onChange(selected);
  };

  const rowSelection = {
    selectedRowKeys: value,
    onChange: onSelectChange,
  };

  useEffect(() => {
    onChange([]);
    load(params);
  }, [JSON.stringify(params)]);

  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (!!error) {
    return <ErrorMessage errors={error} />;
  }
  const { page, count, page_count, page_size } = paging || {};
  const hasSelected = value?.length > 0;

  return (
    <>
      <Button
        type="primary"
        onClick={() => onChange([])}
        disabled={!hasSelected}
      >
        Reload
      </Button>
      <Table
        extra={<Button>123</Button>}
        rowKey={e => e?.id}
        rowSelection={rowSelection}
        size="small"
        dataSource={data}
        loading={refreshing}
        columns={formatTableColumns(rebundleColumns)}
        pagination={{
          current: page,
          total: count,
          hideOnSinglePage: true,
          pageSize: page_size,
          showSizeChanger: false,
          onChange: page => {
            refresh({ ...params, page });
          },
        }}
      />
    </>
  );
};
