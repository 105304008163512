import { Tag } from 'antd';
import {
  orderProgramTypeColors,
  orderProgramTypeLabels,
  orderStatusColors,
  orderTypeColors,
  orderTypeLabels,
} from 'apps/investment/constants/fundOrder';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/formatDate';

export const columnsGenerator = params => [
  {
    dataIndex: 'id',
    align: 'center',
    render: id => <Link to={`/investment/orders/${id}`}>View</Link>,
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    align: 'center',
    render: value => formatDateTime(value),
    sorter: true,
  },
  {
    title: 'Order Date',
    dataIndex: 'fund_order_time',
    align: 'center',
    render: value => formatDateTime(value),
    sorter: true,
  },
  {
    title: 'Order Type',
    dataIndex: 'order_side',
    render: value => (
      <Tag color={orderTypeColors[value]}>{orderTypeLabels[value]}</Tag>
    ),
    align: 'center',
  },
  {
    title: 'Program',
    dataIndex: 'program_type',
    render: value => {
      return (
        <Tag color={orderProgramTypeColors[value]}>
          {orderProgramTypeLabels[value]}
        </Tag>
      );
    },
    align: 'center',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    type: 'formatNumber',
    sorter: true,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    type: 'formatNumber',
    sorter: true,
  },
  {
    title: 'User',
    dataIndex: 'user',
    render: u => (u ? `${u.name ?? ''} (${u.email})` : '-'),
  },
  {
    title: 'Fund Order ID',
    dataIndex: 'fund_order_id',
    align: 'right',
    sorter: true,
  },
  {
    title: 'Product Code',
    align: 'center',
    render: o => `${o.fund_code}`,
  },
  {
    title: 'Trading Account Manager',
    dataIndex: 'trading_account_manager',
    align: 'center',
    render: value => (value ? value.toUpperCase() : '-'),
  },
  {
    title: 'Status',
    align: 'center',
    render: o => {
      return <Tag color={orderStatusColors[o.status]}>{o.status_label}</Tag>;
    },
  },
];
