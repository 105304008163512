import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

const FETCH_MUTUAL_FUND_EDIT_API_ID = 'DASHBOARD/FETCH_MUTUAL_FUND_EDIT_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_MUTUAL_FUND_EDIT_API_ID,
  ({ id, payload }) => ({
    endpoint: `${API_BASE_URL}/marketdb-api/admin/v1/funds/${id}`,
    method: 'PUT',
    body: payload,
    notify: true,
  })
);

export default apiCall;
