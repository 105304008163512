import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';

const POST_INVESTMENT_PLANS_API_ID =
  'INVESTCORE/POST_USER_PROGRAM_INVESTMENT_PLANS_API';

const apiCall = enhancedMakeFetchAction(
  POST_INVESTMENT_PLANS_API_ID,
  ({ id, payload }) => ({
    endpoint: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/user-programs/${id}/create-investment-plan`,
    method: 'POST',
    body: payload,
    notify: true,
  })
);

export default apiCall;
