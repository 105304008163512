import logo from 'assets/images/logo.jpeg';
import useApps from 'hooks/useApps';
import useGoogleAuth from 'hooks/useGoogleAuth';
import { Link } from 'react-router-dom';
import AppSelector from './AppSelector';
import UserMenu from './UserMenu';

const Header = () => {
  const { user, isAuthenticated, loading, signOut } = useGoogleAuth();
  const { currentApp } = useApps();
  return (
    <header className="text-gray-600 body-font">
      <div className="flex flex-wrap p-5 flex-col md:flex-row items-center">
        <Link
          className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
          to={`/${currentApp ? currentApp.key : ''}`}
        >
          <img src={logo} alt="stag logo" className="h-12" />
        </Link>

        <span className="text-xl">
          {currentApp ? (
            <Link
              to={`/${currentApp.key}`}
              style={{
                fontWeight: 700,
                fontSize: 24,
                marginLeft: 15,
              }}
            >
              {currentApp.label}
            </Link>
          ) : (
            <Link
              to="/"
              style={{
                fontWeight: 700,
                fontSize: 24,
                marginLeft: 15,
              }}
            >
              Stag Admin
            </Link>
          )}
        </span>

        <div className="md:ml-auto flex flex-wrap items-center text-base justify-center mt-4 md:mt-0">
          {isAuthenticated ? (
            <nav className="md:ml-auto md:mr-auto flex flex-wrap">
              <AppSelector />
            </nav>
          ) : null}
        </div>

        <div className="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none rounded text-base mt-4 md:mt-0">
          {loading ? (
            '..'
          ) : isAuthenticated ? (
            <UserMenu user={user} signOut={signOut} />
          ) : null}
        </div>
      </div>
    </header>
  );
};

export default Header;
