import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';

const FETCH_INVESTCORE_USER_REQUIRE_RESYNC_VSD_STATUS_API_ID =
  'DASHBOARD/FETCH_INVESTCORE_USER_REQUIRE_RESYNC_VSD_STATUS_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_INVESTCORE_USER_REQUIRE_RESYNC_VSD_STATUS_API_ID,
  () => ({
    endpoint: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/users/required-resync-vsd-status
`,
    method: 'GET',
  })
);

export default apiCall;
