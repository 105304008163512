import {
  EditOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
import { Space, Tag } from 'antd';
import { Link } from 'react-router-dom';

import {
  userProgramStatusColors,
  yesNoColors,
  yesNoLabels,
} from 'apps/investment/constants/userProgram';
import UIIconTooltip from 'common/ui/UIIconTooltip';

import { activeColors, activeLabels } from 'utils/constants';
import { formatNumber } from 'utils/formatAmount';
import { formatDateTime, isZeroDate } from 'utils/formatDate';

export const userProgramsColumns = () => {
  return [
    {
      title: '',
      dataIndex: 'id',
      align: 'center',
      render: id => <Link to={`/investment/user-programs/${id}`}>View</Link>,
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      sorter: true,
      render: value => formatDateTime(value),
    },
    {
      title: 'User',
      dataIndex: 'user',
      render: user => (
        <Link to={`/investment/users/${user?.id}`}>
          {user && user.name ? `${user.name} (${user.email})` : user?.email}
        </Link>
      ),
    },
    {
      title: 'Tên',
      dataIndex: 'program_name',
    },
    {
      title: 'Ngày kết thúc',
      dataIndex: 'end_date',
      type: 'date',
      sorter: true,
    },
    {
      title: 'Ngày thanh toán',
      dataIndex: 'monthly_order_payment',
      align: 'right',
      sorter: true,
      render: value => (value ? `Ngày ${value}` : '-'),
    },
    {
      title: 'Số tiền định kỳ',
      dataIndex: 'contribute_amount',
      align: 'right',
      sorter: true,
      render: value => formatNumber(value),
    },
    {
      title: 'Tự động tạo lệnh',
      align: 'center',
      dataIndex: 'is_auto_create_order',
      render: value => (
        <Tag color={yesNoColors[value]}>{yesNoLabels[value]}</Tag>
      ),
    },
    {
      title: 'Status',
      align: 'center',
      render: (text, record) => (
        <Tag color={userProgramStatusColors[record.status]}>
          {record.status_label}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space>
          <Link to={`/investment/user-programs/${record.id}/edit`}>
            <UIIconTooltip title="Edit" Icon={EditOutlined} />
          </Link>
          <Link to={`/investment/user-programs/${record.id}/investment-plans`}>
            <UIIconTooltip title="Investment Plan" Icon={LineChartOutlined} />
          </Link>
          <Link to={`/investment/user-programs/${record.id}`}>
            <UIIconTooltip title="Detail" Icon={InfoCircleOutlined} />
          </Link>
          <Link to={`/investment/user-programs/${record.id}/portfolio`}>
            <UIIconTooltip title="View Portfolio" Icon={PieChartOutlined} />
          </Link>
        </Space>
      ),
    },
  ];
};

export const userProgramInvestmentPlanColumns = () => [
  {
    title: 'Status',
    dataIndex: 'is_active',
    render: isActive => {
      const status = isActive ? 'active' : 'inactive';
      return <Tag color={activeColors[status]}>{activeLabels[status]}</Tag>;
    },
  },

  {
    title: 'Product Code',
    dataIndex: 'user_investment_details',
    key: 'product_code',

    render: detail => (
      <div>
        {detail?.map(e => (
          <p>{e.product_code}</p>
        ))}
      </div>
    ),
  },
  {
    title: 'Percentage',
    dataIndex: 'user_investment_details',
    key: 'percentage',
    render: detail => (
      <div>
        {detail?.map(e => (
          <p>{e.percentage}%</p>
        ))}
      </div>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'user_investment_details',
    key: 'amount',

    render: detail => (
      <div>
        {detail?.map(e => (
          <p>{new Intl.NumberFormat('en-US').format(e.amount)}</p>
        ))}
      </div>
    ),
  },
  {
    title: 'Version',
    dataIndex: 'investment_plan_version',
  },
  {
    title: 'Activated At',
    dataIndex: 'activated_at',
    render: value => {
      if (isZeroDate(value)) return '-';
      return formatDateTime(value);
    },
  },
];
