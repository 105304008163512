export const surveyTypeLabel = {
  1001: 'Khảo sát',
  1002: 'Khẩu vị rủi ro',
};

export const surveyTypeColors = {
  1001: 'yellow',
  1002: 'green',
};

export const questionTypeLabel = {
  1001: 'Single Choice',
  1002: 'Multiple Choice',
  1003: 'Manual Input',
};

export const questionTypeColors = {
  1001: 'yellow',
  1002: 'green',
  1003: 'blue',
};

export const questionTypeSingleChoice = 1001;
export const questionTypeMultipleChoice = 1002;
export const questionTypeText = 1003;