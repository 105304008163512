import apiCall from 'apps/hub/apiCalls/topic';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { columnsGenerator } from './config';
import ItemForm from './Form';

export const TopicListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    title="All Topics"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="data"
    createUrl={`/hub/topics/create`}
    {...props}
  />
);

export const TopicEditPage = props => (
  <EditPage
    title="Topic Edit"
    apiCall={apiCall}
    listUrl={`/hub/topics`}
    ItemForm={ItemForm}
    resourceName="data"
    {...props}
  />
);

export const TopicCreatePage = props => (
  <CreatePage
    title="Topic"
    apiCall={apiCall}
    listUrl={`/hub/topics`}
    ItemForm={ItemForm}
    resourceName="data"
    {...props}
  />
);
