import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

const FETCH_POLL_DETAIL_API_ID = 'DASHBOARD/FETCH_POLL_DETAIL_API_ID';

const apiCall = enhancedMakeFetchAction(FETCH_POLL_DETAIL_API_ID, ({ id }) => ({
  endpoint: `${API_BASE_URL}/stagedu-api/admin/v1/polls/${id}`,
  method: 'GET',
}));

export default apiCall;
