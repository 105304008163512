import { useFetchApiList } from '@stagapps/redux-utils';
import { Button, Form, Select, Spin } from 'antd';
import apiUsersCall from 'apps/investment/apiCalls/user/list';
import { userProgramStatusOptions } from 'apps/investment/constants/userProgram';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import debounce from 'lodash/debounce';
import QueryString from 'qs';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const debounceTimeout = 800;

const FilterForm = ({ form, name }) => {
  let [filterParams, setSearchParams] = useSearchParams();
  let initialValues = QueryString.parse(filterParams.toString());

  const {
    data: users = [],
    load: loadUsers,
    isLoading: isLoadingUsers,
    loadMore: loadMoreUsers,
  } = useFetchApiList(apiUsersCall, { resourceName: 'data' });

  const debounceFetcher = useMemo(() => {
    const loadOptions = value => {
      loadMoreUsers({ search: value });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [loadUsers, debounceTimeout]);

  const handleSubmit = params => {
    const p = JSON.parse(JSON.stringify(params));
    setSearchParams(p);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  if (isLoadingUsers) {
    return <LoadingSpinner />;
  }

  return (
    <Form
      form={form}
      layout="inline"
      name={name || 'filter_form'}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <Form.Item name="status" label="Status">
        <Select style={{ width: 150 }}>
          {userProgramStatusOptions.map(option => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="user_id" label="User">
        <Select
          style={{ width: 300 }}
          onPopupScroll={e => {
            e.persist();
            let target = e.target;
            if (
              target.scrollTop + target.offsetHeight ===
              target.scrollHeight
            ) {
              loadMoreUsers();
            }
          }}
          onSearch={debounceFetcher}
          notFoundContent={isLoadingUsers ? <Spin size="small" /> : null}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          placeholder="Search Email / Full name"
          options={users.map(({ id, email, name }) => ({
            value: id,
            label: name + ' / ' + email,
          }))}
        />
      </Form.Item>

      <Button type="primary" htmlType="submit">
        Filter
      </Button>
    </Form>
  );
};

export default FilterForm;
