import { useFetchApiList } from '@stagapps/redux-utils';
import { Button, Form, Select, Spin } from 'antd';
import apiProductsCall from 'apps/investment/apiCalls/product/list';
import apiUsersCall from 'apps/investment/apiCalls/user/list';
import debounce from 'lodash/debounce';
import { useEffect, useMemo } from 'react';

import { Input } from 'antd';
import { orderStatusLabels } from 'apps/investment/constants/fundOrder';
import FormattedNumberInput from 'common/form/FormattedNumberInput';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const debounceTimeout = 800;

const OrderForm = ({
  form,
  submiting,
  onFinish,
  initialValues = { payment_status: 'pending' },
  name,
}) => {
  const {
    data: products = [],
    load: loadProducts,
    isLoading: isLoadingProducts,
  } = useFetchApiList(apiProductsCall, { resourceName: 'data' });

  const {
    data: users = [],
    load: loadUsers,
    isLoading: isLoadingUsers,
    loadMore: loadMoreUsers,
  } = useFetchApiList(apiUsersCall, { resourceName: 'data' });

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    loadProducts();
    loadUsers();
  }, []);

  const isLoading = isLoadingProducts;

  const debounceFetcher = useMemo(() => {
    const loadOptions = value => {
      loadUsers({ search: value });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [loadUsers, debounceTimeout]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'fund_order_form'}
      onFinish={values => {
        const { fund_code, ...payload } = values || {};
        const indexOfFund = products.map(e => e.code).indexOf(fund_code);
        const product_id = products[indexOfFund].id;
        onFinish({ ...payload, fund_code, product_id });
      }}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item name="fund_code" label="Fund Code">
        <Select
          placeholder="Chọn quỹ"
          options={products.map(({ code }) => ({
            value: code,
            label: code,
          }))}
        />
      </Form.Item>

      <Form.Item name="fund_manager" label="Fund Manager">
        <Select
          placeholder="Chọn công ty quản lý quỹ"
          options={[{ value: 'vcam', label: 'VCAM' }]}
        />
      </Form.Item>

      <Form.Item name="order_side" label="Order Type">
        <Select
          placeholder="Chọn lệnh"
          options={[
            { value: 'buy', label: 'Mua' },
            { value: 'sell', label: 'Bán' },
          ]}
        />
      </Form.Item>

      <Form.Item name="program_type" label="Program Type">
        <Select
          placeholder="Chọn lệnh"
          options={[
            { value: 1002, label: 'Lệnh FutureFlex' },
            { value: 1003, label: 'Lệnh bình thường' },
          ]}
        />
      </Form.Item>

      <Form.Item name="amount" label="Amount">
        <FormattedNumberInput
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          addonAfter={'VND'}
          min={0}
        />
      </Form.Item>

      <Form.Item name="user_id" label="User">
        <Select
          onPopupScroll={e => {
            e.persist();
            let target = e.target;
            if (
              target.scrollTop + target.offsetHeight ===
              target.scrollHeight
            ) {
              loadMoreUsers();
            }
          }}
          onSearch={debounceFetcher}
          notFoundContent={isLoadingUsers ? <Spin size="small" /> : null}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          placeholder="Search Email / Full name"
          options={users.map(({ id, email, name }) => ({
            value: id,
            label: name + ' / ' + email,
          }))}
        />
      </Form.Item>
      <Form.Item label="Net Amount" name="net_amount">
        <FormattedNumberInput />
      </Form.Item>
      <Form.Item label="Quantity" name="quantity">
        <FormattedNumberInput />
      </Form.Item>
      <Form.Item label="Matched Price" name="matched_price">
        <FormattedNumberInput />
      </Form.Item>
      <Form.Item label="Fee Amount" name="fee_amount">
        <FormattedNumberInput />
      </Form.Item>
      <Form.Item label="Tax Amount" name="tax_amount">
        <FormattedNumberInput />
      </Form.Item>
      <Form.Item name="status" label="Status">
        <Select
          options={Object.keys(orderStatusLabels).map(k => {
            return { value: parseInt(k), label: orderStatusLabels[k] };
          })}
        />
      </Form.Item>
      <hr />

      <Form.Item label="Fund Order ID" name="fund_order_id">
        <Input />
      </Form.Item>
      <Form.Item label="Fund Customer ID" name="fund_customer_id">
        <Input />
      </Form.Item>
      <Form.Item label="Payment Bank Name" name="fund_payment_bank_name">
        <Input />
      </Form.Item>
      <Form.Item
        label="Payment Bank Branch Name"
        name="fund_payment_bank_branch_name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Payment Account Name"
        name="fund_payment_bank_account_name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Payment Account Number"
        name="fund_payment_bank_account_number"
      >
        <Input />
      </Form.Item>
      <Form.Item label="Payment Message" name="fund_payment_message">
        <Input />
      </Form.Item>
      <Form.Item label="Payment Status" name="fund_payment_status">
        <Select placeholder="Select Payment Staus">
          {[
            { value: 'pending', label: 'Pending' },
            { value: 'success', label: 'Success' },
          ].map(option => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OrderForm;
