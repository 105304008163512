import { Card, Descriptions, Tag, Typography } from 'antd';
import {
  orderSideColors,
  orderSideLabels,
  orderStatusColors,
  orderTypeColors,
} from 'apps/investment/constants/stockOrder';
import { formatNumber } from 'utils/formatAmount';
import { formatDate, formatDateTime } from 'utils/formatDate';
const { Title } = Typography;

const ItemDetail = ({ item }) => {
  return (
    <>
      <Card title={<Title level={3}>Stock Order Detail</Title>}>
        <Descriptions column={1} bordered>
          <Descriptions.Item label="UUID">{item.id}</Descriptions.Item>
          <Descriptions.Item label="Symbol">
            {item.stock.symbol}
          </Descriptions.Item>
          <Descriptions.Item label="Name">{item.stock.name}</Descriptions.Item>
          <Descriptions.Item label="Status">
            <Tag color={orderStatusColors[item.status]}>
              {item.status_label}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Order Type">
            <Tag color={orderTypeColors[item.order_type]}>
              {item.order_type.toUpperCase()}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Order Side">
            <Tag color={orderSideColors[item.order_side]}>
              {orderSideLabels[item.order_side]}
            </Tag>
          </Descriptions.Item>

          <Descriptions.Item label="Thời gian đặt lệnh">
            {formatDateTime(item.stock_order_time)}
          </Descriptions.Item>

          <Descriptions.Item label="Price">
            {formatNumber(item.price)}
          </Descriptions.Item>
          <Descriptions.Item label="Volume">
            {formatNumber(item.volume)}
          </Descriptions.Item>
          <Descriptions.Item label="Value">
            {formatNumber(item.value)}
          </Descriptions.Item>
          <Descriptions.Item label="Matched Price">
            {formatNumber(item.matched_price)}
          </Descriptions.Item>
          <Descriptions.Item label="Matched Volume">
            {formatNumber(item.matched_volume)}
          </Descriptions.Item>
          <Descriptions.Item label="Outstanding Volume">
            {formatNumber(item.outstanding_volume)}
          </Descriptions.Item>

          <Descriptions.Item label="Fee">
            {formatNumber(item.fee)}
          </Descriptions.Item>
          <Descriptions.Item label="Tax">
            {formatNumber(item.tax)}
          </Descriptions.Item>

          <Descriptions.Item label="Trade date">
            {formatDate(item.trade_date)}
          </Descriptions.Item>
          <Descriptions.Item label="Settle date">
            {formatDate(item.settle_date)}
          </Descriptions.Item>

          <Descriptions.Item label="Created At">
            {formatDateTime(item.created_at)}
          </Descriptions.Item>
          <Descriptions.Item label="Updated At">
            {formatDateTime(item.updated_at)}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
};

export default ItemDetail;
