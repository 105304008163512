import { Button, Tag } from 'antd';
import apiCall from 'apps/market/apiCalls/industry';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { Link } from 'react-router-dom';
import { statusColors, statusLabel } from 'utils/constants';
import { formatISODate } from 'utils/formatDate';
import { cloudflareCdn } from 'utils/image';
import FilterForm from './FilterForm';
import ItemForm from './Form';

const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Photo',
    dataIndex: 'photo',
    render: value =>
      value && <img width={100} src={cloudflareCdn(value, 100)} />,
  },
  {
    title: 'Industry',
    dataIndex: 'name',
  },
  {
    title: 'ICB Code',
    dataIndex: 'icb_code',
  },
  {
    title: 'Level',
    dataIndex: 'level',
  },
  {
    title: 'Created',
    dataIndex: 'created',
    render: value => formatISODate(value),
  },
  {
    title: 'Modified',
    dataIndex: 'modified',
    render: value => formatISODate(value),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => {
      return <Tag color={statusColors[value]}>{statusLabel[value]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/market/industries/${record.id}/edit`}>Edit</Link>
        </Button>
      </Button.Group>
    ),
  },
];

// Pages

export const IndustryListPage = (props, { params }) => (
  <ListPage
    title="All Industries"
    apiCall={apiCall}
    params={params}
    createUrl={`/market/industries/create`}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="items"
    FilterForm={FilterForm}
    {...props}
  />
);

export const IndustryEditPage = props => (
  <EditPage
    title="Industry"
    apiCall={apiCall}
    listUrl={`/market/industries`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);

export const IndustryCreatePage = props => (
  <CreatePage
    title="Industry"
    apiCall={apiCall}
    listUrl={`/market/industries`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);
