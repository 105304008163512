import detailApiCall from './detail';
import editApiCall from './edit';
import createApiCall from './create';
import listApiCall from './list';
import deleteApiCall from './delete';

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  create: createApiCall,
  edit: editApiCall,
  delete: deleteApiCall,
};

export default ApiCall;
