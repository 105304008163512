import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

function UIIconTooltip({ title, Icon, size = '1.5rem' }) {
  return (
    <Tooltip title={title} color="#0A1A41">
      {Icon ? (
        <Icon style={{ fontSize: size }} />
      ) : (
        <InfoCircleOutlined style={{ fontSize: size }} />
      )}
    </Tooltip>
  );
}

export default UIIconTooltip;
