import cancelApiCall from './cancel';
import createBuyApiCall from './createBuy';
import createSellApiCall from './createSell';
import detailApiCall from './detail';
import editApiCall from './edit';
import generateApiCall from './generate';
import listApiCall from './list';
import rebundleApiCall from './rebundle';

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  edit: editApiCall,
  createBuy: createBuyApiCall,
  createSell: createSellApiCall,
  create: createBuyApiCall,
  delete: editApiCall,
  generate: generateApiCall,
  cancel: cancelApiCall,
  rebundle: rebundleApiCall,
};

export default ApiCall;
