import { Button, Tag } from 'antd';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useFetchApiList, usePostApi } from '@stagapps/redux-utils';
import MetaDecorator from 'apps/home/components/MetaDecorator';
import PageTitle from 'apps/home/components/PageTitle';
import DataTable from 'common/ui/DataTable';
import { activeColors, activeLabels } from 'utils/constants';
import { formatISODate } from 'utils/formatDate';

import notificationListApiCall from 'apps/education/apiCalls/notification/list';
import sendNotificationApiCall from 'apps/education/apiCalls/notification/send';

const columnsGenerator = sendNotification => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    render: value => formatISODate(value),
  },
  {
    title: 'Template',
    dataIndex: 'template',
  },
  {
    title: 'Is Active?',
    dataIndex: 'is_active',
    render: value => {
      const status = value === true ? 'active' : 'inactive';
      return <Tag color={activeColors[status]}>{activeLabels[status]}</Tag>;
    },
  },
  {
    title: 'Notification badges?',
    dataIndex: 'is_firebase_push_notification',
    render: value => {
      const status = value === true ? 'active' : 'inactive';
      return <Tag color={activeColors[status]}>{activeLabels[status]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/edu/notifications/${record.id}/edit`}>Edit</Link>
        </Button>
        {sendNotification && record == 'announcement' ? (
          <Button
            onClick={() => {
              if (confirm('Are you sure?')) {
                sendNotification({ id: record.id });
              }
            }}
            type="primary"
            style={{
              visibility: true,
              marginLeft: 20,
              backgroundColor: '#1890ff',
              borderColor: '#1890ff',
            }}
          >
            Send Notification
          </Button>
        ) : null}
      </Button.Group>
    ),
  },
];

const Notifications = () => {
  const title = 'All Notifications';
  const {
    data: items,
    load: fetchItems,
    isLoading,
    paging,
  } = useFetchApiList(notificationListApiCall, { resourceName: 'items' });

  const {
    post: sendNotification,
    isSubmitting: isSendind,
    error: sendError,
  } = usePostApi(sendNotificationApiCall, {
    resourceName: 'item',
  });

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <>
      <MetaDecorator title={title} />
      <PageTitle
        title={title}
        extra={[
          <Button key="1" type="primary">
            <Link to={`/edu/notifications/create`}>Create</Link>
          </Button>,
        ]}
      />

      <DataTable
        rowKey="id"
        columns={columnsGenerator(sendNotification)}
        dataSource={items}
        totalItems={paging.count}
        currentPage={paging.page}
        pageSize={paging.page_size}
        dataFetcher={fetchItems}
        loading={isLoading}
      />
    </>
  );
};

export default Notifications;
