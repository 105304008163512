import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const MetaDecorator = ({ title, description = '' }) => {
  return (
    <Helmet>
      <title>{`${title} | STAG ACP `}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

MetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
};

export default MetaDecorator;
