import { useFetchApiGet, usePostApi } from '@stagapps/redux-utils';
import { Button, Card } from 'antd';
import PageTitle from 'apps/home/components/PageTitle';
import ErrorMessage from 'common/ui/ErrorMessage';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

const AdminDetail = ({
  title,
  listUrl,
  apiCall,
  options = { title: 'View detail', deleteEnabled: false, editEnabled: true },
  ItemDetail,
  resourceName,
}) => {
  const params = useParams();
  const { title: actionTitle, deleteEnabled, editEnabled } = options;
  const {
    data: item,
    load: fetchItem,
    isLoading,
    error: fetchItemError,
  } = useFetchApiGet(apiCall.detail, { resourceName: resourceName || 'item' });

  const {
    post: deleteItem,
    isLoading: isDeleting,
    error: deleteItemError,
  } = usePostApi(apiCall.delete);

  useEffect(() => {
    fetchItem({ id: params.id });
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (fetchItemError) {
    return <ErrorMessage {...fetchItemError} />;
  }

  return (
    <>
      <PageTitle
        title={`View ${title} detail` || actionTitle}
        backUrl={listUrl}
      />
      <Card
        extra={
          editEnabled && (
            <Button type="primary">
              <Link className="" to={`${listUrl}/${params.id}/edit`}>
                Edit
              </Link>
            </Button>
          )
        }
      >
        <ItemDetail item={item} apiCall={apiCall} />
      </Card>
    </>
  );
};

export default AdminDetail;
