import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_INVESTCORE_USER_LIST_API_ID =
  'DASHBOARD/FETCH_INVESTCORE_USER_LIST_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_INVESTCORE_USER_LIST_API_ID,
  params => ({
    endpoint: generateEndpoint({
      host: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/users`,
      params,
    }),
    method: 'GET',
  })
);

export default apiCall;
