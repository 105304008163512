export { default as analyticsApiCall } from './analytics';
export { default as createApiCall } from './create';
export { default as deleteApiCall } from './delete';
export { default as detailApiCall } from './detail';
export { default as editApiCall } from './edit';
export { default as historyApiCall } from './history';
export { default as listApiCall } from './list';

import analyticsApiCall from './analytics';
import createApiCall from './create';
import deleteApiCall from './delete';
import detailApiCall from './detail';
import editApiCall from './edit';
import historyApiCall from './history';
import listApiCall from './list';

const ApiCall = {
  analytics: analyticsApiCall,
  list: listApiCall,
  detail: detailApiCall,
  create: createApiCall,
  edit: editApiCall,
  delete: deleteApiCall,
  history: historyApiCall,
};

export default ApiCall;
