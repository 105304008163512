import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Space, Tag, Tooltip } from 'antd';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useFetchApiGet, useFetchApiList } from '@stagapps/redux-utils';
import MetaDecorator from 'apps/home/components/MetaDecorator';
import PageTitle from 'apps/home/components/PageTitle';
import apiCall from 'apps/investment/apiCalls/company';
import employeeApiCall from 'apps/investment/apiCalls/employee';
import DataTable from 'common/ui/DataTable';
import { formatISODate } from 'utils/formatDate';

const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'code',
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    type: 'date',
    render: value => formatISODate(value),
  },
  {
    title: 'Full Name',
    dataIndex: 'full_name',
  },
  {
    title: 'Department',
    dataIndex: 'department',
  },
  {
    title: 'Position',
    dataIndex: 'role',
  },
  {
    title: 'Work Email',
    dataIndex: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
  },
  {
    title: 'Date of Birth',
    dataIndex: 'date_of_birth',
  },
  {
    title: 'ID Number',
    dataIndex: 'id_number',
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
  },
  {
    title: 'Stag Account',
    render: (text, record) =>
      record && record.user ? (
        <Link to={`/investment/users/${record.user.id}`}>
          {record.user.name ? record.user.name : record.user.email}{' '}
          {record.user.ekyc_verified ? (
            <Tooltip title="Stag eKYC Verified">
              <CheckCircleOutlined
                style={{ color: 'green', fontSize: '20px' }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Not verified">
              <InfoCircleOutlined
                style={{ color: '#b8860b', fontSize: '20px' }}
              />
            </Tooltip>
          )}
        </Link>
      ) : (
        <Tag color="grey">Not onboard yet</Tag>
      ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => {
      const params = useParams();

      return (
        <Button.Group>
          <Space>
            <Button shape="round">
              <Link to={`/investment/employees/${record.id}/edit`}>Edit</Link>
            </Button>
            <Button shape="round">
              <Link to={`/investment/employees/${record.id}`}>Detail</Link>
            </Button>
          </Space>
        </Button.Group>
      );
    },
  },
];

const InvestmentCompanyEmployees = () => {
  const params = useParams();

  const {
    data: company,
    load: fetchCompany,
    isLoading: isLoadingCompany,
  } = useFetchApiGet(apiCall.detail, { resourceName: 'data' });

  const {
    data: items,
    load: fetchItems,
    isLoading: isLoadingEmployees,
    paging,
  } = useFetchApiList(employeeApiCall.list, { resourceName: 'data' });

  useEffect(() => {
    fetchCompany({ id: params.company_id });
    fetchItems({ company_id: params.company_id });
  }, []);

  if (isLoadingCompany || isLoadingEmployees) {
    return <div>Loading...</div>;
  }

  var title = `All ${company && company.brand_name} Employees`;

  return (
    <>
      <MetaDecorator title={title} />
      <PageTitle title={title} />
      <DataTable
        rowKey="id"
        columns={columnsGenerator(params)}
        dataSource={items}
        totalItems={paging.count}
        currentPage={paging.page}
        pageSize={paging.page_size}
        dataFetcher={fetchItems}
        loading={isLoadingEmployees}
      />
    </>
  );
};

export default InvestmentCompanyEmployees;
