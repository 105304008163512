import { OAUTH2_GET_TOKEN } from 'apps/home/apiCalls/auth';
import { OAUTH2_REFRESH_TOKEN } from 'apps/home/apiCalls/refresh';
import { eq, flow, get } from 'lodash/fp';
import { ACTIONS } from 'redux-api-call';
import { put, takeEvery } from 'redux-saga/effects';
import { history } from 'routerHistory';
import { setAdminAuthTokenAC } from 'state/adminSession/state';
import {
  deleteToken,
  getRefreshToken,
  parseOAuth,
  setAccessToken,
  setRefreshToken,
  setUser,
} from 'utils/auth';

function* onAuthSuccess(action) {
  try {
    if (flow(get('payload.name'), eq(OAUTH2_GET_TOKEN))(action)) {
      const payload = get('payload.json')(action);
      const { user, token, refreshToken, expiresIn } = parseOAuth(payload);

      setAccessToken(token);
      setUser(user);
      setRefreshToken(refreshToken, expiresIn);

      history.push(`/`);
    }
  } catch (error) {
    console.error('error', error);
  }
}

function* onRefreshSuccess(action) {
  try {
    if (flow(get('payload.name'), eq(OAUTH2_REFRESH_TOKEN))(action)) {
      const payload = get('payload.json')(action);
      const { user, token } = parseOAuth(payload);
      setAccessToken(token);
      setUser(user);

      if (user && token) {
        const refreshToken = getRefreshToken();
        yield put(
          setAdminAuthTokenAC({
            user,
            accessToken: { token: token, expired: user?.exp },
            refreshToken: { token: refreshToken.token, expiresIn: 0 },
          })
        );
      }
    }
  } catch (error) {
    console.error('error', error);
  }
}

function* onRefreshFailed(action) {
  try {
    if (
      flow(get('payload.name'), eq(OAUTH2_REFRESH_TOKEN))(action) &&
      flow(get('payload.statusCode'), eq(401))(action)
    ) {
      const payload = get('payload.json')(action);
      console.log('onRefreshFailed', payload);

      deleteToken();
      history.push(`/login?url=${location.pathname}`);
    }
  } catch (error) {
    console.error('error', error);
  }
}

function* watchonAuth() {
  yield takeEvery(ACTIONS.COMPLETE, onAuthSuccess);
  yield takeEvery(ACTIONS.COMPLETE, onRefreshSuccess);
  yield takeEvery(ACTIONS.FAILURE, onRefreshFailed);
}

export default function* authSaga() {
  yield watchonAuth();
}
