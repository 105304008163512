import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';

const DELETE_INVESTMENT_PLANS_API_ID =
  'INVESTCORE/DELETE_INVESTMENT_PLANSL_API';

const apiCall = enhancedMakeFetchAction(
  DELETE_INVESTMENT_PLANS_API_ID,
  ({ id, version, payload }) => ({
    endpoint: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/employee-programs/${id}/investment-plans/${version}`,
    method: 'DELETE',
    notify: true,
    isReturn: false,
  })
);

export default apiCall;
