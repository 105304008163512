import { Tag } from 'antd';
import apiCall from 'apps/education/apiCalls/gameOrders';
import {
  orderStatusColors,
  orderStatusLabels,
  orderTypeColors,
  orderTypeLabels,
} from 'apps/education/constants/gameOrder';
import { ListPage } from 'common/ui/AdminCRUD/page';
import { formatDateTime } from 'utils/formatDate';
// import FilterForm from './FilterForm';
const columnsGenerator = params => [
  {
    title: 'Order Id',
    dataIndex: 'id',
  },
  {
    title: 'Order Type',
    dataIndex: 'order_type',
    align: 'center',
    render: value => {
      return <Tag color={orderTypeColors[value]}>{orderTypeLabels[value]}</Tag>;
    },
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    align: 'center',
  },
  {
    title: 'Order Volume',
    dataIndex: 'volume',
    render: value => new Intl.NumberFormat('en-US').format(value),
    align: 'right',
  },
  {
    title: 'Order Price',
    dataIndex: 'price',
    render: value => new Intl.NumberFormat('en-US').format(value),
    align: 'right',
  },
  {
    title: 'Matched Price',
    dataIndex: 'matched_price',
    render: value => new Intl.NumberFormat('en-US').format(value),
    align: 'right',
  },
  {
    title: 'Matched Volume',
    dataIndex: 'matched_volume',
    align: 'right',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: value => {
      return (
        <Tag color={orderStatusColors[value]}>{orderStatusLabels[value]}</Tag>
      );
    },
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Matched At',
    dataIndex: 'matched_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'User',
    dataIndex: 'user',
    render: obj => `${obj.name} (${obj.email})`,
  },
];

export const GameOrdersListPage = props => {
  return (
    <ListPage
      enableSearch={true}
      searchOptions={{
        placeHolder: "Enter user's name, email, userID or user fullname",
      }}
      title="Game Orders"
      apiCall={apiCall}
      columnGenerator={props => columnsGenerator(props)}
      resourceName="items"
      // FilterForm={FilterForm}
      {...props}
    />
  );
};
