export const productTypeOptions = [
    { value: 'stock', label: 'Stock' },
    { value: 'etf', label: 'ETF' },
    { value: 'mutual_fund', label: 'Mutual Fund' },
    { value: 'crypto', label: 'Crypto' },
    { value: 'bank', label: 'Bank' },
];

export const sortOptions = [
    { value: 'asc', label: 'ASC' },
    { value: 'desc', label: 'DESC' },
];

export const contentTypeOptions = [
    { value: 'desktop', label: 'Desktop' },
    { value: 'app', label: 'App' },
];