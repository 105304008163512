import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, Select } from 'antd';
import { useEffect, useState } from 'react';

import { useFetchApiList } from '@stagapps/redux-utils';
import apiProductsCall from 'apps/investment/apiCalls/product/list';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const { Option } = Select;

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  const [selectedValues, setSelectedValues] = useState([]);

  const handleChange = (value, selectIndex) => {
    const updatedValues = [...selectedValues];
    updatedValues[selectIndex] = value;
    setSelectedValues(updatedValues);
  };

  const {
    data: products = [],
    load: loadProducts,
    isLoading,
  } = useFetchApiList(apiProductsCall, { resourceName: 'data' });

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    loadProducts();
  }, []);

  const { investment_details = [] } = Form.useWatch([], form) || {};

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={onFinish}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.List name="investment_details">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <div key={key} className="flex flex-row items-center">
                <Form.Item
                  label="Product"
                  {...restField}
                  name={[name, 'product_id']}
                  labelCol={{ span: 24 }}
                >
                  <Select
                    style={{ width: '200px' }}
                    placeholder="Chọn quỹ"
                    onChange={value => handleChange(value, index)}
                  >
                    {products.map(innerProduct => (
                      <Option
                        key={innerProduct.id}
                        value={innerProduct.id}
                        disabled={
                          selectedValues.includes(innerProduct.id) &&
                          innerProduct.id !== selectedValues[index]
                        }
                      >
                        {innerProduct.code}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="Percentage"
                  labelCol={{ span: 24 }}
                  name={[name, 'percentage']}
                  rules={[
                    {
                      required: true,
                      type: 'number',
                      message: 'Wrong format!',
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '200px' }}
                    placeholder="Tỉ lệ từ 1-100%"
                    min={1}
                    max={100}
                    step={10}
                    addonAfter={'%'}
                    precision={0}
                  />
                </Form.Item>

                <MinusCircleOutlined
                  style={{
                    fontSize: '16px',
                    color: 'red',
                    height: 'fit-content',
                  }}
                  onClick={() => remove(name)}
                />
              </div>
            ))}

            {investment_details.length === products.length ? null : (
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add product
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
