import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

export const FETCH_CHALLENGE_EDIT_API_ID = 'DASHBOARD/FETCH_CHALLENGE_EDIT_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_CHALLENGE_EDIT_API_ID,
  ({ id, payload, ref }) => ({
    endpoint: `${API_BASE_URL}/stagedu-api/admin/v1/challenges/${id}`,
    method: 'PUT',
    body: payload,
    notify: true,
    ref,
  })
);

export default apiCall;
