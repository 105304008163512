import { usePostApi } from '@stagapps/redux-utils';
import { Button, Spin } from 'antd';
import apiCall from 'apps/investment/apiCalls/userCampaign';
import { userCampaignStatusPending } from 'apps/investment/constants/userCampaign';
import { useEffect, useState } from 'react';

export const UserCampaignAction = ({ text, record }) => {
  const [userCampaignID, setUserCampaignID] = useState(record.id);

  const {
    data: approveUserCampaignData,
    post: approveUserCampaign,
    isLoading: isApproveUserCampaign,
    error: approveUserCampaignError,
  } = usePostApi(apiCall.approve, { resourceName: 'data' });

  const {
    data: rejectUserCampaignData,
    post: rejectUserCampaign,
    isLoading: isRejectUserCampaign,
    error: rejectUserCampaignError,
  } = usePostApi(apiCall.reject, { resourceName: 'data' });

  useEffect(() => {
    setUserCampaignID(record.id);
  }, [record.id]);

  const isLoading = isApproveUserCampaign || isRejectUserCampaign;

  if (isLoading) {
    return <Spin />;
  }

  if (record.status == userCampaignStatusPending) {
    const isDisableApprove =
      isApproveUserCampaign || !record.is_fund_account_approved;

    return (
      <Button.Group>
        <Button
          onClick={() =>
            confirm(
              `Are you sure you want to approve the campaign request for user ${record.user?.email}?`
            ) && approveUserCampaign({ id: record.id })
          }
          disabled={isDisableApprove}
          type="primary"
          style={
            isDisableApprove
              ? {
                  marginLeft: '10px',
                  backgroundColor: '#ccc',
                  borderColor: '#ccc',
                }
              : {
                  marginLeft: '10px',
                  backgroundColor: 'green',
                  borderColor: 'green',
                }
          }
        >
          Approve
        </Button>
        <Button
          onClick={() =>
            confirm(
              `Are you sure you want to reject the campaign request for user ${record.user?.email}?`
            ) && rejectUserCampaign({ id: record.id })
          }
          disabled={isRejectUserCampaign}
          type="primary"
          style={{
            marginLeft: '10px',
            backgroundColor: 'red',
            borderColor: 'red',
          }}
        >
          Reject
        </Button>
      </Button.Group>
    );
  }

  return '-';
};
