import { Menu, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { history } from 'routerHistory';
import { deleteToken } from 'utils/auth';

const UserMenu = ({ user }) => {
  return (
    <Menu
      selectedKeys={['account']}
      disabledOverflow={true}
      mode="horizontal"
      items={[
        {
          label: `My Account (${user.email})`,
          key: 'account',
          children: [
            {
              key: 'logout',
              label: 'Logout',
              label: (
                <Link
                  to={''}
                  onClick={() =>
                    Modal.confirm({
                      title: 'Are you sure?',
                      onOk: () => {
                        deleteToken();

                        history.push('/login');
                      },
                    })
                  }
                >
                  Log Out
                </Link>
              ),
            },
          ],
        },
      ]}
    />
  );
};

export default UserMenu;
