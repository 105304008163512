import { Button } from 'antd';
import { Link } from 'react-router-dom';

import apiCall from 'apps/investment/apiCalls/portfolio';
import { DetailPage, ListPage } from 'common/ui/AdminCRUD/page';

import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const PortfolioListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    title="All Customer's Portfolios"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="data"
    {...props}
  />
);

export const PortfolioDetailPage = props => (
  <DetailPage
    title="Portfolio Summary"
    apiCall={apiCall}
    listUrl={`/investment/portfolios`}
    ItemDetail={ItemDetail}
    options={{ editEnabled: false }}
    extraActions={id => (
      <>
        <Button type="primary">
          <Link to={`/investment/portfolios/${id}`}>Detail</Link>
        </Button>
      </>
    )}
    resourceName="data"
    {...props}
  />
);
