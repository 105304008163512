import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Image,
  Select,
  Upload,
} from 'antd';
import {
  identificationImageTypeOptions,
} from 'apps/investment/constants/identification';
import { useEffect } from 'react';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ItemForm = ({
  form,
  submiting,
  onFinish,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'identification_image_form'}
      onFinish={values =>
        onFinish(values, ['file'])
      }
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item
        name="file"
        label="File"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item name="image_type" label="Loại">
        <Select>
          {identificationImageTypeOptions &&
            identificationImageTypeOptions.map(option => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
