import { useEffect, useState } from 'react';
import slugify from 'slugify';

import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Image,
  Input,
  InputNumber,
  Space,
  Switch,
  Upload,
} from 'antd';
import imageUploadCall from 'apps/education/apiCalls/editorImage/upload';
import TextEditor from 'common/form/TextEditor';
import { DRAFT_STATUS, PUBLISH_STATUS } from 'utils/constants';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  params,
  onDelete,
  visibility = 'hidden',
}) => {
  const { photo, status } = initialValues;
  const onBlur = () => {
    const inputValue = form.getFieldsValue();
    !inputValue.slug &&
      form.setFieldsValue({ slug: slugify(inputValue.title).toLowerCase() });
  };

  const [isRemoved, setIsRemoved] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const removePhoto = () => {
    setIsRemoved(true);
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  var draftButtonLabel = 'Save as draft';
  var publishButtonLabel = 'Save & Publish';

  if (status == PUBLISH_STATUS) {
    publishButtonLabel = 'Save';
    draftButtonLabel = 'Switch to draft';
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={values =>
        onFinish(
          {
            ...values,
            ...params,
            status: isDraft ? DRAFT_STATUS : PUBLISH_STATUS,
          },
          ['photo']
        )
      }
      initialValues={{ ...initialValues, access: 1001 }}
      scrollToFirstError
      onBlur={onBlur}
    >
      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Mã chứng khoán là gì?" />
      </Form.Item>

      <Form.Item
        name="slug"
        label="URL Slug"
        tooltip="URL Slug"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input value={form.fieldValue} placeholder="ma-chung-khoan-la-gi" />
      </Form.Item>

      <Form.Item name="description" label="Description">
        <TextEditor uploadCallback={imageUploadCall} />
      </Form.Item>

      <Form.Item name="excerpt" label="Excerpt">
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item
        name="photo"
        label="Upload a new photo"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      {isRemoved || !photo ? null : (
        <Form.Item
          label="Existing photo"
          style={{
            visibility,
          }}
        >
          <Button
            type="primary"
            shape="circle"
            size="small"
            style={{
              backgroundColor: 'red',
              position: 'absolute',
              zIndex: 99,
              left: 5,
              top: 5,
            }}
            onClick={() => removePhoto()}
          >
            X
          </Button>
          <Image width={200} src={photo}></Image>
        </Form.Item>
      )}

      <Form.Item
        name="duration"
        label="Duration (minutes)"
        tooltip="Chapter Duration in minutes"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
          },
        ]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="ranking"
        label="Chapter Number"
        tooltip="Smallest number will be on top: 1,2,3.."
        rules={[
          {
            required: true,
            message: 'Please input the required field',
          },
        ]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="value"
        label="Reward (VND)"
        tooltip="Earned Money User will get when complete this chapter"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
          },
        ]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item name="is_available" label="Open for access">
        <Switch />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Space>
          <Button htmlType="submit" type="primary" loading={submiting}>
            {publishButtonLabel}
          </Button>

          <Button
            onClick={() => setIsDraft(true)}
            danger
            htmlType="submit"
            loading={submiting}
          >
            {draftButtonLabel}
          </Button>
          {onDelete ? (
            <Button onClick={onDelete} danger>
              Move to trash
            </Button>
          ) : null}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
