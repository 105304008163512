import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_QUESTION_TYPE_FORM_LIST_API_ID =
  'HUB/FETCH_QUESTION_TYPE_FORM_LIST_API_ID';

const apiCall = enhancedMakeFetchAction(
  FETCH_QUESTION_TYPE_FORM_LIST_API_ID,
  params => ({
    endpoint: generateEndpoint({
      host: `${API_BASE_URL}/investcore-api/admin/edu/lesson-question-type-forms`,
      params,
    }),
    method: 'GET',
  })
);

export default apiCall;
