import apiCall from 'apps/investment/apiCalls/notificationLog';
import { notificationLogColumnsGenerator } from 'apps/investment/components/NotificationLog/config';
import { DetailPage, ListPage } from 'common/ui/AdminCRUD/page';
import FilterForm from './FilterForm';
import ItemDetail from './detail';

export const InvestmentNotificationLogListPage = (props, { params }) => (
  <ListPage
    title="All Notification Logs"
    apiCall={apiCall}
    enableSearch={true}
    FilterForm={FilterForm}
    params={params}
    columnGenerator={props => notificationLogColumnsGenerator(props)}
    resourceName="data"
    size="small"
    {...props}
  />
);

export const InvestmentNotificationLogDetailPage = props => (
  <DetailPage
    title="Detail Notification Log"
    apiCall={apiCall}
    listUrl={`/investment/notification-logs`}
    ItemDetail={ItemDetail}
    resourceName="data"
    {...props}
  />
);
