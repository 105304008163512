import apiCall from 'apps/investment/apiCalls/stockOrder';
import { DetailPage, ListPage } from 'common/ui/AdminCRUD/page';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const InvestmentStockOrderListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    title="All Stock Orders"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="data"
    {...props}
  />
);

export const InvestmentStockOrderDetailPage = props => (
  <DetailPage
    title="Stock Order Detail"
    apiCall={apiCall}
    listUrl={`/investment/stock-orders`}
    ItemDetail={ItemDetail}
    resourceName="data"
    {...props}
  />
);
