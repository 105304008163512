import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

export const FETCH_CRYPTO_DELETE_API_ID = 'DASHBOARD/FETCH_CRYPTO_DELETE_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_CRYPTO_DELETE_API_ID,
  ({ id }) => ({
    endpoint: `${API_BASE_URL}/marketdb-api/admin/v1/cryptos/${id}`,
    method: 'DELETE',
    notify: true,
  })
);

export default apiCall;
