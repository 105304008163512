import employeeProgramsApiCall from '../employeeProgram/list';
import createApiCall from './create';
import detailApiCall from './detail';
import editApiCall from './edit';
import listApiCall from './list';
import requiredFundAccountsApiCall from './requiredFundAccounts';
import transactionsApiCall from './transactions';

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  create: createApiCall,
  edit: editApiCall,
  delete: editApiCall,
  employeePrograms: employeeProgramsApiCall,
  transactions: transactionsApiCall,
  requiredFundAccounts: requiredFundAccountsApiCall,
};

export default ApiCall;
