import { Button, Tag } from 'antd';
import { Link } from 'react-router-dom';
import {
  accessColors,
  accessLabel,
  statusColors,
  statusLabel,
} from 'utils/constants';

export const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
  },
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Photo',
    dataIndex: 'photo',
    render: value => value && <img width={100} src={value} />,
  },
  {
    title: 'Access Level',
    dataIndex: 'access',
    render: value => {
      return <Tag color={accessColors[value]}>{accessLabel[value]}</Tag>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: value => {
      return <Tag color={statusColors[value]}>{statusLabel[value]}</Tag>;
    },
  },
  {
    title: 'User Joined',
    dataIndex: 'user_count',
    align: 'center',
  },
  {
    title: 'Ordering',
    dataIndex: 'ordering',
    align: 'center',
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button type="primary" shape="round">
          <Link to={`/edu/game-rooms/${record.id}/settings`}>Settings</Link>
        </Button>
        <Button shape="round">
          <Link to={`/edu/game-rooms/${record.id}/edit`}>Edit</Link>
        </Button>
        <Button shape="round">
          <Link to={`/edu/game-rooms/${record.id}/portfolio-items`}>
            Portfolio Items
          </Link>
        </Button>
        <Button shape="round">
          <Link to={`/edu/game-rooms/${record.id}/orders`}>Orders</Link>
        </Button>
        <Button shape="round">
          <Link to={`/edu/game-rooms/${record.id}/users`}>Users</Link>
        </Button>
      </Button.Group>
    ),
  },
];
