import { useFetchApiList, usePostApi } from '@stagapps/redux-utils';
import { Button, Form, Select } from 'antd';
import fundProductApiCall from 'apps/investment/apiCalls/product/list';
import usersApiCall from 'apps/investment/apiCalls/user/list';
import apiCall from 'apps/investment/apiCalls/userProgramOrder';
import FormattedNumberInput from 'common/form/FormattedNumberInput';
import DebounceSelect from 'common/ui/DebouceSelect';
import { useEffect } from 'react';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const OrderForm = ({
  form,
  initialValues = {
    order_type: 'buy',
    product_type: 'fund',
  },
  name,
}) => {
  const {
    data: users = [],
    load: fetchUserList,
    isLoading: isLoadingUsers,
  } = useFetchApiList(usersApiCall, { resourceName: 'data' });

  const {
    data: fundProducts = [],
    load: fetchFundProductList,
    isLoading: isProductLoading,
  } = useFetchApiList(fundProductApiCall, { resourceName: 'data' });

  const { post: createBuyOrder, isLoading: isBuySubmitting } = usePostApi(
    apiCall.createBuy,
    {
      resourceName: 'item',
    }
  );
  const { post: createSellOrder, isLoading: isSellSubmitting } = usePostApi(
    apiCall.createSell,
    {
      resourceName: 'item',
    }
  );

  const handleSubmit = values => {
    const {
      order_type,
      user_id,
      product_id,
      source,
      amount,
      quantity,
    } = values;
    if (order_type == 'buy') {
      createBuyOrder({ user_id, product_id, source, amount });
    } else {
      createSellOrder({ user_id, product_id, source, quantity });
    }
  };

  useEffect(() => {
    if (initialValues.user_id) {
      fetchUserList({ id: initialValues.user_id });
    }
  }, [initialValues.user_id]);

  useEffect(() => {
    fetchFundProductList();
  }, []);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'user_program_order_form'}
      onFinish={handleSubmit}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item
        name="user_id"
        label="User Account"
        rules={[
          {
            required: true,
            message: 'Please select the required field',
            whitespace: true,
          },
        ]}
      >
        <DebounceSelect
          placeholder="Select User Account"
          fetchOptions={fetchUserList}
          fetching={isLoadingUsers}
          options={users}
          displayFn={o => {
            return {
              value: o.id,
              label: `${o.name} (${o.email})`,
            };
          }}
        />
      </Form.Item>
      <Form.Item
        name="order_type"
        label="Order Type"
        rules={[
          {
            required: true,
            message: 'Please select the required field',
          },
        ]}
      >
        <Select placeholder="Select Order Type">
          {[
            { value: 'buy', label: 'Buy' },
            { value: 'sell', label: 'Sell' },
          ].map(option => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="source"
        label="Product Type"
        rules={[
          {
            required: true,
            message: 'Please select the required field',
          },
        ]}
      >
        <Select placeholder="Select Product Type">
          {[{ value: 1001, label: 'Fund' }].map(option => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="product_id"
        label="Product"
        rules={[
          {
            required: true,
            message: 'Please select the required field',
          },
        ]}
      >
        <Select>
          {fundProducts.map(option => (
            <Option key={option.id} value={option.id}>
              {option.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="amount" label="Amount">
        <FormattedNumberInput addonAfter={'VND'} />
      </Form.Item>
      <Form.Item name="quantity" label="Quantity">
        <FormattedNumberInput />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button
          type="primary"
          htmlType="submit"
          loading={isBuySubmitting || isSellSubmitting}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OrderForm;
