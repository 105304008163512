import apiCall from 'apps/investment/apiCalls/stockAccount';
import { DetailPage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { useParams } from 'react-router-dom';
import ItemForm from './Form';
import EsignContractForm from './EsignContractForm';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const InvestmentStockAccountListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    title="All Stock Accounts"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="data"
    {...props}
  />
);

export const InvestmentStockAccountDetailPage = props => (
  <DetailPage
    title="Stock Account Detail"
    apiCall={apiCall}
    listUrl={`/investment/stock-accounts`}
    ItemDetail={ItemDetail}
    resourceName="data"
    {...props}
  />
);

export const InvestmentStockAccountEditPage = props => {
  const params = useParams();

  return (
    <EditPage
      title="Stock Account Edit"
      apiCall={apiCall}
      listUrl={`/investment/stock-accounts`}
      ItemForm={ItemForm}
      options={{ title: 'Edit Stock Account' }}
      resourceName="data"
      {...props}
    />
  );
};

export const InvestmentStockAccountEsignContractUpdatePage = props => {
  const params = useParams();

  return (
    <EditPage
      title="Stock Account Esign Contract Update"
      apiCall={{
        detail: apiCall.detailEsign,
        edit: apiCall.updateEsign,
        delete: apiCall.detailEsign,
      }}
      listUrl={`/investment/stock-accounts`}
      ItemForm={EsignContractForm}
      options={{ title: 'Esign Contract Stock Account' }}
      resourceName="data"
      {...props}
    />
  );
};
