import { useFetchApiList } from '@stagapps/redux-utils';
import { Avatar, List, Skeleton } from 'antd';
import PageTitle from 'apps/home/components/PageTitle';
import apiCall from 'apps/market/apiCalls/news';
import { useEffect } from 'react';
import { timeAgo } from 'utils/formatDate';

const MarketPage = () => {
  const { data, load: fetchItems, isLoading, error } = useFetchApiList(
    apiCall.list
  );

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <>
      <PageTitle title="Market Overview" />
      <h2>Latest news</h2>
      <List
        dataSource={data}
        renderItem={item => (
          <a href={item.url} target="_blank">
            <List.Item key={item.uuid}>
              <Skeleton loading={isLoading} avatar active>
                <List.Item.Meta
                  avatar={<Avatar src={item.thumbnail_url} />}
                  title={item.title}
                  description={timeAgo(item.published)}
                />
                {item.publisher_name}
              </Skeleton>
            </List.Item>
          </a>
        )}
      />
    </>
  );
};

export default MarketPage;
