const host = window.location.hostname;
console.log('current host', host);

const API_BASE_URL = host.includes('acp.sandbox')
  ? process.env.REACT_APP_SANDBOX_API_BASE_URL
  : process.env.REACT_APP_API_BASE_URL;
const INVESTCORE_API_BASE_URL = host.includes('acp.sandbox')
  ? process.env.REACT_APP_SANDBOX_API_BASE_URL
  : process.env.REACT_APP_API_BASE_URL;
const IAMCORE_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const MY_SECRET_TOKEN = process.env.REACT_APP_MY_SECRET_TOKEN;
const GOOGLE_OAUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;
const REACT_APP_PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
const ENV_NAME = process.env.REACT_APP_ENV_NAME;
const getUrl = url => `${API_BASE_URL}${url}`;

export {
  API_BASE_URL,
  ENV_NAME,
  GOOGLE_OAUTH_CLIENT_ID,
  IAMCORE_API_BASE_URL,
  INVESTCORE_API_BASE_URL,
  MY_SECRET_TOKEN,
  REACT_APP_PROJECT_ID,
  getUrl,
};
