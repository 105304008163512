export const screenOptions = [
    {'value': 'Home', 'label': 'Homepage'},
    {'value': 'TopicList', 'label': 'Topic List'},
    {'value': 'Courses', 'label': 'Topic Detail'},
    {'value': 'CourseDetail', 'label': 'Course Detail'},

    {'value': 'ChallengeDetail', 'label': 'Challenge Detail'},
    {'value': 'ChallengeGroup', 'label': 'Challenge List'},

    {'value': 'MarketScreen', 'label': 'Market Screen'},
    {'value': 'StockSearchScreen', 'label': 'Search Screen'},

    {'value': 'CommunityScreen', 'label': 'Community List'},
    {'value': 'CommunityDetailScreen', 'label': 'Community Detail'},
    {'value': 'CommunityComments', 'label': 'Community Comment Detail'},

    {'value': 'WatchlistDetail', 'label': 'Watchlist Screen'},
    {'value': 'Leaderboard', 'label': 'Leaderboard Screen'},

    {'value': 'IndustryList', 'label': 'Industry List'},
    {'value': 'IndustryDetail', 'label': 'Industry Detail'},

    {'value': 'GameDetail', 'label': 'Game Detail'},
]

export const tabNameOptions = [
    {'value': '', 'label': '-'},
    {'value': '0', 'label': 'Thị trường'},
    {'value': '1', 'label': 'Danh mục'},
    {'value': '2', 'label': 'Sổ lệnh'},
]
