import { Button, Tag } from 'antd';
import apiCall from 'apps/market/apiCalls/stockDividend';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { Link } from 'react-router-dom';
import { statusColors } from 'utils/constants';
import FilterForm from './FilterForm';
import ItemForm from './Form';

const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'User',
    dataIndex: 'user_email',
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
  },
  {
    title: 'Volume',
    dataIndex: 'volume',
  },
  {
    title: 'Type',
    dataIndex: 'dividend_type',
  },
  {
    title: 'Shares Issued',
    dataIndex: 'shares_issued',
  },
  {
    title: 'Cash Issued',
    dataIndex: 'cash_issued',
  },
  {
    title: 'ratio',
    dataIndex: 'ratio',
  },
  {
    title: 'Cash',
    dataIndex: 'cash',
  },
  {
    title: 'Exright date',
    dataIndex: 'exright_date',
  },
  {
    title: 'Issue Date',
    dataIndex: 'issue_date',
  },
  {
    title: 'Issued',
    dataIndex: 'is_issued',
    render: value => {
      const status = value == 1 ? 1002 : 1001;
      const label = value == 1 ? 'Issued' : 'Not Issue';
      return <Tag color={statusColors[status]}>{label}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/edu/stock-dividends/${record.id}/edit`}>Edit</Link>
        </Button>
      </Button.Group>
    ),
  },
];

// Pages

export const StockDividendListPage = (props, { params }) => (
  <ListPage
    title="All Stock Dividends"
    apiCall={apiCall}
    params={params}
    createUrl={`/market/stock-dividends/create`}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="items"
    FilterForm={FilterForm}
    {...props}
  />
);

export const StockDividendEditPage = props => (
  <EditPage
    title="Stock Dividend"
    apiCall={apiCall}
    listUrl={`/market/stock-dividends`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);

export const StockDividendCreatePage = props => (
  <CreatePage
    title="Stock Dividend"
    apiCall={apiCall}
    listUrl={`/market/stock-dividends`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);
