import { eq, flow, get } from 'lodash/fp';
import { ACTIONS } from 'redux-api-call';
import { takeEvery } from 'redux-saga/effects';
import { history } from 'routerHistory';

function* onPermissionError(action) {
  try {
    if (flow(get('payload.statusCode'), eq(403))(action)) {
      history.push('/permission');
    } else if (flow(get('payload.statusCode'), eq(401))(action)) {
      console.error('token expired');
      history.push(`/`);
    }
  } catch (error) {
    console.error('error', error);
  }
}

function* watchonPermission() {
  yield takeEvery(ACTIONS.FAILURE, onPermissionError);
}

export default function* permissionSaga() {
  yield watchonPermission();
}
