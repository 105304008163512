import { useFetchApiList, usePostApi } from '@stagapps/redux-utils';
import { Space, Tag } from 'antd';
import apiCall from 'apps/market/apiCalls/news';
import { ListPage } from 'common/ui/AdminCRUD/page';
import { useSearchParams } from 'react-router-dom';
import { formatDateTime } from 'utils/formatDate';
import { RelevantAction } from './PostActions';

const columnsGenerator = props => {
  let [searchParams, setSearchParams] = useSearchParams();
  const columns = [
    {
      title: 'Thumbnail',
      dataIndex: 'thumbnail_url',
      render: (thumbnail_url, record) => (
        <a href={record.url} target="_blank">
          <img src={thumbnail_url} width={200} />
        </a>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      render: (title, record) => (
        <>
          <a href={record.url} target="_blank">
            {title}
          </a>
        </>
      ),
    },

    {
      title: 'Publisher',
      dataIndex: 'publisher_name',
    },
    {
      title: 'Published',
      dataIndex: 'published',
      align: 'right',
      render: value => formatDateTime(value),
    },
    {
      title: 'Crawled',
      dataIndex: 'created',
      align: 'right',
      render: value => formatDateTime(value),
    },
    {
      title: 'Revelant?',
      key: 'relevant_action',
      align: 'center',
      render: (text, record) => {
        return (
          <RelevantAction
            text={text}
            record={record}
            markRelevantCallback={props['markRelevant']}
            markIrrelevantCallback={props['markIrrelevant']}
          />
        );
      },
    },
    {
      title: 'Relevant Score',
      align: 'right',
      dataIndex: 'relevant_score',
      render: value => (value ? value.toFixed(4) : '-'),
    },
    // {
    //   title: 'Like?',
    //   key: 'like_action',
    //   render: (text, record) => {
    //     return <RecommendAction
    //       text={text}
    //       record={record}
    //       markRecommendCallback={props['markRecommend']}
    //       markDisrecommendCallback={props['markDisrecommend']} />
    //   },
    // },
  ];

  if (searchParams.get('mode') == 'beast') {
    columns.push(
      {
        title: 'Topics',
        dataIndex: 'topics',
        render: topics =>
          topics ? (
            <Space size={[0, 8]} wrap>
              {topics.map(topic => (
                <Tag color="blue">{topic.replaceAll('_', ' ')}</Tag>
              ))}
            </Space>
          ) : (
            ''
          ),
      },
      {
        title: 'Symbols',
        dataIndex: 'symbols',
        render: symbols =>
          symbols ? (
            <Space size={[0, 8]} wrap>
              {symbols.map(symbol => (
                <Tag color="blue">{symbol}</Tag>
              ))}
            </Space>
          ) : (
            ''
          ),
      }
    );
  }

  return columns;
};

export const NewsListPage = (props, { params }) => {
  const listProps = {
    markRelevant: usePostApi(apiCall.relevant),
    markIrrelevant: usePostApi(apiCall.irrelevant),
    markRecommend: usePostApi(apiCall.recommend),
    markDisrecommend: usePostApi(apiCall.disrecommend),
    getNewsList: useFetchApiList(apiCall.list, { resourceName: 'items' }),
  };

  return (
    <ListPage
      title="All News"
      apiCall={apiCall}
      params={params}
      listProps={listProps}
      createUrl=""
      columnGenerator={props => columnsGenerator(props)}
      resourceName="items"
      {...props}
    />
  );
};
