import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, Select } from 'antd';
import { useEffect, useState } from 'react';

import { useFetchApiList } from '@stagapps/redux-utils';
import apiProductsCall from 'apps/investment/apiCalls/product/list';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { formatNumber } from 'utils/formatAmount';

const { Option } = Select;

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
  params,
}) => {
  const employeeContributeAmount = initialValues.employeeContributeAmount;
  const companyContributeAmount = initialValues.companyContributeAmount;

  const [employeeSelectedValues, setEmployeeSelectedValues] = useState([]);
  const [companySelectedValues, setCompanySelectedValues] = useState([]);
  const [employeeRemainContributeAmount, setEmployeeRemainContributeAmount] = useState(employeeContributeAmount);
  const [companyRemainContributeAmount, setCompanyRemainContributeAmount] = useState(companyContributeAmount);

  const { employee_investment_details = [] } = Form.useWatch([], form) || {};
  const { company_investment_details = [] } = Form.useWatch([], form) || {};

  const minimumAmount = 1000000;

  const handleEmployeeChange = (value, selectIndex) => {
    const updatedValues = [...employeeSelectedValues];
    updatedValues[selectIndex] = value;
    setEmployeeSelectedValues(updatedValues);
  };

  const handleCompanyChange = (value, selectIndex) => {
    const updatedValues = [...companySelectedValues];
    updatedValues[selectIndex] = value;
    setCompanySelectedValues(updatedValues);
  };

  const {
    data: products = [],
    load: loadProducts,
    isLoading,
  } = useFetchApiList(apiProductsCall, { resourceName: 'data' });

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    loadProducts();
  }, []);

  useEffect(() => {
    const contributedAmountInput = employee_investment_details.reduce((accumulator, currentItem) => {
      if (currentItem && currentItem.amount) {
        return accumulator + currentItem.amount;
      }

      return accumulator;
    }, 0);
    let remainAmount = employeeContributeAmount - contributedAmountInput;
    setEmployeeRemainContributeAmount(remainAmount);
  }, [employee_investment_details]);

  useEffect(() => {
    const contributedAmountInput = company_investment_details.reduce((accumulator, currentItem) => {
      if (currentItem && currentItem.amount) {
        return accumulator + currentItem.amount;
      }

      return accumulator;
    }, 0);
    let remainAmount = companyContributeAmount - contributedAmountInput;
    setCompanyRemainContributeAmount(remainAmount);
  }, [company_investment_details]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={onFinish}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item label="Employee Plan">
        <Form.List name="employee_investment_details">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <div key={key} className="flex flex-row items-center">
                  <Form.Item
                    label="Product"
                    {...restField}
                    name={[name, 'product_id']}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      style={{ width: '200px' }}
                      placeholder="Chọn quỹ"
                      onChange={value => handleEmployeeChange(value, index)}
                    >
                      {products.map(innerProduct => (
                        <Option
                          key={innerProduct.id}
                          value={innerProduct.id}
                          disabled={
                            employeeSelectedValues.includes(innerProduct.id) &&
                            innerProduct.id !== employeeSelectedValues[index]
                          }
                        >
                          {innerProduct.code}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    label="Amount"
                    labelCol={{ span: 24 }}
                    name={[name, 'amount']}
                    rules={[
                      {
                        required: true,
                        type: 'number',
                        message: 'Vui lòng nhập số tiền',
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '400px' }}
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      placeholder={
                        employeeRemainContributeAmount > minimumAmount ? (
                          `Nhập từ ${formatNumber(minimumAmount)} vnđ đến ${formatNumber(employeeRemainContributeAmount)} vnđ`)
                        : `Nhập tối thiếu ${formatNumber(minimumAmount)} vnđ`
                      }
                      min={1000000}
                      step={1}
                      addonAfter={'vnđ'}
                      precision={0}
                    />
                  </Form.Item>

                  <MinusCircleOutlined
                    style={{
                      fontSize: '16px',
                      color: 'red',
                      height: 'fit-content',
                    }}
                    onClick={() => remove(name)}
                  />
                </div>
              ))}

              {employee_investment_details.length === products.length || employeeContributeAmount == 0 ? null : (
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add product
                  </Button>
                </Form.Item>
              )}
              <Form.Item>
                Tổng NV đóng góp: {formatNumber(employeeContributeAmount)} vnđ<br/>
                {
                  employeeRemainContributeAmount < 0 ? (
                    <span style={{ color: 'red' }}>Số tiền vượt quá mức cho phép</span>
                  ) : (
                    <span>Còn lại: {formatNumber(employeeRemainContributeAmount)} vnđ</span>
                  )
                }
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>

      <Form.Item label="Company Plan">
        <Form.List name="company_investment_details">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <div key={key} className="flex flex-row items-center">
                  <Form.Item
                    label="Product"
                    {...restField}
                    name={[name, 'product_id']}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      style={{ width: '200px' }}
                      placeholder="Chọn quỹ"
                      onChange={value => handleCompanyChange(value, index)}
                    >
                      {products.map(innerProduct => (
                        <Option
                          key={innerProduct.id}
                          value={innerProduct.id}
                          disabled={
                            companySelectedValues.includes(innerProduct.id) &&
                            innerProduct.id !== companySelectedValues[index]
                          }
                        >
                          {innerProduct.code}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    label="Amount"
                    labelCol={{ span: 24 }}
                    name={[name, 'amount']}
                    rules={[
                      {
                        required: true,
                        type: 'number',
                        message: 'Vui lòng nhập số tiền',
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '400px' }}
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      placeholder={
                        companyRemainContributeAmount > minimumAmount ? (
                          `Nhập từ ${formatNumber(minimumAmount)} vnđ đến ${formatNumber(companyRemainContributeAmount)} vnđ`)
                        : `Nhập tối thiếu ${formatNumber(minimumAmount)} vnđ`
                      }
                      min={1000000}
                      step={1}
                      addonAfter={'vnđ'}
                      precision={0}
                    />
                  </Form.Item>

                  <MinusCircleOutlined
                    style={{
                      fontSize: '16px',
                      color: 'red',
                      height: 'fit-content',
                    }}
                    onClick={() => remove(name)}
                  />
                </div>
              ))}

              {company_investment_details.length === products.length || companyContributeAmount == 0 ? null : (
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add product
                  </Button>
                </Form.Item>
              )}
              <Form.Item>
                Tổng DN đóng góp: {formatNumber(companyContributeAmount)} vnđ<br/>
                {
                  companyRemainContributeAmount < 0 ? (
                    <span style={{ color: 'red' }}>Số tiền vượt quá mức cho phép</span>
                  ) : (
                    <span>Còn lại: {formatNumber(companyRemainContributeAmount)} vnđ</span>
                  )
                }
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>


      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
