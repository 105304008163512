import { useFetchApiList, usePostApi } from '@stagapps/redux-utils';
import { Form } from 'antd';
import createApiCall from 'apps/education/apiCalls/challenge/create';
import challengeGroupListApiCall from 'apps/education/apiCalls/challengeGroup/list';
import PageTitle from 'apps/home/components/PageTitle';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { useEffect } from 'react';
import { convertFormData } from 'utils/formData.js';
import ItemForm from './Form';

const ChallengeCreate = () => {
  const [form] = Form.useForm();

  const { post: submitItem, isSubmitting, error: submitItemError } = usePostApi(
    createApiCall,
    {
      resourceName: 'item',
    }
  );

  const {
    data: challengeGroups,
    load: fetchChallengeGroups,
    isChallengeGroupLoading,
    paging,
  } = useFetchApiList(challengeGroupListApiCall, { resourceName: 'items' });

  useEffect(() => {
    fetchChallengeGroups();
  }, []);

  const onFinish = values => {
    console.debug('Received values of form: ', values);

    const postData = async () => {
      try {
        let submitData = values;
        submitData.points = 0;

        if (
          values.photo &&
          values.photo.at(-1) &&
          values.photo.at(-1).originFileObj instanceof File
        ) {
          submitData.photo = values.photo[0].originFileObj;
          submitData.is_active
            ? (submitData.is_active = '1')
            : (submitData.is_active = '0');

          submitData = convertFormData(submitData);
        }

        submitItem({ payload: submitData });
      } catch (error) {
        console.error(error.message);
      }
    };

    postData();
  };

  if (isChallengeGroupLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <PageTitle title={`Create Challenge`} />
      <ItemForm
        submiting={isSubmitting}
        form={form}
        onFinish={onFinish}
        challengeGroups={challengeGroups}
        name="challenge_create_form"
      />
    </>
  );
};

export default ChallengeCreate;
