import apiCall from 'apps/education/apiCalls/lesson';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { useParams } from 'react-router-dom';
import { columnsGenerator } from './config';
import ItemForm from './Form';

export const LessonListPage = (props, { params }) => {
  const { course_id, chapter_id } = useParams();

  return (
    <ListPage
      enableSearch={true}
      title="All lessons"
      apiCall={apiCall}
      params={{ ...params, course: course_id, chapter: chapter_id }}
      columnGenerator={() => columnsGenerator({ course_id, chapter_id })}
      resourceName="items"
      createUrl={`/edu/courses/${course_id}/chapters/${chapter_id}/lessons/create`}
      {...props}
    />
  );
};

export const LessonEditPage = props => {
  const { course_id, chapter_id } = useParams();

  return (
    <EditPage
      title="Chapter Edit"
      apiCall={apiCall}
      listUrl={`/edu/courses/${course_id}/chapters/${chapter_id}/lessons`}
      ItemForm={ItemForm}
      resourceName="item"
      {...props}
    />
  );
};

export const LessonCreatePage = props => {
  const { course_id, chapter_id } = useParams();

  return (
    <CreatePage
      title="Chapter"
      apiCall={apiCall}
      listUrl={`/edu/courses/${course_id}/chapters/${chapter_id}/lessons`}
      ItemForm={ItemForm}
      resourceName="item"
      {...props}
    />
  );
};
