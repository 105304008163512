import apiCall from 'apps/education/apiCalls/advertisement';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import ItemForm from './Form';
import { columnsGenerator } from './config';

export const AdvertisementListPage = () => (
  <ListPage
    title="All Advertisement"
    apiCall={apiCall}
    createUrl={`/edu/advertisement/create`}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="items"
  />
);

export const AdvertisementCreatePage = () => (
  <CreatePage
    title="Advertisement Create"
    apiCall={apiCall}
    listUrl={`/edu/advertisement`}
    ItemForm={ItemForm}
    resourceName="item"
  />
);

export const AdvertisementEditPage = () => (
  <EditPage
    title="Advertisement Edit"
    apiCall={apiCall}
    listUrl={`/edu/advertisement`}
    ItemForm={ItemForm}
    resourceName="item"
  />
);
