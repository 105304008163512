import { useFetchApiList } from '@stagapps/redux-utils';
import { Button, Select, Spin } from 'antd';
import apiCall from 'apps/market/apiCalls/stock';
import debounce from 'lodash/debounce';
import { map } from 'lodash/fp';
import { useEffect, useMemo, useState } from 'react';
import copyTextToClipboard from 'utils/clipboard';

export default function StockSelectField({ copyEnabled = false, ...props }) {
  const [options, setOptions] = useState([]);
  const {
    data: items,
    load: fetchItems,
    isLoading,
  } = useFetchApiList(apiCall.list, { resourceName: 'items' });

  const debounceFetcher = useMemo(() => {
    const loadOptions = value => {
      fetchItems({ search: value });
    };

    return debounce(loadOptions, 50);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setOptions(
        items.map((v, k) => {
          return {
            value: v.symbol,
            label: v.symbol,
          };
        })
      );
    }
  }, [isLoading]);

  return (
    <>
      <Select
        placeholder="Select Stocks"
        notFoundContent={isLoading ? <Spin size="small" /> : null}
        mode="tags"
        style={{ width: '100%' }}
        options={options}
        onSearch={debounceFetcher}
        tokenSeparators={[',']}
        {...props}
      >
        {map(
          ({ value, label }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ),
          options
        )}
      </Select>

      {copyEnabled && (
        <Button
          onClick={() => {
            console.log(props);
            const { value } = props;
            const text = value.join(',');
            copyTextToClipboard(text)
              .then(() => {
                console.log('copied', text);
              })
              .catch(err => {
                console.log(err);
              });
          }}
        >
          Copy {props.label} to Clipboard
        </Button>
      )}
    </>
  );
}
