import detailApiCall from './detail';
import editApiCall from './edit';
import approveApiCall from './approve';
import rejectApiCall from './reject';
import listApiCall from './list';

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  create: listApiCall,
  edit: editApiCall,
  delete: editApiCall,
  approve: approveApiCall,
  reject: rejectApiCall,
};

export default ApiCall;
