import { usePostApi } from '@stagapps/redux-utils';
import { Button, Spin } from 'antd';
import apiCall from 'apps/investment/apiCalls/order';
import {
  orderStatusCancelled,
  orderStatusCompleted,
} from 'apps/investment/constants/fundOrder';

export const FundOrderAction = ({ order }) => {
  if (!order || !order.id) {
    return null;
  }

  const { post: syncOrderStatus, isLoading } = usePostApi(apiCall.sync, {
    resourceName: 'data',
  });

  if (isLoading) {
    return <Spin />;
  }

  if (
    order.fund_order_id ||
    order.status == orderStatusCancelled ||
    order.status == orderStatusCompleted
  ) {
    return null;
  }

  return (
    <>
      <Button
        onClick={() =>
          confirm(
            `Are you sure you want to send the order #${order.code} to Fund Manager?`
          ) && syncOrderStatus({ id: order.id })
        }
        disabled={isLoading}
        type="primary"
        style={{
          marginLeft: '10px',
          backgroundColor: 'green',
          borderColor: 'green',
        }}
      >
        Send to Fund
      </Button>
    </>
  );
};
