import { Button } from 'antd';
import apiCall from 'apps/market/apiCalls/bank';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { Link } from 'react-router-dom';
import ItemForm from './Form';

const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
  },
  {
    title: 'Photo',
    dataIndex: 'photo',
    render: value => value && <img width={100} src={value} />,
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/market/banks/${record.id}/edit`}>Edit</Link>
        </Button>
      </Button.Group>
    ),
  },
];

// Pages

export const BankListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    title="All Banks"
    apiCall={apiCall}
    params={params}
    createUrl={`/market/banks/create`}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="items"
    {...props}
  />
);

export const BankEditPage = props => (
  <EditPage
    title="Bank"
    apiCall={apiCall}
    listUrl={`/market/banks`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);

export const BankCreatePage = props => (
  <CreatePage
    title="Bank"
    apiCall={apiCall}
    listUrl={`/market/banks`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);
