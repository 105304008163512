import styled from 'styled-components';
import PoppinsVN from './PoppinsVN-400.ttf';
import PoppinsVNBold from './PoppinsVN-700.ttf';

export const Screen = styled.div`
  width: 415px;
  height: 763px;
  background: #ffffff;
  padding: 16px;
  border-width: 20px;
  overflow-y: scroll;
  border-radius: 30px;
  border-color: black;
  position: fixed;
  top: 7%;
  right: 2%;
`;

export const Container = styled.div`
  background: #f2f4f8;
  border-radius: 13px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 36px 22px;
  margin: 20px 0;
`;

export const Title = styled.h1`
  font-family: 'PoppinsVNBold';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 150%;
  text-align: center;
  color: #0f172a;
  margin-bottom: 15px;
`;

export const StyledImage = styled.img`
  width: auto !important;
  width: 100%;
  margin-bottom: 15px;
  object-fit: cover;
`;

const SetInnerHTML = ({ className, description }) => (
  <div
    className={className}
    dangerouslySetInnerHTML={{ __html: description }}
  />
);

export const Description = styled(SetInnerHTML)`
  h1: {},
  p: {
  font-family: 'PoppinsVN';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #0F172A;
  margin-bottom: 10px
  },
  img: {
    width: auto !important;
    width: 100%;
    margin-bottom: 10px

  },
  ul: {
    margin-top: 5px,
  },
  li: {
    font-family: 'PoppinsVN';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #0F172A;
    margin: -5px 6px 10px 6px
  },
  br: {
    margin: 0,
  },
  `;
