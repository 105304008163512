import { Button, DatePicker, Form, Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { useEffect, useMemo } from 'react';

import { useFetchApiList } from '@stagapps/redux-utils';
import apiCompaniesCall from 'apps/investment/apiCalls/company/list';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { convertDateFormData, ensureDateFields } from 'utils/formData';

const debounceTimeout = 800;
const dateFields = ['order_date'];

const GenerateForm = ({
  form,
  submiting,
  onFinish,
  initialValues = {},
  name,
}) => {
  useEffect(() => {
    form.setFieldsValue(ensureDateFields(initialValues, dateFields));
  }, [form, initialValues]);

  const {
    data: companies = [],
    load: loadCompanies,
    isLoading: isLoadingCompanies,
    loadMore: loadMoreCompanies,
  } = useFetchApiList(apiCompaniesCall, { resourceName: 'data' });

  const debounceFetcher = useMemo(() => {
    const loadOptions = value => {
      loadMoreCompanies({ search: value });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [loadCompanies, debounceTimeout]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'generate_form'}
      onFinish={values =>
        onFinish({ ...convertDateFormData(values, dateFields) })
      }
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item name="company_id" label="Company">
        <Select
          style={{ width: 400 }}
          onPopupScroll={e => {
            e.persist();
            let target = e.target;
            if (
              target.scrollTop + target.offsetHeight ===
              target.scrollHeight
            ) {
              loadMoreCompanies();
            }
          }}
          onSearch={debounceFetcher}
          notFoundContent={isLoadingCompanies ? <Spin size="small" /> : null}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          placeholder="Search Email / Full name"
          options={companies.map(({ id, email, name }) => ({
            value: id,
            label: name + ' / ' + email,
          }))}
        />
      </Form.Item>

      <Form.Item name="order_date" label="Order Date">
        <DatePicker placeholder="" />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default GenerateForm;
