import { useGoogleLogin } from '@react-oauth/google';
import { usePostApi } from '@stagapps/redux-utils';
import { Button } from 'antd';
import apiCall from 'apps/home/apiCalls/auth';
import useGoogleAuth from 'hooks/useGoogleAuth';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const AdminLoginPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const url = searchParams.get('url') || '';

  const { user, isAuthenticated } = useGoogleAuth();
  const { post, isLoading } = usePostApi(apiCall, {
    resourceName: 'item',
  });

  const login = useGoogleLogin({
    onSuccess: res => post({ code: res.code }),
    conFailure: error => console.log(error),
    flow: 'auth-code',
  });

  const redirectUser = () => {
    if (url && url.startsWith('/')) {
      navigate(url);
    } else {
      navigate('/home');
    }
  };

  if (user && isAuthenticated) {
    redirectUser();

    return <div>Redirecting...</div>;
  }

  useEffect(() => {
    if (!isLoading && user) {
      redirectUser();
    }
  }, [isLoading]);

  return (
    <>
      <Button size="large" onClick={() => login()}>
        Sign in with Google 🚀
      </Button>
    </>
  );
};

export default AdminLoginPage;
