import { useFetchApiList } from '@stagapps/redux-utils';
import { Button, Form, Space } from 'antd';
import PageTitle from 'apps/home/components/PageTitle';
import DataTable from 'common/ui/DataTable';
import { useEffect } from 'react';
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { history } from 'routerHistory';
import SearchForm from './SearchForm';

const AdminList = ({
  title,
  columnGenerator,
  apiCall,
  createUrl,
  listProps,
  resourceName,
  enableSearch = false,
  searchOptions = {},
  FilterForm,
  showBreadscrumb = true,
  extra,
  params,
  ...props
}) => {
  const [form] = Form.useForm();
  const pageParams = useParams();
  const location = useLocation();
  let [searchParams] = useSearchParams();
  let queryParams = Object.fromEntries(new URLSearchParams(location.search));

  const {
    data: items,
    load: fetchItems,
    isLoading,
    paging,
  } = useFetchApiList(apiCall.list, { resourceName: resourceName || 'items' });

  useEffect(() => {
    if (searchParams.get('page')) {
      queryParams.page = searchParams.get('page');
    }

    if (searchParams.get('search')) {
      queryParams.search = searchParams.get('search');
    }

    if (searchParams.get('ordering')) {
      queryParams.ordering = searchParams.get('ordering');
    }
  }, [queryParams]);

  useEffect(() => {
    fetchItems({ ...pageParams, ...queryParams, ...params });
  }, [searchParams]);

  const onFiltering = values => {
    console.log('Received values of form: ', values);

    const payload = Object.fromEntries(
      Object.entries(values).filter(([k, v]) => v)
    );
    const queryString = new URLSearchParams(payload).toString();
    history.push(location.pathname + `?${queryString}`);

    fetchItems({ ...pageParams, ...queryParams, ...params });
  };

  return (
    <>
      <PageTitle
        title={title}
        showBreadscrumb={showBreadscrumb}
        extra={
          <>
            {extra}
            {createUrl && [
              <Button key="1" type="primary">
                <Link to={createUrl}>Create</Link>
              </Button>,
            ]}
          </>
        }
      />
      <Space direction="vertical">
        {enableSearch ? <SearchForm {...searchOptions} /> : null}
        {FilterForm && (
          <FilterForm
            form={form}
            onFinish={onFiltering}
            submiting={isLoading}
            initialValues={queryParams}
            name="filter_form"
          />
        )}
      </Space>
      <br/>
      {
        props.warningMessages && props.warningMessages.map((item, index) => item)
      }
      <DataTable
        rowKey="id"
        columns={columnGenerator(listProps)}
        dataSource={items}
        totalItems={paging?.count}
        currentPage={paging?.page}
        defaultCurrent={1}
        defaultPageSize={paging?.page_size}
        pageSize={paging?.page_size}
        dataFetcher={fetchItems}
        loading={isLoading}
        //filters keep filter params when go to another page
        filters={{ ...pageParams, ...queryParams, ...params }}
        {...props}
      />
    </>
  );
};

export default AdminList;
