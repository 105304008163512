import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/formatDate';

export const columnsGenerator = params => [
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Name',
    dataIndex: 'user_name',
  },
  {
    title: 'Company',
    dataIndex: 'company_name',
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'utm_source',
    dataIndex: 'metadata',
    render: value => {
      if (!value) return '-';
      return JSON.parse(value).map((item, index) => {
        if (item.key == 'utm_source') return item.value;
      });
    },
  },
  {
    title: 'utm_medium',
    dataIndex: 'metadata',
    render: value => {
      if (!value) return '-';
      return JSON.parse(value).map((item, index) => {
        if (item.key == 'utm_medium') return item.value;
      });
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/hub/fhc-reports/${record.id}`}>Detail</Link>
        </Button>
      </Button.Group>
    ),
  },
];
