import React, { useEffect, useState } from 'react';
import SelectField from './SelectField';

export default function ExchangeSelectField(props) {
  let options = [
    { value: 'hose', label: 'HOSE' },
    { value: 'hnx', label: 'HNX' },
    { value: 'upcom', label: 'UPCOM' },
  ];

  return (
    <SelectField placeholder="Select Exchanges" mode="multiple" style={{ width: '100%' }} {...props} options={options} />
  );
}