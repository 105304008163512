import { Space, Tag } from 'antd';

import { formatDateTime, isZeroDate } from 'utils/formatDate';

export const columnsGenerator = props => [
  {
    title: 'Question',
    dataIndex: 'question_title',
  },
  {
    title: 'Answers',
    dataIndex: 'answer_titles',
    render: value => (
      <Space direction="vertical">
        {value.map((answer, index) => (
          <Tag key={index}>{answer}</Tag>
        ))}
      </Space>
    ),
  },
  {
    title: 'Survey',
    dataIndex: 'survey_title',
    render: value => (
      <Tag color={'green'}>{value}</Tag>
    ),
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    render: value => {
      if (isZeroDate(value)) return '-';
      return formatDateTime(value);
    },
  },
];
