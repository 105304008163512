import {
  CheckCircleOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PieChartOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Space, Tag, Tooltip } from 'antd';
import UIIconTooltip from 'common/ui/UIIconTooltip';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/formatDate';

export const columnsGenerator = params => [
  {
    title: '',
    dataIndex: 'id',
    align: 'center',
    render: id => <Link to={`/investment/companies/${id}`}>View</Link>,
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
    render: (value, record) => (
      <div>
        <span>{value}</span>
        <span>{` (${record?.brand_name})`}</span>
      </div>
    ),
  },

  {
    title: 'Tax Number',
    dataIndex: 'tax_number',
  },
  {
    title: 'Work Email',
    dataIndex: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
  },
  {
    title: 'Stag Account',
    dataIndex: 'phone',
    render: (text, record) =>
      record && record.user ? (
        <Link to={`/investment/users/${record.user.id}`}>
          {record.user.name ? record.user.name : record.user.email}{' '}
          {record.user.ekyc_verified ? (
            <Tooltip title="Stag eKYC Verified">
              <CheckCircleOutlined
                style={{ color: 'green', fontSize: '20px' }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Not verified">
              <InfoCircleOutlined
                style={{ color: '#b8860b', fontSize: '20px' }}
              />
            </Tooltip>
          )}
        </Link>
      ) : (
        <Tag color="grey">Not onboard yet</Tag>
      ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space>
        <Link to={`/investment/companies/${record.id}/edit`}>
          <UIIconTooltip title="Edit" Icon={EditOutlined} />
        </Link>
        <Link to={`/investment/companies/${record.id}/employees`}>
          <UIIconTooltip title="View employees" Icon={TeamOutlined} />
        </Link>
        <Link to={`/investment/companies/${record.id}/portfolio`}>
          <UIIconTooltip title="View Portfolio" Icon={PieChartOutlined} />
        </Link>
      </Space>
    ),
  },
];
