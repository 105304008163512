import createApiCall from './create';
import detailApiCall from './detail';
import editApiCall from './edit';
import listApiCall from './list';

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  create: createApiCall,
  edit: editApiCall,
};

export default ApiCall;
