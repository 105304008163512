import balancesApiCall from './balances';
import createApiCall from './create';
import detailApiCall from './detail';
import editApiCall from './edit';
import listApiCall from './list';
import portfolioApiCall from './portfolio';
import unallocatedHistoryApiCall from './unallocatedHistory';

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  create: createApiCall,
  edit: editApiCall,
  delete: editApiCall,
  balances: balancesApiCall,
  portfolio: portfolioApiCall,
  unallocatedHistory: unallocatedHistoryApiCall,
};

export default ApiCall;
