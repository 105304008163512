import { Layout } from 'antd';
import SideMenu from 'apps/home/components/SideMenu';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

const AppLayout = () => (
  <Layout>
    <SideMenu />
    <Layout>
      <Header />
      <Layout.Content style={{ margin: '16px' }}>
        <div className="site-layout-background p-6" style={{ minHeight: 360 }}>
          <Outlet />
        </div>
      </Layout.Content>
      <Footer />
    </Layout>
  </Layout>
);

export default AppLayout;
