import { usePostApi } from '@stagapps/redux-utils';
import apiCall from 'apps/home/apiCalls/refresh';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  adminAccessTokenSelector,
  adminRefreshTokenSelector,
  adminUserSelector,
} from 'state/adminSession/state';

export default function useRefreshToken() {
  const user = useSelector(state => adminUserSelector(state));
  const accessToken = useSelector(state => adminAccessTokenSelector(state));
  const refreshToken = useSelector(state => adminRefreshTokenSelector(state));
  const { post } = usePostApi(apiCall, {
    resourceName: 'item',
  });

  const refresh = token => {
    console.debug('Refreshing token');
    post({ refresh_token: token });
  };

  const getTimeoutId = timestamp => {
    const timeoutTime = new Date(timestamp) - new Date();
    const delta = 90_000; // 1000 * 60 * 15 ~ 15min
    return timeoutTime - delta;
  };

  // refresh token 15 mins before it expires
  useEffect(() => {
    if (user && user.exp) {
      const timeoutId = setTimeout(
        () => refresh(refreshToken),
        getTimeoutId(user.exp * 1000)
      );
      return () => clearTimeout(timeoutId);
    }
  }, [user?.exp]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (accessToken) {
        const timeoutId = getTimeoutId(user.exp * 1000);
        if (timeoutId < 0) {
          refresh(refreshToken);
        }
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [accessToken]);

  return {};
}
