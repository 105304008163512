import { Descriptions } from 'antd';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/formatDate';

const ItemDetail = ({ item }) => {
  return (
    <Descriptions column={1} bordered>
      <Descriptions.Item label={'Company'}>
        {item.company ? (
          <Link to={`/investment/companies/${item.company.id}`}>
            {item.company.brand_name}
          </Link>
        ) : (
          '-'
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Code">{item.code}</Descriptions.Item>
      <Descriptions.Item label="Full Name">{item.full_name}</Descriptions.Item>
      <Descriptions.Item label="Department">
        {item.department}
      </Descriptions.Item>
      <Descriptions.Item label="Position">{item.role}</Descriptions.Item>
      <Descriptions.Item label="Nationality">
        {item.nationality}
      </Descriptions.Item>
      <Descriptions.Item label="Phone">{item.phone}</Descriptions.Item>
      <Descriptions.Item label="Work Email">{item.email}</Descriptions.Item>
      <Descriptions.Item label="Date of birth">
        {item.date_of_birth}
      </Descriptions.Item>
      <Descriptions.Item label="Gender">{item.gender}</Descriptions.Item>
      <Descriptions.Item label="ID Number">{item.id_number}</Descriptions.Item>
      <Descriptions.Item label="Issue Date">
        {item.issue_date}
      </Descriptions.Item>
      <Descriptions.Item label="Issue Place">
        {item.issue_place}
      </Descriptions.Item>
      <Descriptions.Item label="Address">{item.address}</Descriptions.Item>
      <Descriptions.Item label="Original Address">
        {item.original_address}
      </Descriptions.Item>
      <Descriptions.Item label="Bank Name">{item.bank_name}</Descriptions.Item>
      <Descriptions.Item label="Bank Account Name">
        {item.bank_account_name}
      </Descriptions.Item>
      <Descriptions.Item label="Bank Account Number">
        {item.bank_account_number}
      </Descriptions.Item>
      <Descriptions.Item label="Bank Branch Name">
        {item.bank_branch_name}
      </Descriptions.Item>
      <Descriptions.Item label="Stag Account">
        {item.user ? (
          <Link to={`/investment/users/${item.user.id}`}>
            {item.user.name ? item.user.name : item.user.email}{' '}
          </Link>
        ) : (
          'Not onboard yet'
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Created At">
        {formatDateTime(item.created_at)}
      </Descriptions.Item>
      <Descriptions.Item label="Updated At">
        {formatDateTime(item.updated_at)}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default ItemDetail;
