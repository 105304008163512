import React, { useEffect } from 'react';
import { Form, Input, Button, Switch, Upload, Select } from 'antd';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const MetricForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'metric_name_form'}
      onFinish={onFinish}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label="Metric name"
        tooltip="price"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="label"
        label="Label"
        tooltip="Giá"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="type"
        label="Type"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Select placeholder="Select an option" allowClear>
          <Option value="decimal_2_places">Decimal 2 places</Option>
          <Option value="decimal_1_places">Decimal 1 places</Option>
          <Option value="decimal_0_places">Decimal 0 places</Option>
          <Option value="integer">Integer</Option>
          <Option value="currency">Currency (*1000d)</Option>
          <Option value="currency_thousand">Currency (thousand)</Option>
          <Option value="currency_million">Currency (million)</Option>
          <Option value="currency_billion">Currency (billion)</Option>
          <Option value="string">String</Option>
          <Option value="thumbnail_chart">Thumbnail chart</Option>
          <Option value="change_percentage">Change percentage</Option>
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>

        {onDelete ? (
          <Button
            onClick={onDelete}
            type="primary"
            style={{
              visibility: visibility,
              margin: 10,
              backgroundColor: 'red',
              borderColor: 'red',
            }}
          >
            Delete
          </Button>
        ) : null}
      </Form.Item>
    </Form>
  );
};

export default MetricForm;
