import { generateEndpoint } from 'utils/urlHelpers';
import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';

const FETCH_INVESTCORE_EMPLOYEE_LIST_API_ID = 'DASHBOARD/FETCH_INVESTCORE_EMPLOYEE_LIST_API';

const apiCall = enhancedMakeFetchAction(FETCH_INVESTCORE_EMPLOYEE_LIST_API_ID, params => ({
  endpoint: generateEndpoint({
    host: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/employees`,
    params,
  }),
  method: 'GET',
}));

export default apiCall;
