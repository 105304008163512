import { useFetchApiGet } from '@stagapps/redux-utils';
import { Card, Col, Row, Space, Spin, Statistic, Table } from 'antd';
import MetaDecorator from 'apps/home/components/MetaDecorator';
import PageTitle from 'apps/home/components/PageTitle';
import apiCall from 'apps/investment/apiCalls/company';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { formatDateTime } from 'utils/formatDate';

const portColums = [
  {
    title: 'Created At',
    dataIndex: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Product ID',
    dataIndex: 'product_id',
  },
  {
    title: 'Product Code',
    dataIndex: 'product_code',
  },
  {
    title: 'Employee Program',
    dataIndex: 'employee_program',
    render: employee_program => (
      <Link
        to={`/investment/employee-programs/${employee_program?.id}/portfolio`}
      >
        {employee_program?.code}
      </Link>
    ),
  },
  {
    title: 'Total Company Contributed Amount',
    dataIndex: 'total_contributed_amount',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
];

const unallocatedBalanceColums = [
  {
    title: 'Created At',
    dataIndex: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Product ID',
    dataIndex: 'product_id',
  },
  {
    title: 'Product Code',
    dataIndex: 'product_code',
  },
  {
    title: 'Employee Program',
    dataIndex: 'unallocated_quantity',
    render: value => 'N/A',
  },
  {
    title: 'Total Company Contributed Amount',
    dataIndex: 'unallocated_quantity',
    align: 'right',
    render: value => 'N/A',
  },
  {
    title: 'Quantity',
    dataIndex: 'unallocated_quantity',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
];

const InvestmentCompanyPortfolio = () => {
  const params = useParams();

  const {
    data: company,
    load: fetchCompany,
    isLoading: isLoadingCompany,
  } = useFetchApiGet(apiCall.detail, { resourceName: 'data' });

  const {
    data: portfolio,
    load: fetchPortfolio,
    isLoading: isLoadingPortfolio,
  } = useFetchApiGet(apiCall.portfolio, { resourceName: 'data' });

  const {
    data: balances,
    load: fetchBalances,
    isLoading: isLoadingBalances,
  } = useFetchApiGet(apiCall.balances, { resourceName: 'data' });

  useEffect(() => {
    fetchCompany({ id: params.company_id });
    fetchPortfolio({ id: params.company_id });
    fetchBalances({ id: params.company_id });
  }, []);

  const isLoading = isLoadingCompany || isLoadingPortfolio || isLoadingBalances;

  if (isLoading) {
    return (
      <Spin tip="Loading" size="large">
        <div className="content" />
      </Spin>
    );
  }

  const title = `Company Portfolio`;

  const { items } = portfolio || {};
  const { company_unallocated_balance } = balances || {};
  const formatter = value => <Statistic value={value} />;
  return (
    <>
      <MetaDecorator title={title} />
      <PageTitle title={company.brand_name} />
      <Space direction="vertical" size={'large'}>
        <Card title="Portfolio">
          <Row gutter={16} className="mb-4">
            <Col span={8}>
              <Statistic
                title="Total Company Contributed Amount"
                value={portfolio.sum_total_contributed_amount}
                precision={0}
                formatter={formatter}
              />
            </Col>
          </Row>

          <Table
            rowKey="id"
            columns={portColums}
            dataSource={items}
            pagination={false}
          />
        </Card>

        <Card title="Unallocated Balance">
          <Table
            rowKey="id"
            columns={unallocatedBalanceColums}
            dataSource={company_unallocated_balance}
            pagination={false}
          />
          <Link to={`/investment/companies/${company.id}/unallocated-history`}>
            Unallocated Balance Histories of{' '}
            {company.brand_name ? company.brand_name : '-'}
          </Link>
        </Card>
      </Space>
    </>
  );
};

export default InvestmentCompanyPortfolio;
