import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/formatDate';

export const columnsGenerator = params => [
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Modified',
    dataIndex: 'updated_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link
            to={`/hub/courses/${params.course_id}/chapters/${params.chapter_id}/lessons/${params.lesson_id}/multicheckbox-questions/${record.id}/edit`}
          >
            Edit
          </Link>
        </Button>
      </Button.Group>
    ),
  },
];
