import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { IAMCORE_API_BASE_URL } from 'config/env.config';

export const OAUTH2_GET_TOKEN = 'DASHBOARD/OAUTH2_GET_TOKEN';

const apiCall = enhancedMakeFetchAction(OAUTH2_GET_TOKEN, payload => ({
  endpoint: `${IAMCORE_API_BASE_URL}/iamcore-api/v1/auth/get-token`,
  method: 'POST',
  body: payload,
}));

export default apiCall;
