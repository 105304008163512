import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';

const FETCH_PRODUCT_EDIT_API_ID = 'INVESTCORE/FETCH_PRODUCT_EDIT_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_PRODUCT_EDIT_API_ID,
  ({ id, payload }) => ({
    endpoint: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/products/${id}`,
    method: 'PUT',
    body: payload,
    notify: true,
  })
);

export default apiCall;
