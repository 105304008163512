import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

export const FETCH_QUESTION_DELETE_API_ID =
  'DASHBOARD/FETCH_QUESTION_DELETE_API';

const apiCall = lesson_id =>
  enhancedMakeFetchAction(FETCH_QUESTION_DELETE_API_ID, ({ id }) => ({
    endpoint: `${API_BASE_URL}/stagedu-api/admin/v1/lessons/${lesson_id}/questions/${id}`,
    method: 'DELETE',
    notify: true,
  }));

export default apiCall;
