import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

export const FETCH_CHAPTER_EDIT_API_ID = 'HUB/FETCH_CHAPTER_EDIT_API_ID';

const apiCall = enhancedMakeFetchAction(
  FETCH_CHAPTER_EDIT_API_ID,
  ({ id, payload }) => ({
    endpoint: `${API_BASE_URL}/investcore-api/admin/edu/chapters/${id}`,
    method: 'PATCH',
    body: payload,
    notify: true,
  })
);

export default apiCall;
