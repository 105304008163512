import React, { useEffect, useState } from 'react';

import {
  Form,
  Input,
  DatePicker
} from 'antd';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { convertDateFormData, ensureDateFields } from 'utils/formData';

const dateFields = ['date'];

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
}) => {

  useEffect(() => {
    form.setFieldsValue(ensureDateFields(initialValues, dateFields));
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={values => onFinish(convertDateFormData(values, dateFields))}
      initialValues={ensureDateFields(initialValues, dateFields)}
      scrollToFirstError
    >

      <Form.Item
        name="symbol"
        label="Symbol"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="ACB" />
      </Form.Item>

      <Form.Item name="date" label="Date">
        <DatePicker />
      </Form.Item>

      <Form.Item
        name="year"
        label="Year"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="quarter"
        label="Quarter"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="currency"
        label="Currency"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="market_price"
        label="Price"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="market_cap"
        label="Market Cap"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="eps"
        label="EPS"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="bvps"
        label="BVPS"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="pe"
        label="PE"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="peg"
        label="PEG"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="roa"
        label="ROA"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="roe"
        label="ROE"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="ros"
        label="ROS"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="revenue_growth_rate"
        label="Revenue Growth Rate"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="earnings_growth_rate"
        label="Earnings Growth Rate"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="dept_to_asset"
        label="Dept to Asset"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="dept_to_equity"
        label="Dept to Equity"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="dividend"
        label="Dividend"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="ev_ebitda"
        label="EV Ebitda"
      >
        <Input placeholder="0" />
      </Form.Item>
      
      <Form.Item
        name="ev_sales"
        label="EV Sales"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="gross_profit_to_sales"
        label="Gross Profit To Sales"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="outstanding_shares"
        label="Outstanding Shares"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="profit_margin"
        label="Profit Margin"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="ps"
        label="PS"
      >
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item
        name="quick_ratio"
        label="Quick Ratio"
      >
        <Input placeholder="0" />
      </Form.Item>

    </Form>
  );
};

export default ItemForm;
