import { Avatar, Select, Space } from 'antd';
import useApps from 'hooks/useApps';
import { useNavigate } from 'react-router-dom';

const AppSelector = () => {
  let navigate = useNavigate();
  const { appKey, appItems } = useApps();

  const onSelect = (value, event) => {
    navigate(`/${value}`);
  };

  return (
    <Select
      style={{ width: '300px' }}
      size="large"
      onSelect={onSelect}
      value={appKey}
    >
      {appItems.map(o => (
        <Select.Option key={o.key} value={o.key}>
          <Space>
            <Avatar style={{ backgroundColor: o.color }}>
              {o.key.charAt(0).toUpperCase()}
            </Avatar>
            {o.label}
          </Space>
        </Select.Option>
      ))}
    </Select>
  );
};

export default AppSelector;
