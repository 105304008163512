import { Breadcrumb } from 'antd';
import { startCase } from 'lodash/fp';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const BreadCrumb = () => {
  const location = useLocation();
  const { pathname } = location;
  const pathnames = pathname.split('/').filter(item => item);

  return (
    <Breadcrumb>
      {pathnames.map((name, index) => {
        if (!name) name = '';

        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;
        const key = name + index;
        const label = name
          ? startCase(name.toLowerCase().replace('-', ' '))
          : '';
        return isLast ? (
          <Breadcrumb.Item key={key}>{label}</Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={key}>
            <Link to={`${routeTo}`}>{label}</Link>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default BreadCrumb;
