import { Tag } from 'antd';
import {
  orderTypeColors,
  orderTypeLabels,
} from 'apps/investment/constants/fundOrder';
import {
  orderSideColors,
  orderSideLabels,
  programOrderMatchSourceCompanyPortfolio,
  programOrderStatus,
  programOrderStatusColors,
} from 'apps/investment/constants/programOrder';
import { Link } from 'react-router-dom';
import formatAmount from 'utils/formatAmount';
import { formatDateTime } from 'utils/formatDate';
import { getUUIDFootprint } from 'utils/uuid';
import { FundOrderAction } from './PostActions';

export const employeeProgramOrdersColumnsGenerator = params => [
  {
    align: 'center',
    render: record => (
      <Link to={`/investment/program-orders/${record.id}`}>View</Link>
    ),
  },
  {
    title: 'Admin Created At',
    dataIndex: 'created_at',
    sorter: true,
    render: value => formatDateTime(value),
  },
  {
    title: 'Order Date',
    dataIndex: 'order_date',
    sorter: true,
    render: value => formatDateTime(value, 'MM/YYYY'),
  },
  {
    title: 'Match Type',
    dataIndex: 'order_side',
    render: text => (
      <Tag color={orderSideColors[text]}>{orderSideLabels[text]}</Tag>
    ),
  },
  {
    title: 'Company',
    dataIndex: 'company',
    render: value => value.name,
  },
  {
    title: 'Employee',
    dataIndex: 'employee',
    render: value => value.full_name,
  },
  {
    title: 'Program',
    dataIndex: 'employee_program',
    align: 'center',
    render: (program, record) => {
      if (!program) {
        return '-';
      }

      return (
        <>
          <Link to={`/investment/employee-programs/${program.id}`}>
            View program
          </Link>
        </>
      );
    },
  },
  {
    title: 'Type',
    dataIndex: 'order_type',
    align: 'center',
    render: (text, record) => {
      let orderType = record.order_type;
      if (
        record.order_type == 'buy' &&
        record.matched_source == programOrderMatchSourceCompanyPortfolio
      ) {
        orderType = 'redistribute';
      }

      return (
        <Tag color={orderTypeColors[orderType]}>
          {orderTypeLabels[orderType]}
        </Tag>
      );
    },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    type: 'formatNumber',
  },
  {
    title: 'Matched Price',
    dataIndex: 'matched_price',
    type: 'formatNumber',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    type: 'formatNumber',
  },
  {
    title: 'Product',
    dataIndex: 'product_code',
    sorter: true,
    align: 'center',
  },

  {
    title: 'Status',
    align: 'center',
    render: o => {
      return (
        <Tag color={programOrderStatusColors[o.status]}>{o.status_label}</Tag>
      );
    },
  },
  {
    title: 'Fund Order',
    dataIndex: 'order',
    align: 'right',
    render: order => {
      if (!order) {
        return '-';
      }

      return (
        <>
          <Link to={`/investment/orders/${order.id}`}>
            {getUUIDFootprint(order.id, 6)}
          </Link>
          <br />
          {formatAmount(order.amount)}
        </>
      );
    },
  },
  {
    title: 'Action',
    key: 'action',
    dataIndex: 'order',
    align: 'center',
    render: order => <FundOrderAction order={order} />,
  },
];

export const rebundleColumns = [
  {
    title: 'ID',
    dataIndex: 'code',
    render: (value, record) => (
      <Link to={`/investment/program-orders/${record.id}`}>{value}</Link>
    ),
  },
  {
    title: 'Order Side',
    dataIndex: 'order_side',
    render: text => (
      <Tag color={orderSideColors[text]}>{orderSideLabels[text]}</Tag>
    ),
  },
  {
    title: 'Employee',
    dataIndex: 'employee',
    render: value => value.full_name,
  },
  {
    title: 'Interval',
    dataIndex: 'interval',
    align: 'right',
  },
  {
    title: 'Order Type',
    dataIndex: 'order_type',
    align: 'center',
    render: (text, record) => {
      let orderType = record.order_type;
      if (
        record.order_type == 'buy' &&
        record.matched_source == programOrderMatchSourceCompanyPortfolio
      ) {
        orderType = 'redistribute';
      }

      return (
        <Tag color={orderTypeColors[orderType]}>
          {orderTypeLabels[orderType]}
        </Tag>
      );
    },
  },
  {
    title: 'Order Amount',
    dataIndex: 'amount',
    type: 'formatNumber',
  },
  {
    title: 'Product Code',
    dataIndex: 'product_code',
    align: 'center',
  },

  {
    title: 'Created',
    dataIndex: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: status => {
      return (
        <Tag color={programOrderStatusColors[status]}>
          {programOrderStatus[status]}
        </Tag>
      );
    },
  },
  {
    title: 'Fund Order',
    dataIndex: 'order',
    render: order => {
      if (!order) {
        return '-';
      }

      return <Link to={`/investment/orders/${order.id}`}>{order.code}</Link>;
    },
  },
  {
    title: 'Employee Program',
    dataIndex: 'employee_program',
    render: program => {
      if (!program) {
        return '-';
      }

      return (
        <Link to={`/investment/employee-programs/${program.id}`}>
          {program.code}
        </Link>
      );
    },
  },
];
