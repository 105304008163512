import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { usePostApi } from '@stagapps/redux-utils';
import { Button, Descriptions, List, Space, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/formatDate';

import {
  employeeProgramRequiredCompanyFundAccountReady,
  employeeProgramRequiredCompanyUser,
  employeeProgramRequiredCompanyUserVerified,
  employeeProgramRequiredEmployeeFundAccountReady,
  employeeProgramRequiredEmployeeUser,
  employeeProgramRequiredEmployeeUserVerified,
  employeeProgramRequiredInvestmentPlan,
  employeeProgramRequiredStartDateEndDateValid,
  employeeProgramStatus,
  employeeProgramStatusCanceling,
  employeeProgramStatusColors,
  employeeProgramStatusCompleted,
  employeeProgramStatusInProgress,
  employeeProgramStatusPending,
  employeeProgramStatusVesting,
} from 'apps/investment/constants/employeeProgram';
import convertObjectToArrayData, {
  removeKeyList,
} from 'utils/convertObjectToArrayData';
import EstimateVesting from './EstimateVesting';

const ItemDetail = ({ item, apiCall }) => {
  const {
    code,
    company_investment_detail,
    employee_investment_detail,
    company,
    employee,
    ...data
  } = item;

  const { id: companyId, name } = company || {};
  const { id: employeeId, full_name } = employee || {};

  const {
    id,
    status,
    can_vesting,
    can_complete,
    can_terminate,
    can_cancel,
    joined_at,
    required_active_conditions,
    required_employee_fund_accounts,
    required_company_fund_accounts,
    ...programDetail
  } = data || {};
  const programDetailArray = convertObjectToArrayData(
    removeKeyList(programDetail, [
      'employee_id',
      'company_id',
      'program_id',
      'joined_at',
    ])
  );

  const { post, data: responseData, isLoading: isSubmitting } = usePostApi(
    apiCall.invite,
    {
      resourceName: 'data',
    }
  );

  const { post: postVesting, isLoading: isSubmittingVesting } = usePostApi(
    apiCall.vesting,
    {
      resourceName: 'data',
    }
  );

  const { post: postTerminate, isLoading: isSubmittingTerminate } = usePostApi(
    apiCall.terminate,
    {
      resourceName: 'data',
    }
  );

  const { post: postComplete, isLoading: isSubmittingComplete } = usePostApi(
    apiCall.complete,
    {
      resourceName: 'data',
    }
  );

  const { post: postCancel, isLoading: isSubmittingCancel } = usePostApi(
    apiCall.cancel,
    {
      resourceName: 'data',
    }
  );

  const {
    post: postInProgress,
    isLoading: isSubmittingInProgress,
  } = usePostApi(apiCall.inProgress, {
    resourceName: 'data',
  });

  const { post: postPending, isLoading: isSubmittingPending } = usePostApi(
    apiCall.pending,
    {
      resourceName: 'data',
    }
  );

  const onInvite = () => {
    post({ id });
  };

  const onVesting = () => {
    postVesting({ id });
  };

  const onTerminate = () => {
    postTerminate({ id });
  };

  const onComplete = () => {
    postComplete({ id });
  };

  const onCancel = () => {
    postCancel({ id });
  };

  const onInProgress = () => {
    postInProgress({ id });
  };

  const onPending = () => {
    postPending({ id });
  };

  const programActions = [
    {
      label: 'Invite',
      onClick: onInvite,
      loading: isSubmitting,
      disabled: status !== employeeProgramStatusPending,
      confirmMessage: '',
      showButton: true,
      buttonType: 'primary',
      buttonLabel: 'Invite',
    },
    {
      label: 'Trigger Vesting',
      onClick: onVesting,
      loading: isSubmittingVesting,
      disabled: !can_vesting,
      confirmMessage:
        'Confirm to vesting program? This action can not rollback',
      showButton: status == employeeProgramStatusInProgress,
      buttonType: 'primary',
      buttonLabel: 'Vesting',
    },
    {
      label: 'Trigger Complete',
      onClick: onComplete,
      loading: isSubmittingComplete,
      disabled: !can_complete,
      confirmMessage:
        'Confirm to complete program? This action can not rollback',
      showButton: status == employeeProgramStatusVesting,
      buttonType: 'primary',
      buttonLabel: 'Complete',
    },
    {
      label: 'Trigger Terminate',
      onClick: onTerminate,
      loading: isSubmittingTerminate,
      disabled: !can_terminate,
      confirmMessage:
        'Confirm to terminate program? This action can not rollback',
      showButton:
        status == employeeProgramStatusPending ||
        status == employeeProgramStatusInProgress,
      buttonType: 'danger',
      buttonLabel: 'Terminate',
    },
    {
      label: 'Trigger Cancel',
      onClick: onCancel,
      loading: isSubmittingCancel,
      disabled: !can_cancel,
      confirmMessage: 'Confirm to cancel program? This action can not rollback',
      showButton: status == employeeProgramStatusCanceling,
      buttonType: 'danger',
      buttonLabel: 'Cancel',
    },
    {
      label: 'Trigger Active',
      onClick: onInProgress,
      loading: isSubmittingInProgress,
      disabled:
        required_active_conditions && required_active_conditions.length > 0,
      confirmMessage:
        'Confirm to switch program to active? This action can not rollback',
      showButton: status == employeeProgramStatusPending,
      buttonType: 'primary',
      warningMessage:
        required_active_conditions && required_active_conditions.length > 0
          ? 'Chưa đủ điều kiện'
          : '',
      buttonLabel: 'Active Program',
    },
    {
      label: 'Trigger Pending',
      onClick: onPending,
      loading: isSubmittingPending,
      disabled: false,
      confirmMessage:
        'Confirm to switch program to pending? This action can not rollback',
      showButton: status == employeeProgramStatusInProgress,
      buttonType: 'danger',
      buttonLabel: 'Inactive Program',
    },
  ];

  const showEstimateVesting =
    status == employeeProgramStatusVesting ||
    status == employeeProgramStatusCompleted;

  const programActionsRender = programActions
    .filter(({ showButton }) => showButton)
    .map(
      ({
        label,
        onClick,
        loading,
        disabled,
        confirmMessage,
        buttonType,
        buttonLabel,
        warningMessage,
      }) => (
        <Space align="center" key={label}>
          <Button
            disabled={disabled}
            loading={loading}
            type={buttonType}
            onClick={() => confirm(confirmMessage) && onClick()}
          >
            {buttonLabel}
          </Button>
          {warningMessage && (
            <Tooltip title={warningMessage}>
              <InfoCircleOutlined
                style={{ color: '#b8860b', fontSize: '20px' }}
              />
            </Tooltip>
          )}
          <Space>&nbsp;</Space>
        </Space>
      )
    );

  return (
    <>
      <Descriptions
        title="Program Detail"
        bordered
        column={1}
        extra={programActionsRender}
      >
        <Descriptions.Item label={'Status'}>
          <Tag color={employeeProgramStatusColors[status]}>
            {employeeProgramStatus[status]}
          </Tag>
        </Descriptions.Item>
        {status == employeeProgramStatusPending ? (
          <Descriptions.Item label={'Yêu cầu kích hoạt chương trình'}>
            <List
              size="large"
              header={<div>A) Yêu cầu về chi tiết chương trình:</div>}
              dataSource={[
                {
                  label:
                    'Doanh nghiệp phải tạo chương trình và NV tham gia (nhập mã tham gia hợp lệ) vào chương trình',
                  condition: employeeProgramRequiredEmployeeUser,
                },
                {
                  label:
                    'Ngày bắt đầu của chương trình đã kích hoạt (đạt hoặc vượt qua) và chưa đến thời điểm / ngày kết thúc',
                  condition: employeeProgramRequiredStartDateEndDateValid,
                },
                {
                  label:
                    'Nhân viên phải hoàn thành việc chọn danh mục đầu tư (bao gồm đạt các yêu cầu về chọn giá trị đầu tư định kỳ tối thiểu)',
                  condition: employeeProgramRequiredInvestmentPlan,
                },
              ]}
              renderItem={item => (
                <List.Item>
                  - {item.label}{' '}
                  {required_active_conditions &&
                  required_active_conditions.includes(item.condition) ? (
                    <CloseCircleOutlined
                      style={{ color: 'red', fontSize: '20px' }}
                    />
                  ) : (
                    <CheckCircleOutlined
                      style={{ color: 'green', fontSize: '20px' }}
                    />
                  )}
                </List.Item>
              )}
            />
            <List
              size="large"
              header={<div>B) Yêu cầu về tài khoản:</div>}
              dataSource={[
                {
                  label: 'Doanh nghiệp có tài khoản Stag active',
                  condition: employeeProgramRequiredCompanyUser,
                },
                {
                  label:
                    'Doanh nghiệp phải hoàn thành định danh level 3 trên hệ thống Stag (email, phone, eKYC)',
                  condition: employeeProgramRequiredCompanyUserVerified,
                },
                {
                  label:
                    'Doanh nghiệp phải hoàn thành mở tài khoản giao dịch CCQ tại cty QLQ được Nhân viên lựa chọn trong danh mục',
                  condition: employeeProgramRequiredCompanyFundAccountReady,
                },
                {
                  label:
                    'Doanh nghiệp được gắn kết nối giữa tài khoản Stag và tài khoản giao dịch CCQ',
                  condition: employeeProgramRequiredCompanyFundAccountReady,
                },
                {
                  label: 'Nhân viên có tài khoản Stag active',
                  condition: employeeProgramRequiredEmployeeUser,
                },
                {
                  label:
                    'Nhân viên hoàn thành định danh level 3 trên hệ thống Stag (email, phone, eKYC)',
                  condition: employeeProgramRequiredEmployeeUserVerified,
                },
                {
                  label:
                    'Nhân viên hoàn thành mở tài khoản giao dịch CCQ tại cty QLQ được Nhân viên lựa chọn trong danh mục',
                  condition: employeeProgramRequiredEmployeeFundAccountReady,
                },
                {
                  label:
                    'Nhân viên được gắn kết nối giữa tài khoản Stag và tài khoản giao dịch CCQ',
                  condition: employeeProgramRequiredEmployeeFundAccountReady,
                },
              ]}
              renderItem={item => (
                <List.Item>
                  - {item.label}{' '}
                  {required_active_conditions &&
                  required_active_conditions.includes(item.condition) ? (
                    <CloseCircleOutlined
                      style={{ color: 'red', fontSize: '20px' }}
                    />
                  ) : (
                    <CheckCircleOutlined
                      style={{ color: 'green', fontSize: '20px' }}
                    />
                  )}
                </List.Item>
              )}
            />
          </Descriptions.Item>
        ) : null}
        <Descriptions.Item label={'Code'}>{code}</Descriptions.Item>
        <Descriptions.Item label={'Accept Invitation'}>
          {joined_at ? formatDateTime(joined_at) : 'Not yet'}
        </Descriptions.Item>
        {programDetailArray.map(({ label, text }) => (
          <Descriptions.Item label={label}>{text}</Descriptions.Item>
        ))}
        <Descriptions.Item label={'Employee detail'}>
          <Link to={`/investment/employees/${employeeId}`}>{full_name}</Link>
        </Descriptions.Item>
        <Descriptions.Item label={'Company detail'}>
          <Link to={`/investment/companies/${companyId}`}>{name}</Link>
        </Descriptions.Item>
        <Descriptions.Item label={'Employee Program Orders'}>
          <Link to={`/investment/program-orders?employee_program_id=${id}`}>
            Xem danh sách lệnh
          </Link>
        </Descriptions.Item>
      </Descriptions>

      {showEstimateVesting && (
        <EstimateVesting
          id={id}
          companyId={companyId}
          employeeId={employeeId}
          apiCall={apiCall}
          programStatus={status}
        />
      )}
    </>
  );
};

export default ItemDetail;
