import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Image, Input, InputNumber, Space, Upload } from 'antd';
import { useEffect, useState } from 'react';
import slugify from 'slugify';
import { DRAFT_STATUS, PUBLISH_STATUS } from 'utils/constants';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  params,
  onDelete,
  visibility = 'hidden',
}) => {
  const { photo, status, thumbnail } = initialValues;

  const onBlur = () => {
    const inputValue = form.getFieldsValue();
    !inputValue.slug &&
      form.setFieldsValue({ slug: slugify(inputValue.title).toLowerCase() });
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    form.setFieldsValue({
      ...initialValues,
      author: 'stag',
    });
  }, [form, initialValues]);

  const [isRemoved, setIsRemoved] = useState(false);
  const [isRemovedThumbnail, setIsRemovedThumbnail] = useState(false);
  const [isDraft, setIsDraft] = useState(false);

  const removePhoto = () => {
    setIsRemoved(true);
  };
  const removeThumbnail = () => {
    setIsRemovedThumbnail(true);
  };

  var draftButtonLabel = 'Save as draft';
  var publishButtonLabel = 'Save & Publish';

  if (status == PUBLISH_STATUS) {
    publishButtonLabel = 'Save';
    draftButtonLabel = 'Switch to draft';
  }

  return (
    <Form
      style={{ width: '75%' }}
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onBlur={onBlur}
      onFinish={values =>
        onFinish(
          {
            ...values,
            ...params,
            status: isDraft ? DRAFT_STATUS : PUBLISH_STATUS,
          },

          ['photo', 'thumbnail']
        )
      }
      scrollToFirstError
    >
      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="slug"
        label="URL Slug"
        tooltip="URL Slug"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input value={form.fieldValue} />
      </Form.Item>

      <Form.Item name="description" label="Description">
        <Input />
      </Form.Item>
      <Form.Item
        name="url"
        label="Video URL"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="photo"
        label="Upload new photo"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>
      {isRemoved || !photo ? null : (
        <Form.Item
          label="Upload a new photo"
          style={{
            visibility,
          }}
        >
          <Button
            type="primary"
            shape="circle"
            size="small"
            style={{
              backgroundColor: 'red',
              position: 'absolute',
              zIndex: 99,
              left: 5,
              top: 5,
            }}
            onClick={() => removePhoto()}
          >
            X
          </Button>
          <Image width={200} src={photo}></Image>
        </Form.Item>
      )}

      <Form.Item
        name="thumbnail"
        label="Upload new thumbnail"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>
      {isRemovedThumbnail || !thumbnail ? null : (
        <Form.Item
          label="Upload a new thumbnail"
          style={{
            visibility,
          }}
        >
          <Button
            type="primary"
            shape="circle"
            size="small"
            style={{
              backgroundColor: 'red',
              position: 'absolute',
              zIndex: 99,
              left: 5,
              top: 5,
            }}
            onClick={() => removeThumbnail()}
          >
            X
          </Button>
          <Image width={200} src={thumbnail}></Image>
        </Form.Item>
      )}

      <Form.Item
        name="ranking"
        label="Video Number"
        tooltip="Smallest number will be on top: 1,2,3.."
        rules={[
          {
            required: true,
            message: 'Please input the required field',
          },
        ]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Space>
          <Button htmlType="submit" type="primary" loading={submiting}>
            {publishButtonLabel}
          </Button>
          <Button
            onClick={() => setIsDraft(true)}
            danger
            htmlType="submit"
            loading={submiting}
          >
            {draftButtonLabel}
          </Button>
          {onDelete ? (
            <Button onClick={onDelete} danger>
              Move to trash
            </Button>
          ) : null}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
