import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_INVESTCORE_PORTFOLIO_DETAIL_API =
  'DASHBOARD/FETCH_INVESTCORE_PORTFOLIO_DETAIL_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_INVESTCORE_PORTFOLIO_DETAIL_API,
  params => ({
    endpoint: generateEndpoint({
      host: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/portfolios`,
      params: {
        ordering: '-market_value',
        ...params,
      },
    }),
    method: 'GET',
  })
);

export default apiCall;
