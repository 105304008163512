import { useEffect } from 'react';

import { Button, DatePicker, Form, Input, Select } from 'antd';
import { typeOptions } from 'apps/market/apiCalls/stockDividend/option';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { convertDateFormData, ensureDateFields } from 'utils/formData';

const dateFields = [
  'issue_date__lte',
  'issue_date__gte',
  'exright_date__lte',
  'exright_date__gte',
];

const FilterForm = ({
  form,
  initialValues = {},
  onFinish,
  submiting,
  name,
}) => {
  useEffect(() => {
    form.setFieldsValue(ensureDateFields(initialValues, dateFields));
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'filter_form'}
      onFinish={values => onFinish(convertDateFormData(values, dateFields))}
      scrollToFirstError
    >
      <Form.Item name="symbol" label="Symbol">
        <Input placeholder="ACB" />
      </Form.Item>

      <Form.Item name="exright_date__gte" label="Exright Date (From)">
        <DatePicker />
      </Form.Item>

      <Form.Item name="exright_date__lte" label="Exright Date (To)">
        <DatePicker />
      </Form.Item>

      <Form.Item name="issue_date__gte" label="Issue Date (From)">
        <DatePicker />
      </Form.Item>

      <Form.Item name="issue_date__lte" label="Issue Date (To)">
        <DatePicker />
      </Form.Item>

      <Form.Item name="dividend_type" label="Type">
        <Select>
          {typeOptions &&
            typeOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FilterForm;
