import apiCall from 'apps/investment/apiCalls/userProgram';
import apiInvestmentPlanCall from 'apps/investment/apiCalls/userProgram/investmentPlan';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import { useParams } from 'react-router-dom';
import FilterForm from './FilterForm';
import ItemForm from './Form';
import InvestmentPlanForm from './InvestmentPlanForm';
import {
  userProgramInvestmentPlanColumns,
  userProgramsColumns,
} from './config';
import ItemDetail from './detail';
import ItemPortfolio from './portfolio';

export const UserProgramListPage = (props, { params }) => {
  return (
    <ListPage
      enableSearch={true}
      FilterForm={FilterForm}
      title="All User Programs"
      createUrl={`/investment/user-programs/create`}
      apiCall={apiCall}
      params={params}
      columnGenerator={props => userProgramsColumns(props)}
      resourceName="data"
      {...props}
    />
  );
};

export const UserProgramDetailPage = (props, { params }) => (
  <DetailPage
    title="User Program Detail"
    listUrl={`/investment/user-programs`}
    apiCall={apiCall}
    ItemDetail={ItemDetail}
    params={params}
    resourceName="data"
    {...props}
  />
);

export const UserProgramEditPage = props => (
  <EditPage
    title="User Program Edit"
    apiCall={apiCall}
    listUrl={`/investment/user-programs`}
    ItemForm={ItemForm}
    resourceName="data"
    {...props}
  />
);

export const UserProgramCreatePage = props => (
  <CreatePage
    title="User Program Create"
    apiCall={apiCall}
    listUrl={`/investment/user-programs`}
    ItemForm={ItemForm}
    resourceName="data"
    {...props}
  />
);

export const UserProgramInvestmentPlanListPage = props => {
  const { id } = useParams();

  return (
    <ListPage
      rowKey={'investment_plan_version'}
      title="User Program Investment Plan"
      apiCall={apiInvestmentPlanCall}
      columnGenerator={props => userProgramInvestmentPlanColumns(props)}
      resourceName="data"
      createUrl={`/investment/user-programs/${id}/investment-plans/create`}
    />
  );
};

export const UserProgramInvestmentPlanCreatePage = props => {
  const { id } = useParams();

  return (
    <CreatePage
      title="User Program Investment Plan Create"
      apiCall={apiInvestmentPlanCall}
      listUrl={`/investment/user-programs/${id}/investment-plans`}
      ItemForm={InvestmentPlanForm}
      resourceName="item"
      {...props}
    />
  );
};

export const UserProgramPortfolioPage = (props, { params }) => (
  <DetailPage
    title="User Program Portfolio"
    listUrl={`/investment/user-programs`}
    apiCall={apiCall}
    ItemDetail={ItemPortfolio}
    params={params}
    resourceName="data"
    {...props}
  />
);
