export const productStatusColors = {
  1001: 'yellow',
  1002: 'green',
  1003: 'orange',
};

export const productStatusLabels = {
  1001: 'Drafted',
  1002: 'Published',
  1003: 'Staging',
};

export const productStatusOptions = [
  { value: 1001, label: 'Drafted' },
  { value: 1002, label: 'Published' },
  { value: 1003, label: 'Staging' },
];

export const productRiskLevelOptions = [
  { value: 1001, label: 'Low' },
  { value: 1002, label: 'Low Medium' },
  { value: 1003, label: 'Medium' },
  { value: 1004, label: 'Medium High' },
  { value: 1005, label: 'High' },
];
