import { Button, Tag } from 'antd';
import { Link } from 'react-router-dom';

import { statusColors, statusLabel } from 'utils/constants';
import { formatISODate } from 'utils/formatDate';
import { cloudflareCdn } from 'utils/image';

export const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Photo',
    dataIndex: 'photo',
    render: value =>
      value && <img width={100} src={cloudflareCdn(value, 100)} />,
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    render: value => formatISODate(value),
  },
  {
    title: 'Type',
    dataIndex: 'type',
  },
  {
    title: 'Position',
    dataIndex: 'placeholder',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => {
      return <Tag color={statusColors[value]}>{statusLabel[value]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/hub/polls/${record.id}/edit`}>Edit</Link>
        </Button>
      </Button.Group>
    ),
  },
];
