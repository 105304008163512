import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_COLLECTION_GROUP_LIST_API_ID =
  'DASHBOARD/FETCH_COLLECTION_GROUP_LIST_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_COLLECTION_GROUP_LIST_API_ID,
  params => ({
    endpoint: generateEndpoint({
      host: `${API_BASE_URL}/marketdb-api/admin/v1/collection-groups`,
      params,
    }),
    method: 'GET',
  })
);

export default apiCall;
