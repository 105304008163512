import {
  useFetchApiGet,
  useFetchApiList,
  usePostApi,
} from '@stagapps/redux-utils';
import { Form } from 'antd';
import deleteApiCall from 'apps/education/apiCalls/course/delete';
import getApiCall from 'apps/education/apiCalls/course/detail';
import editApiCall from 'apps/education/apiCalls/course/edit';
import courseListApiCall from 'apps/education/apiCalls/course/list';
import topicListApiCall from 'apps/education/apiCalls/topic/list';
import PageTitle from 'apps/home/components/PageTitle';
import ErrorMessage from 'common/ui/ErrorMessage';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { convertFormData } from 'utils/formData.js';
import ItemForm from './Form';

const CourseEdit = () => {
  const [form] = Form.useForm();
  const params = useParams();

  const {
    data: item,
    load: fetchItem,
    isLoading,
    error: fetchItemError,
  } = useFetchApiGet(getApiCall, { resourceName: 'item' });

  const {
    data: topics,
    load: fetchTopics,
    isTopicLoading,
    paging,
  } = useFetchApiList(topicListApiCall, { resourceName: 'items' });

  const {
    data: courses,
    load: fetchCourses,
    isCourseLoading,
    pagingCourses,
  } = useFetchApiList(courseListApiCall, { resourceName: 'items' });

  const { post: submitItem, isSubmitting, error: submitItemError } = usePostApi(
    editApiCall,
    {
      resourceName: 'item',
    }
  );

  const {
    post: deleteItem,
    isSubmitting: isDeleting,
    error: deleteError,
  } = usePostApi(deleteApiCall, {
    resourceName: 'item',
  });

  useEffect(() => {
    fetchTopics();
    fetchCourses();
    fetchItem({ id: params.id });
  }, []);

  const onFinish = (values, isDraft, isRemoved) => {
    console.debug('Received values of form: ', values);
    const postData = async () => {
      try {
        let submitData = values;
        submitData.points = 0;
        submitData.is_active = 0;

        if (
          values.photo &&
          values.photo.at(-1) &&
          values.photo.at(-1).originFileObj instanceof File
        ) {
          submitData.photo = values.photo[0].originFileObj;
          submitData = convertFormData(submitData);
        } else {
          delete submitData['photo'];
          if (isRemoved) {
            submitData.photo = null;
          }
        }

        submitItem({ id: params.id, payload: submitData });
      } catch (error) {
        console.error(error.message);
      }
    };

    postData();
  };

  if (isLoading || isTopicLoading || isCourseLoading) {
    return <LoadingSpinner />;
  }

  if (fetchItemError) {
    return <ErrorMessage {...fetchItemError} />;
  }

  let newCourses = courses.filter(c => c.id != params.id);

  return (
    <>
      <PageTitle title={`Edit Course`} />
      <ItemForm
        submiting={isSubmitting}
        courses={newCourses}
        form={form}
        onFinish={onFinish}
        initialValues={item}
        name="course_edit_form"
        visibility="visible"
        topics={topics}
        onDelete={() => {
          if (confirm('Are you sure?')) {
            deleteItem({ id: params.id });
          }
        }}
      />
    </>
  );
};

export default CourseEdit;
