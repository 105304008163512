import LoadingSpinner from 'common/ui/LoadingSpinner';
import useGoogleAuth from 'hooks/useGoogleAuth';
import { Navigate, Route } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const { isLoading, isAuthenticated, user } = useGoogleAuth();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!isAuthenticated) {
    const url = location.pathname
      ? `/login?url=${location.pathname}`
      : '/login';
    return <Navigate to={url} />;
  }

  return children;
};

const GuestRoute = ({ children }) => {
  const { loading, isAuthenticated } = useGoogleAuth();

  if (loading) {
    return (
      <Route>
        <LoadingSpinner />
      </Route>
    );
  }

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
};

export { GuestRoute, PrivateRoute };
