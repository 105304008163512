import { usePostApi } from '@stagapps/redux-utils';
import { Form } from 'antd';
import PageTitle from 'apps/home/components/PageTitle';
import { useParams } from 'react-router-dom';
import { convertFormData } from 'utils/formData.js';

const AdminCreate = ({ ItemForm, apiCall, title, listUrl, initialValues }) => {
  const [form] = Form.useForm();
  const params = useParams();

  const {
    post: submitItem,
    isLoading: isSubmitting,
    error: submitItemError,
  } = usePostApi(apiCall.create, {
    resourceName: 'item',
  });

  const onFinish = (values, photoFields = ['photo']) => {
    console.debug(
      'Received values of form: ',
      values,
      'phootFields',
      photoFields
    );

    const postData = async () => {
      try {
        let submitData = values;
        let isConvertFormData = false;

        for (let i = 0; i < photoFields.length; i++) {
          const field = photoFields[i];
          const fieldValue = values[field];
          if (
            fieldValue &&
            fieldValue.at(-1) &&
            fieldValue.at(-1).originFileObj instanceof File
          ) {
            submitData[field] = values[field][0].originFileObj;
            isConvertFormData = true;
          } else {
            delete submitData[field];
          }
        }

        if (isConvertFormData) {
          submitData = convertFormData(submitData);
        }
        submitItem({ ...params, payload: submitData });
      } catch (error) {
        console.error(error.message);
      }
    };

    postData();
  };

  return (
    <>
      <PageTitle title={title} backUrl={listUrl} />
      <ItemForm
        params={params}
        submiting={isSubmitting}
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        name="create_form"
      />
    </>
  );
};

export default AdminCreate;
