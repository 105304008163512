import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Tag } from 'antd';
import apiCall from 'apps/investment/apiCalls/investmentPlan';
import { CreatePage, ListPage } from 'common/ui/AdminCRUD/page';
import { activeColors, activeLabels } from 'utils/constants';
import { formatDateTime, isZeroDate } from 'utils/formatDate';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { useFetchApiList } from '@stagapps/redux-utils';
import apiEmployeeProgramDetailCall from 'apps/investment/apiCalls/employeeProgram/detail';

import ItemForm from './Form';

export const InvestmentPlanListPage = props => {
  const [select, setSelect] = useState(null);

  const { id } = useParams();

  const columnsGenerator = () => [
    {
      title: 'Status',
      dataIndex: 'is_active',
      render: isActive => {
        const status = isActive ? 'active' : 'inactive';
        return <Tag color={activeColors[status]}>{activeLabels[status]}</Tag>;
      },
    },
    {
      title: 'Company Investment Details',
      dataIndex: 'company_investment_details',
      children: [
        {
          title: 'Product Code',
          dataIndex: 'company_investment_details',
          key: 'company_product_code',
          width: 150,
          render: detail => (
            <div>
              {detail ? detail.map(e => (
                <p>{e.product_code}</p>
              )) : '-'}
            </div>
          ),
        },
        {
          title: 'Percentage',
          dataIndex: 'company_investment_details',
          key: 'company_percentage',
          width: 150,
          render: detail => (
            <div>
              {detail ? detail.map(e => (
                <p>{e.percentage}%</p>
              )) : '-'}
            </div>
          ),
        },
        {
          title: 'Amount',
          dataIndex: 'company_investment_details',
          key: 'company_amount',
          width: 150,
          render: detail => (
            <div>
              {detail ? detail.map(e => (
                <p>{new Intl.NumberFormat('en-US').format(e.amount)}</p>
              )) : '-'}
            </div>
          ),
        },
      ],
    },
    {
      title: 'Employee Investment Details',
      dataIndex: 'employee_investment_details',
      children: [
        {
          title: 'Product Code',
          dataIndex: 'employee_investment_details',
          key: 'product_code',
          width: 150,
          render: detail => (
            <div>
              {detail ? detail.map(e => (
                <p>{e.product_code}</p>
              )) : '-'}
            </div>
          ),
        },
        {
          title: 'Percentage',
          dataIndex: 'employee_investment_details',
          key: 'percentage',
          width: 150,
          render: detail => (
            <div>
              {detail ? detail.map(e => (
                <p>{e.percentage}%</p>
              )) : '-'}
            </div>
          ),
        },
        {
          title: 'Amount',
          dataIndex: 'employee_investment_details',
          key: 'amount',
          width: 150,
          render: detail => (
            <div>
              {detail ? detail.map(e => (
                <p>{new Intl.NumberFormat('en-US').format(e.amount)}</p>
              )) : '-'}
            </div>
          ),
        },
      ],
    },
    {
      title: 'Version',
      dataIndex: 'investment_plan_version',
    },
    {
      title: 'Activated At',
      dataIndex: 'activated_at',
      render: value => {
        if (isZeroDate(value)) return '-';
        return formatDateTime(value);
      },
    },
  ];

  return (
    <ListPage
      rowKey={'investment_plan_version'}
      title="Investment Plan"
      apiCall={apiCall}
      columnGenerator={props => columnsGenerator(props)}
      resourceName="data"
      createUrl={`/investment/employee-programs/${id}/investment-plans/create`}
    />
  );
};

export const InvestmentPlanCreatePage = props => {
  const { id } = useParams();

  const {
    data: employeeProgram = {},
    load: loadEmployeeProgram,
    isLoadingEmployeeProgram,
  } = useFetchApiList(apiEmployeeProgramDetailCall, { resourceName: 'data' });

  useEffect(() => {
    loadEmployeeProgram({id: id});
  }, []);

  if (isLoadingEmployeeProgram) {
    return <LoadingSpinner />;
  }

  console.log('xxx', employeeProgram);

  return (
    <CreatePage
      title="Investment Plan Create"
      apiCall={apiCall}
      listUrl={`/investment/employee-programs/${id}/investment-plans`}
      ItemForm={ItemForm}
      resourceName="item"
      initialValues={{ employeeContributeAmount: employeeProgram.employee_contribute_amount, companyContributeAmount: employeeProgram.company_contribute_amount }}
      {...props}
    />
  );
};
