import { Button, Descriptions, Space, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { statusColors, statusLabel } from 'utils/constants';
import { formatDateTime } from 'utils/formatDate';
import { cloudflareCdn } from 'utils/image';
import PerformanceDetail from './Performance';

const ItemDetail = ({ item }) => {
  return (
    <>
      <Descriptions
        title="Fund Info"
        column={1}
        bordered
        style={{ margin: '10px 0' }}
      >
        <Descriptions.Item label="Symbol">{item.symbol}</Descriptions.Item>
        <Descriptions.Item label="Name">{item.name}</Descriptions.Item>
        <Descriptions.Item label="Fund Type">{item.type}</Descriptions.Item>
        <Descriptions.Item label="Description">
          {item.description}
        </Descriptions.Item>
        <Descriptions.Item label="Investment Objective">
          {item.investment_objective}
        </Descriptions.Item>
        <Descriptions.Item label="Strategy">{item.strategy}</Descriptions.Item>
        <Descriptions.Item label="Photo">
          <img width={60} src={cloudflareCdn(item.photo, 100)} />
        </Descriptions.Item>
        <Descriptions.Item label="Fund Manager">
          {item.fund_manager}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          {' '}
          <Tag color={statusColors[item.status]}>
            {statusLabel[item.status]}
          </Tag>
        </Descriptions.Item>
      </Descriptions>

      <Descriptions column={2} bordered style={{ margin: '10px 0' }}>
        <Descriptions.Item label="Management Fee">
          {item.management_fee}
        </Descriptions.Item>
        <Descriptions.Item label="Subscription Fee">
          {item.subscription_fee}
        </Descriptions.Item>
        <Descriptions.Item label="Management Fee">
          {item.redemption_fee}
        </Descriptions.Item>
        <Descriptions.Item label="Switching Fee">
          {item.switching_fee}
        </Descriptions.Item>
        <Descriptions.Item label="Trading Cycle">
          {item.trading_cycle}
        </Descriptions.Item>
        <Descriptions.Item label="Distributors">
          {item.distributors}
        </Descriptions.Item>
        <Descriptions.Item label="Created At">
          {formatDateTime(item.created)}
        </Descriptions.Item>
        <Descriptions.Item label="Updated At">
          {formatDateTime(item.modified)}
        </Descriptions.Item>
        <Descriptions.Item label="Actions">
          <Space>
            <Button type="primary">
              <Link to={`/market/funds/${item.id}/edit`}>Edit</Link>
            </Button>
          </Space>
        </Descriptions.Item>
      </Descriptions>
      <PerformanceDetail symbol={item.symbol} />
    </>
  );
};

export default ItemDetail;
