import { Button, Form, Select } from 'antd';
import { useEffect } from 'react';

import { programOrderStatusOptions } from 'apps/investment/constants/programOrder';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const EmployeeProgramForm = ({
  form,
  submiting,
  onFinish,
  initialValues = {},
  name,
}) => {
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'stock_form'}
      onFinish={onFinish}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item name="status" label="Status">
        <Select>
          {programOrderStatusOptions.map(option => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EmployeeProgramForm;
