import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { useMemo, useRef } from 'react';

function DebounceSelect({
  fetchOptions,
  fetching,
  options,
  debounceTimeout = 800,
  displayFn,
  params = {},
  ...props
}) {
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = value => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      if (value) fetchOptions({ search: value, ...params });

      // .then(newOptions => {
      //   if (fetchId !== fetchRef.current) {
      //     // for fetch callback order
      //     return;
      //   }
      // });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      showSearch={true}
      onSearch={debounceFetcher}
      filterOption={false}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      options={displayFn ? options.map(displayFn) : options}
      allowClear
      {...props}
    />
  );
}

export default DebounceSelect;
