import React, { useEffect } from 'react';
import { Form, Input, Button, InputNumber, Switch } from 'antd';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
  props,
}) => {
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={onFinish}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item name="user" label="User">
        <InputNumber readOnly />
      </Form.Item>

      <Form.Item name="community" label="Community">
        <InputNumber readOnly />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        {onDelete ? (
          <Button
            onClick={onDelete}
            type="primary"
            style={{
              visibility: visibility,
              margin: 10,
              backgroundColor: 'red',
              borderColor: 'red',
            }}
          >
            Remove user
          </Button>
        ) : null}
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
