import { useFetchApiList } from '@stagapps/redux-utils';
import apiCall from 'apps/market/apiCalls/industry';
import { useEffect } from 'react';
import SelectField from './SelectField';

export default function IndustrySelectField(props) {
  let options = [];

  const {
    data: items,
    load: fetchItems,
    loadMore,
    isLoadingMore,
    isLoading,
    paging,
  } = useFetchApiList(apiCall.list, { resourceName: 'items' });

  useEffect(() => {
    console.log('Fetch industry...');

    if (props.filters) {
      fetchItems(props.filters);
    } else {
      fetchItems({ level: 1 });
    }
  }, []);

  if (!isLoading && items && items.length > 0) {
    options = items.map((v, k) => {
      return {
        value: v.icb_code,
        label: `${v.name} (#${v.icb_code}) - Level: ${v.level}`,
      };
    });
  }

  options = options.filter(v => v.value);

  return (
    <SelectField
      placeholder="Select Industries"
      mode="multiple"
      style={{ width: '100%' }}
      {...props}
      options={options}
    />
  );
}
