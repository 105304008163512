import cancelApiCall from './cancel';
import detailApiCall from './detail';
import editApiCall from './edit';
import generateApiCall from './generate';
import listApiCall from './list';
import rebundleApiCall from './rebundle';

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  create: listApiCall,
  edit: editApiCall,
  delete: editApiCall,
  create: generateApiCall,
  cancel: cancelApiCall,
  rebundle: rebundleApiCall,
};

export default ApiCall;
