import { Descriptions } from 'antd';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/formatDate';

const ItemDetail = ({ item }) => {
  return (
    <>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="Name">{item.name}</Descriptions.Item>
        <Descriptions.Item label="Name English">
          {item.name_en}
        </Descriptions.Item>
        <Descriptions.Item label="Brand Name">
          {item.brand_name}
        </Descriptions.Item>
        <Descriptions.Item label="Tax Number">
          {item.tax_number}
        </Descriptions.Item>
        <Descriptions.Item label="Phone">{item.phone}</Descriptions.Item>
        <Descriptions.Item label="Work Email">{item.email}</Descriptions.Item>
        <Descriptions.Item label="Address">{item.address}</Descriptions.Item>
        <Descriptions.Item label="Bank Name">
          {item.bank_name}
        </Descriptions.Item>
        <Descriptions.Item label="Bank Account Name">
          {item.bank_account_name}
        </Descriptions.Item>
        <Descriptions.Item label="Bank Account Number">
          {item.bank_account_number}
        </Descriptions.Item>
        <Descriptions.Item label="Bank Branch Name">
          {item.bank_branch_name}
        </Descriptions.Item>
        <Descriptions.Item label="Stag Business Account">
          {item.user ? (
            <Link to={`/investment/users/${item.user.id}`}>
              {item.user.name}{' '}
            </Link>
          ) : (
            'Not onboard yet'
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Created At">
          {formatDateTime(item.created_at)}
        </Descriptions.Item>
        <Descriptions.Item label="Updated At">
          {formatDateTime(item.updated_at)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default ItemDetail;
