import apiCall from 'apps/education/apiCalls/gamePortfolioItems';
import { ListPage } from 'common/ui/AdminCRUD/page';
const columnsGenerator = params => [
  {
    title: 'Room Id',
    dataIndex: 'game_room',
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
  },
  {
    title: 'Volume',
    dataIndex: 'volume',
    render: value => new Intl.NumberFormat('en-US').format(value),
    align: 'right',
  },
  {
    title: 'Avg Price',
    dataIndex: 'avg_price',
    render: value => new Intl.NumberFormat('en-US').format(value),
    align: 'right',
  },
  {
    title: 'Market Value',
    dataIndex: 'market_price',
    render: value => new Intl.NumberFormat('en-US').format(value),
    align: 'right',
  },
  {
    title: 'ROI',
    dataIndex: 'roi',
    render: value => new Intl.NumberFormat('en-US').format(value),
    align: 'right',
  },
  {
    title: 'User',
    dataIndex: 'user',
    render: obj => `${obj.name} (${obj.email})`,
  },
  {
    title: 'Item Id',
    dataIndex: 'id',
  },
];

export const GamePortfolioItemsListPage = props => {
  return (
    <ListPage
      enableSearch={true}
      searchOptions={{
        placeHolder: "Enter user's name, email, userID or user fullname",
      }}
      title="Game Portfolio Items"
      apiCall={apiCall}
      columnGenerator={props => columnsGenerator(props)}
      resourceName="items"
      {...props}
    />
  );
};
