import { useFetchApiList } from '@stagapps/redux-utils';
import { Pagination, Select, Spin } from 'antd';
import { debounce } from 'lodash';
import { useEffect, useMemo } from 'react';

const debounceTimeout = 800;

function SelectListData({
  apiCall,
  params,
  callback,
  isDebounceFetcher,
  ...props
}) {
  const { data, load, isLoading, loadMore, error, refresh, paging } =
    useFetchApiList(apiCall, { resourceName: 'data' }) || {};

  useEffect(() => {
    load(params);
  }, [JSON.stringify(params)]);

  const { error: errorMessage, error_code } = error || {};
  const { page, count, page_count, page_size } = paging || {};

  const list = e => {
    const { id, name } = e || {};
    return {
      value: id,
      label: name,
    };
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = value => {
      load({ ...params, search: value });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [load, debounceTimeout]);

  return (
    <>
      <Select
        notFoundContent={isLoading ? <Spin size="small" /> : null}
        dropdownRender={menu => (
          <div>
            {menu}
            <Pagination
              size="small"
              current={page}
              defaultPageSize={page_size}
              pageSize={page_size}
              total={count}
              onChange={page => load({ page, ...params })}
              showSizeChanger={false}
              hideOnSinglePage={true}
            />
          </div>
        )}
        status={!error ? null : 'error'}
        allowClear
        onSearch={debounceFetcher}
        loading={isLoading}
        options={
          !!error || isLoading
            ? []
            : data.map(e => (callback ? callback(e) : list(e)))
        }
        {...props}
      />
      {!!error ? (
        <span className="text-red-500">
          {errorMessage || error_code || error}
        </span>
      ) : null}
    </>
  );
}

export default SelectListData;
