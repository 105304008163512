import { useState } from 'react';
import slugify from 'slugify';

import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Image, Input, InputNumber, Select, Upload } from 'antd';
import {
  placeholderOptions,
  typeOptions,
} from 'apps/education/apiCalls/poll/options';
import { statusOptions } from 'utils/constants';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  const { photo } = initialValues;

  const onBlur = () => {
    const inputValue = form.getFieldsValue();
    !inputValue.slug &&
      form.setFieldsValue({ slug: slugify(inputValue.title).toLowerCase() });
  };

  const [isRemoved, setIsRemoved] = useState(false);
  const removePhoto = () => {
    setIsRemoved(true);
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={values => onFinish(values)}
      initialValues={initialValues}
      scrollToFirstError
      onBlur={onBlur}
    >
      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Nền tảng chứng khoán căn bản" />
      </Form.Item>

      <Form.Item name="description" label="Description">
        <Input.TextArea rows={10} />
      </Form.Item>

      <Form.Item
        name="photo"
        label="Upload a new photo"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      {isRemoved || !photo ? null : (
        <Form.Item
          label="Existing photo"
          style={{
            visibility,
          }}
        >
          <Button
            type="primary"
            shape="circle"
            size="small"
            style={{
              backgroundColor: 'red',
              position: 'absolute',
              zIndex: 99,
              left: 5,
              top: 5,
            }}
            onClick={() => removePhoto()}
          >
            X
          </Button>
          <Image width={200} src={photo}></Image>
        </Form.Item>
      )}

      <Form.Item name="option1" label="Option 1">
        <Input placeholder="Đáp án câu 1" />
      </Form.Item>
      <Form.Item name="option2" label="Option 2">
        <Input placeholder="Đáp án câu 2" />
      </Form.Item>
      <Form.Item name="option3" label="Option 3">
        <Input placeholder="Đáp án câu 3" />
      </Form.Item>
      <Form.Item name="option4" label="Option 4">
        <Input placeholder="Đáp án câu 4" />
      </Form.Item>
      <Form.Item name="option5" label="Option 5">
        <Input placeholder="Đáp án câu 5" />
      </Form.Item>
      <Form.Item name="option6" label="Option 6">
        <Input placeholder="Đáp án câu 6" />
      </Form.Item>
      <Form.Item name="option7" label="Option 7">
        <Input placeholder="Đáp án câu 7" />
      </Form.Item>
      <Form.Item name="option8" label="Option 8">
        <Input placeholder="Đáp án câu 8" />
      </Form.Item>
      <Form.Item name="option9" label="Option 9">
        <Input placeholder="Đáp án câu 9" />
      </Form.Item>
      <Form.Item name="option10" label="Option 10">
        <Input placeholder="Đáp án câu 10" />
      </Form.Item>
      <Form.Item name="option11" label="Option 11">
        <Input placeholder="Đáp án câu 11" />
      </Form.Item>
      <Form.Item name="option12" label="Option 12">
        <Input placeholder="Đáp án câu 12" />
      </Form.Item>
      <Form.Item name="option13" label="Option 13">
        <Input placeholder="Đáp án câu 13" />
      </Form.Item>
      <Form.Item name="option14" label="Option 14">
        <Input placeholder="Đáp án câu 14" />
      </Form.Item>
      <Form.Item name="option15" label="Option 15">
        <Input placeholder="Đáp án câu 15" />
      </Form.Item>
      <Form.Item name="option16" label="Option 16">
        <Input placeholder="Đáp án câu 16" />
      </Form.Item>
      <Form.Item name="option17" label="Option 17">
        <Input placeholder="Đáp án câu 17" />
      </Form.Item>
      <Form.Item name="option18" label="Option 18">
        <Input placeholder="Đáp án câu 18" />
      </Form.Item>
      <Form.Item name="option19" label="Option 19">
        <Input placeholder="Đáp án câu 19" />
      </Form.Item>
      <Form.Item name="option20" label="Option 20">
        <Input placeholder="Đáp án câu 20" />
      </Form.Item>

      <Form.Item
        name="ranking"
        label="Poll Number"
        tooltip="Smallest number will be on top: 1,2,3.."
      >
        <InputNumber placeholder="1" />
      </Form.Item>

      <Form.Item name="type" label="Poll Type">
        <Select>
          {typeOptions &&
            typeOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item name="placeholder" label="Position">
        <Select>
          {placeholderOptions &&
            placeholderOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item name="status" label="Status">
        <Select>
          {statusOptions &&
            statusOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>

        {onDelete ? (
          <Button
            onClick={onDelete}
            type="primary"
            style={{
              visibility: visibility,
              margin: 10,
              backgroundColor: 'red',
              borderColor: 'red',
            }}
          >
            Delete
          </Button>
        ) : null}
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
