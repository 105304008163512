import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Image, Input, Select, Space, Upload } from 'antd';
import imageUploadCall from 'apps/education/apiCalls/editorImage/upload';
import TextEditor from 'common/form/TextEditor';
import DatePicker from 'common/ui/UIDatePicker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DRAFT_STATUS, PUBLISH_STATUS } from 'utils/constants';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { mappingAdvisoryAccessType } from './option';

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  params,
  onDelete,
  visibility = 'hidden',
}) => {
  const { photo, status, access_type = 1001, published_at } = initialValues;

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    form.setFieldsValue({
      ...initialValues,
      author: 'stag',
      published_at: published_at ? moment(published_at) : undefined,
      access_type: access_type?.toString(),
    });
  }, [form, initialValues]);

  const [isRemoved, setIsRemoved] = useState(false);
  const [isDraft, setIsDraft] = useState(false);

  const removePhoto = () => {
    setIsRemoved(true);
  };

  var draftButtonLabel = 'Save as draft';
  var publishButtonLabel = 'Save & Publish';

  if (status == PUBLISH_STATUS) {
    publishButtonLabel = 'Save';
    draftButtonLabel = 'Switch to draft';
  }

  return (
    <Form
      style={{ width: '75%' }}
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={values =>
        onFinish(
          {
            ...values,
            ...params,
            published_at: values?.published_at
              ? values.published_at.toISOString()
              : null,
            access_type: parseInt(values.access_type),
            status: isDraft ? DRAFT_STATUS : PUBLISH_STATUS,
          },

          ['photo']
        )
      }
      scrollToFirstError
    >
      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Mã chứng khoán là gì?" />
      </Form.Item>

      <Form.Item name="description" label="Description">
        <TextEditor uploadCallback={imageUploadCall} value={form.fieldValue} />
      </Form.Item>

      <Form.Item name="suggestion" label="Suggestion">
        <TextEditor uploadCallback={imageUploadCall} value={form.fieldValue} />
      </Form.Item>

      <Form.Item name="published_at" label="Published date">
        <DatePicker showTime format="DD-MM-YYYY HH:mm:ss" />
      </Form.Item>

      <Form.Item name="excerpt" label="Excerpt">
        <Input.TextArea rows={5} />
      </Form.Item>

      <Form.Item
        name="photo"
        label="Upload new photo"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>
      {isRemoved || !photo ? null : (
        <Form.Item
          label="Upload a new photo"
          style={{
            visibility,
          }}
        >
          <Button
            type="primary"
            shape="circle"
            size="small"
            style={{
              backgroundColor: 'red',
              position: 'absolute',
              zIndex: 99,
              left: 5,
              top: 5,
            }}
            onClick={() => removePhoto()}
          >
            X
          </Button>
          <Image width={200} src={photo}></Image>
        </Form.Item>
      )}

      <Form.Item
        name="author"
        label="Author"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Select placeholder="Select an author">
          <Select.Option value={'stag'}>{'Stag'}</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="access_type"
        label="Access Type"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Select placeholder="Select an access type">
          {Object.entries(mappingAdvisoryAccessType).map(([k, v]) => (
            <Select.Option key={k} value={k}>
              {v}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Space>
          <Button htmlType="submit" type="primary" loading={submiting}>
            {publishButtonLabel}
          </Button>
          <Button
            onClick={() => setIsDraft(true)}
            danger
            htmlType="submit"
            loading={submiting}
          >
            {draftButtonLabel}
          </Button>
          {onDelete ? (
            <Button onClick={onDelete} danger>
              Move to trash
            </Button>
          ) : null}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
