import apiCall from 'apps/investment/apiCalls/fundAccount';
import { DetailPage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { useParams } from 'react-router-dom';
import ItemForm from './Form';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const InvestmentFundAccountListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    title="All Fund Accounts"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="data"
    {...props}
  />
);

export const InvestmentFundAccountDetailPage = props => (
  <DetailPage
    title="Fund Account Detail"
    apiCall={apiCall}
    listUrl={`/investment/fund-accounts`}
    ItemDetail={ItemDetail}
    resourceName="data"
    {...props}
  />
);

export const InvestmentFundAccountEditPage = props => {
  const params = useParams();

  return (
    <EditPage
      title="Fund Account Edit"
      apiCall={apiCall}
      listUrl={`/investment/fund-accounts`}
      ItemForm={ItemForm}
      options={{ title: 'Edit Fund Account' }}
      resourceName="data"
      {...props}
    />
  );
};
