import { useEffect } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Image, Input, InputNumber, Select, Upload } from 'antd';
import { screenOptions } from 'apps/education/constants/screen/options';
import { statusOptions } from 'utils/constants';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  const { photo } = initialValues;

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={onFinish}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="photo"
        label="Upload a new photo"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item
        label="Existing photo"
        style={{
          visibility: visibility,
        }}
      >
        <Image width={200} src={photo} />
      </Form.Item>

      <Form.Item
        name="ranking"
        label="Group Number"
        tooltip="Smallest number will be on top: 1,2,3.."
      >
        <InputNumber />
      </Form.Item>

      <Form.Item name="excerpt" label="Excerpt">
        <Input.TextArea rows={10} />
      </Form.Item>

      <Form.Item name="description" label="Description">
        <Input.TextArea rows={10} />
      </Form.Item>

      <Form.Item name="screen_name" label="Screen Name">
        <Select>
          {screenOptions &&
            screenOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item name="tab_name" label="Tab Name">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="screen_id" label="Screen ID">
        <InputNumber />
      </Form.Item>

      <Form.Item name="external_link" label="External Link">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="status" label="Content status">
        <Select>
          {statusOptions.map(o => (
            <Select.Option key={o.value} value={o.value}>
              {o.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>

        {onDelete ? (
          <Button
            onClick={onDelete}
            type="primary"
            style={{
              visibility: visibility,
              margin: 10,
              backgroundColor: 'red',
              borderColor: 'red',
            }}
          >
            Delete
          </Button>
        ) : null}
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
