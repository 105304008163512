import { Button, Form, Input, InputNumber, Select, Space, Switch } from 'antd';
import imageUploadCall from 'apps/education/apiCalls/editorImage/upload';
import ExchangeSelectField from 'common/form/ExchangeSelectField';
import IndustrySelectField from 'common/form/IndustrySelectField';
import MarketIndexSelectField from 'common/form/MarketIndexSelectField';
import StockSelectField from 'common/form/StockSelectField';
import TextEditor from 'common/form/TextEditor';
import DatePicker from 'common/ui/UIDatePicker';
import moment from 'moment';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { convertDateFormData, ensureDateTimeFields } from 'utils/formData';

const { RangePicker } = DatePicker;
const dateFields = ['join_cut_off_time'];

const rangeConfig = {
  rules: [
    {
      type: 'array',
      required: false,
      message: 'Please select time!',
    },
  ],
};

const ItemForm = ({ form, onFinish, submiting, initialValues = {}, name }) => {
  const convertFormData = values => {
    const formValues = ensureDateTimeFields(values, dateFields);
    const startDateValue = formValues.started_at
      ? moment(formValues.started_at)
      : '';
    const endDateValue = formValues.ended_at ? moment(formValues.ended_at) : '';

    return {
      ...formValues,
      game_datetime_range_picker: [startDateValue, endDateValue],
    };
  };

  const isGameStarted = moment().isAfter(moment(initialValues.started_at));
  const isGameEnded = moment().isAfter(moment(initialValues.ended_at));

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={values => {
        // build start / end date range
        const rangeValue = values['game_datetime_range_picker'];
        delete values['game_datetime_range_picker'];

        const formValues = {
          ...convertDateFormData(values),
          started_at: rangeValue[0] ? rangeValue[0].toISOString() : null,
          ended_at: rangeValue[1] ? rangeValue[1].toISOString() : null,
          join_cut_off_time: values.join_cut_off_time
            ? values.join_cut_off_time.toISOString()
            : null,
        };
        return onFinish(formValues);
      }}
      initialValues={convertFormData(initialValues)}
      scrollToFirstError
    >
      <Form.Item name="initial_capital" label="Initial Capital">
        <InputNumber disabled={isGameStarted} />
      </Form.Item>
      <Form.Item name="currency_code" label="Currency Code">
        <Input />
      </Form.Item>
      {/* <Form.Item name="currency_icon" label="Currency Icon" >
        <Input />
      </Form.Item> */}
      <Form.Item name="currency_color" label="Currency Color">
        <Input placeholder="#ffffff" />
      </Form.Item>

      <Form.Item
        name="game_datetime_range_picker"
        label="Start / End"
        {...rangeConfig}
      >
        <RangePicker
          showTime
          format="YYYY-MM-DD HH:mm:ss"
          disabled={isGameEnded}
        />
      </Form.Item>

      <Form.Item name="join_type" label="Join Type">
        <Select disabled={isGameEnded}>
          <Select.Option key={1000} value={1000}>
            Free to go
          </Select.Option>
          <Select.Option key={1001} value={1001}>
            Request to join
          </Select.Option>
          <Select.Option key={1002} value={1002}>
            Password Required
          </Select.Option>
          <Select.Option key={1003} value={1003}>
            Ticket purchase (virtual / real cash)
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="join_cut_off_time"
        label="Join Cut Off Time"
        tooltip="Start date will be the cut off time when the field is not set."
      >
        <DatePicker showTime={true} disabled={isGameEnded} />
      </Form.Item>

      <Form.Item name="password" label="Password To Join">
        <Input />
      </Form.Item>

      <Form.Item
        name="join_description"
        label="Joining Description"
        tooltip="Describe the way to join the game"
      >
        <TextEditor uploadCallback={imageUploadCall} value={form.fieldValue} />
      </Form.Item>

      <ExchangeSelectField
        name="ticker_exchange"
        label="Tickers by Exchange"
        placeholder="Select exchanges"
        disabled={isGameStarted}
      />

      <MarketIndexSelectField
        name="ticker_index"
        label="Ticker by Index"
        placeholder="Select indexes"
        disabled={isGameStarted}
      />

      <IndustrySelectField
        filters={{ level: 1 }}
        name="ticker_industry_icb"
        label="Ticket by Industry ICB"
        disabled={isGameStarted}
      />

      <Form.Item name="ticker_list" label="Ticket List">
        <StockSelectField placeholder="Select symbols" copyEnabled={true} />
      </Form.Item>

      <Form.Item name="ticker_exception_list" label="Ticket Exception List">
        <StockSelectField
          placeholder="Select exception symbols"
          disabled={isGameStarted}
          copyEnabled={true}
        />
      </Form.Item>

      <Form.Item name="brokerage_fee" label="Brokerage Fee">
        <InputNumber disabled={isGameStarted} />
      </Form.Item>
      <Form.Item name="tax_rate" label="Tax Rate">
        <InputNumber disabled={isGameStarted} />
      </Form.Item>
      <Form.Item name="depository_rate" label="Depository Rate">
        <InputNumber disabled={isGameStarted} />
      </Form.Item>
      <Form.Item name="minimum_ticker_ranking" label="Minimum Number Ticker ">
        <InputNumber disabled={isGameStarted} />
      </Form.Item>
      <Form.Item
        name="portfolio_visibility"
        label="Portfolio Visibility"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        name="portfolio_performance_summary"
        label="Portfolio Performance Summary"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item name="game_mode" label="Game Mode">
        <Select disabled={isGameStarted}>
          <Select.Option key={1001} value={1001}>
            Normal Mode
          </Select.Option>
          <Select.Option key={1002} value={1002}>
            Death Mode
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Space>
          <Button
            htmlType="submit"
            type="primary"
            loading={submiting}
            disabled={isGameEnded}
          >
            Save
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
