import { Tag } from 'antd';
import apiCall from 'apps/market/apiCalls/portfolioStock';
import { ListPage } from 'common/ui/AdminCRUD/page';

const columnsGenerator = () => [
  {
    title: 'Stock',
    dataIndex: 'stock',
    render: value => {
      const symbol = value && value.code.toUpperCase();
      return <Tag color={'#52c41a'}>{symbol}</Tag>;
    },
  },
  {
    title: 'User',
    dataIndex: 'portfolio',
    render: value => {
      return value && value.user && value.user.email;
    },
  },
  {
    title: 'Total own volume',
    dataIndex: 'volume',
  },
  {
    title: 'Avg buy price',
    dataIndex: 'avg_price',
  },
];

export const PortfolioStocksListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    searchOptions={{
      placeHolder: "Enter user's name, email, userID or device name",
    }}
    title="All Portfolios"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="items"
    {...props}
  />
);
