export { default as createApiCall } from './create';
export { default as deleteApiCall } from './delete';
export { default as detailApiCall } from './detail';
export { default as editApiCall } from './edit';
export { default as listApiCall } from './list';

import createApiCall from './create';
import deleteApiCall from './delete';
import detailApiCall from './detail';
import editApiCall from './edit';
import listApiCall from './list';

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  create: createApiCall,
  edit: editApiCall,
  delete: deleteApiCall,
};

export default ApiCall;
