import { Button, Tag } from 'antd';
import { usePostApi } from '@stagapps/redux-utils';
import { useParams } from 'react-router-dom';
import apiCall from 'apps/investment/apiCalls/userIdentificationImage';
import { CreatePage, ListPage } from 'common/ui/AdminCRUD/page';
import {
  investmentIdentificationImageTypeColors,
  investmentIdentificationImageTypeLabels,
  investmentIdentificationImageStatusColors,
  investmentIdentificationImageStatusLabel,
} from 'utils/constants';
import { formatISODate } from 'utils/formatDate';
import { cloudflareCdn } from 'utils/image';
import ItemForm from './Form';

const columnsGenerator = (params, onDelete, isDeleting) => [
  {
    title: 'Code',
    dataIndex: 'code',
  },
  {
    title: 'Type',
    dataIndex: 'image_type',
    render: imageType => {
      return (
        <Tag color={investmentIdentificationImageTypeColors[imageType]}>
          {investmentIdentificationImageTypeLabels[imageType]}
        </Tag>
      );
    },
  },
  {
    title: 'Photo',
    dataIndex: 'image_url',
    render: value =>
      value && <img width={400} src={cloudflareCdn(value, 400)} />,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: status => {
      return (
        <Tag color={investmentIdentificationImageStatusColors[status]}>
          {investmentIdentificationImageStatusLabel[status]}
        </Tag>
      );
    },
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    sorter: true,
    render: value => formatISODate(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        {onDelete ? (
          <Button
            onClick={() => {
              let confirmCode = prompt(`Please enter image ID to delete: ${record.code}`);
              if (confirmCode === record.code) {
                onDelete({ userId: record.user_id, identificationImageId: record.id });
              }
            }}
            loading={isDeleting}
            type="primary"
            style={{
              visibility: true,
              margin: 10,
              backgroundColor: 'red',
              borderColor: 'red',
            }}
          >
            Delete
          </Button>
        ) : null}
      </Button.Group>
    ),
  },
];

export const InvestmentUserIdentificationImageListPage = (props, { params }) => {
  const routeParams = useParams();

  const {
    post: onDelete,
    isLoading: isDeleting,
    error: deleteItemError,
  } = usePostApi(apiCall.delete);

  return <ListPage
    title="All Identification Images"
    apiCall={apiCall}
    params={params}
    createUrl={`/investment/users/${routeParams.userId}/identification-images/create`}
    columnGenerator={props => columnsGenerator(props, onDelete, isDeleting)}
    resourceName="data"
    {...props}
  />
};

export const InvestmentUserIdentificationImageCreatePage = props => {
  const routeParams = useParams();

  return <CreatePage
    title="Identification Images"
    apiCall={apiCall}
    listUrl={`/investment/users/${routeParams.userId}/identification-images/create`}
    ItemForm={ItemForm}
    resourceName="data"
    {...props}
  />
};