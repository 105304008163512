import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

export const FETCH_QUESTION_TYPE_FORM_CREATE_API_ID =
  'HUB/FETCH_QUESTION_TYPE_FORM_CREATE_API_ID';

const apiCall = enhancedMakeFetchAction(
  FETCH_QUESTION_TYPE_FORM_CREATE_API_ID,
  ({ payload }) => ({
    endpoint: `${API_BASE_URL}/investcore-api/admin/edu/lesson-question-type-forms`,
    method: 'POST',
    body: payload,
    notify: true,
  })
);

export default apiCall;
