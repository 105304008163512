export const mappingLessonType = {
    'content': 'Content',
    'quiz_check_box': 'Quiz Checkbox',
    'quiz_multi_check_box': 'Quiz Multi Checkbox',
    'quiz_type_form': 'Quiz Type Form',
    'poll': 'Poll',
    'video': 'Video',
}

export const isQuiz = {
    'content': false,
    'quiz_check_box': true,
    'quiz_multi_check_box': true,
    'quiz_type_form': true,
}