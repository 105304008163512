import React from 'react';
import slugify from 'slugify';

import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Image, Input, Upload } from 'antd';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  params,
  onDelete,
  visibility = 'hidden',
}) => {
  const { photo } = initialValues;

  const onBlur = () => {
    const inputValue = form.getFieldsValue();
    !inputValue.slug &&
      form.setFieldsValue({ slug: slugify(inputValue.title).toLowerCase() });
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={values => onFinish({ ...params, ...values })}
      initialValues={initialValues}
      scrollToFirstError
      onBlur={onBlur}
    >
      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="description" label="Description">
        <Input.TextArea rows={10} />
      </Form.Item>

      <Form.Item
        name="photo"
        label="Upload a new photo"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item
        label="Existing photo"
        style={{
          visibility: visibility,
        }}
      >
        <Image width={200} src={photo} />
      </Form.Item>

      <Form.Item name="option1" label="Option 1">
        <Input placeholder="Đáp án câu 1" />
      </Form.Item>
      <Form.Item name="option2" label="Option 2">
        <Input placeholder="Đáp án câu 2" />
      </Form.Item>
      <Form.Item name="option3" label="Option 3">
        <Input placeholder="Đáp án câu 3" />
      </Form.Item>
      <Form.Item name="option4" label="Option 4">
        <Input placeholder="Đáp án câu 4" />
      </Form.Item>
      <Form.Item name="option5" label="Option 5">
        <Input placeholder="Đáp án câu 5" />
      </Form.Item>
      <Form.Item name="option6" label="Option 6">
        <Input placeholder="Đáp án câu 6" />
      </Form.Item>
      <Form.Item name="option7" label="Option 7">
        <Input placeholder="Đáp án câu 7" />
      </Form.Item>
      <Form.Item name="option8" label="Option 8">
        <Input placeholder="Đáp án câu 8" />
      </Form.Item>
      <Form.Item name="option9" label="Option 9">
        <Input placeholder="Đáp án câu 9" />
      </Form.Item>
      <Form.Item name="option10" label="Option 10">
        <Input placeholder="Đáp án câu 10" />
      </Form.Item>

      <Form.Item name="explanation1" label="Explanation 1">
        <Input placeholder="Giải thích cho câu 1" />
      </Form.Item>
      <Form.Item name="explanation2" label="Explanation 2">
        <Input placeholder="Giải thích cho câu 2" />
      </Form.Item>
      <Form.Item name="explanation3" label="Explanation 3">
        <Input placeholder="Giải thích cho câu 3" />
      </Form.Item>
      <Form.Item name="explanation4" label="Explanation 4">
        <Input placeholder="Giải thích cho câu 4" />
      </Form.Item>
      <Form.Item name="explanation5" label="Explanation 5">
        <Input placeholder="Giải thích cho câu 5" />
      </Form.Item>
      <Form.Item name="explanation6" label="Explanation 6">
        <Input placeholder="Giải thích cho câu 1" />
      </Form.Item>
      <Form.Item name="explanation7" label="Explanation 7">
        <Input placeholder="Giải thích cho câu 7" />
      </Form.Item>
      <Form.Item name="explanation8" label="Explanation 8">
        <Input placeholder="Giải thích cho câu 1" />
      </Form.Item>
      <Form.Item name="explanation9" label="Explanation 9">
        <Input placeholder="Giải thích cho câu 9" />
      </Form.Item>
      <Form.Item name="explanation10" label="Explanation 10">
        <Input placeholder="Giải thích cho câu 10" />
      </Form.Item>

      <Form.Item
        name="answer"
        label="Answer"
        tooltip="Input Option ID. Ex: 1"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input type="number" placeholder="1" />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>

        {onDelete ? (
          <Button
            onClick={onDelete}
            type="primary"
            style={{
              visibility: visibility,
              margin: 10,
              backgroundColor: 'red',
              borderColor: 'red',
            }}
          >
            Delete
          </Button>
        ) : null}
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
