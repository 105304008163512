import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Image, Input, Select, Upload } from 'antd';
import { bankStatusOptions } from 'apps/investment/constants/bank';
import { useEffect } from 'react';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { convertDateFormData, ensureDateFields } from 'utils/formData';

const dateFields = [];

const ItemForm = ({
  form,
  submiting,
  onFinish,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  const { logo } = initialValues;

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    form.setFieldsValue(ensureDateFields(initialValues, dateFields));
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'bank_form'}
      onFinish={values =>
        onFinish(convertDateFormData(values, dateFields), ['logo'])
      }
      initialValues={ensureDateFields(initialValues, dateFields)}
      scrollToFirstError
    >
      <Form.Item name="code" label="VCAM Code">
        <Input placeholder="" readOnly disabled />
      </Form.Item>
      <Form.Item
        name="logo"
        label="Logo"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item
        label="Existing logo"
        style={{
          visibility: visibility,
        }}
      >
        <Image width={200} src={logo} />
      </Form.Item>

      <Form.Item name="name" label="Name">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="en_name" label="Name En">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="trading_name" label="Brand Name (eg: Vietcombank)">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="status" label="Status">
        <Select>
          {bankStatusOptions &&
            bankStatusOptions.map(option => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>

        {onDelete ? (
          <Button
            onClick={onDelete}
            type="primary"
            style={{
              visibility: visibility,
              margin: 10,
              backgroundColor: 'red',
              borderColor: 'red',
            }}
          >
            Delete
          </Button>
        ) : null}
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
