import { useFetchApiGet, usePostApi } from '@stagapps/redux-utils';
import { Button, Card, Descriptions, Space, Tag, Typography } from 'antd';
import apiCall from 'apps/investment/apiCalls/order';
import userProgramOrderListApiCall from 'apps/investment/apiCalls/userProgramOrder/list';
import {
  fundOrderStatusColors,
  fundOrderStatusLabels,
  orderProgramTypeColors,
  orderProgramTypeFlex,
  orderProgramTypeGoal,
  orderProgramTypeLabels,
  orderStatusCancelled,
  orderStatusColors,
  orderStatusCompleted,
  orderTypeColors,
  orderTypeLabels,
} from 'apps/investment/constants/fundOrder';
import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
const { Title } = Typography;

import { formatNumber } from 'utils/formatAmount';
import { formatDateTime } from 'utils/formatDate';

const ItemDetail = ({ item }) => {
  const {
    post: syncOrderStatus,
    isLoading: isSyncOrderStatus,
    error: syncOrderStatusError,
  } = usePostApi(apiCall.sync);

  const {
    data: userProgramOrderList,
    load: fetchUserProgramOrderList,
    isLoading: isFetchUserProgramOrderList,
    error: fetchUserProgramOrderListError,
  } = useFetchApiGet(userProgramOrderListApiCall, { resourceName: 'data' });

  const {
    post: cancelOrder,
    isLoading: isCancelling,
    error: cancelOrderError,
  } = usePostApi(apiCall.cancel);

  const {
    post: pullOrderData,
    isLoading: isPulling,
    error: pullOrderDataError,
  } = usePostApi(apiCall.pull);

  useEffect(() => {
    if (item.id && item.program_type == orderProgramTypeGoal) {
      fetchUserProgramOrderList({ order_id: item.id });
    }
  }, [item.id]);

  let userProgramOrderCreateSourceLabel = '';
  let userProgramOrderCreateSource = 0;
  let goalProgramName = '';
  let goalProgramID = '';
  if (userProgramOrderList && userProgramOrderList.length > 0) {
    userProgramOrderCreateSourceLabel =
      userProgramOrderList[0]?.create_source_label;
    userProgramOrderCreateSource = userProgramOrderList[0]?.create_source;
    goalProgramName = userProgramOrderList[0]?.user_program?.program_name;
    goalProgramID = userProgramOrderList[0]?.user_program?.id;
  }

  return (
    <>
      <Card
        className="my-5"
        title={<Title level={3}>Order Detail</Title>}
        extra={
          item.status != orderStatusCompleted &&
          item.status != orderStatusCancelled ? (
            <>
              <Button type="primary">
                <Link to={`/investment/orders/${item.id}/edit`}>Edit</Link>
              </Button>
              <Button
                disabled={isCancelling}
                onClick={() => {
                  let warningMessage = `Are you sure you want to cancel the order ${item.code}? Enter order code to confirm: ${item.code}`;
                  if (item.source == 1002) {
                    warningMessage = `Are you sure you want to cancel the order ${item.code}.Will delete all linked program orders. Enter order code to confirm: ${item.code}`;
                  }
                  let confirmCode = prompt(warningMessage);

                  if (confirmCode == item.code) {
                    cancelOrder({ id: item.id });
                    return;
                  } else {
                    alert('order code was not match');
                  }
                }}
                type="primary"
                style={{
                  margin: 10,
                  backgroundColor: 'red',
                  borderColor: 'red',
                }}
              >
                Cancel
              </Button>
            </>
          ) : null
        }
      >
        <Descriptions column={1} bordered>
          <Descriptions.Item label="UUID">{item.id}</Descriptions.Item>
          <Descriptions.Item label="Product">
            <Link to={`/investment/products/${item.product_id}`}>
              View product ({item.fund_manager} - {item.fund_code})
            </Link>
          </Descriptions.Item>
          <Descriptions.Item label="Order Type">
            <Tag color={orderTypeColors[item.order_side]}>
              {orderTypeLabels[item.order_side]}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Program Type">
            <Tag color={orderProgramTypeColors[item.program_type]}>
              {orderProgramTypeLabels[item.program_type]}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="User">
            <Link to={`/investment/users/${item.user_id}`}>
              {item.user ? (
                <>
                  <Space>
                    <span>
                      {item.user.name} ({item.user.email})
                    </span>
                    {item.user.ekyc_verified ? (
                      <Tag color="green">KYC đã xác thực</Tag>
                    ) : (
                      <Tag color="red">KYC chưa xác thực</Tag>
                    )}
                  </Space>
                </>
              ) : (
                'no email'
              )}
            </Link>
          </Descriptions.Item>
          <Descriptions.Item label="Thời gian đặt lệnh">
            {formatDateTime(item.fund_order_time)}
          </Descriptions.Item>
          <Descriptions.Item label="Amount">
            {formatNumber(item.amount)}
          </Descriptions.Item>
          <Descriptions.Item label="Net Amount">
            {formatNumber(item.net_amount)}
          </Descriptions.Item>
          <Descriptions.Item label="Quantity">
            {formatNumber(item.quantity)}
          </Descriptions.Item>
          <Descriptions.Item label="Matched Price">
            {formatNumber(item.matched_price)}
          </Descriptions.Item>
          <Descriptions.Item label="Tax Amount">
            {formatNumber(item.tax_amount)}
          </Descriptions.Item>
          <Descriptions.Item label="Estimate Quantity">
            {formatNumber(item.estimate_quantity)}
          </Descriptions.Item>
          <Descriptions.Item label="Estimate Amount">
            {formatNumber(item.estimate_amount)}
          </Descriptions.Item>
          <Descriptions.Item label="Estimate Net Amount">
            {formatNumber(item.estimate_net_amount)}
          </Descriptions.Item>
          <Descriptions.Item label="Estimate Fee Amount">
            {formatNumber(item.estimate_fee_amount)}
          </Descriptions.Item>
          <Descriptions.Item label="Estimate Tax Amount">
            {formatNumber(item.estimate_tax_amount)}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <Tag color={orderStatusColors[item.status]}>
              {item.status_label}
            </Tag>
          </Descriptions.Item>

          <Descriptions.Item label="Created At">
            {formatDateTime(item.created_at)}
          </Descriptions.Item>
          <Descriptions.Item label="Updated At">
            {formatDateTime(item.updated_at)}
          </Descriptions.Item>
          {item.program_type == orderProgramTypeFlex ? (
            <Descriptions.Item label={'Flex Program Orders'}>
              <Link to={`/investment/program-orders?order_id=${item.id}`}>
                Xem danh sách lệnh
              </Link>
            </Descriptions.Item>
          ) : null}
          {item.program_type == orderProgramTypeGoal ? (
            <Descriptions.Item label={'Goal Program Orders'}>
              <Link to={`/investment/user-program-orders?order_id=${item.id}`}>
                Xem danh sách lệnh
              </Link>
            </Descriptions.Item>
          ) : null}
        </Descriptions>
      </Card>

      <Card
        title={<Title level={3}>Fund Order Data</Title>}
        extra={
          item.trading_account_manager === 'vcam' && !item.fund_order_id ? (
            <Button
              onClick={() =>
                confirm(
                  `Are you sure you want to send the order #${item.code} to Fund Manager?`
                ) && syncOrderStatus({ id: item.id })
              }
              disabled={isSyncOrderStatus}
              type="primary"
              style={{
                margin: 10,
                backgroundColor: 'green',
                borderColor: 'green',
              }}
            >
              Send to {item.trading_account_manager.toUpperCase()}
            </Button>
          ) : null
        }
      >
        <Descriptions column={1} bordered>
          <Descriptions.Item label="Fund Order ID">
            {item.fund_order_id}
          </Descriptions.Item>
          <Descriptions.Item label="Fund Manager">
            {item.fund_manager} - {item.fund_code}
          </Descriptions.Item>
          <Descriptions.Item label="Trading Account Manager">
            {item.trading_account_manager}
          </Descriptions.Item>
          <Descriptions.Item label="Customer ID">
            {item.fund_customer_id}
          </Descriptions.Item>
          <Descriptions.Item label="Purchase Date">
            {formatDateTime(item.fund_purchase_date)}
          </Descriptions.Item>
          <Descriptions.Item label="Payment Bank Name">
            {item.fund_payment_bank_name}
          </Descriptions.Item>
          <Descriptions.Item label="Payment Bank Branch Name">
            {item.fund_payment_bank_branch_name}
          </Descriptions.Item>
          <Descriptions.Item label="Payment Bank Account Name">
            {item.fund_payment_bank_account_name}
          </Descriptions.Item>
          <Descriptions.Item label="Payment Bank Account Number">
            {item.fund_payment_bank_account_number}
          </Descriptions.Item>
          <Descriptions.Item label="Payment Message">
            {item.fund_payment_message}
          </Descriptions.Item>
          <Descriptions.Item label="Payment Status">
            {item.fund_payment_status}
          </Descriptions.Item>
          <Descriptions.Item label="Can Cancel">
            {item.can_cancel ? 'Yes' : 'No'}
          </Descriptions.Item>
          <Descriptions.Item label="Order Status">
            <Tag color={fundOrderStatusColors[item.fund_order_status]}>
              {fundOrderStatusLabels[item.fund_order_status]}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Fund Updated At">
            {Date.parse(item.fund_updated_at) > 0
              ? formatDateTime(item.fund_updated_at)
              : '-'}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
};

export default ItemDetail;
