import {
  Descriptions,
  Typography,
  Spin,
  Card,
  Table,
  Col,
  Row,
  Statistic,
} from 'antd';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useFetchApiGet } from '@stagapps/redux-utils';
import { formatDateTime } from 'utils/formatDate';
import { getChangeValueColor } from 'utils/color';
import { formatCurrency, formatNumber } from 'utils/formatAmount';
import apiCallFund from 'apps/investment/apiCalls/portfolioFund';
import apiCallStock from 'apps/investment/apiCalls/portfolioStock';
import apiCallFlex from 'apps/investment/apiCalls/portfolioFlex';
import {
  portfolioFundColumns,
  portfolioStockColumns,
  portfolioFlexColumns,
} from './config';

const { Title } = Typography;

const ItemDetail = ({ item }) => {
  const params = useParams();
  const userId = item.user_id;
  const formatter = value => <Statistic value={formatNumber(value)} />;

  const {
    data: portfolioFunds,
    load: fetchPortfolioFunds,
    isLoading: isLoadingPortfolioFunds,
  } = useFetchApiGet(apiCallFund.list, { resourceName: 'data' });

  const {
    data: portfolioStocks,
    load: fetchPortfolioStocks,
    isLoading: isLoadingPortfolioStocks,
  } = useFetchApiGet(apiCallStock.list, { resourceName: 'data' });

  const {
    data: portfolioFlexs,
    load: fetchPortfolioFlexs,
    isLoading: isLoadingPortfolioFlexs,
  } = useFetchApiGet(apiCallFlex.list, { resourceName: 'data' });

  useEffect(() => {
    if (userId) {
      fetchPortfolioFunds({ user_id: userId });
      fetchPortfolioStocks({ user_id: userId });
      fetchPortfolioFlexs({ userId: userId });
    }
  }, []);

  const isLoading =
    isLoadingPortfolioFunds ||
    isLoadingPortfolioStocks ||
    isLoadingPortfolioFlexs;

  if (isLoading) {
    return (
      <Spin tip="Loading" size="large">
        <div className="content" />
      </Spin>
    );
  }

  var overview = (
    <>
      <Card className="my-4">
        <Row gutter={32}>
          <Col span={6}>
            <Statistic
              valueStyle={{
                color: getChangeValueColor(item?.unrealized_gain_loss_amount),
              }}
              title="Khách hàng"
              value={item?.user?.name}
              // suffix={`(${item?.user?.email})`}
              suffix={
                <Link to={`/investment/users/${item.user_id}`}>
                  {item?.user?.email}
                </Link>
              }
            />
          </Col>
          <Col span={6}>
            <Statistic
              valueStyle={{
                color: getChangeValueColor(item?.unrealized_gain_loss_amount),
              }}
              title="Hiệu quả đầu tư"
              value={item?.unrealized_gain_loss_amount}
              precision={2}
              formatter={formatCurrency}
              suffix={`(${formatNumber(
                item?.unrealized_gain_loss_percentage
              )}%)`}
            />
          </Col>
          <Col span={4}>
            <Statistic
              title="Giá trị hiện tại"
              value={item?.market_value}
              precision={2}
              formatter={formatCurrency}
            />
          </Col>
          <Col span={4}>
            <Statistic
              title="Vốn mua"
              value={item.buy_value}
              precision={2}
              formatter={formatCurrency}
            />
          </Col>
          <Col span={4}>
            <Statistic
              title="Tiền mặt CK"
              value={item.total_cash}
              precision={2}
              formatter={formatCurrency}
            />
            <Link to={`/investment/users/${item.user_id}/stock-balance-histories`}>Chi tiết</Link>
          </Col>
        </Row>
      </Card>
      <Card className="my-4" title="Danh mục chứng chỉ quỹ">
        <Table
          rowKey="id"
          columns={portfolioFundColumns}
          dataSource={portfolioFunds}
          pagination={true}
        />
      </Card>
      <Card className="my-4" title="Danh mục chứng khoán">
        <Table
          rowKey="id"
          columns={portfolioStockColumns}
          dataSource={portfolioStocks}
          pagination={true}
        />
      </Card>
      {portfolioFlexs.length > 0 && (
        <Card className="my-4" title="Danh mục Future Flex">
          <Table
            rowKey="id"
            columns={portfolioFlexColumns}
            dataSource={portfolioFlexs}
            pagination={true}
          />
        </Card>
      )}
    </>
  );

  return overview;
};

export default ItemDetail;
