import { Tag } from 'antd';
import apiCall from 'apps/market/apiCalls/portfolio';
import { ListPage } from 'common/ui/AdminCRUD/page';

const columnsGenerator = () => [
  {
    title: 'Port ID',
    dataIndex: 'id',
  },
  {
    title: 'Email',
    dataIndex: 'user',
    render: value => {
      return value && value.email;
    },
  },
  {
    title: 'Port Value',
    dataIndex: 'value',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Cash',
    dataIndex: 'cash',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'ROI',
    dataIndex: 'roi',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Initial Cash',
    dataIndex: 'initial_cash',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Stocks',
    dataIndex: 'stocks',
    render: value => {
      return (
        value &&
        value.map(item => (
          <Tag color={'#52c41a'}>{item.code.toUpperCase()}</Tag>
        ))
      );
    },
  },
];

export const PortfoliosListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    searchOptions={{
      placeHolder: "Enter user's name, email, userID or device name",
    }}
    title="All Portfolios"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="items"
    {...props}
  />
);
