import { useParams } from 'react-router-dom';

import { Button } from 'antd';
import apiCall from 'apps/investment/apiCalls/staff';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import { Link } from 'react-router-dom';
import ItemForm from './Form';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const StaffListPage = (props, { params }) => {
  return (
    <ListPage
      enableSearch={true}
      title="All HR Admin"
      apiCall={apiCall}
      params={params}
      columnGenerator={props => columnsGenerator(props)}
      resourceName="data"
      createUrl={`/investment/staffs/create`}
      {...props}
    />
  );
};

export const StaffEditPage = props => {
  const { id } = useParams();

  return (
    <EditPage
      title="HR Admin"
      apiCall={apiCall}
      listUrl={`/investment/staffs/${id}`}
      ItemForm={ItemForm}
      options={{ title: 'Edit account' }}
      resourceName="data"
      {...props}
    />
  );
};

export const StaffDetailPage = props => {
  return (
    <DetailPage
      title="HR Admin"
      apiCall={apiCall}
      listUrl={`/investment/staffs`}
      ItemDetail={ItemDetail}
      resourceName="data"
      extraActions={id => (
        <Button type="primary">
          <Link to={`/investment/staffs/${id}/edit`}>Edit</Link>
        </Button>
      )}
      {...props}
    />
  );
};

export const StaffCreatePage = props => (
  <CreatePage
    title="HR Admin"
    apiCall={apiCall}
    listUrl={`/investment/staffs`}
    ItemForm={ItemForm}
    resourceName="data"
    {...props}
  />
);
