import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { IAMCORE_API_BASE_URL } from 'config/env.config';

export const OAUTH2_REFRESH_TOKEN = 'DASHBOARD/OAUTH2_REFRESH_TOKEN';

const apiCall = enhancedMakeFetchAction(OAUTH2_REFRESH_TOKEN, payload => ({
  endpoint: `${IAMCORE_API_BASE_URL}/iamcore-api/v1/auth/refresh-token`,
  method: 'POST',
  body: payload,
  notify: false,
}));

export default apiCall;
