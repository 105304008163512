import { Button } from 'antd';
import apiCall from 'apps/market/apiCalls/stockFA';
import { EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { Link } from 'react-router-dom';
import FilterForm from './FilterForm';
import ItemForm from './Form';

const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
  },
  {
    title: 'Date Crawled',
    dataIndex: 'date',
  },
  {
    title: 'Year',
    dataIndex: 'year',
  },
  {
    title: 'Quarter',
    dataIndex: 'quarter',
  },
  {
    title: 'Price',
    dataIndex: 'market_price',
  },
  {
    title: 'Market Cap',
    dataIndex: 'market_cap',
  },
  {
    title: 'EPS',
    dataIndex: 'eps',
  },
  {
    title: 'BVPS',
    dataIndex: 'bvps',
  },
  {
    title: 'PE',
    dataIndex: 'pb',
  },
  {
    title: 'PEG',
    dataIndex: 'peg',
  },
  {
    title: 'ROA',
    dataIndex: 'roa',
  },
  {
    title: 'ROE',
    dataIndex: 'roe',
  },
  {
    title: 'ROS',
    dataIndex: 'ros',
  },
  {
    title: 'Revenue Growth Rate',
    dataIndex: 'revenue_growth_rate',
  },
  {
    title: 'Earnings Growth Rate',
    dataIndex: 'earnings_growth_rate',
  },
  {
    title: 'Dept to Asset',
    dataIndex: 'debt_to_asset',
  },
  {
    title: 'Dept to Equity',
    dataIndex: 'debt_to_equity',
  },
  {
    title: 'Dividend',
    dataIndex: 'dividend',
  },
  {
    title: 'EV Ebitda',
    dataIndex: 'ev_ebitda',
  },
  {
    title: 'EV Sales',
    dataIndex: 'ev_sales',
  },
  {
    title: 'Gross Profit To Sales',
    dataIndex: 'gross_profit_to_sales',
  },
  {
    title: 'Outstanding Shares',
    dataIndex: 'outstanding_shares',
  },
  {
    title: 'Profit Margin',
    dataIndex: 'profit_margin',
  },
  {
    title: 'PS',
    dataIndex: 'ps',
  },
  {
    title: 'Quick Ratio',
    dataIndex: 'quick_ratio',
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/market/stock-fas/${record.id}/edit`}>View</Link>
        </Button>
      </Button.Group>
    ),
  },
];

// Pages

export const StockFAListPage = (props, { params }) => (
  <ListPage
    title="All Stock FA"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="items"
    FilterForm={FilterForm}
    {...props}
  />
);

export const StockFAEditPage = props => (
  <EditPage
    title="Stock FA"
    apiCall={apiCall}
    listUrl={`/market/stock-fas`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);
