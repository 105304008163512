export { default as listApiCall } from './list';
export { default as detailApiCall } from './detail';

import listApiCall from './list';
import detailApiCall from './detail';

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
};

export default ApiCall;
