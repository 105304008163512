import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

export const FETCH_LESSON_CREATE_API_ID =
  'DASHBOARD/FETCH_LESSON_CREATE_API_ID';

const apiCall = enhancedMakeFetchAction(
  FETCH_LESSON_CREATE_API_ID,
  ({ payload }) => ({
    endpoint: `${API_BASE_URL}/stagedu-api/admin/v1/lessons`,
    method: 'POST',
    body: payload,
    notify: true,
  })
);

export default apiCall;
