import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';

const FETCH_INVESTCORE_USER_IDENTIFICATION_IMAGE_CREATE_API_ID =
  'DASHBOARD/FETCH_INVESTCORE_USER_IDENTIFICATION_IMAGE_CREATE_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_INVESTCORE_USER_IDENTIFICATION_IMAGE_CREATE_API_ID,
  ({ userId, payload }) => ({
    endpoint: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/users/${userId}/identification-images`,
    method: 'POST',
    body: payload,
    notify: true,
  })
);

export default apiCall;
