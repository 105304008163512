import apiCall from 'apps/market/apiCalls/fund';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import ItemForm from './Form';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const FundListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    title="All Funds"
    apiCall={apiCall}
    params={params}
    createUrl={`/market/funds/create`}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="items"
    {...props}
  />
);

export const FundEditPage = props => (
  <EditPage
    title="Funds"
    apiCall={apiCall}
    listUrl={`/market/funds`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);

export const FundDetailPage = props => (
  <DetailPage
    title="Funds"
    apiCall={apiCall}
    listUrl={`/market/funds`}
    ItemDetail={ItemDetail}
    resourceName="item"
    {...props}
  />
);

export const FundCreatePage = props => (
  <CreatePage
    title="Funds"
    apiCall={apiCall}
    listUrl={`/market/funds`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);
