import { useFetchApiList } from '@stagapps/redux-utils';
import { Button, Form, Input, Select } from 'antd';
import usersApiCall from 'apps/investment/apiCalls/user/list';
import DebounceSelect from 'common/ui/DebouceSelect';
import { useEffect } from 'react';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const permissionOptions = [
  {
    value: 'admin',
    label: 'Admin',
  },
  {
    value: 'manager',
    label: 'Manager',
  },
];

const StaffForm = ({ form, submiting, onFinish, initialValues = {}, name }) => {
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const {
    data: users = [],
    load: fetchUserList,
    isLoading: isLoadingUsers,
  } = useFetchApiList(usersApiCall, { resourceName: 'data' });

  useEffect(() => {
    if (initialValues.user_id) {
      fetchUserList({ id: initialValues.user_id, account_type: 'business' });
    }
  }, [initialValues.user_id]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'user_form'}
      onFinish={values => {
        return onFinish({
          email_verified: true,
          ...values,
        });
      }}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item name="name" label="Name">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="phone" label="Phone">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="password" label="Password">
        <Input.Password placeholder="Enter to update staff's password" />
      </Form.Item>

      <Form.Item
        name="user_id"
        label="Stag Business Account"
        rules={[
          {
            required: true,
            message: 'Please select the required field',
            whitespace: true,
          },
        ]}
      >
        <DebounceSelect
          placeholder="Select Stag Business Account"
          fetchOptions={fetchUserList}
          fetching={isLoadingUsers}
          options={users}
          params={{ account_type: 'business' }}
          displayFn={o => {
            return {
              value: o.id,
              label: `${o.name} (${o.email})`,
            };
          }}
        />
      </Form.Item>

      <Form.Item
        name="permission_role"
        label="Permission Role"
        initialValue={permissionOptions[1].value}
        rules={[
          {
            required: true,
            message: 'Please select the required field',
            whitespace: true,
          },
        ]}
      >
        <Select>
          {permissionOptions.map(option => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="status"
        label="Status"
        initialValue="active"
        rules={[
          {
            required: true,
            message: 'Please select the required field',
            whitespace: true,
          },
        ]}
      >
        <Select defaultValue={'active'}>
          {[
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' },
          ].map(option => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default StaffForm;
