import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

export const FETCH_EDITOR_UPLOADER_API_ID = 'EDITOR/FETCH_UPLOADER_API_ID';

const apiCall = enhancedMakeFetchAction(
  FETCH_EDITOR_UPLOADER_API_ID,
  ({ payload }) => ({
    endpoint: `${API_BASE_URL}/stagedu-api/admin/v1/image-upload`,
    method: 'POST',
    body: payload,
  })
);

export default apiCall;
