import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';

const FETCH_INVESTCORE_USER_STOCK_ACCOUNT_API_ID =
  'DASHBOARD/FETCH_INVESTCORE_USER_STOCK_ACCOUNT_LIST_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_INVESTCORE_USER_STOCK_ACCOUNT_API_ID,
  ({ id }) => ({
    endpoint: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/users/${id}/stock-account`,
    method: 'GET',
  })
);

export default apiCall;

const FETCH_INVESTCORE_STOCKS_ACCOUNT_ENABLE_MUTUAL_FUND_API_ID =
  'DASHBOARD/FETCH_INVESTCORE_STOCKS_ACCOUNT_ENABLE_MUTUAL_FUND_API';

export const enableMutualFundAccount = enhancedMakeFetchAction(
  FETCH_INVESTCORE_STOCKS_ACCOUNT_ENABLE_MUTUAL_FUND_API_ID,
  ({ id }) => ({
    endpoint: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/users/${id}/nhsv/enable-mutual-fund`,
    method: 'POST',
    notify: true,
  })
);
