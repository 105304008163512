import apiCall from 'apps/investment/apiCalls/userProgramOrder';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';

import { userProgramOrdersColumnsGenerator } from 'apps/investment/components/UserProgramOrders/config';
import FilterForm from './FilterForm';
import ItemForm from './Form';
import ItemDetail from './detail';

export const InvestmentUserProgramOrderListPage = (props, { params }) => (
  <ListPage
    title="All Orders"
    createUrl={`/investment/user-program-orders/create`}
    apiCall={apiCall}
    enableSearch={true}
    FilterForm={FilterForm}
    params={params}
    columnGenerator={props => userProgramOrdersColumnsGenerator(props)}
    resourceName="data"
    size="small"
    {...props}
  />
);

export const InvestmentUserProgramOrderEditPage = props => (
  <EditPage
    title="Edit Order"
    apiCall={apiCall}
    listUrl={`/investment/user-program-orders`}
    ItemForm={ItemForm}
    resourceName="data"
    {...props}
  />
);

export const InvestmentUserProgramOrderDetailPage = props => (
  <DetailPage
    title="Order Detail"
    apiCall={apiCall}
    listUrl={`/investment/user-program-orders`}
    ItemDetail={ItemDetail}
    resourceName="data"
    {...props}
  />
);

export const InvestmentUserProgramOrderCreatePage = props => (
  <CreatePage
    title="Create Order"
    apiCall={apiCall}
    listUrl={`/investment/user-program-orders`}
    ItemForm={ItemForm}
    resourceName="data"
    {...props}
  />
);
