import { useFetchApiGet, usePostApi } from '@stagapps/redux-utils';
import { Form } from 'antd';
import deleteApiCall from 'apps/education/apiCalls/poll/delete';
import getApiCall from 'apps/education/apiCalls/poll/detail';
import editApiCall from 'apps/education/apiCalls/poll/edit';
import PageTitle from 'apps/home/components/PageTitle';
import ErrorMessage from 'common/ui/ErrorMessage';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { convertFormData } from 'utils/formData.js';
import ItemForm from './Form';

const PollEdit = () => {
  const [form] = Form.useForm();
  const params = useParams();

  const {
    data: item,
    load: fetchItem,
    isLoading,
    error: fetchItemError,
  } = useFetchApiGet(getApiCall, {
    resourceName: 'item',
  });

  const { post: submitItem, isSubmitting, error: submitItemError } = usePostApi(
    editApiCall,
    {
      resourceName: 'item',
    }
  );

  const {
    post: deleteItem,
    isSubmitting: isDeleting,
    error: deleteError,
  } = usePostApi(deleteApiCall, {
    resourceName: 'item',
  });

  useEffect(() => {
    fetchItem({ id: params.id });
  }, []);

  const onFinish = (values, isRemoved) => {
    console.debug('Received values of form: ', values);

    const postData = async () => {
      try {
        let submitData = values;
        submitData.lesson = null;
        submitData.points = 0;

        if (
          values.photo &&
          values.photo.at(-1) &&
          values.photo.at(-1).originFileObj instanceof File
        ) {
          submitData.photo = values.photo[0].originFileObj;
          submitData.points = '0';
          submitData = convertFormData(submitData);
        } else {
          delete submitData['photo'];
          if (isRemoved) {
            submitData.photo = null;
          }
        }

        submitItem({
          id: params.id,
          payload: submitData,
        });
      } catch (error) {
        console.error(error.message);
      }
    };

    postData();
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (fetchItemError) {
    return <ErrorMessage {...fetchItemError} />;
  }

  return (
    <>
      <PageTitle title={`Edit Poll`} />
      <ItemForm
        submiting={isSubmitting}
        form={form}
        onFinish={onFinish}
        initialValues={item}
        name="poll_edit_form"
        visibility="visible"
        onDelete={() => {
          if (confirm('Are you sure?')) {
            deleteItem({
              id: params.id,
            });
          }
        }}
      />
    </>
  );
};

export default PollEdit;
