import { Button, Tag } from 'antd';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useFetchApiList } from '@stagapps/redux-utils';
import courseListApiCall from 'apps/education/apiCalls/course/list';
import MetaDecorator from 'apps/home/components/MetaDecorator';
import PageTitle from 'apps/home/components/PageTitle';
import DataTable from 'common/ui/DataTable';
import {
  accessColors,
  accessLabel,
  statusColors,
  statusLabel,
} from 'utils/constants';
import { formatISODate } from 'utils/formatDate';
import { cloudflareCdn } from 'utils/image';

const columnsGenerator = params => [
  {
    title: 'Course ID',
    dataIndex: 'id',
  },
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Photo',
    dataIndex: 'photo',
    render: value =>
      value && <img width={100} src={cloudflareCdn(value, 100)} />,
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    render: value => formatISODate(value),
  },
  {
    title: 'Modified',
    dataIndex: 'modified_at',
    render: value => formatISODate(value),
  },
  {
    title: 'Ordering',
    dataIndex: 'ranking',
  },
  {
    title: 'Access Level',
    dataIndex: 'access',
    render: value => {
      return <Tag color={accessColors[value]}>{accessLabel[value]}</Tag>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => {
      return <Tag color={statusColors[value]}>{statusLabel[value]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/edu/courses/${record.id}/edit`}>Edit</Link>
        </Button>
        <Button shape="round">
          <Link to={`/edu/courses/${record.id}/chapters`}>All Chapters</Link>
        </Button>
      </Button.Group>
    ),
  },
];

const Courses = () => {
  const title = 'All Courses';
  const {
    data: items,
    load: fetchItems,
    isLoading,
    paging,
  } = useFetchApiList(courseListApiCall, { resourceName: 'items' });

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <>
      <MetaDecorator title={title} />
      <PageTitle
        title={title}
        extra={[
          <Button key="1" type="primary">
            <Link to={`/edu/courses/create`}>Create</Link>
          </Button>,
        ]}
      />

      <DataTable
        rowKey="id"
        columns={columnsGenerator()}
        dataSource={items}
        totalItems={paging.count}
        currentPage={paging.page}
        pageSize={paging.page_size}
        dataFetcher={fetchItems}
        loading={isLoading}
      />
    </>
  );
};

export default Courses;
