import { Tag } from 'antd';
import { securityAccountStatusColors } from 'apps/investment/constants/user';
import { Link } from 'react-router-dom';

import { formatDateTime } from 'utils/formatDate';

export const columnsGenerator = props => [
  {
    dataIndex: 'id',
    align: 'center',
    render: id => <Link to={`/investment/stock-accounts/${id}`}>View</Link>,
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    sorter: true,
    render: value => formatDateTime(value),
  },
  {
    title: 'Account Number',
    dataIndex: 'account_number',
    key: 'account_number',
  },
  {
    title: 'Contract Number',
    dataIndex: 'contract_number',
    key: 'contract_number',
  },
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
  },
  {
    title: 'eContract',
    dataIndex: 'is_sign_contract',
    key: 'is_sign_contract',
    render: (value, record) => {
      if (record.is_sign_contract) {
        return <Tag color={'green'}>Đã ký</Tag>;
      }

      return (
        <>
        <Tag color={'yellow'}>Chưa ký</Tag><br/>
        <br/>
        {
          record.is_econtract_link_created ? (
            <Tag color={'green'}>Đã có eContract</Tag>
          ) : (
            <Tag color={'grey'}>Chưa có eContract</Tag>
          )
        }
        </>
      );
    },
  },

  {
    title: 'VSD',
    dataIndex: 'is_vsd_approved',
    key: 'is_vsd_approved',
    render: (value, record) => {
      if (value) {
        return <Tag color={'green'}>Đã duyệt</Tag>;
      }

      return <Tag color={'yellow'}>Chưa duyệt</Tag>;
    },
  },
  {
    title: 'Security Manager',
    dataIndex: 'security_manager',
    align: 'center',
    key: 'security_manager',
    render: value => value?.toUpperCase(),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    key: 'status',
    render: (value, record) => {
      return (
        <Tag color={securityAccountStatusColors[record.status]}>
          {record.status_label}
        </Tag>
      );
    },
  },
  {
    title: 'User',
    render: record => (
      <Link to={`/investment/users/${record.user_id}`}>
        {record && record.name
          ? `${record.name} (${record.email})`
          : record.email}
      </Link>
    ),
  },
  {
    title: 'Approved',
    dataIndex: 'approved_at',
    sorter: true,
    render: value => (value ? formatDateTime(value) : '-'),
  },
];
