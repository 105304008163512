import { ConfigProvider, Layout, Menu, theme } from 'antd';
import { ENV_NAME } from 'config/env.config';
import useApps from 'hooks/useApps';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { items } from '../../../../config/sideMenuData';

const DEFAULT_SECTIONS = ['market', 'edu', 'investment', 'investcore', 'hub'];

const SideMenu = () => {
  const location = useLocation();
  const { urlKey } = useApps();
  let [openKeys, setOpenKeys] = useState([]);
  let [menuItems, setMenuItems] = useState([]);
  const urlItems = location.pathname.split('/').filter(o => o);
  const isProd = ENV_NAME === 'production';

  useEffect(() => {
    if (urlKey == 'home') {
      setMenuItems([]);
    } else {
      setOpenKeys(`/${urlItems.slice(0, 2).join('/')}`);
    }
  }, [location.pathname]);

  const getSection = () => {
    const urlItems = location.pathname.split('/').filter(o => o);
    if (!urlItems.length) {
      setMenuItems([]);
      return [];
    }

    const selectedSection = urlItems[0];

    if (DEFAULT_SECTIONS.includes(selectedSection)) {
      const selectMenuItems = items.filter(
        i => i.key == '/home' || i.key == selectedSection
      );
      setMenuItems(selectMenuItems);
      return [selectedSection];
    }

    setMenuItems([]);
    return DEFAULT_SECTIONS;
  };
  const customTheme = !isProd
    ? { algorithm: theme.darkAlgorithm }
    : {
        algorithm: theme.darkAlgorithm,

        token: {
          colorPrimaryBg: '#cb2b83',
        },
        components: {
          Menu: {
            // if you use "dark" theme on menu
            colorItemBg: '#551c3b', // colorBgBase -3% lightness
            colorSubItemBg: '#75204f', // colorBgBase -6% lightness
            colorGroupTitle: 'white',
            itemSelectedColor: 'white',
          },
        },
      };
  return (
    <ConfigProvider theme={customTheme}>
      <Layout.Sider
        breakpoint="xl"
        collapsedWidth="0"
        style={isProd ? { backgroundColor: '#551c3b' } : undefined}
      >
        <Menu
          theme={!isProd ? 'dark' : undefined}
          mode="inline"
          defaultOpenKeys={getSection}
          selectedKeys={openKeys}
          items={menuItems}
        />
      </Layout.Sider>
    </ConfigProvider>
  );
};

export default SideMenu;
