import React, { useEffect, useState } from 'react';

import {
  Form,
  Button,
  InputNumber,
} from 'antd';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';


const FilterForm = ({
  form,
  initialValues = {},
  onFinish,
  submiting,
  name,
}) => {

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'filter_form'}
      onFinish={onFinish}
      scrollToFirstError
    >
      <Form.Item name="level" label="Level">
        <InputNumber />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Filter
        </Button>
      </Form.Item>

    </Form>
  );
};

export default FilterForm;
