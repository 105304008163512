import apiCall from 'apps/hub/apiCalls/lessonQuizCheckbox';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { useParams } from 'react-router-dom';
import { columnsGenerator } from './config';
import ItemForm from './ItemForm';

export const QuizCheckBoxListPage = (props, { params }) => {
  const { course_id, chapter_id, lesson_id } = useParams();

  return (
    <ListPage
      enableSearch={true}
      title="All Questions"
      apiCall={apiCall}
      params={params}
      columnGenerator={() =>
        columnsGenerator({ course_id, chapter_id, lesson_id })
      }
      resourceName="data"
      createUrl={`/hub/courses/${course_id}/chapters/${chapter_id}/lessons/${lesson_id}/checkbox-questions/create`}
      {...props}
    />
  );
};

export const QuizCheckBoxEditPage = props => {
  const { course_id, chapter_id, lesson_id } = useParams();

  return (
    <EditPage
      title="Question Edit"
      apiCall={apiCall}
      listUrl={`/hub/courses/${course_id}/chapters/${chapter_id}/lessons/${lesson_id}/checkbox-questions`}
      ItemForm={ItemForm}
      resourceName="data"
      {...props}
    />
  );
};

export const QuizCheckBoxCreatePage = props => {
  const { course_id, chapter_id, lesson_id } = useParams();

  return (
    <CreatePage
      title="New Question"
      apiCall={apiCall}
      listUrl={`/hub/courses/${course_id}/chapters/${chapter_id}/lessons/${lesson_id}/checkbox-questions`}
      ItemForm={ItemForm}
      resourceName="data"
      {...props}
    />
  );
};
