import { Avatar, Divider, List } from 'antd';
import MetaDecorator from 'apps/home/components/MetaDecorator';
import useApps from 'hooks/useApps';
import { get } from 'lodash/fp';
import { connect } from 'react-redux';
import { compose } from 'recompose';

const Homepage = ({ name, user }) => {
  const { appItems } = useApps();

  return (
    <>
      <MetaDecorator title={'Admin CP | Home'} />
      <h1>Hi {name} 👋</h1>
      <p>Welcome to Stag Team Workspace.</p>

      <div>
        <>
          <Divider orientation="left"></Divider>
          <List
            itemLayout="horizontal"
            dataSource={appItems}
            renderItem={(item, index) => (
              <a href={`/${item.key}`} key={index}>
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar style={{ backgroundColor: item.color }}>
                        {item.key.charAt(0).toUpperCase()}
                      </Avatar>
                    }
                    title={item.label}
                    description={item.description}
                  />
                </List.Item>
              </a>
            )}
          />
        </>
      </div>
    </>
  );
};

const enhance = compose(
  connect(state => ({
    user: get('adminSession.user')(state),
  }))
);

export default enhance(Homepage);
