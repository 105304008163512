import apiCall from 'apps/education/apiCalls/gameSetting';
import { EditPage } from 'common/ui/AdminCRUD/page';
import ItemForm from './Form';

export const GameSettingEditPage = props => (
  <EditPage
    title="Game Setting"
    apiCall={apiCall}
    listUrl={`/edu/game-rooms`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);
