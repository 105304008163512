import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_INVESTMENT_API_ID =
  'INVESTCORE/FETCH_USER_PROGRAM_INVESTMENT_PLAN_API_ID';

const apiCall = enhancedMakeFetchAction(
  FETCH_INVESTMENT_API_ID,
  ({ id, ...params }) => ({
    endpoint: generateEndpoint({
      host: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/user-programs/${id}/investment-plans`,
      params,
    }),
    method: 'GET',
  })
);

export default apiCall;
