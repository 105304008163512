import { Button } from 'antd';
import apiCall from 'apps/investment/apiCalls/employee';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import { Link } from 'react-router-dom';
import ItemDetail from './Detail';
import FilterForm from './FilterForm';
import ItemForm from './Form';
import { columnsGenerator } from './config';

export const InvestmentEmployeeListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    title="All Employees"
    apiCall={apiCall}
    params={params}
    FilterForm={FilterForm}
    createUrl={`/investment/employees/create`}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="data"
    {...props}
  />
);

export const InvestmentEmployeeDetailPage = props => (
  <DetailPage
    title="Employee"
    apiCall={apiCall}
    listUrl={`/investment/employees`}
    ItemDetail={ItemDetail}
    resourceName="data"
    extraActions={id => (
      <Button type="primary">
        <Link to={`/investment/employees/${id}/edit`}>Edit</Link>
      </Button>
    )}
    {...props}
  />
);

export const InvestmentEmployeeEditPage = props => (
  <EditPage
    title="Employee"
    apiCall={apiCall}
    listUrl={`/investment/employees`}
    ItemForm={ItemForm}
    resourceName="data"
    options={{
      title: 'Edit Employee',
    }}
    {...props}
  />
);

export const InvestmentEmployeeCreatePage = props => (
  <CreatePage
    title="Employee"
    apiCall={apiCall}
    listUrl={`/investment/employees`}
    ItemForm={ItemForm}
    resourceName="data"
    {...props}
  />
);
