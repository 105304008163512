import { usePostApi } from '@stagapps/redux-utils';
import { Button, Card, Descriptions, Tag } from 'antd';
import { Link } from 'react-router-dom';

import apiCall from 'apps/investment/apiCalls/userProgramOrder';
import {
  orderTypeColors,
  orderTypeLabels,
} from 'apps/investment/constants/fundOrder';
import {
  programOrderMatchSourceCompanyPortfolio,
  programOrderStatusColors,
  programOrderStatusComplete,
  programOrderTypeRedistribute,
  userProgramOrderMethodColors,
  userProgramOrderMethodScheduled,
} from 'apps/investment/constants/programOrder';
import { formatDateTime } from 'utils/formatDate';

const ItemDetail = ({ item }) => {
  const {
    id,
    employee,
    employee_program,
    company,
    order,
    company_id,
    employee_id,
    employee_program_id,
    employee_program_code,
    order_id,
    status,
    status_label,
    order_type,
    product_code,
    order_side,
    estimate_quantity,
    quantity,
    matched_source,
    ...programOrder
  } = item;

  const {
    post: cancelOrder,
    isLoading: isCancelling,
    error: cancelOrderError,
  } = usePostApi(apiCall.cancel);

  const { product_id } = order || {};

  let orderType = order_type;
  if (
    order_type == 'buy' &&
    matched_source == programOrderMatchSourceCompanyPortfolio
  ) {
    orderType = programOrderTypeRedistribute;
  }

  return (
    <>
      <Card>
        <Descriptions
          title="Order detail"
          bordered
          column={1}
          extra={
            <Button
              disabled={isCancelling}
              onClick={() => {
                let warningMessage = `Are you sure you want to cancel the program order ${item.code}? Enter order code to confirm: ${item.code}`;
                let confirmCode = prompt(warningMessage);

                if (confirmCode == item.code) {
                  cancelOrder({ id: id });
                  return;
                } else {
                  alert('program order code was not match');
                }
              }}
              type="primary"
              style={{
                margin: 10,
                backgroundColor: 'red',
                borderColor: 'red',
              }}
            >
              Cancel
            </Button>
          }
        >
          <Descriptions.Item label={'Status'}>
            <Tag color={programOrderStatusColors[status]}>{status_label}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label={'Method'}>
            <Tag color={userProgramOrderMethodColors[item.create_source]}>
              {item.create_source_label}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label={'Interval'}>
            {item.create_source == userProgramOrderMethodScheduled
              ? item.interval
              : '-'}
          </Descriptions.Item>
          <Descriptions.Item label={'Order Type'}>
            <Tag color={orderTypeColors[orderType]}>
              {orderTypeLabels[orderType]}
            </Tag>
          </Descriptions.Item>

          <Descriptions.Item label={'Matched Quantity'}>
            {status == programOrderStatusComplete ? quantity : '-'}
          </Descriptions.Item>

          <Descriptions.Item label={'Product Code'}>
            <Link to={`/investment/products/${product_id}`}>
              {product_code}
            </Link>
          </Descriptions.Item>

          <Descriptions.Item label={'Fund Order Id'}>
            <Link to={`/investment/orders/${order_id}`}>View Fund Order</Link>
          </Descriptions.Item>

          <Descriptions.Item label="Created At">
            {formatDateTime(item.created_at)}
          </Descriptions.Item>
          <Descriptions.Item label="Updated At">
            {formatDateTime(item.updated_at)}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
};

export default ItemDetail;
