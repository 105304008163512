import {
  CheckCircleOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';

import UIIconTooltip from 'common/ui/UIIconTooltip';
import { Link } from 'react-router-dom';
import { formatDate, formatDateTime } from 'utils/formatDate';

export const columnsGenerator = params => [
  {
    title: '',
    dataIndex: 'id',
    align: 'center',
    render: id => <Link to={`/investment/employees/${id}`}>View</Link>,
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Full Name',
    dataIndex: 'full_name',
    sorter: true,
  },
  {
    title: 'Company',
    dataIndex: 'company',
    render: company => (
      <Link to={`/investment/companies/${company?.id}`}>
        {company?.brand_name}
      </Link>
    ),
  },
  {
    title: 'Department',
    dataIndex: 'department',
  },
  {
    title: 'Position',
    dataIndex: 'role',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    type: 'number',
  },
  {
    title: 'DOB',
    dataIndex: 'date_of_birth',
    render: value => formatDate(value),
  },

  {
    title: 'Work Email',
    dataIndex: 'email',
  },
  {
    title: 'Stag Account',
    dataIndex: 'phone',
    render: (text, record) =>
      record && record.user ? (
        <Link to={`/investment/users/${record.user.id}`}>
          {record.user.name ? record.user.name : record.user.email}{' '}
          {record.user.ekyc_verified ? (
            <Tooltip title="Stag eKYC Verified">
              <CheckCircleOutlined
                style={{ color: 'green', fontSize: '20px' }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Not verified">
              <InfoCircleOutlined
                style={{ color: '#b8860b', fontSize: '20px' }}
              />
            </Tooltip>
          )}
        </Link>
      ) : (
        <Tag color="grey">Not onboard yet</Tag>
      ),
  },
  {
    title: 'Action',
    key: 'action',
    align: 'center',
    render: (text, record) => {
      return (
        <Link to={`/investment/employees/${record.id}/edit`}>
          <UIIconTooltip title="Edit" Icon={EditOutlined} />
        </Link>
      );
    },
  },
];
