import { notification } from 'antd';
import { flow, get } from 'lodash/fp';
import { ACTIONS } from 'redux-api-call';
import { takeEvery } from 'redux-saga/effects';
import { history } from 'routerHistory';

function* onNotificationSuccess(action) {
  try {
    if (flow(get('payload.notify'))(action)) {
      notification.open({
        type: 'success',
        message: 'Success',
      });

      const isReload = flow(get('payload.isReload'))(action);
      if (isReload) {
        return window.location.reload();
      }
      const isStay = flow(get('payload.isStay'))(action);
      if (isStay) {
        return;
      }

      const ref = flow(get('payload.ref'))(action);
      if (ref) {
        return history.push(ref);
      } else {
        const isReturn = flow(get('payload.isReturn'))(action);
        if (isReturn === false) {
          history.go(0);
        } else {
          history.goBack();
        }
      }
    }
  } catch (error) {
    console.error('error', error);
  }
}

function getFormattedError(response) {
  if (response && response['error'] !== undefined) {
    return response['error_code']
      ? `${response['error_code']}: ${response['error']}`
      : response['error'];
  }
  return JSON.stringify(response);
}

function* onNotificationFailure(action) {
  try {
    if (flow(get('payload.notify'))(action)) {
      const response = get(`payload.json`)(action);
      const statusCode = get(`payload.statusCode`)(action);

      // ignore if not notified is specified. Otherwise, just show it
      const isNotify = flow(get('payload.notify'))(action);
      if (isNotify !== false) {
        notification.open({
          type: 'error',
          message: 'Something went wrong!',
          description: getFormattedError(
            response || {
              error: 'Unknown error with status code ' + statusCode,
            }
          ),
          duration: 20,
        });
      }
    }
  } catch (error) {
    console.error('error', error);
  }
}

function* watchonNotification() {
  yield takeEvery(ACTIONS.COMPLETE, onNotificationSuccess);
  yield takeEvery(ACTIONS.FAILURE, onNotificationFailure);
}

export default function* notificationSaga() {
  yield watchonNotification();
}
