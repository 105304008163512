export const identificationImageTypeOptions = [
  { value: 'selfie', label: 'Chân dung' },
  { value: 'front_id_card', label: 'CCCD/CMND mặt trước' },
  { value: 'back_id_card', label: 'CCCD/CMND mặt sau' },
  { value: 'passport', label: 'Passport' },
];

export const compareMatchingLabels = {
  'to-be-reviewed': 'Cần xác minh lại',
  matched: 'Trùng khớp',
};

export const compareMatchingColors = {
  'to-be-reviewed': 'red',
  matched: 'green',
};
