import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { middleware as apiMiddleware } from 'redux-api-call';
import rootSaga from 'sagas';
import requestHeaderMiddleware from './middlewares/requestHeaderMiddleware';
import rootReducer from './rootReducer';

const sagaMiddleware = createSagaMiddleware();

// eslint-disable-next-line
const reduxCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = reduxCompose(
  applyMiddleware(thunk, requestHeaderMiddleware, sagaMiddleware, apiMiddleware)
);

// Redux: Store
const store = createStore(rootReducer, enhancer);

// Middleware: Redux Saga
sagaMiddleware.run(rootSaga);

export default store;
