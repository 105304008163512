import { useFetchApiGet } from '@stagapps/redux-utils';
import { LineChart } from '@tremor/react';
import { historyApiCall } from 'apps/market/apiCalls/fund';
import { useEffect } from 'react';
import { formatNumber } from 'utils/formatAmount';
import AnalyticsDetail from './Analytics';

const PerformanceDetail = ({ symbol }) => {
  const {
    rawData,
    load: fetchItem,
    isLoading,
    error,
  } = useFetchApiGet(historyApiCall, { resourceName: 'prices' });

  useEffect(() => {
    if (symbol) {
      fetchItem({ symbol, date_range: 'ytd' });
    }
  }, [symbol]);

  if (isLoading) return 'Loading..';
  if (error) return 'error..';

  const { prices } = rawData;

  return (
    <>
      <AnalyticsDetail symbol={symbol} />
      <div className="ant-descriptions-header">
        <div className="ant-descriptions-title">Fund Historical Price</div>
      </div>
      <div>
        <LineChart
          data={prices}
          index="date"
          className="mt-4 h-72"
          categories={['nav']}
          yAxisWidth={65}
          colors={['blue']}
          valueFormatter={formatNumber}
        />
      </div>
    </>
  );
};

export default PerformanceDetail;
