import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_PRODUCT_LIST_API_ID = 'INVESTCORE/FETCH_PRODUCT_LIST_API';

const apiCall = enhancedMakeFetchAction(FETCH_PRODUCT_LIST_API_ID, params => ({
  endpoint: generateEndpoint({
    host: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/products`,
    params,
  }),
  method: 'GET',
}));

export default apiCall;
