import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Select, Upload } from 'antd';
import imageUploadCall from 'apps/education/apiCalls/editorImage/upload';
import TextEditor from 'common/form/TextEditor';
import { useEffect } from 'react';
import { statusOptions } from 'utils/constants';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { listingOptions } from './config';

const StocksForm = ({
  form,
  submiting,
  onFinish,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'stock_form'}
      onFinish={onFinish}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Ngân hàng TMCP Á Châu" />
      </Form.Item>

      <Form.Item name="name_en" label="Name English">
        <Input placeholder="ACB Bank" />
      </Form.Item>

      <Form.Item
        name="symbol"
        label="Symbol"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="ACB" />
      </Form.Item>

      <Form.Item name="description" label="Description">
        <TextEditor uploadCallback={imageUploadCall} />
      </Form.Item>

      <Form.Item name="currency" label="Currency">
        <Input />
      </Form.Item>

      <Form.Item name="exchange" label="Exchange">
        <Input placeholder="hose" />
      </Form.Item>

      <Form.Item name="industry" label="Industry">
        <InputNumber />
      </Form.Item>

      <Form.Item name="super_sector" label="Super Sector">
        <InputNumber />
      </Form.Item>

      <Form.Item name="sector" label="Sector">
        <InputNumber />
      </Form.Item>

      <Form.Item name="sub_sector" label="Sub Sector">
        <InputNumber />
      </Form.Item>

      <Form.Item name="brand_name" label="Brand Name">
        <Input />
      </Form.Item>

      <Form.Item name="inception_date" label="Inception date">
        <Input />
      </Form.Item>

      <Form.Item name="ipo_price" label="IPO Price">
        <InputNumber />
      </Form.Item>

      <Form.Item name="ipo_shares" label="IPO Shares">
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="photo"
        label="Photo"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item name="exchange_status" label="Listing Status">
        <Select>
          {listingOptions &&
            listingOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item name="status" label="Publishing Status">
        <Select>
          {statusOptions &&
            statusOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>

        {onDelete ? (
          <Button
            onClick={onDelete}
            type="primary"
            style={{
              visibility: visibility,
              margin: 10,
              backgroundColor: 'red',
              borderColor: 'red',
            }}
          >
            Delete
          </Button>
        ) : null}
      </Form.Item>
    </Form>
  );
};

export default StocksForm;
