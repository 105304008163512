import { usePostApi } from '@stagapps/redux-utils';
import { Button, Descriptions, List, Space, Tag } from 'antd';
import { Link } from 'react-router-dom';

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import {
  userProgramRequiredCompleteInformation,
  userProgramRequiredFundAccountReady,
  userProgramRequiredInvestmentPlan,
  userProgramRequiredStartDateEndDateValid,
  userProgramRequiredUser,
  userProgramRequiredUserVerified,
  userProgramStatusActive,
  userProgramStatusColors,
  userProgramStatusPaused,
  userProgramStatusPending,
  yesNoColors,
  yesNoLabels,
} from 'apps/investment/constants/userProgram';
import convertObjectToArrayData, {
  removeKeyList,
} from 'utils/convertObjectToArrayData';
import { formatNumber } from 'utils/formatAmount';
import { formatDate, formatDateTime } from 'utils/formatDate';
import { cloudflareCdn } from 'utils/image';

const ItemDetail = ({ item, apiCall }) => {
  const { code, user_investment_detail, user, logo, ...data } = item;

  const { id, status, required_active_conditions, ...programDetail } =
    data || {};
  const programDetailArray = convertObjectToArrayData(
    removeKeyList(programDetail, ['user_id', 'user_investment_detail', 'logo'])
  );

  const {
    post: postDelete,
    data: responseDataDelete,
    isLoading: isSubmittingDelete,
  } = usePostApi(apiCall.delete, {
    resourceName: 'data',
  });

  const {
    post: postActive,
    data: responseDataActive,
    isLoading: isSubmittingActive,
  } = usePostApi(apiCall.active, {
    resourceName: 'data',
  });

  const {
    post: postPause,
    data: responseDataPause,
    isLoading: isSubmittingPause,
  } = usePostApi(apiCall.pause, {
    resourceName: 'data',
  });

  const onDelete = () => {
    postDelete({ id });
  };

  const onActive = () => {
    postActive({ id });
  };

  const onPause = () => {
    postPause({ id });
  };

  const programActions = [
    {
      label: 'Active',
      onClick: onActive,
      loading: isSubmittingActive,
      disabled:
        required_active_conditions && required_active_conditions.length > 0,
      confirmMessage: `Confirm to switch program to active? This action can not rollback. Please input program code: ${code}`,
      showButton:
        status == userProgramStatusPending || status == userProgramStatusPaused,
      buttonType: 'primary',
      warningMessage:
        required_active_conditions && required_active_conditions.length > 0
          ? 'Chưa đủ điều kiện'
          : '',
      buttonLabel: 'Tham gia',
    },
    {
      label: 'Delete',
      onClick: onDelete,
      loading: isSubmittingDelete,
      disabled: false,
      confirmMessage: `Confirm to delete program? This action can not rollback. Please input program code: ${code}`,
      showButton: true,
      buttonType: 'primary',
      buttonLabel: 'Xoá',
      danger: true,
    },
    {
      label: 'Pause',
      onClick: onPause,
      loading: isSubmittingPause,
      disabled: status != userProgramStatusActive,
      confirmMessage: `Confirm to pause program? This action can not rollback. Please input program code: ${code}`,
      showButton: status == userProgramStatusActive,
      buttonType: 'default',
      warningMessage: '',
      buttonLabel: 'Tạm dừng',
    },
  ];

  const programActionsRender = programActions
    .filter(({ showButton }) => showButton)
    .map(
      ({
        danger,
        onClick,
        loading,
        disabled,
        confirmMessage,
        buttonType,
        buttonLabel,
      }) => (
        <Button
          disabled={disabled}
          loading={loading}
          type={buttonType}
          danger={danger || false}
          onClick={() => {
            let confirmCode = prompt(confirmMessage);

            if (confirmCode == item.code) {
              onClick({ id: item.id });
              return;
            } else {
              alert('confirm code was not match');
            }
          }}
        >
          {buttonLabel}
        </Button>
      )
    );

  return (
    <Descriptions
      title="User Program Detail"
      bordered
      column={1}
      extra={
        <Space align="center">
          {programActionsRender}
          <Button type="default">
            <Link to={`/investment/user-programs/${id}/edit`}>Edit</Link>
          </Button>
        </Space>
      }
    >
      <Descriptions.Item label={'Status'}>
        <Tag color={userProgramStatusColors[status]}>{item.status_label}</Tag>
      </Descriptions.Item>
      {status == userProgramStatusPending ||
      status == userProgramStatusPaused ? (
        <Descriptions.Item label={'Yêu cầu kích hoạt chương trình'}>
          <List
            size="large"
            header={<div>A) Yêu cầu về chi tiết chương trình:</div>}
            dataSource={[
              {
                label:
                  'Ngày bắt đầu của chương trình đã kích hoạt (đạt hoặc vượt qua) và chưa đến thời điểm / ngày kết thúc',
                condition: userProgramRequiredStartDateEndDateValid,
              },
              {
                label:
                  'Người dùng phải hoàn thành việc chọn danh mục đầu tư (bao gồm đạt các yêu cầu về chọn giá trị đầu tư định kỳ tối thiểu)',
                condition: userProgramRequiredInvestmentPlan,
              },
              {
                label:
                  'Chương trình phải nhập đầy đủ thông tin bao gồm: ngày bắt đầu/kết thúc, giá trị đầu tư định kỳ hàng tháng,...',
                condition: userProgramRequiredCompleteInformation,
              },
            ]}
            renderItem={item => (
              <List.Item>
                - {item.label}{' '}
                {required_active_conditions &&
                required_active_conditions.includes(item.condition) ? (
                  <CloseCircleOutlined
                    style={{ color: 'red', fontSize: '20px' }}
                  />
                ) : (
                  <CheckCircleOutlined
                    style={{ color: 'green', fontSize: '20px' }}
                  />
                )}
              </List.Item>
            )}
          />
          <List
            size="large"
            header={<div>B) Yêu cầu về tài khoản:</div>}
            dataSource={[
              {
                label: 'Người dùng có tài khoản Stag active',
                condition: userProgramRequiredUser,
              },
              {
                label:
                  'Người dùng phải hoàn thành định danh level 3 trên hệ thống Stag (email, phone, eKYC)',
                condition: userProgramRequiredUserVerified,
              },
              {
                label:
                  'Người dùng phải hoàn thành mở tài khoản giao dịch CCQ tại cty QLQ được lựa chọn trong danh mục',
                condition: userProgramRequiredFundAccountReady,
              },
              {
                label:
                  'Người dùng được gắn kết nối giữa tài khoản Stag và tài khoản giao dịch CCQ',
                condition: userProgramRequiredFundAccountReady,
              },
            ]}
            renderItem={item => (
              <List.Item>
                - {item.label}{' '}
                {required_active_conditions &&
                required_active_conditions.includes(item.condition) ? (
                  <CloseCircleOutlined
                    style={{ color: 'red', fontSize: '20px' }}
                  />
                ) : (
                  <CheckCircleOutlined
                    style={{ color: 'green', fontSize: '20px' }}
                  />
                )}
              </List.Item>
            )}
          />
        </Descriptions.Item>
      ) : null}
      <Descriptions.Item label={'User'}>
        <Link
          to={`/investment/users/${user.id}`}
        >{`${user.name} (${user.email})`}</Link>
      </Descriptions.Item>
      <Descriptions.Item label={'Photo'}>
        <img width={400} src={cloudflareCdn(logo, 400)} />
      </Descriptions.Item>
      <Descriptions.Item label="Program Name">
        {programDetail.program_name}
      </Descriptions.Item>
      <Descriptions.Item label={'Start Date'}>
        {formatDate(programDetail.start_date)}
      </Descriptions.Item>
      <Descriptions.Item label={'End Date'}>
        {formatDate(programDetail.end_date)}
      </Descriptions.Item>
      <Descriptions.Item label={'Contribute Amount'}>
        {formatNumber(programDetail.contribute_amount)}
      </Descriptions.Item>
      <Descriptions.Item label={'Investment Interval'}>
        {programDetail.investment_interval > 0
          ? programDetail.investment_interval
          : '-'}
      </Descriptions.Item>
      <Descriptions.Item label={'Created At'}>
        {formatDateTime(programDetail.created_at)}
      </Descriptions.Item>
      <Descriptions.Item label={'Modifed At'}>
        {formatDateTime(programDetail.updated_at)}
      </Descriptions.Item>
      <Descriptions.Item label={'Investment Plan Version'}>
        <Link to={`/investment/plans/${programDetail.investment_plan_version}`}>
          View investment plan
        </Link>
      </Descriptions.Item>
      <Descriptions.Item label={'Monthly Order Payment'}>
        {formatNumber(programDetail.monthly_order_payment)}
      </Descriptions.Item>
      <Descriptions.Item label={'Target Amount'}>
        {formatNumber(programDetail.target_amount)}
      </Descriptions.Item>
      <Descriptions.Item label={'Target Return Ratio'}>
        {formatNumber(programDetail.target_return_ratio)}
      </Descriptions.Item>
      <Descriptions.Item label={'Init Contribute Amount'}>
        {formatNumber(programDetail.init_contribute_amount)}
      </Descriptions.Item>
      <Descriptions.Item label={'Tự động tạo lệnh'}>
        <Tag color={yesNoColors[programDetail.is_auto_create_order]}>
          {yesNoLabels[programDetail.is_auto_create_order]}
        </Tag>
      </Descriptions.Item>

      <Descriptions.Item label={'Status'}>
        <Tag color={userProgramStatusColors[item.status]}>
          {item.status_label}
        </Tag>
      </Descriptions.Item>

      <Descriptions.Item label={'Program Orders'}>
        <Link to={`/investment/user-program-orders?user_program_id=${id}`}>
          Xem danh sách lệnh
        </Link>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default ItemDetail;
