export const orderStatusColors = {
  1001: 'yellow',
  1002: 'green',
  1003: 'orange',
  1004: 'grey',
};

export const orderStatusLabels = {
  1001: 'Pending',
  1002: 'Matched',
  1003: 'Cancelled',
  1004: 'Expired',
};

export const orderTypeColors = {
  lo: 'blue',
  mp: 'green',
  ato: 'red',
  atc: 'purple',
};

export const orderTypeLabels = {
  lo: 'LO',
  mp: 'MP',
  ato: 'ATO',
  atc: 'ATC',
};
