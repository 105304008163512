import listApiCall from './list';
import detailApiCall from './detail';
import editApiCall from './edit';
import createApiCall from './create';
import deleteApiCall from './delete';
import questionsApiCall from './questions';
import questionAnswersApiCall from './questionAnswers';

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  create: createApiCall,
  edit: editApiCall,
  delete: deleteApiCall,
  questions: questionsApiCall,
  questionAnswers: questionAnswersApiCall,
};

export default ApiCall;
