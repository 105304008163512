import { Button, Form, Input, Switch, Select } from 'antd';
import { useEffect } from 'react';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { convertDateFormData, ensureDateFields } from 'utils/formData';

const { Option } = Select;

const dateFields = [];

const ItemForm = ({
  form,
  submiting,
  onFinish,
  initialValues,
  name,
  params,
}) => {
  const {
    id,
  } = initialValues || {};

  useEffect(() => {
    if (!!initialValues) {
      form.setFieldsValue(ensureDateFields(initialValues, dateFields));
    }
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'user_form'}
      onFinish={values => {
        if (!id) {
          values.status = 1002;
        }

        onFinish({ ...convertDateFormData(values, dateFields) });
      }}
      scrollToFirstError
    >
      <Form.Item
        name="email"
        label="Email"
      >
        <Input placeholder="" disabled />
      </Form.Item>

      <Form.Item name="is_nhsv_esign_contract_approve" label="NHSV Esign is complete">
        <Switch />
      </Form.Item>

      <Form.Item name="is_vcam_esign_contract_approve" label="VCAM Esign is complete">
        <Switch />
      </Form.Item>

      <Form.Item name="is_mirae_esign_contract_approve" label="Mirae Esign is complete">
        <Switch />
      </Form.Item>

      <Form.Item name="is_vcbf_esign_contract_approve" label="VCBF Esign is complete">
        <Switch />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
