import { Button, Tag } from 'antd';
import apiCall from 'apps/market/apiCalls/newsIndustry';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { Link } from 'react-router-dom';
import ItemForm from './Form';

const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'ICB',
    dataIndex: 'icb_code',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Level',
    dataIndex: 'level',
  },
  {
    title: 'Keywords',
    dataIndex: 'keywords',
    render: value => {
      return value.map(item => <Tag color="#a9a9a9">{item}</Tag>);
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/market/news-industries/${record.id}/edit`}>Edit</Link>
        </Button>
      </Button.Group>
    ),
  },
];

// Pages
export const NewsIndustryListPage = (props, { params }) => (
  <ListPage
    title="All Industry Keywords"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="items"
    {...props}
  />
);

export const NewsIndustryEditPage = props => (
  <EditPage
    title="Industry Keyword"
    apiCall={apiCall}
    listUrl={`/market/news-industries`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);

export const NewsIndustryCreatePage = props => (
  <CreatePage
    title="Industry Keyword"
    apiCall={apiCall}
    listUrl={`/market/news-industries`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);
