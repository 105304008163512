import { FlagOutlined, LikeOutlined } from '@ant-design/icons';
import { usePostApi } from '@stagapps/redux-utils';
import { Button, Spin } from 'antd';
import apiCall from 'apps/market/apiCalls/news';
import { useEffect, useState } from 'react';

export const RelevantAction = ({ text, record }) => {
  const [isRelevant, setIsRelevant] = useState(record.is_relevant);

  const {
    data: relevantData,
    post: submitRelevant,
    isLoading: isSubmittingRelevant,
    error: submitRelevantError,
  } = usePostApi(apiCall.relevant);

  const {
    data: irrelevantData,
    post: submitIrrelevant,
    isLoading: isSubmittingIrrelevant,
    error: submitIrrelevantError,
  } = usePostApi(apiCall.irrelevant);

  useEffect(() => {
    setIsRelevant(record.is_relevant);
  }, [record.is_relevant]);

  useEffect(() => {
    if (!relevantData || isSubmittingRelevant) {
      return;
    }

    const { is_relevant } = relevantData;
    setIsRelevant(is_relevant);
  }, [isSubmittingRelevant]);

  useEffect(() => {
    if (!irrelevantData || isSubmittingIrrelevant) {
      return;
    }

    const { is_relevant } = irrelevantData;
    setIsRelevant(is_relevant);
  }, [isSubmittingIrrelevant]);

  if (isSubmittingRelevant || isSubmittingIrrelevant) {
    return <Spin />;
  }

  if (isRelevant) {
    return (
      <Button.Group>
        <Button
          onClick={() => {
            submitIrrelevant({ id: record.uuid });
          }}
          key={record.uuid}
          type="primary"
          size="small"
          style={{
            backgroundColor: '#29a329',
            borderColor: '#29a329',
          }}
        >
          <FlagOutlined style={{ fontSize: '20px' }} />
        </Button>
      </Button.Group>
    );
  } else {
    return (
      <Button.Group>
        <Button
          onClick={() => {
            submitRelevant({ id: record.uuid });
          }}
          key={record.uuid}
          type="primary"
          size="small"
          style={{
            backgroundColor: '#e60000',
            borderColor: '#e60000',
          }}
        >
          <FlagOutlined style={{ fontSize: '20px' }} />
        </Button>
      </Button.Group>
    );
  }
};

export const RecommendAction = ({
  text,
  record,
  markRecommendCallback,
  markDisrecommendCallback,
}) => {
  const [isRecommend, setIsRecommend] = useState(record.is_recommend);

  return (
    <Button.Group>
      {isRecommend ? (
        <Button
          onClick={() => {
            markDisrecommendCallback({ id: record.uuid });
            setIsRecommend(false);
          }}
          key={record.uuid}
          type="primary"
          size="small"
          style={{
            backgroundColor: '#0047b3',
            borderColor: '#0047b3',
          }}
        >
          <LikeOutlined style={{ fontSize: '20px' }} />
        </Button>
      ) : (
        <Button
          onClick={() => {
            markRecommendCallback({ id: record.uuid });
            setIsRecommend(true);
          }}
          key={record.uuid}
          type="primary"
          size="small"
          style={{
            backgroundColor: '#ddd',
            borderColor: '#ddd',
          }}
        >
          <LikeOutlined style={{ fontSize: '20px' }} />
        </Button>
      )}
    </Button.Group>
  );
};
