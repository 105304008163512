import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';

const POST_INVESTMENT_PLANS_API_ID = 'INVESTCORE/POST_INVESTMENT_PLANS_API';

const apiCall = enhancedMakeFetchAction(
  POST_INVESTMENT_PLANS_API_ID,
  ({ id, payload }) => ({
    endpoint: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/employee-programs/${id}/investment-plans`,
    method: 'POST',
    body: payload,
    notify: true,
  })
);

export default apiCall;
