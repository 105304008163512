import { Button } from 'antd';
import apiCall from 'apps/investment/apiCalls/company';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import { Link } from 'react-router-dom';
import CompanyForm from './Form';
import ItemDetail from './ItemDetail';
import { columnsGenerator } from './config';

export const InvestmentCompanyListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    title="All Companies"
    apiCall={apiCall}
    params={params}
    createUrl={`/investment/companies/create`}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="data"
    {...props}
  />
);

export const InvestmentCompanyDetailPage = props => (
  <DetailPage
    title="Company"
    apiCall={apiCall}
    listUrl={`/investment/companies`}
    ItemDetail={ItemDetail}
    resourceName="data"
    extraActions={id => (
      <>
        <Button type="primary">
          <Link className="" to={`/investment/companies/${id}/edit`}>
            Edit
          </Link>
        </Button>
      </>
    )}
    {...props}
  />
);

export const InvestmentCompanyEditPage = props => (
  <EditPage
    title="Company"
    apiCall={apiCall}
    listUrl={`/investment/companies`}
    ItemForm={CompanyForm}
    options={{ title: 'Edit company info' }}
    resourceName="data"
    {...props}
  />
);

export const InvestmentCompanyCreatePage = props => (
  <CreatePage
    title="Company"
    apiCall={apiCall}
    listUrl={`/investment/companies`}
    ItemForm={CompanyForm}
    resourceName="data"
    {...props}
  />
);
