import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

export const FETCH_NEWS_DELETE_API_ID = 'DASHBOARD/FETCH_NEWS_DELETE_API_ID';

const apiCall = enhancedMakeFetchAction(FETCH_NEWS_DELETE_API_ID, ({ id }) => ({
  endpoint: `${API_BASE_URL}/marketdb-api/xpider-admin/v1/posts/${id}`,
  method: 'DELETE',
  notify: true,
}));

export default apiCall;
