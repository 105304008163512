import { useFetchApiList, usePostApi } from '@stagapps/redux-utils';
import { Form } from 'antd';
import createApiCall from 'apps/education/apiCalls/course/create';
import courseListApiCall from 'apps/education/apiCalls/course/list';
import topicListApiCall from 'apps/education/apiCalls/topic/list';
import PageTitle from 'apps/home/components/PageTitle';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { useEffect } from 'react';
import { DRAFT_STATUS, PUBLISH_STATUS } from 'utils/constants';
import { convertFormData } from 'utils/formData.js';
import ItemForm from './Form';

const CourseCreate = () => {
  const [form] = Form.useForm();

  const { post: submitItem, isSubmitting, error: submitItemError } = usePostApi(
    createApiCall,
    {
      resourceName: 'item',
    }
  );

  const {
    data: topics,
    load: fetchTopics,
    isTopicLoading,
    paging,
  } = useFetchApiList(topicListApiCall, { resourceName: 'items' });

  const {
    data: courses,
    load: fetchCourses,
    isCourseLoading,
    pagingCourses,
  } = useFetchApiList(courseListApiCall, { resourceName: 'items' });

  useEffect(() => {
    fetchTopics();
    fetchCourses();
  }, []);

  const onFinish = (values, isDraft) => {
    console.debug('Received values of form: ', values);

    const postData = async () => {
      try {
        let submitData = values;

        submitData.points = 0;
        submitData.is_active = 0;
        submitData.status = PUBLISH_STATUS;

        if (isDraft) {
          submitData.status = DRAFT_STATUS;
        }
        if (
          values.photo &&
          values.photo.at(-1) &&
          values.photo.at(-1).originFileObj instanceof File
        ) {
          submitData.photo = values.photo[0].originFileObj;
          submitData.is_active
            ? (submitData.is_active = '1')
            : (submitData.is_active = '0');

          submitData = convertFormData(submitData);
        }

        submitItem({ payload: submitData });
      } catch (error) {
        console.error(error.message);
      }
    };

    postData();
  };

  if (isTopicLoading || isCourseLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <PageTitle title={`Create Course`} />
      <ItemForm
        submiting={isSubmitting}
        courses={courses}
        form={form}
        onFinish={onFinish}
        topics={topics}
        name="course_create_form"
      />
    </>
  );
};

export default CourseCreate;
