import { useFetchApiGet, usePostApi } from '@stagapps/redux-utils';
import { Form } from 'antd';
import deleteApiCall from 'apps/education/apiCalls/lessonVideo/delete';
import getApiCall from 'apps/education/apiCalls/lessonVideo/detail';
import editApiCall from 'apps/education/apiCalls/lessonVideo/edit';
import PageTitle from 'apps/home/components/PageTitle';
import ErrorMessage from 'common/ui/ErrorMessage';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { convertFormData } from 'utils/formData.js';
import ItemForm from './Form';

const LessonVideoEdit = () => {
  const [form] = Form.useForm();
  const params = useParams();

  const {
    data: item,
    load: fetchItem,
    isLoading,
    error: fetchItemError,
  } = useFetchApiGet(getApiCall(params.lesson_id), {
    resourceName: 'item',
  });

  const { post: submitItem, isSubmitting, error: submitItemError } = usePostApi(
    editApiCall(params.lesson_id),
    {
      resourceName: 'item',
    }
  );

  const {
    post: deleteItem,
    isSubmitting: isDeleting,
    error: deleteError,
  } = usePostApi(deleteApiCall(params.lesson_id), {
    resourceName: 'item',
  });

  useEffect(() => {
    fetchItem({ id: params.id });
  }, []);

  const onFinish = values => {
    console.debug('Received values of form: ', values);

    const postData = async () => {
      try {
        let submitData = values;
        submitData.lesson = params.lesson_id;

        if (
          values.video &&
          values.video.at(-1) &&
          values.video.at(-1).originFileObj instanceof File
        ) {
          submitData.video = values.video[0].originFileObj;
          submitData = convertFormData(submitData);
        } else {
          delete submitData['video'];
        }

        submitItem({
          lesson_id: params.lesson_id,
          id: params.id,
          payload: submitData,
        });
      } catch (error) {
        console.error(error.message);
      }
    };

    postData();
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (fetchItemError) {
    return <ErrorMessage {...fetchItemError} />;
  }

  return (
    <>
      <PageTitle title={`Edit Video`} />
      <ItemForm
        submiting={isSubmitting}
        form={form}
        onFinish={onFinish}
        initialValues={item}
        name="lesson_video_edit_form"
        visibility="visible"
        onDelete={() => {
          if (confirm('Are you sure?')) {
            deleteItem({
              lesson_id: params.lesson_id,
              id: params.id,
            });
          }
        }}
      />
    </>
  );
};

export default LessonVideoEdit;
