import { Tag } from 'antd';
import {
  orderSideColors,
  orderSideLabels,
  orderStatusColors,
  orderTypeColors,
} from 'apps/investment/constants/stockOrder';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/formatDate';
import { formatNumber } from 'utils/formatAmount';

export const columnsGenerator = params => [
  {
    title: '',
    dataIndex: 'id',
    align: 'center',
    render: (value, record) => (
      <Link to={`/investment/stock-orders/${record.id}`}>View detail</Link>
    ),
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    align: 'center',
    render: value => formatDateTime(value),
    sorter: true,
  },
  {
    title: 'Order Date',
    dataIndex: 'stock_order_time',
    align: 'center',
    render: value => formatDateTime(value),
    sorter: true,
  },
  {
    title: 'Order Side',
    dataIndex: 'order_side',
    render: value => (
      <Tag color={orderSideColors[value]}>{orderSideLabels[value]}</Tag>
    ),
    align: 'center',
  },
  {
    title: 'Order Type',
    dataIndex: 'order_type',
    render: value => (
      <Tag color={orderTypeColors[value]}>{value.toUpperCase()}</Tag>
    ),
    align: 'center',
  },
  {
    title: 'Symbol',
    render: o => `${o.symbol}`,
    align: 'center',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    render: (value, record) => {
      if (record.order_type == 'lo') {
        return <span>{formatNumber(record.price)}</span>;
      } else {
        return <span>-</span>;
      }
    },
  },
  {
    title: 'Matched Price',
    dataIndex: 'matched_price',
    render: (value, record) => {
      if (value > 0) {
        return <span>{formatNumber(record.price)}</span>;
      } else {
        return <span>-</span>;
      }
    },
  },
  {
    title: 'Volume',
    dataIndex: 'volume',
    type: 'formatNumber',
    sorter: true,
  },
  {
    title: 'Value',
    dataIndex: 'value',
    type: 'formatNumber',
    sorter: true,
  },
  {
    title: 'User',
    dataIndex: 'user',
    render: u => (
      <Link to={`/investment/users/${u.id}`}>
        {u ? `${u.name ?? ''} (${u.email})` : '-'}
      </Link>
    ),
  },
  {
    title: 'Status',
    align: 'center',
    render: o => {
      return <Tag color={orderStatusColors[o.status]}>{o.status_label}</Tag>;
    },
  },
];
