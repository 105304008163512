export const adsFormatOptions = [
  { value: 'banner', label: 'Banner' },
  { value: 'floating', label: 'Floating' },
];

export const adsSlotOptions = [
  { value: 'home', label: 'Home' },
  { value: 'game_leaderboard', label: 'Game Leaderboard' },
  { value: 'global', label: 'Global' },
];
