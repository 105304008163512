import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_ARTICLE_LIST_ADVERTISEMENTS_ID =
  'DASHBOARD/FETCH_ARTICLE_LIST_ADVERTISEMENTS';

const apiCall = enhancedMakeFetchAction(
  FETCH_ARTICLE_LIST_ADVERTISEMENTS_ID,
  params => ({
    endpoint: generateEndpoint({
      host: `${API_BASE_URL}/stagedu-api/admin/v1/ads-content-items`,
      params,
    }),
    method: 'GET',
  })
);

export default apiCall;
