import apiCall from 'apps/hub/apiCalls/chapter';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { useParams } from 'react-router-dom';
import { columnsGenerator } from './config';
import ItemForm from './Form';

export const ChapterListPage = (props, { params }) => {
  const { course_id } = useParams();

  return (
    <ListPage
      enableSearch={true}
      title="All Chapters"
      apiCall={apiCall}
      params={params}
      columnGenerator={props => columnsGenerator(props)}
      resourceName="data"
      createUrl={`/hub/courses/${course_id}/chapters/create`}
      listUrl={`/hub/courses/${course_id}/chapters`}
      {...props}
    />
  );
};

export const ChapterEditPage = props => {
  const { course_id } = useParams();

  return (
    <EditPage
      title="Chapter Edit"
      apiCall={apiCall}
      listUrl={`/hub/courses/${course_id}/chapters`}
      resourceName="data"
      ItemForm={ItemForm}
      {...props}
    />
  );
};

export const ChapterCreatePage = props => {
  const { course_id } = useParams();

  return (
    <CreatePage
      title="Chapter"
      apiCall={apiCall}
      listUrl={`/hub/courses/${course_id}/chapters`}
      resourceName="data"
      ItemForm={ItemForm}
      {...props}
    />
  );
};
