import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';

const CREATE_BUY_ORDER_API_ID = 'DASHBOARD/CREATE_BUY_ORDER_API_ID';

const apiCall = enhancedMakeFetchAction(CREATE_BUY_ORDER_API_ID, payload => ({
  endpoint: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/user-program-orders/create-buy`,
  method: 'POST',
  body: payload,
  notify: true,
}));

export default apiCall;
