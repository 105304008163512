import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'routerHistory';
import {
  adminAccessTokenSelector,
  adminUserSelector,
  resetAdminAuthTokenAC,
  setAdminAuthTokenAC,
} from 'state/adminSession/state';
import {
  deleteToken,
  getAccessToken,
  getRefreshToken,
  getUser,
} from 'utils/auth';

export default function useGoogleAuth() {
  const [isLoading, setIsLoading] = useState(true);
  const authenticatedUser = useSelector(state => adminUserSelector(state));
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);

    const user = getUser();
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();
    if (user && accessToken) {
      dispatch(
        setAdminAuthTokenAC({
          user,
          accessToken: { token: accessToken.token, expired: user?.exp },
          refreshToken: { token: refreshToken.token, expiresIn: 0 },
        })
      );
    }

    setIsLoading(false);
  }, []);

  const signOut = () => {
    dispatch(resetAdminAuthTokenAC());
    deleteToken();

    history.push(`/login`);
  };

  return {
    user: authenticatedUser,
    token: useSelector(state => adminAccessTokenSelector(state)),
    isLoading,
    isAuthenticated: !!(authenticatedUser && authenticatedUser.email),
    signOut,
  };
}
