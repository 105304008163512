import moment from 'moment';

export const convertFormData = values => {
  const keys = Object.keys(values);
  const data = new FormData();
  keys.forEach(key => {
    if (values[key]) {
      data.append(key, values[key]);
    } else {
      data.append(key, '');
    }
  });
  return data;
};

export const normalizeData = values => {
  const keys = Object.keys(values);
  keys.forEach(key => {
    if (typeof values[key] === 'undefined') {
      values[key] = null;
    }
  });
  return values;
};

const DATE_FORTMAT = 'YYYY-MM-DD';

export const ensureDateFields = (values, fields = []) => {
  return {
    ...values,
    ...Object.assign(
      {},
      ...fields.map(key =>
        values[key]
          ? { [key]: moment(values[key], DATE_FORTMAT) }
          : { [key]: null }
      )
    ),
  };
};

export const ensureDateTimeFields = (values, fields = []) => {
  return {
    ...values,
    ...Object.assign(
      {},
      ...fields.map(key =>
        values[key] ? { [key]: moment(values[key]) } : { [key]: null }
      )
    ),
  };
};

export const convertDateFormData = (values, fields = []) => {
  return {
    ...values,
    ...Object.assign(
      {},
      ...fields.map(key =>
        values[key]
          ? { [key]: values[key].format(DATE_FORTMAT) }
          : { [key]: null }
      )
    ),
  };
};
