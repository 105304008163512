import { useEffect, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Image,
  Input,
  InputNumber,
  Select,
  Switch,
  Upload,
} from 'antd';
import {
  eventOptions,
  operatorOptions,
  typeOptions,
} from 'apps/education/apiCalls/challenge/options';
import {
  screenOptions,
  tabNameOptions,
} from 'apps/education/constants/screen/options';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  challengeGroups,
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  const { photo } = initialValues;
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const [ref, setRef] = useState();

  const [isRemoved, setIsRemoved] = useState(false);
  const removePhoto = () => {
    setIsRemoved(true);
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={values => onFinish(values, isRemoved, ref)}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="description" label="Description">
        <Input.TextArea rows={10} />
      </Form.Item>

      <Form.Item name="type" label="Frequency">
        <Select>
          {typeOptions &&
            typeOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item name="value" label="Reward" tooltip="Reward">
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="photo"
        label="Upload a new photo"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      {isRemoved || !photo ? null : (
        <Form.Item
          label="Existing photo"
          style={{
            visibility,
          }}
        >
          <Button
            type="primary"
            shape="circle"
            size="small"
            style={{
              backgroundColor: 'red',
              position: 'absolute',
              zIndex: 99,
              left: 5,
              top: 5,
            }}
            onClick={() => removePhoto()}
          >
            X
          </Button>
          <Image width={200} src={photo}></Image>
        </Form.Item>
      )}

      <Form.Item
        name="ranking"
        label="Priority"
        tooltip="Smallest number will be on top: 1,2,3.."
      >
        <InputNumber />
      </Form.Item>

      <Form.Item name="challenge_group" label="Challenge Group">
        <Select>
          <Option key="" value="">
            -
          </Option>
          {challengeGroups &&
            challengeGroups.map(challengeGroup => (
              <Option key={challengeGroup.id} value={challengeGroup.id}>
                {challengeGroup.title}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item name="condition_action" label="Trigger">
        <Select>
          {eventOptions &&
            eventOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item name="condition_operator" label="Operator">
        <Select>
          {operatorOptions &&
            operatorOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item name="condition_value" label="Min Value to activate">
        <Input />
      </Form.Item>

      <Form.Item name="screen_name" label="Default Screen Direct User">
        <Select>
          {screenOptions &&
            screenOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item name="screen_id" label="Screen ID">
        <InputNumber />
      </Form.Item>

      <Form.Item name="tab_name" label="Tab">
        <Select>
          {tabNameOptions &&
            tabNameOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item name="is_active" label="Status" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button
          type="primary"
          name="save"
          htmlType="submit"
          loading={submiting}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
