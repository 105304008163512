export const typeOptions = [
    {'value': 'once', 'label': 'Once'},
    {'value': 'daily', 'label': 'Daily'},
];

export const renderTypeName = value => {
    var matchOption = typeOptions.filter(option => option.value == value);
    return matchOption[0].label ?? 'Unknown';
}

export const eventOptions = [
    {'value': 'login', 'label': 'Login'},
    {'value': 'complete_course', 'label': 'Complete Any Course'},
    {'value': 'complete_poll', 'label': 'Complete Any Poll'},
    {'value': 'complete_question', 'label': 'Complete Any Question'},
    {'value': 'complete_lesson', 'label': 'Complete Any Lesson'},
    {'value': 'complete_chapter', 'label': 'Complete Any Chapter'},
    {'value': 'complete_order_buy', 'label': 'Buy Any Stock'},
    {'value': 'complete_order_sell', 'label': 'Sell Any Stock'},
    {'value': 'complete_order_buy_value', 'label': 'Buy Stock with Value condition'},
    {'value': 'complete_order_sell_value', 'label': 'Sell Stock with Value condition'},
    {'value': 'comment_course', 'label': 'Comment Course'},
    {'value': 'comment_community', 'label': 'Comment Community'},
    {'value': 'join_community', 'label': 'Join Community'},
    {'value': 'leave_community', 'label': 'Leave Community'},
    {'value': 'add_to_watchlist', 'label': 'Add To Watchlist'},
    {'value': 'watch_stock_detail', 'label': 'Watch Stock Detail'},
];

export const operatorOptions = [
    {'value': 'sum', 'label': 'Sum(tổng số lần)'},
    {'value': 'max', 'label': 'Max(Giá trị cao nhất)'},
];

export const renderEventName = value => {
    var matchOption = eventOptions.filter(option => option.value == value);
    return matchOption[0].label ?? 'Unknown';
}

export const renderOperatorName = value => {
    var matchOption = operatorOptions.filter(option => option.value == value);
    return matchOption[0].label ?? 'Unknown';
}