export const activeColors = {
  // 1000: '#fadb14',
  active: '#52c41a',
  inactive: '#f5222d',
};

export const activeLabels = {
  active: 'Active',
  inactive: 'Inactive',
};

export const statusColors = {
  1001: '#a9a9a9',
  1002: '#52c41a',
  1003: '#f5222d',
  1004: '#FFD700',
};

export const successLabels = {
  1001: 'Pending',
  1002: 'Success',
  1003: 'Error',
};

export const successColors = {
  1001: '#a9a9a9',
  1002: '#52c41a',
  1003: '#FFD700',
};

export const statusOptions = [
  { value: 1001, label: 'Drafted' },
  { value: 1002, label: 'Published' },
  { value: 1003, label: 'Trash' },
  { value: 1004, label: 'Private' },
];

export const DRAFT_STATUS = 1001;
export const PUBLISH_STATUS = 1002;
export const TRASH_STATUS = 1003;
export const PRIVATE_STATUS = 1004;

export const statusLabel = {
  1001: 'Draft',
  1002: 'Published',
  1003: 'Trash',
  1004: 'Private',
};

export const accessColors = {
  1001: '#52c41a',
  1002: '#FFD700',
  1003: '#a9a9a9',
};

export const accessLabel = {
  1001: 'Public',
  1002: 'Private',
  1003: 'Listed Only',
};

export const investmentStatusOptions = [
  { value: 0, label: 'Drafted' },
  { value: 1, label: 'Published' },
];

export const investmentStatusColors = {
  0: '#a9a9a9',
  1: '#52c41a',
};

export const investmentStatusLabels = {
  0: 'Draft',
  1: 'Published',
};

export const investmentIdentificationImageTypeColors = {
  selfie: 'green',
  front_id_card: 'blue',
  back_id_card: 'cyan',
  passport: 'yellow',
  signature: 'purple',
};

export const investmentIdentificationImageTypeLabels = {
  selfie: 'Chân dung',
  front_id_card: 'CCCD/CMND mặt trước',
  back_id_card: 'CCCD/CMND mặt sau',
  passport: 'Passport',
  signature: 'Chữ ký',
};

export const investmentIdentificationImageStatusColors = {
  failed: 'red',
  succeeded: 'green',
};

export const investmentIdentificationImageStatusLabel = {
  failed: 'Thất bại',
  succeeded: 'Thành công',
};

export const investmentUnallocatedBalanceHistoryTypeColors = {
  out: 'red',
  in: 'green',
};

export default {};
