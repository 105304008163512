import { usePostApi } from '@stagapps/redux-utils';
import { useState } from 'react';
import { useFetchApiList } from '@stagapps/redux-utils';
import { Button, Form, Tag, Input, Select } from 'antd';
import apiCall from 'apps/investment/apiCalls/survey';
import DebounceSelect from 'common/ui/DebouceSelect';
import { useEffect } from 'react';
import QueryString from 'qs';
import { useSearchParams } from 'react-router-dom';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { surveyTypeLabel, surveyTypeColors, questionTypeText, questionTypeSingleChoice } from 'apps/investment/constants/survey';

const UserSurveyHistoryForm = ({ form, submiting, onFinish, initialValues = {}, name }) => {
  let [filterParams, setSearchParams] = useSearchParams();
  let requestParams = QueryString.parse(filterParams.toString());

  const userID = requestParams?.user_id;

  const { question_code } = Form.useWatch([], form) || {};
  // const [questionType, setQuestionType] = useState(questionTypeSingleChoice);
  let questionType = questionTypeSingleChoice;

  const {
    data: questions,
    load: loadQuestions,
    isLoading: isLoadingQuestions,
    error: loadQuestionsError,
  } = useFetchApiList(apiCall.questions, {
    resourceName: 'data',
  });

  const {
    data: questionAnswers,
    load: loadQuestionAnswers,
    isLoading: isLoadingQuestionAnswers,
    error: loadQuestionAnswersError,
  } = useFetchApiList(apiCall.questionAnswers, {
    resourceName: 'data',
  });

  const {
    post: deleteUserSurveyHistory,
    isLoading: isDeletingUserSurveyHistory,
    error: deleteUserSurveyHistoryError,
  } = usePostApi(apiCall.delete);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    loadQuestions();
  }, []);

  useEffect(() => {
    if (
      question_code !== null &&
      question_code !== undefined
    ) {
      loadQuestionAnswers({ question_code: question_code });
    }
  }, [question_code]);

  if (isLoadingQuestions) {
    return <LoadingSpinner />;
  }

  let questionOptions = [];
  let answerOptions = [];
  let mapQuestionType = {};
  let mapSurveyType = {};

  if (questions) {
    questionOptions = questions.map(question => ({
      label: question.question_title,
      survey_type: question.survey_type,
      value: question.question_code,
    }));

    questions.forEach(question => {
      mapQuestionType[question.question_code] = question.question_type;
      mapSurveyType[question.question_code] = question.survey_type;
    });
  }

  if (questionAnswers) {
    answerOptions = questionAnswers.map(answer => ({
      label: answer.answer_title,
      value: answer.answer_code,
    }));

    questionType = mapQuestionType[question_code];
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'user_form'}
      onFinish={values => {
        return onFinish({
          user_id: userID,
          question_type: mapQuestionType[values.question_code],
          survey_type: mapSurveyType[values.question_code],
          ...values,
        });
      }}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item
        name="question_code"
        label="Câu hỏi"
      >
        <DebounceSelect
          placeholder="Select or search"
          options={questionOptions}
          displayFn={o => {
            return {
              value: o.value,
              label: (
                <>
                  {o.label} &nbsp;
                  <Tag color={surveyTypeColors[o.survey_type]}>
                    {surveyTypeLabel[o.survey_type]}
                  </Tag>
                </>
              ),
            };
          }}
        />
      </Form.Item>

      {
        questionType === questionTypeSingleChoice ? (
          <Form.Item name="answer_codes" label="Answer">
            <Select>
              {answerOptions.map(o => (
                <Select.Option key={o.value} value={o.value}>
                  {o.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <Form.Item name="answer_codes" label="Answer">
            <Input />
          </Form.Item>
        )
      }

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>

        {initialValues.id ? (
          <Button
            disabled={isDeletingUserSurveyHistory}
            onClick={() => {
              let warningMessage = `Are you sure you want to delete the user answer? Enter id to confirm: ${initialValues.id}`;
              let confirmCode = prompt(warningMessage);

              if (confirmCode == initialValues.id) {
                deleteUserSurveyHistory({ id: initialValues.id });
                return;
              } else {
                alert('code was not match');
              }
            }}
            type="primary"
            style={{
              margin: 10,
              backgroundColor: 'red',
              borderColor: 'red',
            }}
          >
            Delete
          </Button>
        ) : null}
      </Form.Item>
    </Form>
  );
};

export default UserSurveyHistoryForm;
