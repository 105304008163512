import { useFetchApiGet, usePostApi } from '@stagapps/redux-utils';
import { Form } from 'antd';
import deleteApiCall from 'apps/education/apiCalls/notification/delete';
import getApiCall from 'apps/education/apiCalls/notification/detail';
import editApiCall from 'apps/education/apiCalls/notification/edit';
import PageTitle from 'apps/home/components/PageTitle';
import ErrorMessage from 'common/ui/ErrorMessage';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ItemForm from './Form';

const NotificationEdit = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const {
    data: item,
    load: fetchItem,
    isLoading,
    error: fetchItemError,
  } = useFetchApiGet(getApiCall, { resourceName: 'item' });

  const { post: submitItem, isSubmitting, error: submitItemError } = usePostApi(
    editApiCall,
    {
      resourceName: 'item',
    }
  );

  const {
    post: deleteItem,
    isSubmitting: isDeleting,
    error: deleteError,
  } = usePostApi(deleteApiCall, {
    resourceName: 'item',
  });

  useEffect(() => {
    fetchItem({ id: params.id });
  }, []);

  const onFinish = values => {
    console.debug('Received values of form: ', values);

    const postData = async () => {
      try {
        let submitData = values;
        submitItem({ id: params.id, payload: submitData });
      } catch (error) {
        console.error(error.message);
      }
    };

    postData();
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (fetchItemError) {
    return <ErrorMessage {...fetchItemError} />;
  }

  return (
    <>
      <PageTitle title={`Edit Notification #${params.id}`} />
      <ItemForm
        submiting={isSubmitting}
        form={form}
        onFinish={onFinish}
        initialValues={item}
        name="notification_edit_form"
        visibility="visible"
        onDelete={() => {
          if (confirm('Are you sure?')) {
            deleteItem({ id: params.id });
          }
        }}
      />
    </>
  );
};

export default NotificationEdit;
