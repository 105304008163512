import { useEffect } from 'react';

import { Button, DatePicker, Form, Input, Select, Switch } from 'antd';
import { typeOptions } from 'apps/market/apiCalls/stockDividend/option';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { convertDateFormData, ensureDateFields } from 'utils/formData';

const dateFields = ['issue_date', 'exright_date', 'record_date'];

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  useEffect(() => {
    form.setFieldsValue(ensureDateFields(initialValues, dateFields));
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={values => onFinish(convertDateFormData(values, dateFields))}
      initialValues={ensureDateFields(initialValues, dateFields)}
      scrollToFirstError
    >
      <Form.Item
        name="symbol"
        label="Symbol"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="ACB" />
      </Form.Item>

      <Form.Item name="user" label="User ID">
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item name="volume" label="Volume">
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item name="record_date" label="Record Date">
        <DatePicker />
      </Form.Item>

      <Form.Item name="issue_date" label="Issue Date">
        <DatePicker />
      </Form.Item>

      <Form.Item name="exright_date" label="ExRight Date">
        <DatePicker />
      </Form.Item>

      <Form.Item name="dividend_type" label="Type">
        <Select>
          {typeOptions &&
            typeOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item name="ratio" label="Ratio">
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item name="cash" label="Cash">
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item name="shares_issued" label="Shares Ratio">
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item name="cash_issued" label="Shares Cash">
        <Input placeholder="0" />
      </Form.Item>

      <Form.Item name="is_issued" label="Issued?">
        <Switch />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>

        {onDelete ? (
          <Button
            onClick={onDelete}
            type="primary"
            style={{
              visibility: visibility,
              margin: 10,
              backgroundColor: 'red',
              borderColor: 'red',
            }}
          >
            Delete
          </Button>
        ) : null}
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
