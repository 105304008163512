import { Card, Space, Spin, Table, Tag } from 'antd';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useFetchApiGet } from '@stagapps/redux-utils';
import MetaDecorator from 'apps/home/components/MetaDecorator';
import PageTitle from 'apps/home/components/PageTitle';
import apiCall from 'apps/investment/apiCalls/company';
import { investmentUnallocatedBalanceHistoryTypeColors } from 'utils/constants';
import { formatDateTime } from 'utils/formatDate';

const unallocatedBalanceColums = [
  {
    title: 'Created At',
    dataIndex: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Product ID',
    dataIndex: 'product_id',
  },
  {
    title: 'Product Code',
    dataIndex: 'product_code',
  },
  {
    title: 'Unallocated Quantity',
    dataIndex: 'unallocated_quantity',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
];

const unallocatedHistoriesColumns = [
  {
    title: 'Created At',
    dataIndex: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Product ID',
    dataIndex: 'product_id',
  },
  {
    title: 'Product Code',
    dataIndex: 'product_code',
  },
  {
    title: 'Transfer Quantity',
    dataIndex: 'quantity',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Transfer Type',
    dataIndex: 'type',
    align: 'left',
    render: type => {
      return (
        <Tag color={investmentUnallocatedBalanceHistoryTypeColors[type]}>
          {type}
        </Tag>
      );
    },
  },
  {
    title: 'Source Program Order',
    dataIndex: 'program_order_id',
    render: program_order_id => (
      <Link to={`/investment/program-orders/${program_order_id}`}>
        {program_order_id ? program_order_id : 'N/A'}
      </Link>
    ),
  },
];

const InvestmentCompanyUnallocatedHistory = () => {
  const params = useParams();

  const {
    data: company,
    load: fetchCompany,
    isLoading: isLoadingCompany,
  } = useFetchApiGet(apiCall.detail, { resourceName: 'data' });

  const {
    data: balances,
    load: fetchBalances,
    isLoading: isLoadingBalances,
  } = useFetchApiGet(apiCall.balances, { resourceName: 'data' });

  const {
    data: historyTxs,
    load: fetchHistory,
    isLoading: isLoadingHistory,
  } = useFetchApiGet(apiCall.unallocatedHistory, { resourceName: 'data' });

  useEffect(() => {
    fetchCompany({ id: params.company_id });
    fetchBalances({ id: params.company_id });
    fetchHistory({ id: params.company_id });
  }, []);

  const isLoading = isLoadingCompany || isLoadingHistory || isLoadingBalances;

  if (isLoading) {
    return (
      <Spin tip="Loading" size="large">
        <div className="content" />
      </Spin>
    );
  }

  const title = `Company Unallocated History - ${
    company.brand_name ? company.brand_name : ''
  }`;

  const { company_unallocated_balance } = balances || {};

  return (
    <>
      <MetaDecorator title={title} />
      <PageTitle title={title} />
      <Space direction="vertical" size={'large'}>
        <Card title="Unallocated Balances">
          <Table
            rowKey="id"
            columns={unallocatedBalanceColums}
            dataSource={company_unallocated_balance}
            pagination={false}
          />
        </Card>
        <Card title="Unallocated Histories">
          <Table
            rowKey="id"
            columns={unallocatedHistoriesColumns}
            dataSource={historyTxs}
            pagination={true}
          />
        </Card>
      </Space>
    </>
  );
};

export default InvestmentCompanyUnallocatedHistory;
