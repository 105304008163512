import { Button, Space, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { statusColors, statusLabel } from 'utils/constants';
import { formatISODate } from 'utils/formatDate';
import { cloudflareCdn } from 'utils/image';

export const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
  },
  {
    title: 'Photo',
    dataIndex: 'photo',
    render: value => value && <img width={60} src={cloudflareCdn(value, 60)} />,
  },
  {
    title: 'Created',
    dataIndex: 'created',
    render: value => formatISODate(value),
  },
  {
    title: 'Modified',
    dataIndex: 'modified',
    render: value => formatISODate(value),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => {
      return <Tag color={statusColors[value]}>{statusLabel[value]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Space>
          <Button shape="round">
            <Link to={`/market/funds/${record.id}`}>View detail</Link>
          </Button>
          <Button shape="round">
            <Link to={`/market/funds/${record.id}/edit`}>Edit info</Link>
          </Button>
        </Space>
      </Button.Group>
    ),
  },
];
