import { EditOutlined } from '@ant-design/icons';
import { Space, Tag } from 'antd';
import UIIconTooltip from 'common/ui/UIIconTooltip';
import { Link } from 'react-router-dom';
import { statusColors, statusLabel } from 'utils/constants';
import { formatDateTime } from 'utils/formatDate';

export const columnsGenerator = params => [
  {
    title: 'Title',
    dataIndex: 'title',
  },

  {
    title: 'Video URL',
    dataIndex: 'url',
    width: '200px',
    render: value => (
      <video
        width="180"
        height="320"
        controls
        style={{ backgroundColor: 'red' }}
      >
        <source src={value} type="video/mp4" />
      </video>
    ),
  },
  {
    title: 'Ranking',
    dataIndex: 'ranking',
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    render: value => formatDateTime(value),
  },

  {
    title: 'Status',
    dataIndex: 'status',
    render: value => {
      return <Tag color={statusColors[value]}>{statusLabel[value]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space>
        <Link to={`/hub/videos/${record.id}/edit`}>
          <UIIconTooltip title="Edit" Icon={EditOutlined} />
        </Link>
      </Space>
    ),
  },
];
