import educationRoutes from 'apps/education/routes';
import AppLayout from 'apps/home/components/Layout';
import GuestLayout from 'apps/home/components/LayoutGuest';
import AdminLoginPage from 'apps/home/pages/AdminLogin';
import Homepage from 'apps/home/pages/Homepage';
import PageNotFound from 'apps/home/pages/PageNotFound';
import hubRoutes from 'apps/hub/routes';
import investcoreRoutes from 'apps/investcore/routes';
import investmentRoutes from 'apps/investment/routes';
import marketRoutes from 'apps/market/routes';
import { GuestRoute, PrivateRoute } from 'common/ui/AuthRoute';
import PermissionError from 'common/ui/PermissionError';
import useRefreshToken from 'hooks/useRefreshToken';
import { Navigate, Route, Routes } from 'react-router-dom';

const AppRouter = () => {
  const { isLoading } = useRefreshToken();

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/market" element={<Navigate to="/market/etfs" />} />
        <Route path="/edu" element={<Navigate to="/edu/courses" />} />
        <Route path="/cms" element={<Navigate to="/cms/settings" />} />
        <Route path="/hub" element={<Navigate to="/hub/courses" />} />
        <Route
          path="/investment"
          element={<Navigate to="/investment/users" />}
        />
        <Route
          path="/investcore"
          element={<Navigate to="/investcore/banks" />}
        />

        <Route
          element={
            <GuestRoute>
              <GuestLayout />
            </GuestRoute>
          }
        >
          <Route exact path="/login" element={<AdminLoginPage />} />
        </Route>

        <Route
          element={
            <PrivateRoute>
              <AppLayout />
            </PrivateRoute>
          }
        >
          <Route exact path="/home" element={<Homepage />} />

          {hubRoutes.map(e => (
            <Route key={e?.path} {...e} />
          ))}
          {educationRoutes.map(e => (
            <Route key={e?.path} {...e} />
          ))}
          {marketRoutes.map(e => (
            <Route key={e?.path} {...e} />
          ))}
          {investmentRoutes.map(e => (
            <Route key={e?.path} {...e} />
          ))}
          {investcoreRoutes.map(e => (
            <Route key={e?.path} {...e} />
          ))}

          <Route exact path="/permission" element={<PermissionError />} />
          <Route exact path="/404" element={<PageNotFound />} />
          <Route exact path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRouter;
