import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_POLL_LIST_API_ID = 'DASHBOARD/FETCH_POLL_LIST_API_ID';

const apiCall = lesson_id =>
  enhancedMakeFetchAction(FETCH_POLL_LIST_API_ID, params => ({
    endpoint: generateEndpoint({
      host: `${API_BASE_URL}/stagedu-api/admin/v1/lessons/${lesson_id}/polls`,
      params,
    }),
    method: 'GET',
  }));

export default apiCall;
