import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Upload } from 'antd';
import DatePicker from 'common/ui/UIDatePicker';
import { useEffect } from 'react';
import { statusOptions } from 'utils/constants';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { convertDateFormData, ensureDateFields } from 'utils/formData';

const dateFields = ['conversion_date', 'inception_date'];

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    form.setFieldsValue(ensureDateFields(initialValues, dateFields));
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={values => onFinish(convertDateFormData(values, dateFields))}
      initialValues={ensureDateFields(initialValues, dateFields)}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Quỹ Đầu tư Chứng khoán Năng động DC" />
      </Form.Item>

      <Form.Item
        name="type"
        label="Type"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Quỹ cân bằng" />
      </Form.Item>

      <Form.Item
        name="symbol"
        label="Symbol"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="DCDS" />
      </Form.Item>

      <Form.Item name="management_fee" label="Management Fee">
        <Input placeholder="2%/NAV/năm (mức tối đa)" />
      </Form.Item>

      <Form.Item name="redemption_fee" label="Redemption Fee">
        <Input placeholder="0% - 2,5% tùy thời gian nắm giữ" />
      </Form.Item>

      <Form.Item name="subscription_fee" label="Subscription Fee">
        <Input placeholder="0%" />
      </Form.Item>

      <Form.Item name="switching_fee" label="Switching Fee">
        <Input placeholder="0% - 1% tùy thời gian nắm giữ" />
      </Form.Item>

      <Form.Item name="currency" label="Currency">
        <Input placeholder="vnd" />
      </Form.Item>

      <Form.Item name="distributors" label="Distributors">
        <Input />
      </Form.Item>

      <Form.Item name="conversion_date" label="Conversion Date">
        <DatePicker />
      </Form.Item>

      <Form.Item name="inception_date" label="Inception Date">
        <DatePicker />
      </Form.Item>

      <Form.Item name="trading_cycle" label="Trading Cycle">
        <Input placeholder="Hàng ngày" />
      </Form.Item>

      <Form.Item name="cut_off_time" label="Cut Off Time">
        <Input placeholder="14h30 ngày T-1" />
      </Form.Item>

      <Form.Item name="fund_manager" label="Fund Manager">
        <Input placeholder="Công ty Cổ phần Quản lý Quỹ Đầu tư Dragon Capital Việt Nam" />
      </Form.Item>

      <Form.Item name="custodian_bank" label="Custodian Bank">
        <Input placeholder="Standard Chartered Việt Nam" />
      </Form.Item>

      <Form.Item name="transfer_agency" label="Transfer Agency">
        <Input placeholder="Trung tâm lưu ký chứng khoán (VSD)" />
      </Form.Item>

      <Form.Item name="dividend" label="Dividend">
        <Input placeholder="Có thể chia hàng năm." />
      </Form.Item>

      <Form.Item name="description" label="Description">
        <Input.TextArea rows={10} />
      </Form.Item>

      <Form.Item name="strategy" label="Strategy">
        <Input.TextArea rows={10} />
      </Form.Item>

      <Form.Item name="investment_objective" label="Investment Objective">
        <Input.TextArea rows={10} />
      </Form.Item>

      <Form.Item
        name="photo"
        label="Photo"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item name="status" label="Status">
        <Select>
          {statusOptions &&
            statusOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>

        {onDelete ? (
          <Button
            onClick={onDelete}
            type="primary"
            style={{
              visibility: visibility,
              margin: 10,
              backgroundColor: 'red',
              borderColor: 'red',
            }}
          >
            Delete
          </Button>
        ) : null}
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
