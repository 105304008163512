export const typeOptions = [
  {'value': '', 'label': '-'},
  {'value': 'Trả cổ tức bằng tiền mặt', 'label': 'Trả cổ tức bằng tiền mặt(*)'},
  {'value': 'Phát hành cổ phiếu', 'label': 'Trả cổ tức bằng cổ phiếu(*)'},
  {'value': 'Niêm yết mới', 'label': 'Niêm yết mới'},
  {'value': 'Đại hội Cổ đông Bất thường', 'label': 'Đại hội Cổ đông Bất thường'},
  {'value': 'Sự kiện khác', 'label': 'Sự kiện khác'},
  {'value': 'Mua Bán và Sát Nhập', 'label': 'Mua Bán và Sát Nhập'},
  {'value': 'Lấy ý kiến cổ đông bằng văn bản', 'label': 'Lấy ý kiến cổ đông bằng văn bản'},
  {'value': 'Tạm dừng Niêm yết', 'label': 'Tạm dừng Niêm yết'},
  {'value': 'Đại hội Đồng Cổ đông', 'label': 'Đại hội Đồng Cổ đông'},
  {'value': 'Niêm yết thêm', 'label': 'Niêm yết thêm'},
  {'value': 'Chuyển Sàn', 'label': 'Chuyển Sàn'},
  {'value': 'Giao dịch cổ phiếu quỹ', 'label': 'Giao dịch cổ phiếu quỹ'},
];