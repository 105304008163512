import apiCall from 'apps/hub/apiCalls/videos';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import { columnsGenerator } from './config';
import ItemDetail from './detail';
import ItemForm from './Form';

export const VideosListPage = (props, { params }) => (
  <ListPage
    title="All Videos"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="data"
    createUrl={`/hub/videos/create`}
    {...props}
  />
);

export const VideosDetailPage = props => (
  <DetailPage
    title="Video Detail"
    apiCall={apiCall}
    listUrl={`/hub/videos`}
    ItemDetail={ItemDetail}
    resourceName="data"
    options={{ editEnabled: false }}
    {...props}
  />
);

export const VideosCreatePage = props => {
  return (
    <CreatePage
      title="Video Create"
      apiCall={apiCall}
      listUrl={`/hub/videos`}
      ItemForm={ItemForm}
      resourceName="data"
      {...props}
    />
  );
};

export const VideosEditPage = props => {
  return (
    <EditPage
      title="Video Edit"
      apiCall={apiCall}
      listUrl={`/hub/videos`}
      ItemForm={ItemForm}
      resourceName="data"
      {...props}
    />
  );
};
