import { Button } from 'antd';
import apiCall from 'apps/market/apiCalls/stockEventLog';
import { ListPage } from 'common/ui/AdminCRUD/page';
import FilterForm from './FilterForm';
// import ItemForm from './Form';

const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
  },
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Type',
    dataIndex: 'name',
  },
  {
    title: 'value',
    dataIndex: 'value',
  },
  {
    title: 'ratio',
    dataIndex: 'ratio',
  },
  {
    title: 'Exright Date',
    dataIndex: 'exright_date',
  },
  {
    title: 'Record Date',
    dataIndex: 'record_date',
  },
  {
    title: 'Public date',
    dataIndex: 'public_date',
  },
  {
    title: 'Issue Date',
    dataIndex: 'issue_date',
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">"Not support"</Button>
      </Button.Group>
    ),
  },
];

// Pages

export const StockEventLogListPage = (props, { params }) => (
  <ListPage
    title="All Stock Event Logs"
    apiCall={apiCall}
    params={params}
    // createUrl={`/market/stock-event-logs/create`}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="items"
    FilterForm={FilterForm}
    {...props}
  />
);
