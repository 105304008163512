import { useFetchApiGet, usePostApi } from '@stagapps/redux-utils';
import { Form } from 'antd';
import getLessonDetailApiCall from 'apps/education/apiCalls/lesson/detail';
import createApiCall from 'apps/education/apiCalls/question/create';
import PageTitle from 'apps/home/components/PageTitle';
import ErrorMessage from 'common/ui/ErrorMessage';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { convertFormData } from 'utils/formData.js';
import ItemFormCheckBox from './FormCheckBox';
import ItemFormMultiCheckBox from './FormMultiCheckBox';
import ItemFormTypeForm from './FormTypeForm';

const QuestionCreate = () => {
  const [form] = Form.useForm();
  const params = useParams();

  const { post: submitItem, isSubmitting, error: submitItemError } = usePostApi(
    createApiCall(params.lesson_id),
    {
      resourceName: 'item',
    }
  );

  const {
    data: lessonItem,
    load: fetchLessonItem,
    isLessonLoading,
    error: fetchLessonItemError,
  } = useFetchApiGet(getLessonDetailApiCall, { resourceName: 'item' });

  useEffect(() => {
    fetchLessonItem({ id: params.lesson_id });
  }, []);

  const onFinish = values => {
    console.debug('Received values of form: ', values);

    const postData = async () => {
      try {
        let submitData = values;

        submitData.lesson = params.lesson_id;
        submitData.points = 0;

        if (
          values.photo &&
          values.photo.at(-1) &&
          values.photo.at(-1).originFileObj instanceof File
        ) {
          submitData.photo = values.photo[0].originFileObj;
          submitData.is_active
            ? (submitData.is_active = '1')
            : (submitData.is_active = '0');
          submitData.points = '0';

          submitData = convertFormData(submitData);
        }

        submitItem({ lesson_id: params.lesson_id, payload: submitData });
      } catch (error) {
        console.error(error.message);
      }
    };

    postData();
  };

  if (isLessonLoading) {
    return <LoadingSpinner />;
  }

  if (fetchLessonItemError) {
    return <ErrorMessage {...fetchLessonItemError} />;
  }

  var ItemForm = null;
  if (lessonItem && lessonItem.type == 'quiz_check_box') {
    ItemForm = ItemFormCheckBox;
  } else if (lessonItem && lessonItem.type == 'quiz_multi_check_box') {
    ItemForm = ItemFormMultiCheckBox;
  } else if (lessonItem && lessonItem.type == 'quiz_type_form') {
    ItemForm = ItemFormTypeForm;
  }

  if (!isLessonLoading && !ItemForm) {
    return <ErrorMessage />;
  }

  return (
    <>
      <PageTitle title={`Create Question`} />
      <ItemForm
        submiting={isSubmitting}
        form={form}
        onFinish={onFinish}
        name="question_create_form"
      />
    </>
  );
};

export default QuestionCreate;
