import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

const FETCH_QUESTION_DETAIL_API_ID = 'DASHBOARD/FETCH_QUESTION_DETAIL';

const apiCall = lesson_id =>
  enhancedMakeFetchAction(FETCH_QUESTION_DETAIL_API_ID, ({ id }) => ({
    endpoint: `${API_BASE_URL}/stagedu-api/admin/v1/lessons/${lesson_id}/questions/${id}`,
    method: 'GET',
  }));

export default apiCall;
