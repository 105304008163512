import { Button, Form, Input, Space } from 'antd';
import { useSearchParams } from 'react-router-dom';

const SearchForm = ({ form, placeholder, name }) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const handleSubmit = event => {
    const { search } = event;
    setSearchParams({ search });
  };

  return (
    <Form
      form={form}
      layout="inline"
      name={name || 'search_form'}
      onFinish={handleSubmit}
      initialValues={{ search: searchParams.get('search') }}
    >
      <Form.Item name="search" label="Search">
        <Space>
          <Input placeholder={placeholder || 'Enter to search'} />
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default SearchForm;
