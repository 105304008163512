import { usePostApi } from '@stagapps/redux-utils';
import { Form } from 'antd';
import createApiCall from 'apps/education/apiCalls/topic/create';
import PageTitle from 'apps/home/components/PageTitle';
import { convertFormData } from 'utils/formData.js';
import ItemForm from './Form';

const TopicCreate = () => {
  const [form] = Form.useForm();

  const { post: submitItem, isSubmitting, error: submitItemError } = usePostApi(
    createApiCall,
    {
      resourceName: 'item',
    }
  );

  const onFinish = values => {
    console.debug('Received values of form: ', values);

    const postData = async () => {
      try {
        let submitData = values;

        if (
          values.photo &&
          values.photo.at(-1) &&
          values.photo.at(-1).originFileObj instanceof File
        ) {
          submitData.photo = values.photo[0].originFileObj;
          submitData.is_active
            ? (submitData.is_active = '1')
            : (submitData.is_active = '0');

          submitData = convertFormData(submitData);
        }

        submitItem({ payload: submitData });
      } catch (error) {
        console.error(error.message);
      }
    };

    postData();
  };

  return (
    <>
      <PageTitle title={`Create Topic`} />
      <ItemForm
        submiting={isSubmitting}
        form={form}
        onFinish={onFinish}
        name="topic_create_form"
      />
    </>
  );
};

export default TopicCreate;
