import {
  stockBalanceHistoriesTypeColors,
} from 'apps/investment/constants/stockAccount';
import { Tag } from 'antd';

import { formatDateTime, formatDate } from 'utils/formatDate';

export const stockBalanceHistoriesColumnsGenerator = params => [
  {
    title: 'Created At',
    dataIndex: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Date',
    dataIndex: 'trade_date',
    render: value => formatDate(value),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    type: 'formatNumber',
    sorter: true,
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    type: 'formatNumber',
    sorter: true,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    render: (value, record) => {
      return (
        <Tag color={stockBalanceHistoriesTypeColors[record.type]}>
          {record.type_label}
        </Tag>
      );
    },
  },
  {
    title: 'Note',
    dataIndex: 'note',
  },
];
