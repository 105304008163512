import jwtDecode from 'jwt-decode';

export async function setAccessToken(token) {
  localStorage.setItem(
    'adminSession.accessToken',
    JSON.stringify({ token, expired: 0 })
  );
}
export async function setRefreshToken(token, expiresIn) {
  console.log(token);
  localStorage.setItem(
    'adminSession.refreshToken',
    JSON.stringify({ token, expiresIn })
  );
}

export async function setUser(user) {
  localStorage.setItem('adminSession.user', JSON.stringify(user));
}

export async function deleteToken() {
  localStorage.removeItem('adminSession.accessToken');
  localStorage.removeItem('adminSession.refreshToken');
  localStorage.removeItem('adminSession.user');
}

export function getAccessToken() {
  const tokenObject = localStorage.getItem('adminSession.accessToken');
  if (tokenObject) return JSON.parse(tokenObject);
  return {};
}

export function getRefreshToken() {
  const tokenObject = localStorage.getItem('adminSession.refreshToken');
  if (tokenObject) return JSON.parse(tokenObject);
  return {};
}

export function getUser() {
  const user = localStorage.getItem('adminSession.user');
  return JSON.parse(user);
}

export function parseOAuth(oauthObject) {
  if (oauthObject) {
    const { id_token, refresh_token, expires_in, ...props } = oauthObject;
    if (!id_token) {
      return {};
    }

    const user = jwtDecode(id_token);

    return {
      user,
      token: id_token,
      refreshToken: refresh_token,
      expiresIn: expires_in,
      props,
    };
  }

  return {};
}
