import { useFetchApiGet } from '@stagapps/redux-utils';
import { Image, Space, Table, Tag } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import MetaDecorator from 'apps/home/components/MetaDecorator';
import PageTitle from 'apps/home/components/PageTitle';
import {
  orderSourceColors,
  orderSourceLabels,
} from 'apps/investment/constants/fundOrder';
import ErrorMessage from 'common/ui/ErrorMessage';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { formatNumber } from 'utils/formatAmount';

const columns = [
  {
    title: 'Source',
    dataIndex: 'source',
    render: value =>
      value ? (
        <Tag color={orderSourceColors[value]}>{orderSourceLabels[value]}</Tag>
      ) : null,
  },

  {
    title: 'Product Code',
    dataIndex: 'fund_code',
    render: (value, record) => (
      <Space>
        <Image width={51} src={record?.logo} />
        <span>{value}</span>
      </Space>
    ),
  },

  {
    title: 'Quantity',
    dataIndex: 'quantity',
    render: value => formatNumber(value),
    align: 'right',
  },

  {
    title: 'NAV Price',
    dataIndex: 'estimate_market_price',
    render: value => formatNumber(value),
    align: 'right',
  },
  {
    title: 'Cert Market',
    dataIndex: 'cert_market',
    render: value => formatNumber(value),
    align: 'right',
  },
  {
    title: 'Total Subscription Amount',
    dataIndex: 'total_subscription_amount',
    render: value => formatNumber(value),
    align: 'right',
  },
];

function Portfolio({ apiCall }) {
  const title = 'Stag Account Portfolio';

  const params = useParams();

  const {
    data: portfolio,
    load: loadPortfolio,
    isLoading: isLoadingPortfolio,
    error: errorPortfolio,
  } = useFetchApiGet(apiCall.portfolio, { resourceName: 'data' });

  useEffect(() => {
    loadPortfolio(params);
  }, []);

  if (isLoadingPortfolio) {
    return <LoadingSpinner />;
  }
  if (errorPortfolio) {
    return <ErrorMessage errors={errorPortfolio} />;
  }

  const { portfolio_items = [] } = portfolio || {};
  return (
    <>
      <MetaDecorator title={title} />
      <PageTitle title={title} backUrl={`/investment/users`} />
      <Table
        dataSource={portfolio_items}
        columns={columns}
        pagination={false}
      />
    </>
  );
}

export default Portfolio;
