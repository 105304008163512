import { useFetchApiGet } from '@stagapps/redux-utils';
import { Card, Space, Statistic } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import MetaDecorator from 'apps/home/components/MetaDecorator';
import PageTitle from 'apps/home/components/PageTitle';
import ErrorMessage from 'common/ui/ErrorMessage';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { getChangeValueColor } from 'utils/color';
import { formatNumber } from 'utils/formatAmount';

function ProgramDetailPortfolio({ apiCall }) {
  const title = 'Employee Program Portfolio';
  const params = useParams();
  const {
    data: port,
    load: loadPort,
    isLoading: isLoadingPort,
    error: errorPort,
  } = useFetchApiGet(apiCall.portfolio, {
    resourceName: 'data',
  });

  useEffect(() => {
    loadPort(params);
  }, []);

  if (isLoadingPort) {
    return <LoadingSpinner />;
  }
  if (errorPort) {
    return <ErrorMessage errors={error} />;
  }

  const { total_summary, company_summary, employee_summary } = port;
  return (
    <div className="flex flex-col gap-6">
      <MetaDecorator title={title} />
      <PageTitle title={title} backUrl={`/investment/employee-programs`} />
      <Contribute {...total_summary} />
      <Contribute {...company_summary} />
      <Contribute {...employee_summary} />
    </div>
  );
}

const Contribute = ({
  side,
  contributed_amount,
  market_value,
  unrealized_gain_loss_amount,
  unrealized_gain_loss_percentage,
}) => (
  <Card title={side?.toUpperCase()}>
    <Space size="large">
      <Statistic
        title="Contributed amount"
        value={contributed_amount}
        formatter={formatNumber}
      />
      <Statistic
        title="NAV Price"
        value={market_value}
        formatter={formatNumber}
      />
      <Statistic
        valueStyle={{ color: getChangeValueColor(unrealized_gain_loss_amount) }}
        title="Unrealized Gain Loss"
        value={unrealized_gain_loss_amount}
        formatter={formatNumber}
        suffix={`(${formatNumber(unrealized_gain_loss_percentage)}%)`}
      />
    </Space>
  </Card>
);

export default ProgramDetailPortfolio;
