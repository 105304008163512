import React from 'react';

const ErrorMessage = props => {
  const { errors, history } = props;

  if (!errors) {
    return (
      <div id="content">
        <div className="container">Something went wrong. Please try again.</div>
      </div>
    );
  }

  const { error } = errors;

  return (
    <div id="content">
      <div className="container">{error || errors}</div>
    </div>
  );
};

export default ErrorMessage;
