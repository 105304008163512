import { Button, Tag } from 'antd';
import { Link } from 'react-router-dom';

import { statusColors, statusLabel } from 'utils/constants';
import { formatISODate } from 'utils/formatDate';
import { cloudflareCdn } from 'utils/image';

export const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
  },
  {
    title: 'Market Cap',
    dataIndex: 'market_cap',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Rank',
    dataIndex: 'market_cap_rank',
  },
  {
    title: 'Photo',
    dataIndex: 'photo',
    render: value => value && <img width={60} src={cloudflareCdn(value, 60)} />,
  },
  {
    title: 'Created',
    dataIndex: 'created',
    render: value => formatISODate(value),
  },
  {
    title: 'Modified',
    dataIndex: 'modified',
    render: value => formatISODate(value),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => {
      return <Tag color={statusColors[value]}>{statusLabel[value]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/market/cryptos/${record.id}/edit`}>Edit</Link>
        </Button>
      </Button.Group>
    ),
  },
];
