export const programOrderStatusWaitingForApprove = 1002;
export const programOrderStatusComplete = 1005;
export const programOrderStatusCanceled = 1006;
export const programOrderMatchSourceFundManager = 1001;
export const programOrderMatchSourceCompanyPortfolio = 1002;
export const programOrderTypeRedistribute = 'redistribute';

export const userProgramOrderStatusCanceled = 1006;

export const programOrderStatus = {
  1001: 'Mới',
  1002: 'Chờ duyệt',
  1003: 'Đã duyệt',
  1004: 'Đã đóng gói',
  1005: 'Đã phân phối',
  1006: 'Đã hủy',
  1007: 'Đã đóng gói lại',
};
export const programOrderStatusColors = {
  1001: 'yellow',
  1002: 'yellow',
  1003: 'blue',
  1004: 'yellow',
  1005: 'green',
  1006: 'gray',
  1007: 'yellow',
};

export const programOrderStatusOptions = [
  { value: 1001, label: 'Mới' },
  { value: 1002, label: 'Chờ duyệt' },
  { value: 1003, label: 'Đã duyệt' },
  { value: 1004, label: 'Đã đóng gói' },
  { value: 1005, label: 'Đã phân phối' },
  { value: 1006, label: 'Đã hủy' },
  { value: 1007, label: 'Đã đóng gói lại' },
];

export const orderSideColors = {
  employee: 'blue',
  company: 'orange',
};

export const orderSideLabels = {
  employee: 'Employee',
  company: 'Company',
};

export const orderTypeColors = {
  buy: 'green',
  rollover: 'blue',
  sell: 'red',
};

export const userProgramOrderStatus = {
  1001: 'Mới',
  1002: 'Chờ duyệt',
  1003: 'Đã duyệt',
  1004: 'Đã đóng gói',
  1005: 'Đã phân phối',
  1006: 'Đã hủy',
  1007: 'Đã đóng gói lại',
};
export const userProgramOrderStatusColors = {
  1001: 'yellow',
  1002: 'yellow',
  1003: 'blue',
  1004: 'yellow',
  1005: 'green',
  1006: 'gray',
  1007: 'yellow',
};

export const userProgramOrderMethodScheduled = 1001;
export const userProgramOrderMethodTopup = 1002;

export const userProgramOrderMethodColors = {
  1001: 'blue',
  1002: 'green',
}

export const userProgramOrderStatusOptions = [
  { value: 1001, label: 'Mới' },
  { value: 1002, label: 'Chờ duyệt' },
  { value: 1003, label: 'Đã duyệt' },
  { value: 1004, label: 'Đã đóng gói' },
  { value: 1005, label: 'Đã phân phối' },
  { value: 1006, label: 'Đã hủy' },
  { value: 1007, label: 'Đã đóng gói lại' },
];