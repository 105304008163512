import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_INVESTCORE_PORTFOLIO_STOCK_LIST_API =
  'INVESTMENT/FETCH_PORTFOLIO_STOCK_LIST_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_INVESTCORE_PORTFOLIO_STOCK_LIST_API,
  params => ({
    endpoint: generateEndpoint({
      host: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/portfolios/stocks`,
      params: {
        // ordering: 'updated_at',
        ...params,
      },
    }),
    method: 'GET',
  })
);

export default apiCall;
