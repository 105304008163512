import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

const FETCH_GAME_USERS_EDIT_API = 'DASHBOARD/FETCH_GAME_USERS_EDIT_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_GAME_USERS_EDIT_API,
  ({ id, user_id, payload }) => ({
    endpoint: `${API_BASE_URL}/stagedu-api/admin/v1/game-rooms/${id}/participants/${user_id}`,
    method: 'PATCH',
    body: payload,
    notify: true,
  })
);

export default apiCall;
