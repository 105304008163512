export { default as listApiCall } from './list';

import listApiCall from './list';
import detailApiCall from './detail';

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  create: detailApiCall,
  edit: detailApiCall,
  delete: detailApiCall,
};

export default ApiCall;
