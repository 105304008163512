import { Button } from 'antd';
import apiCall from 'apps/education/apiCalls/gameParticipants';
import MetaDecorator from 'apps/home/components/MetaDecorator';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { Link, useParams } from 'react-router-dom';
import ItemForm from './Form';
import ImportForm from './ImportForm';
import { columnsGenerator } from './config';

export const GameUserEditPage = props => (
  <EditPage
    title="Game Participants"
    apiCall={apiCall}
    listUrl={`/edu/game-rooms`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);

export const GameUserListPage = props => {
  const { id } = useParams();

  return (
    <ListPage
      enableSearch={true}
      searchOptions={{
        placeHolder: "Enter user's name, email, userID or user fullname",
      }}
      title="Game Participants"
      extra={[
        <Button key="2">
          <Link to={`/edu/game-rooms/${id}/users/import`}>Import</Link>
        </Button>,
        // <Button key="1">
        //   <Link to={`/edu/game-rooms/${id}/users/create`}>Add</Link>
        // </Button>,
      ]}
      apiCall={apiCall}
      columnGenerator={props => columnsGenerator(props)}
      resourceName="items"
      {...props}
    />
  );
};

export const GameUserCreatePage = props => (
  <CreatePage
    title="Game Participants"
    apiCall={apiCall}
    listUrl={`/edu/game-rooms`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);

export const GameParticipantImport = props => {
  const title = 'Import Game Participant';
  return (
    <>
      <MetaDecorator title={title} />
      {/* <PageTitle title={title} /> */}
      <ImportForm />
    </>
  );
};
