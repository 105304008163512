import { useFetchApiGet, usePostApi } from '@stagapps/redux-utils';
import { Button, Card, Descriptions, Space, Table, Tag } from 'antd';
import {
  orderTypeColors,
  programOrderStatus,
  programOrderStatusColors,
  programOrderStatusComplete,
  programOrderStatusWaitingForApprove,
} from 'apps/investment/constants/programOrder';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { useEffect } from 'react';
import { formatDateTime } from 'utils/formatDate';

const EstimateVesting = ({
  id,
  companyId,
  employeeId,
  apiCall,
  programStatus,
}) => {
  const {
    data: estimateData,
    load: fetchEstimateVesting,
    isLoading: isLoadingEstimateVesting,
  } = useFetchApiGet(apiCall.estimate, {
    resourceName: 'data',
  });

  const {
    post: postTriggerCompanySellOrder,
    data: responseDataTriggerSellOrder,
    isLoading: isSubmittingTriggerSellOrder,
  } = usePostApi(apiCall.createCompanySellOrder, {
    resourceName: 'data',
  });

  const {
    post: postApproveProgramOrder,
    data: responseDataApproveProgramOrder,
    isLoading: isSubmittingApproveProgramOrder,
  } = usePostApi(apiCall.approveProgramOrder, {
    resourceName: 'data',
  });

  const {
    post: postTriggerEmployeeSellOrder,
    data: responseDataTriggerEmployeeSellOrder,
    isLoading: isSubmittingTriggerEmployeeSellOrder,
  } = usePostApi(apiCall.createEmployeeSellOrder, {
    resourceName: 'data',
  });

  const onTriggerCompanySellOrder = () => {
    const payload = {
      company_id: companyId,
      employee_program_id: id,
    };
    postTriggerCompanySellOrder({ id, payload });
  };
  const onTriggerEmployeeSellOrder = ({ product_id, quantity }) => {
    const payload = {
      company_id: companyId,
      employee_program_id: id,
      product_id: product_id,
      quantity: quantity,
    };
    postTriggerEmployeeSellOrder({ id, payload });
  };

  const onApproveProgramOrder = payload => {
    postApproveProgramOrder({ companyId, payload });
  };

  useEffect(() => {
    fetchEstimateVesting({ id });
  }, []);

  if (isLoadingEstimateVesting) {
    return <LoadingSpinner />;
  }

  const { estimate, program_orders } = estimateData;
  const employeeProgramOrders = program_orders.filter(
    o => o.order_side == 'employee' && o.order_type == 'buy'
  );
  const employeeProgramOrdersMap = employeeProgramOrders.reduce((map, obj) => {
    map[obj.interval] = obj;
    return map;
  }, {});
  const companyProgramOrders = program_orders.filter(
    o => o.order_side == 'company'
  );
  const companyProgramOrdersMap = companyProgramOrders.reduce((map, obj) => {
    map[obj.interval] = obj;
    return map;
  }, {});
  const programSellOrders = program_orders.filter(o => o.order_type == 'sell');

  var estimateDetailCols = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Fee',
      dataIndex: 'fee_percentage',
      key: 'fee_percentage',
    },
    {
      title: 'Fund Certs Quantity',
      dataIndex: 'fund_cert_quantity',
      key: 'fund_cert_quantity',
    },
    {
      title: 'Fund Certs Amount',
      dataIndex: 'fund_cert_amount',
      key: 'fund_cert_amount',
    },
    {
      title: 'Fee Amount',
      dataIndex: 'fee_amount',
      key: 'fee_amount',
    },
  ];

  var programOrderBuyCols = [
    {
      title: 'Interval',
      dataIndex: 'interval',
      key: 'interval',
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Order Date',
      dataIndex: 'order_date',
      key: 'order_date',
    },
    {
      title: 'Product',
      dataIndex: 'product_code',
      key: 'product_code',
    },
    {
      title: 'Matched Price',
      dataIndex: 'matched_price',
      key: 'matched_price',
    },
    {
      title: 'Employee Contribute Amount',
      dataIndex: 'employee_amount',
      key: 'employee_amount',
    },
    {
      title: 'Company Contribute Amount',
      dataIndex: 'company_amount',
      key: 'company_amount',
    },
    {
      title: 'Employee Quantity',
      dataIndex: 'employee_quantity',
      key: 'employee_quantity',
    },
    {
      title: 'Company Quantity',
      dataIndex: 'company_quantity',
      key: 'company_quantity',
    },
    {
      title: 'Order Type',
      dataIndex: 'company_order_type',
      key: 'company_order_type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  let programOrderBuyDataSource = employeeProgramOrders.map((item, index) => ({
    key: item.interval,
    interval: item.interval,
    note:
      companyProgramOrdersMap[item.interval] &&
      companyProgramOrdersMap[item.interval].status ==
        programOrderStatusComplete ? (
        <Tag color="green">Công ty đóng đủ</Tag>
      ) : (
        <Tag color="red">Công ty đóng thiếu</Tag>
      ),
    order_date: formatDateTime(item.order_date),
    product_code: item.product_code,
    matched_price: new Intl.NumberFormat('en-US').format(item.matched_price),
    employee_amount: new Intl.NumberFormat('en-US').format(item.amount),
    company_amount:
      companyProgramOrdersMap[item.interval] &&
      companyProgramOrdersMap[item.interval].amount
        ? new Intl.NumberFormat('en-US').format(
            companyProgramOrdersMap[item.interval].amount
          )
        : 0,
    employee_quantity: new Intl.NumberFormat('en-US').format(item.quantity),
    company_quantity:
      companyProgramOrdersMap[item.interval] &&
      companyProgramOrdersMap[item.interval].quantity
        ? new Intl.NumberFormat('en-US').format(
            companyProgramOrdersMap[item.interval].quantity
          )
        : 0,
    company_order_type: companyProgramOrdersMap[item.interval] ? (
      <Tag
        color={
          orderTypeColors[companyProgramOrdersMap[item.interval].order_type]
        }
      >
        {companyProgramOrdersMap[item.interval].order_type}
      </Tag>
    ) : (
      ''
    ),
    status: (
      <Tag color={programOrderStatusColors[item.status]}>
        {programOrderStatus[item.status]}
      </Tag>
    ),
  }));

  programOrderBuyDataSource.sort((a, b) => a.interval - b.interval);
  let disabledTriggerCompanySellOrder =
    programSellOrders.length > 0 &&
    programSellOrders.some(order => order.order_side === 'company');

  var programOrderSellCols = [
    {
      title: 'Interval',
      dataIndex: 'interval',
      key: 'interval',
    },
    {
      title: 'Order Date',
      dataIndex: 'order_date',
      key: 'order_date',
    },
    {
      title: 'Product',
      dataIndex: 'product_code',
      key: 'product_code',
    },
    {
      title: 'Matched Price',
      dataIndex: 'matched_price',
      key: 'matched_price',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Order Type',
      dataIndex: 'order_type',
      key: 'order_type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  let programOrderSellDataSource = programSellOrders.map((item, index) => ({
    key: item.interval,
    interval: item.interval,
    order_date: formatDateTime(item.order_date),
    product_code: item.product_code,
    matched_price: new Intl.NumberFormat('en-US').format(item.matched_price),
    employee_amount: new Intl.NumberFormat('en-US').format(item.amount),
    amount: new Intl.NumberFormat('en-US').format(item.amount),
    quantity: new Intl.NumberFormat('en-US').format(item.quantity),
    order_type: (
      <Tag color={orderTypeColors[item.order_type]}>{item.order_type}</Tag>
    ),
    status: (
      <Tag color={programOrderStatusColors[item.status]}>
        {programOrderStatus[item.status]}
      </Tag>
    ),
    action: (
      <Button
        disabled={item.status != programOrderStatusWaitingForApprove}
        loading={isSubmittingApproveProgramOrder}
        type="primary"
        onClick={() =>
          confirm('Confirm to approve sell order?') &&
          onApproveProgramOrder({ program_order_ids: [item.id] })
        }
      >
        Approve
      </Button>
    ),
  }));

  return (
    <Space direction="vertical" size="large">
      <Card>
        <Descriptions
          title="Estimate Vesting"
          column={1}
          extra={
            <Button
              disabled={disabledTriggerCompanySellOrder}
              loading={isSubmittingTriggerSellOrder}
              type="primary"
              onClick={() =>
                confirm(
                  'Confirm to trigger company sell order? This action can not rollback'
                ) && onTriggerCompanySellOrder()
              }
            >
              Company Trigger Sell Order
            </Button>
          }
        ></Descriptions>
        {estimate &&
          estimate.map((estimateItem, index) => (
            <Space direction="vertical" size="large">
              <Descriptions
                className="px-4"
                title={`${estimateItem.fund_code} Estimate Vesting`}
                bordered
                column={1}
              >
                <Descriptions.Item label={'Product'}>
                  {estimateItem.fund_code}
                </Descriptions.Item>
                <Descriptions.Item label={'Product NAV'}>
                  {new Intl.NumberFormat('en-US').format(estimateItem.fund_nav)}
                </Descriptions.Item>
                <Descriptions.Item label={'Total Fund Certs Quantity'}>
                  {new Intl.NumberFormat('en-US').format(
                    estimateItem.fund_cert_quantity
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={'Total Fund Certs Amount'}>
                  {new Intl.NumberFormat('en-US').format(
                    estimateItem.fund_cert_amount
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={'Total Company Unpaid Amount'}>
                  {new Intl.NumberFormat('en-US').format(
                    estimateItem.company_unpaid_amount
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={'Total Fee'}>
                  {new Intl.NumberFormat('en-US').format(
                    estimateItem.fee_amount
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={'Total Amount'}>
                  <Tag color="purple">
                    {new Intl.NumberFormat('en-US').format(estimateItem.amount)}
                  </Tag>
                </Descriptions.Item>
              </Descriptions>
              <Descriptions title="Detail" column={2}>
                <Descriptions.Item>
                  <Table
                    dataSource={estimateItem.items.map((item, index) => ({
                      key: index,
                      label: item.label,
                      fee_percentage: `${item.fee_percentage}%`,
                      fund_cert_quantity: new Intl.NumberFormat('en-US').format(
                        item.fund_cert_quantity
                      ),
                      fund_cert_amount: new Intl.NumberFormat('en-US').format(
                        item.fund_cert_amount
                      ),
                      fee_amount: new Intl.NumberFormat('en-US').format(
                        item.fee_amount
                      ),
                    }))}
                    pagination={false}
                    columns={estimateDetailCols}
                  />
                </Descriptions.Item>
              </Descriptions>
            </Space>
          ))}

        <Descriptions title="Program Buy Orders" column={3}>
          <Descriptions.Item>
            <Table
              dataSource={programOrderBuyDataSource}
              pagination={false}
              columns={programOrderBuyCols}
            />
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Program Sell Orders" column={3}>
          <Descriptions.Item>
            <Table
              dataSource={programOrderSellDataSource}
              pagination={false}
              columns={programOrderSellCols}
            />
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </Space>
  );
};

export default EstimateVesting;
