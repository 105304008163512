import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_TOPIC_LIST_API_ID = 'EDU/FETCH_TOPIC_LIST_API';

const apiCall = enhancedMakeFetchAction(FETCH_TOPIC_LIST_API_ID, params => ({
  endpoint: generateEndpoint({
    host: `${API_BASE_URL}/investcore-api/admin/edu/topics`,
    params,
  }),
  method: 'GET',
}));

export default apiCall;
