import { Space, Tag } from 'antd';
import VerificationMark from 'common/ui/VerificationMark';
import { Link } from 'react-router-dom';
import { activeColors, activeLabels } from 'utils/constants';

import { formatDateTime } from 'utils/formatDate';

export const columnsGenerator = props => [
  {
    title: '',
    dataIndex: 'id',
    align: 'center',
    render: id => <Link to={`/investment/staffs/${id}`}>View</Link>,
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    sorter: true,
    render: value => formatDateTime(value),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    render: (value, record) => (
      <VerificationMark value={value} isVerified={record.email_verified} />
    ),
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    align: 'right',
  },
  {
    title: 'Business Account',
    dataIndex: 'user',
    align: 'left',
    render: user => {
      return user ? `${user.name} (${user.email})` : null;
    },
  },
  {
    title: 'Permission role',
    dataIndex: 'permission_role',
    align: 'center',
    sorter: true,
  },

  {
    title: 'Status',
    dataIndex: 'status',
    render: status => {
      return <Tag color={activeColors[status]}>{activeLabels[status]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space>
        <Link to={`/investment/staffs/${record.id}/edit`}>Edit</Link>
      </Space>
    ),
  },
];
