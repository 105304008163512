// Employee Program

export const employeeProgramStatusPending = 1001;
export const employeeProgramStatusInProgress = 1002;
export const employeeProgramStatusVesting = 1003;
export const employeeProgramStatusCompleted = 1004;
export const employeeProgramStatusCanceling = 1005;
export const employeeProgramStatusCanceled = 1006;

export const employeeProgramStatus = {
  1001: 'Chờ tham gia',
  1002: 'Đang tham gia',
  1003: 'Chờ chuyển giao',
  1004: 'Đã chuyển giao',
  1005: 'Chờ hủy',
  1006: 'Đã hủy',
};

export const employeeProgramStatusColors = {
  1001: 'yellow',
  1002: 'green',
  1003: 'purple',
  1004: 'cyan',
  1005: 'volcano',
  1006: '',
};

export const employeeProgramStatusOptions = [
  { value: 1001, label: 'Chờ tham gia' },
  { value: 1002, label: 'Đang tham gia' },
  { value: 1003, label: 'Chờ chuyển giao' },
  { value: 1004, label: 'Đã chuyển giao' },
  { value: 1005, label: 'Chờ hủy' },
  { value: 1006, label: 'Đã hủy' },
];

export const employeeProgramRequiredStartDateEndDateValid = 1002
export const employeeProgramRequiredEmployeeUser = 1003
export const employeeProgramRequiredCompanyUser = 1004
export const employeeProgramRequiredEmployeeUserVerified = 1005
export const employeeProgramRequiredCompanyUserVerified = 1006
export const employeeProgramRequiredInvestmentPlan = 1007
export const employeeProgramRequiredEmployeeFundAccountReady = 1008
export const employeeProgramRequiredCompanyFundAccountReady = 1009
export const employeeProgramRequiredCompleteInformation = 1010
