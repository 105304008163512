import {
  useFetchApiGet,
  useFetchApiList,
  usePostApi,
} from '@stagapps/redux-utils';
import { Form } from 'antd';
import deleteApiCall from 'apps/education/apiCalls/challenge/delete';
import getApiCall from 'apps/education/apiCalls/challenge/detail';
import editApiCall from 'apps/education/apiCalls/challenge/edit';
import challengeGroupListApiCall from 'apps/education/apiCalls/challengeGroup/list';
import PageTitle from 'apps/home/components/PageTitle';
import ErrorMessage from 'common/ui/ErrorMessage';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { convertFormData } from 'utils/formData.js';
import ItemForm from './Form';

const ChallengeEdit = () => {
  const [form] = Form.useForm();
  const params = useParams();

  const {
    data: item,
    load: fetchItem,
    isLoading,
    error: fetchItemError,
  } = useFetchApiGet(getApiCall, { resourceName: 'item' });

  const {
    data: challengeGroups,
    load: fetchChallengeGroups,
    isChallengeGroupLoading,
    paging,
  } = useFetchApiList(challengeGroupListApiCall, { resourceName: 'items' });

  const { post: submitItem, isSubmitting, error: submitItemError } = usePostApi(
    editApiCall,
    {
      resourceName: 'item',
    }
  );

  const {
    post: deleteItem,
    isSubmitting: isDeleting,
    error: deleteError,
  } = usePostApi(deleteApiCall, {
    resourceName: 'item',
  });

  useEffect(() => {
    fetchChallengeGroups();
    fetchItem({ id: params.id });
  }, []);

  const onFinish = (values, isRemoved, ref) => {
    console.debug('Received values of form: ', values);

    const postData = async () => {
      try {
        let submitData = values;
        submitData.points = 0;

        if (
          values.photo &&
          values.photo.at(-1) &&
          values.photo.at(-1).originFileObj instanceof File
        ) {
          submitData.photo = values.photo[0].originFileObj;
          submitData.is_active
            ? (submitData.is_active = '1')
            : (submitData.is_active = '0');

          submitData = convertFormData(submitData);
        } else {
          delete submitData['photo'];
          if (isRemoved) {
            submitData.photo = null;
          }
        }

        submitItem({ id: params.id, payload: submitData, ref });
      } catch (error) {
        console.error(error.message);
      }
    };

    postData();
  };

  if (isLoading || isChallengeGroupLoading) {
    return <LoadingSpinner />;
  }

  if (fetchItemError) {
    return <ErrorMessage {...fetchItemError} />;
  }

  return (
    <>
      <PageTitle title={`Edit Challenge`} />
      <ItemForm
        submiting={isSubmitting}
        form={form}
        onFinish={onFinish}
        initialValues={item}
        name="challenge_edit_form"
        visibility="visible"
        challengeGroups={challengeGroups}
        onDelete={() => {
          if (confirm('Are you sure?')) {
            deleteItem({ id: params.id });
          }
        }}
      />
    </>
  );
};

export default ChallengeEdit;
