import { useFetchApiGet, usePostApi } from '@stagapps/redux-utils';
import { Form } from 'antd';
import PageTitle from 'apps/home/components/PageTitle';
import ErrorMessage from 'common/ui/ErrorMessage';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { convertFormData, normalizeData } from 'utils/formData.js';

const AdminEdit = ({
  ItemForm,
  listUrl,
  apiCall,
  resourceName,
  options = {},
}) => {
  const [form] = Form.useForm();
  const params = useParams();
  const { title } = options;
  const {
    data: item,
    load: fetchItem,
    isLoading,
    error: fetchItemError,
  } = useFetchApiGet(apiCall.detail, { resourceName: resourceName || 'item' });

  const {
    post: submitItem,
    isLoading: isSubmitting,
    error: submitItemError,
  } = usePostApi(apiCall.edit);

  const {
    post: deleteItem,
    isLoading: isDeleting,
    error: deleteItemError,
  } = usePostApi(apiCall.delete);

  useEffect(() => {
    fetchItem(params);
  }, []);

  const onFinish = (values, photoFields = ['photo']) => {
    console.debug('Received values of form: ', values);

    const postData = async () => {
      try {
        let submitData = normalizeData(values);
        let isConvertFormData = false;

        for (let i = 0; i < photoFields.length; i++) {
          const field = photoFields[i];
          const fieldValue = values[field];
          if (
            fieldValue &&
            fieldValue.at(-1) &&
            fieldValue.at(-1).originFileObj instanceof File
          ) {
            submitData[field] = values[field][0].originFileObj;
            isConvertFormData = true;
          } else {
            delete submitData[field];
          }
        }

        if (isConvertFormData) {
          submitData = convertFormData(submitData);
        }
        submitItem({ ...params, payload: submitData });
      } catch (error) {
        console.error(error.message);
      }
    };

    postData();
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (fetchItemError) {
    return <ErrorMessage {...fetchItemError} />;
  }

  return (
    <>
      <PageTitle title={`${title || 'Edit Item'} `} backUrl={listUrl} />
      <ItemForm
        params={params}
        submiting={isSubmitting}
        submitItemError={submitItemError}
        deleting={isDeleting}
        deleteItemError={deleteItemError}
        form={form}
        onFinish={onFinish}
        initialValues={item}
        name="edit_form"
        visibility="visible"
        onDelete={() => {
          if (confirm('Are you sure?')) {
            deleteItem(params);
          }
        }}
      />
    </>
  );
};

export default AdminEdit;
