import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

const FETCH_STOCK_FA_DETAIL_API_ID = 'DASHBOARD/FETCH_STOCK_FA_DETAIL_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_STOCK_FA_DETAIL_API_ID,
  ({ id }) => ({
    endpoint: `${API_BASE_URL}/marketdb-api/admin/v1/stock-fas/${id}`,
    method: 'GET',
  })
);

export default apiCall;
