import { usePostApi } from '@stagapps/redux-utils';
import { Button, Descriptions, Tag } from 'antd';
import { Link } from 'react-router-dom';

import apiCall from 'apps/investment/apiCalls/employeeProgramOrder';
import {
  orderTypeColors,
  orderTypeLabels,
} from 'apps/investment/constants/fundOrder';
import {
  orderSideColors,
  orderSideLabels,
  programOrderMatchSourceCompanyPortfolio,
  programOrderStatusColors,
  programOrderStatusComplete,
  programOrderTypeRedistribute,
} from 'apps/investment/constants/programOrder';
import { formatNumber } from 'utils/formatAmount';
import { formatDateTime } from 'utils/formatDate';
import { FundOrderAction } from './PostActions';

const ItemDetail = ({ item }) => {
  const { employee, employee_program, company, order, ...data } = item || {};

  const {
    id,
    code,
    company_id,
    employee_id,
    status,
    status_label,
    order_type,
    product_code,
    order_side,
    estimate_quantity,
    quantity,
    matched_source,
    company_investment_detail_id,
    employee_investment_detail_id,
  } = data;

  const {
    post: cancelOrder,
    isLoading: isCancelling,
    error: cancelOrderError,
  } = usePostApi(apiCall.cancel);

  const { name: companyName } = company || {};
  const { full_name: employeeName } = employee || {};
  const { product_id } = order || {};

  let orderType = order_type;
  if (
    order_type == 'buy' &&
    matched_source == programOrderMatchSourceCompanyPortfolio
  ) {
    orderType = programOrderTypeRedistribute;
  }

  return (
    <>
      <Descriptions
        title="Order detail"
        bordered
        column={1}
        extra={
          <>
            <Button
              disabled={isCancelling}
              onClick={() => {
                let warningMessage = `Are you sure you want to cancel the program order ${item.code}? Enter order code to confirm: ${item.code}`;
                let confirmCode = prompt(warningMessage);

                if (confirmCode == item.code) {
                  cancelOrder({ id: item.id });
                  return;
                } else {
                  alert('program order code was not match');
                }
              }}
              type="primary"
              style={{
                margin: 10,
                backgroundColor: 'red',
                borderColor: 'red',
              }}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Descriptions.Item label={'ID'}>{id}</Descriptions.Item>
        <Descriptions.Item label={'Status'}>
          <Tag color={programOrderStatusColors[status]}>{status_label}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label={'Order Type'}>
          <Tag color={orderTypeColors[orderType]}>
            {orderTypeLabels[orderType]}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label={'Order Side'}>
          <Tag color={orderSideColors[order_side]}>
            {orderSideLabels[order_side]}
          </Tag>
        </Descriptions.Item>

        <Descriptions.Item label={'Amount'}>
          {formatNumber(item.amount)}
        </Descriptions.Item>

        <Descriptions.Item label={'Quantity'}>
          {item.quantity ? item.quantity : '-'}
        </Descriptions.Item>

        <Descriptions.Item label={'Matched Price'}>
          {item.matched_price ? item.matched_price : '-'}
        </Descriptions.Item>

        {status != programOrderStatusComplete &&
        orderType == programOrderTypeRedistribute ? (
          <Descriptions.Item label={'Estimated Redistribution Quantity'}>
            estimate_quantity
          </Descriptions.Item>
        ) : null}

        <Descriptions.Item label={'Fund Order'}>
          {order ? (
            <Link to={`/investment/orders/${order.id}`}>
              View Fund Order {order.id}
            </Link>
          ) : (
            'Not created yet'
          )}
        </Descriptions.Item>

        <Descriptions.Item label={'Product Code'}>
          <Link to={`/investment/products/${item.product.id}`}>
            {item.product.code}
          </Link>
        </Descriptions.Item>

        <Descriptions.Item label={'Company investment detail'}>
          {company_investment_detail_id}
        </Descriptions.Item>
        <Descriptions.Item label={'Employee investment detail'}>
          {employee_investment_detail_id}
        </Descriptions.Item>
        <Descriptions.Item label={'Matched source'}>
          {matched_source}
        </Descriptions.Item>

        <Descriptions.Item label={'Company'}>
          <Link to={`/investment/companies/${company_id}`}>{companyName}</Link>
        </Descriptions.Item>
        <Descriptions.Item label={'Employee'}>
          <Link to={`/investment/employees/${employee_id}`}>
            {employeeName}
          </Link>
        </Descriptions.Item>
        <Descriptions.Item label={'Employee Program'}>
          <Link to={`/investment/employee-programs/${employee_program.id}`}>
            View program
          </Link>
        </Descriptions.Item>
        <Descriptions.Item label="Created At">
          {formatDateTime(item.created_at)}
        </Descriptions.Item>
        <Descriptions.Item label="Updated At">
          {formatDateTime(item.updated_at)}
        </Descriptions.Item>
        <Descriptions.Item label={'Action'}>
          <FundOrderAction order={order} />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default ItemDetail;
