export const appsConfig = {
  hub: {
    key: 'hub',
    namespace: 'hub',
    label: 'Education Hub',
    color: 'blue',
    description: 'Manage education content for Stag Invest app',
  },
  investment: {
    key: 'investment',
    namespace: 'investment',
    label: 'Investment',
    color: '#ffbf00',
    description:
      'Core investment system: manage investors, orders on Stag Invest app',
  },
  investcore: {
    key: 'investcore',
    namespace: 'investcore',
    label: 'Investcore',
    color: 'red',
    description: 'Stag platform system ops, for admins only',
  },
  market: {
    key: 'market',
    namespace: 'marketdb',
    label: 'Market Data',
    color: '#f56a00',
    description: 'All market data, analytics, news...',
  },
  edu: {
    key: 'edu',
    namespace: 'edu',
    label: 'Stag Learn',
    color: '#7265e6',
    description: 'Manage users, content, and data for Stock Edu app',
  },
};
