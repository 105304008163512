import MetaDecorator from 'apps/home/components/MetaDecorator';

const PermissionError = () => (
  <>
    <MetaDecorator title={'Admin CP | Permission denied'} />
    <p style={{ fontSize: '24px', textAlign: 'center', color: 'red' }}>
      You do not have permission to access
    </p>
  </>
);

export default PermissionError;
