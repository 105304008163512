import { Button, Tag } from 'antd';
import apiCall from 'apps/market/apiCalls/etf';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { Link } from 'react-router-dom';
import { statusColors, statusLabel } from 'utils/constants';
import { formatISODate } from 'utils/formatDate';
import { cloudflareCdn } from 'utils/image';
import ItemForm from './Form';

export const listingLabels = {
  1001: 'Initialized',
  1002: 'Listed',
  1003: 'Delisted',
};

export const listingOptions = [
  { value: 1001, label: 'Initialized' },
  { value: 1002, label: 'Listed' },
  { value: 1003, label: 'Delisted' },
];

const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Company',
    dataIndex: 'name',
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
  },
  {
    title: 'Exchange',
    dataIndex: 'exchange',
  },
  {
    title: 'Outstanding Shares',
    dataIndex: 'outstanding_shares',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Photo',
    dataIndex: 'photo',
    render: value => value && <img width={60} src={cloudflareCdn(value, 60)} />,
  },
  {
    title: 'Created',
    dataIndex: 'created',
    render: value => formatISODate(value),
  },
  {
    title: 'Modified',
    dataIndex: 'modified',
    render: value => formatISODate(value),
  },
  {
    title: 'Listing Status',
    dataIndex: 'exchange_status',
    render: value => {
      return <Tag color={statusColors[value]}>{listingLabels[value]}</Tag>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => {
      return <Tag color={statusColors[value]}>{statusLabel[value]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/market/etfs/${record.id}/edit`}>Edit</Link>
        </Button>
      </Button.Group>
    ),
  },
];

export const ETFListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    title="All ETFs"
    apiCall={apiCall}
    params={params}
    createUrl={`/market/etfs/create`}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="items"
    {...props}
  />
);

export const ETFEditPage = props => (
  <EditPage
    title="ETF"
    apiCall={apiCall}
    listUrl={`/market/etfs`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);

export const ETFCreatePage = props => (
  <CreatePage
    title="ETF"
    apiCall={apiCall}
    listUrl={`/market/etfs`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);
