import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

export const FETCH_NEWS_DISRECOMMEND_EDIT_API_ID =
  'DASHBOARD/FETCH_NEWS_DISRECOMMEND_EDIT_API_ID';

const apiCall = enhancedMakeFetchAction(
  FETCH_NEWS_DISRECOMMEND_EDIT_API_ID,
  ({ id }) => ({
    endpoint: `${API_BASE_URL}/marketdb-api/xpider-admin/v1/posts/${id}`,
    method: 'PATCH',
    body: { is_recommend: 0 },
  })
);

export default apiCall;
