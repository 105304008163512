import draftDetailApiCall from './detail';
import identDetailApiCall from './detailIdent';
import detailCompareApiCall from './detailCompare';

const ApiCall = {
  list: null,
  detail: draftDetailApiCall,
  detailIdent: identDetailApiCall,
  compareReKYC: detailCompareApiCall,
  approveReKYC: null,
};

export default ApiCall;
