import { Descriptions, Tag } from 'antd';
import VerificationMark from 'common/ui/VerificationMark';
import { activeColors, activeLabels } from 'utils/constants';

import { formatDateTime } from 'utils/formatDate';

const { Item: DescItem } = Descriptions;

const ItemDetail = ({ item }) => {
  const {
    name,
    user,
    email,
    company,
    email_verified,
    phone,
    phone_verified,
    permission_role,
    status,
    updated_at,
    created_at,
  } = item || {};

  return (
    <Descriptions column={1} bordered>
      <DescItem label="Tên">{name}</DescItem>
      <DescItem label="Email">
        <VerificationMark value={email} isVerified={email_verified} />
      </DescItem>

      <DescItem label="Số điện thoại">{phone}</DescItem>

      <DescItem label="Phân quyền">{permission_role}</DescItem>
      <DescItem label="Trạng thái">
        <Tag color={activeColors[status]}>{activeLabels[status]}</Tag>
      </DescItem>

      <DescItem label="Thông tin tài khoản doanh nghiệp">
        {user ? (
          <VerificationMark
            value={`${user.name} (${user.email})`}
            isVerified={user.ekyc_verified}
          />
        ) : null}
      </DescItem>

      <DescItem label="Thông tin công ty">
        {company
          ? `${company.name} (${company.brand_name}) - MST ${company.tax_number}`
          : ''}
      </DescItem>

      <DescItem label="Thời gian tạo">{formatDateTime(created_at)}</DescItem>

      <DescItem label="Thời gian cập nhật">
        {formatDateTime(updated_at)}
      </DescItem>
    </Descriptions>
  );
};

export default ItemDetail;
