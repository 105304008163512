import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';
import { generateEndpoint } from 'utils/urlHelpers';

const apiCall = type => {
  const FETCH_INVESTCORE_DATA_LIST_API_ID = `DASHBOARD/FETCH_INVESTCORE_DISTRICTS_${type}`;

  return enhancedMakeFetchAction(FETCH_INVESTCORE_DATA_LIST_API_ID, params => ({
    endpoint: generateEndpoint({
      host: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/districts`,
      params,
    }),
    method: 'GET',
  }));
};

export default apiCall;
