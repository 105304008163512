import { Button } from 'antd';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useFetchApiList } from '@stagapps/redux-utils';
import MetaDecorator from 'apps/home/components/MetaDecorator';
import PageTitle from 'apps/home/components/PageTitle';
import listApiCall from 'apps/market/apiCalls/crypto/list';
import DataTable from 'common/ui/DataTable';
import { columnsGenerator } from './config';

const Cryptos = () => {
  const title = 'All Cryptos';
  const {
    data: items,
    load: fetchItems,
    isLoading,
    paging,
  } = useFetchApiList(listApiCall, { resourceName: 'items' });

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <>
      <MetaDecorator title={title} />
      <PageTitle
        title={title}
        extra={[
          <Button key="1" type="primary">
            <Link to={`/market/cryptos/create`}>Create</Link>
          </Button>,
        ]}
      />

      <DataTable
        rowKey="id"
        columns={columnsGenerator()}
        dataSource={items}
        totalItems={paging.count}
        currentPage={paging.page}
        pageSize={paging.page_size}
        dataFetcher={fetchItems}
        loading={isLoading}
      />
    </>
  );
};

export default Cryptos;
