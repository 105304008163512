import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

const FETCH_COLLECTION_LIST_METRIC_API_ID =
  'DASHBOARD/FETCH_COLLECTION_LIST_METRIC_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_COLLECTION_LIST_METRIC_API_ID,
  ({ product_type }) => ({
    endpoint: `${API_BASE_URL}/marketdb-api/admin/v1/collections/${product_type}/metrics`,
    method: 'GET',
  })
);

export default apiCall;
