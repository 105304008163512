import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useFetchApiGet } from '@stagapps/redux-utils';
import { Image, Space, Table, Tag, Tooltip } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import MetaDecorator from 'apps/home/components/MetaDecorator';
import PageTitle from 'apps/home/components/PageTitle';
import {
  orderFundManagerColors,
  orderFundManagerLabels,
} from 'apps/investment/constants/fundOrder';
import ErrorMessage from 'common/ui/ErrorMessage';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { formatNumber } from 'utils/formatAmount';

const columns = [
  {
    title: 'Fund Manager',
    dataIndex: 'fund_manager',
    render: value =>
      value ? (
        <Tag color={orderFundManagerColors[value]}>
          {orderFundManagerLabels[value]}
        </Tag>
      ) : null,
  },
  {
    title: 'Product Code',
    dataIndex: 'product_code',
    render: (value, record) => (
      <Space>
        <Image width={51} src={record?.logo} />
        <span>{value}</span>
      </Space>
    ),
  },
  {
    title: 'Free-to-sell Quantity',
    dataIndex: 'available_quantity',
    render: value => formatNumber(value),
    align: 'right',
  },
  {
    title: 'Locked Quantity',
    dataIndex: 'lock_quantity',
    render: value => (value && value >= 0 ? formatNumber(value) : '0'),
    align: 'right',
  },
  {
    title: 'Free-to-sell Quantity (Pull from Fund Manager)',
    dataIndex: 'fund_available_quantity',
    render: value => formatNumber(value),
    align: 'right',
  },
  {
    title: 'Locked Quanity (Pull from Fund Manager)',
    dataIndex: 'fund_lock_quantity',
    render: value => formatNumber(value),
    align: 'right',
  },
  {
    title: 'Validation',
    render: (value, record) => {
      const lockQuantity = record.lock_quantity ?? 0;
      const fundLockQuantity = record.fund_lock_quantity ?? 0;
      const availableQuantity = record.available_quantity ?? 0;
      const fundAvailableQuantity = record.fund_available_quantity ?? 0;
      const fundManager = record.fund_manager ?? '';

      if (fundManager == 'vcamb2b') {
        if (
          lockQuantity + availableQuantity !=
          fundLockQuantity + fundAvailableQuantity
        ) {
          return (
            <Tooltip
              title={
                'There is a mismatch with quantity sync from fund manager (B2B)'
              }
              color="#0A1A41"
            >
              <CloseCircleOutlined style={{ color: 'red', fontSize: '20px' }} />
            </Tooltip>
          );
        } else {
          return (
            <CheckCircleOutlined
              title={'Match with quantity sync from fund manager'}
              style={{ color: 'green', fontSize: '20px' }}
            />
          );
        }
      }
      return lockQuantity == fundLockQuantity &&
        availableQuantity == fundAvailableQuantity ? (
        <CheckCircleOutlined
          title={'Match with quantity sync from fund manager'}
          style={{ color: 'green', fontSize: '20px' }}
        />
      ) : (
        <Tooltip
          title={'There is a mismatch with quantity sync from fund manager'}
          color="#0A1A41"
        >
          <CloseCircleOutlined style={{ color: 'red', fontSize: '20px' }} />
        </Tooltip>
      );
    },
  },
];

function PortfolioFunds({ apiCall }) {
  const title = 'Stag Account Portfolio Items vs Fund Data';

  const params = useParams();

  const {
    data: portfolioFunds,
    load: loadPortfolioFunds,
    isLoading: isLoadingPortfolioFunds,
    error: errorPortfolioFunds,
  } = useFetchApiGet(apiCall.portfolioFunds, { resourceName: 'data' });

  const {
    data: portfolioFlex,
    load: loadPortfolioFlex,
    isLoading: isLoadingPortfolioFlex,
    error: errorPortfolioFlex,
  } = useFetchApiGet(apiCall.portfolioFlex, { resourceName: 'data' });

  useEffect(() => {
    loadPortfolioFunds(params);
    loadPortfolioFlex(params);
  }, []);

  if (isLoadingPortfolioFunds || isLoadingPortfolioFlex) {
    return <LoadingSpinner />;
  }
  if (errorPortfolioFunds || errorPortfolioFlex) {
    return <ErrorMessage errors={errorPortfolioFunds} />;
  }

  let availableQuantityByProductCode = {};
  portfolioFlex?.items?.forEach(item => {
    if (item.product_code == '') return;

    availableQuantityByProductCode[item.product_code] =
      (availableQuantityByProductCode[item.product_code] || 0) +
      item.available_quantity;
  });

  let lockQuantityByProductCode = {};
  portfolioFlex?.items?.forEach(item => {
    if (item.product_code == '') return;

    lockQuantityByProductCode[item.product_code] =
      (lockQuantityByProductCode[item.product_code] || 0) + item.lock_quantity;
  });

  let dataSource = [];

  const filteredPortfolioFunds = portfolioFunds.filter(
    item => item.product_code != ''
  );

  filteredPortfolioFunds?.forEach(item => {
    let availableQty = null;
    let lockQty = null;

    if (item.fund_manager == 'vcamb2b') {
      lockQty = lockQuantityByProductCode[item.product_code] ?? 0;
      availableQty = availableQuantityByProductCode[item.product_code] ?? 0;
    } else {
      availableQty = item.available_quantity;
      lockQty = item.lock_quantity;
    }

    dataSource.push({
      ...item,
      available_quantity: availableQty,
      lock_quantity: lockQty,
    });
  });
  return (
    <>
      <MetaDecorator title={title} />
      <PageTitle title={title} backUrl={`/investment/users`} />
      <Table dataSource={dataSource} columns={columns} pagination={false} />
    </>
  );
}

export default PortfolioFunds;
