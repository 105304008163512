import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

export const FETCH_POLL_DELETE_API_ID = 'HUB/FETCH_POLL_DELETE_API';

const apiCall = enhancedMakeFetchAction(FETCH_POLL_DELETE_API_ID, ({ id }) => ({
  endpoint: `${API_BASE_URL}/investcore-api/admin/edu/lesson-polls/${id}`,
  method: 'DELETE',
  notify: true,
}));

export default apiCall;
