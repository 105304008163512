import detailApiCall from './detail';
import editApiCall from './edit';
import listApiCall from './list';
import createApiCall from './create';
import deleteApiCall from './delete';
import activeApiCall from './active';
import pauseApiCall from './pause';
import portfolioApiCall from './portfolio';
import portfolioAnalyticsApiCall from './portfolioAnalytics';

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  create: createApiCall,
  edit: editApiCall,
  delete: deleteApiCall,
  active: activeApiCall,
  pause: pauseApiCall,
  portfolio: portfolioApiCall,
  analytics: portfolioAnalyticsApiCall,
};

export default ApiCall;
