import { Card, Descriptions, Typography } from 'antd';
import { formatDate, formatDateTime } from 'utils/formatDate';
import { cloudflareCdn } from 'utils/image';
const { Title } = Typography;

const ItemDetail = ({ item }) => {
  return (
    <>
      <Card title={<Title level={3}>ETF Product Detail</Title>}>
        <Descriptions column={1} bordered>
          <Descriptions.Item label="ID">{item.id}</Descriptions.Item>
          <Descriptions.Item label="Symbol">{item.symbol}</Descriptions.Item>
          <Descriptions.Item label="Logo">
            {item.logo && (
              <img width={100} src={cloudflareCdn(item.logo, 100)} />
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Name">{item.name}</Descriptions.Item>
          <Descriptions.Item label="Fund Manager Name">
            {item.fund_manager_name}
          </Descriptions.Item>
          <Descriptions.Item label="Description">
            {item.description}
          </Descriptions.Item>
          <Descriptions.Item label="Strategy">
            {item.strategy}
          </Descriptions.Item>
          <Descriptions.Item label="Inception Date">
            {formatDate(item.inception_date)}
          </Descriptions.Item>
          <Descriptions.Item label="Policy Url">
            <a href={item.policy_url} target="_blank">
              {item.policy_url}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="Prospectus Url">
            <a href={item.prospectus_url} target="_blank">
              {item.prospectus_url}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="Registration Certificate of Establishment">
            {item.registration_certificate}
          </Descriptions.Item>
          <Descriptions.Item label="Subscription Fee">
            {item.subscription_fee}
          </Descriptions.Item>
          <Descriptions.Item label="Trading Fee">
            {item.trading_fee}
          </Descriptions.Item>
          <Descriptions.Item label="Management Fee">
            {item.management_fee}
          </Descriptions.Item>
          <Descriptions.Item label="Audit Company">
            {item.audit_company}
          </Descriptions.Item>
          <Descriptions.Item label="Created At">
            {formatDateTime(item.created_at)}
          </Descriptions.Item>
          <Descriptions.Item label="Updated At">
            {formatDateTime(item.updated_at)}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
};

export default ItemDetail;
