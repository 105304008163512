import { useFetchApiList } from '@stagapps/redux-utils';
import { Button } from 'antd';
import MetaDecorator from 'apps/home/components/MetaDecorator';
import PageTitle from 'apps/home/components/PageTitle';
import criteriaListApiCall from 'apps/market/apiCalls/criteria/list';
import DataTable from 'common/ui/DataTable';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { formatISODate } from 'utils/formatDate';

const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Label',
    dataIndex: 'label',
  },
  {
    title: 'Type',
    dataIndex: 'type',
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    render: value => formatISODate(value),
  },
  {
    title: 'Modified',
    dataIndex: 'modified_at',
    render: value => formatISODate(value),
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/market/metrics/${record.id}/edit`}>Edit</Link>
        </Button>
      </Button.Group>
    ),
  },
];

const Metric = () => {
  const title = 'All Metrics';

  const {
    data: items,
    load: fetchItems,
    isLoading,
    paging,
  } = useFetchApiList(criteriaListApiCall, { resourceName: 'items' });

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <>
      <MetaDecorator title={title} />
      <PageTitle
        title={title}
        extra={[
          <Button key="1" type="primary">
            <Link to={`/market/metrics/create`}>Create</Link>
          </Button>,
        ]}
      />
      <DataTable
        rowKey="id"
        columns={columnsGenerator()}
        dataSource={items}
        totalItems={paging.count}
        currentPage={paging.page}
        pageSize={paging.page_size}
        dataFetcher={fetchItems}
        loading={isLoading}
      />
    </>
  );
};

export default Metric;
