import { Button, Tag } from 'antd';
import { Link, useParams } from 'react-router-dom';

import {
  accessColors,
  accessLabel,
  statusColors,
  statusLabel,
} from 'utils/constants';
import { formatISODate } from 'utils/formatDate';
import { cloudflareCdn } from 'utils/image';

export const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
  },
  
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Photo',
    dataIndex: 'photo',
    render: value =>
      value && <img width={100} src={cloudflareCdn(value, 100)} />,
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    render: value => formatISODate(value),
  },
  {
    title: 'Modified',
    dataIndex: 'modified_at',
    render: value => formatISODate(value),
  },
  {
    title: 'Ordering',
    dataIndex: 'ranking',
  },
  {
    title: 'Access Level',
    dataIndex: 'access',
    render: value => {
      return <Tag color={accessColors[value]}>{accessLabel[value]}</Tag>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => {
      return <Tag color={statusColors[value]}>{statusLabel[value]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => {
      const params = useParams();

      return (
        <Button.Group>
          <Button shape="round">
            <Link
              to={`/edu/courses/${params.course_id}/chapters/${record.id}/edit`}
            >
              Edit
            </Link>
          </Button>
          <Button shape="round">
            <Link
              to={`/edu/courses/${params.course_id}/chapters/${record.id}/lessons`}
            >
              All Lessons
            </Link>
          </Button>
        </Button.Group>
      );
    },
  },
];
