import { Card, Descriptions, Typography } from 'antd';
const { Title } = Typography;

const ItemDetail = ({ item }) => {
  return (
    <>
      <Card title={<Title level={3}>Bank Detail</Title>}>
        <Descriptions column={1} bordered>
          <Descriptions.Item label="ID">{item.id}</Descriptions.Item>
          <Descriptions.Item label="VCAM Code">{item.bank_code}</Descriptions.Item>
          <Descriptions.Item label="Name">{item.name}</Descriptions.Item>
          <Descriptions.Item label="Name (English)">
            {item.en_name}
          </Descriptions.Item>
          <Descriptions.Item label="Brand Name">
            {item.trading_name}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
};

export default ItemDetail;
