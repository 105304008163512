import { Button, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { statusColors, statusLabel } from 'utils/constants';
import { cloudflareCdn } from 'utils/image';

export const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Photo',
    dataIndex: 'photo',
    render: value =>
      value && <img width={100} src={cloudflareCdn(value, 100)} />,
  },
  {
    title: 'Ads Format',
    dataIndex: 'ads_format',
  },
  {
    title: 'Ads Slot',
    dataIndex: 'ads_slot',
  },
  {
    title: 'External Link',
    dataIndex: 'external_link',
    render: value => <Link to={value}>{value}</Link>,
  },

  {
    title: 'Ordering',
    dataIndex: 'ranking',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => {
      return <Tag color={statusColors[value]}>{statusLabel[value]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/edu/advertisement/${record.id}/edit`}>Edit</Link>
        </Button>
      </Button.Group>
    ),
  },
];
