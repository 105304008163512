import { Descriptions, Tag, Typography } from 'antd';
import {
  productStatusColors,
  productStatusLabels,
} from 'apps/investment/constants/fundProduct';
import { formatDate, formatDateTime } from 'utils/formatDate';
import { cloudflareCdn } from 'utils/image';
const { Title } = Typography;

const ItemDetail = ({ item }) => {
  return (
    <>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="UUID">{item.id}</Descriptions.Item>
        <Descriptions.Item label="Code">{item.code}</Descriptions.Item>
        <Descriptions.Item label="Logo">
          {item.logo && <img width={100} src={cloudflareCdn(item.logo, 100)} />}
        </Descriptions.Item>
        <Descriptions.Item label="Alias">{item.alias}</Descriptions.Item>
        <Descriptions.Item label="Name">{item.name}</Descriptions.Item>
        <Descriptions.Item label="Fund Type">
          {item.fund_type_name} ({item.fund_type})
        </Descriptions.Item>
        <Descriptions.Item label="Fund Manager">
          {item.fund_manager}
        </Descriptions.Item>
        <Descriptions.Item label="Fund Manager Name">
          {item.fund_manager_name}
        </Descriptions.Item>
        <Descriptions.Item label="Custodian Bank">
          {item.custodian_bank && item.custodian_bank.name
            ? `${item.custodian_bank.name} - ${item.custodian_bank.code}`
            : '-'}
        </Descriptions.Item>
        <Descriptions.Item label="Supervisory Bank">
          {item.supervisory_bank && item.supervisory_bank.name
            ? `${item.supervisory_bank.name} - ${item.supervisory_bank.code}`
            : '-'}
        </Descriptions.Item>
        <Descriptions.Item label="Description">
          {item.description}
        </Descriptions.Item>
        <Descriptions.Item label="Strategy">{item.strategy}</Descriptions.Item>
        <Descriptions.Item label="Inception Date">
          {formatDate(item.inception_date)}
        </Descriptions.Item>
        <Descriptions.Item label="Order closing time">
          {item.order_closing_time}
        </Descriptions.Item>
        <Descriptions.Item label="Order time">
          {item.order_time}
        </Descriptions.Item>
        <Descriptions.Item label="Policy Url">
          <a href={item.policy_url} target="_blank">
            {item.policy_url}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label="Prospectus Url">
          <a href={item.prospectus_url} target="_blank">
            {item.prospectus_url}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label="Registration Certificate of Establishment">
          {item.registration_certificate}
        </Descriptions.Item>
        <Descriptions.Item label="Subscription Fee">
          {item.subscription_fee}
        </Descriptions.Item>
        <Descriptions.Item label="Trading Fee">
          {item.trading_fee}
        </Descriptions.Item>
        <Descriptions.Item label="Management Fee">
          {item.management_fee}
        </Descriptions.Item>
        <Descriptions.Item label="Redemption Fee Config">
          <div className="bg-sky-800 text-yellow-300 p-4">
            {item.redemption_fee_text}
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="Audit Company">
          {item.audit_company}
        </Descriptions.Item>
        <Descriptions.Item label="Buy Minimum Amount">
          {new Intl.NumberFormat('en-US').format(item.buy_minimum_amount)}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <Tag color={productStatusColors[item.status]}>
            {productStatusLabels[item.status]}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Created At">
          {formatDateTime(item.created_at)}
        </Descriptions.Item>
        <Descriptions.Item label="Updated At">
          {formatDateTime(item.updated_at)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default ItemDetail;
