import { Layout } from 'antd';

import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

const LayoutGuest = () => (
  <Layout>
    <Header />
    <Layout.Content style={{ margin: '24px 16px' }}>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <Outlet />
      </div>
    </Layout.Content>
    <Footer />
  </Layout>
);

export default LayoutGuest;
