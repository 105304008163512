import apiCall from 'apps/investment/apiCalls/user';
import { stockBalanceHistoriesColumnsGenerator } from 'apps/investment/components/StockBalanceHistories/config';
import { ListPage } from 'common/ui/AdminCRUD/page';
import { Tag } from 'antd';

export const InvestmentStockBalanceHistoriesListPage = (props, { params }) => {
  return (
    <ListPage
      title="All Transactions"
      apiCall={{
        list: apiCall.stockBalanceHistories
      }}
      enableSearch={true}
      params={params}
      columnGenerator={props => stockBalanceHistoriesColumnsGenerator(props)}
      resourceName="data"
      size="small"
      {...props}
    />
  );
};
