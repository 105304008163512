import apiCall from 'apps/investment/apiCalls/order';
import { DetailPage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import FilterForm from './FilterForm';
import Form from './Form';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const InvestmentOrderListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    title="All Fund Orders"
    apiCall={apiCall}
    params={params}
    FilterForm={FilterForm}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="data"
    size="small"
    {...props}
  />
);

export const InvestmentOrderEditPage = props => (
  <EditPage
    title="Edit Fund Order"
    apiCall={apiCall}
    listUrl={`/investment/orders`}
    ItemForm={Form}
    resourceName="data"
    {...props}
  />
);

export const InvestmentOrderDetailPage = props => (
  <DetailPage
    title="Detail Fund Order"
    apiCall={apiCall}
    listUrl={`/investment/orders`}
    ItemDetail={ItemDetail}
    resourceName="data"
    {...props}
  />
);
