import {
  InvestmentBankDetailPage,
  InvestmentBankEditPage,
  InvestmentBankListPage,
} from 'apps/investcore/components/Bank/pages';
import {
  InvestmentProductDetailPage,
  InvestmentProductEditPage,
  InvestmentProductListPage,
} from 'apps/investcore/components/FundProduct/config';
import {
  InvestmentNotificationLogDetailPage,
  InvestmentNotificationLogListPage,
} from 'apps/investment/components/NotificationLog/pages';
import { Navigate } from 'react-router-dom';
import {
  InvestmentStockDetailPage,
  InvestmentStockEditPage,
  InvestmentStockListPage,
} from '../components/ETFProduct/config';

const investcoreRoutes = [
  { path: '/investcore/banks', element: <InvestmentBankListPage /> },
  { path: '/investcore/banks/:id', element: <InvestmentBankDetailPage /> },
  { path: '/investcore/banks/:id/edit', element: <InvestmentBankEditPage /> },
  {
    path: '/investcore/products',
    element: <Navigate to="/investcore/products/funds" />,
  },
  {
    path: '/investcore/products/funds',
    element: <InvestmentProductListPage />,
  },
  {
    path: '/investcore/products/funds/:id',
    element: <InvestmentProductDetailPage />,
  },
  {
    path: '/investcore/products/funds/:id/edit',
    element: <InvestmentProductEditPage />,
  },
  { path: '/investcore/products/etfs', element: <InvestmentStockListPage /> },
  {
    path: '/investcore/products/etfs/:id',
    element: <InvestmentStockDetailPage />,
  },
  {
    path: '/investcore/products/etfs/:id/edit',
    element: <InvestmentStockEditPage />,
  },
  {
    path: '/investcore/notification-logs',
    element: <InvestmentNotificationLogListPage />,
  },
  {
    path: '/investcore/notification-logs/:id',
    element: <InvestmentNotificationLogDetailPage />,
  },
];

export default investcoreRoutes;
