import { useFetchApiGet } from '@stagapps/redux-utils';
import { Descriptions, Tag } from 'antd';
import { analyticsApiCall } from 'apps/market/apiCalls/fund';
import { useEffect } from 'react';

function getReturnColor(value) {
  if (value >= 0) {
    return 'green';
  }
  if (-20.0 < value < 0) {
    return 'yellow';
  }
  if (value < 0) {
    return 'red';
  }

  return '';
}
function generateTagColor(percentage) {
  if (percentage !== null) {
    var color = getReturnColor(percentage);
    return <Tag color={color}> {percentage} %</Tag>;
  }

  return '-';
}
function formatNav(nav) {
  if (nav !== null) {
    return Intl.NumberFormat('en-US').format(nav);
  }
  return '-';
}

const AnalyticsDetail = ({ symbol }) => {
  const {
    data,
    load: fetchItem,
    isLoading,
    error,
  } = useFetchApiGet(analyticsApiCall, { resourceName: 'item' });

  useEffect(() => {
    if (symbol) {
      fetchItem({ symbol, date_range: '3y' });
    }
  }, [symbol]);

  if (isLoading) return 'Loading..';
  if (error) return 'error..';

  const { annualized_return_percentage } = data;
  const { annualized_return_n_year } = data;
  const { maximum_drawdown_percentage } = data;
  const { nav } = data;
  const { nav_ytd } = data;
  const { change_percentage_ytd } = data;
  const { nav_1y } = data;
  const { change_percentage_1y } = data;
  const { nav_3y } = data;
  const { change_percentage_3y } = data;
  const { nav_5y } = data;
  const { change_percentage_5y } = data;

  return (
    <>
      <Descriptions
        title="Fund Performance"
        column={2}
        bordered
        style={{ margin: '10px 0' }}
      >
        <Descriptions.Item
          label={`Lợi nhuận bình quân hàng năm (Annualized Return) (${annualized_return_n_year} năm gần nhất)`}
          contentStyle={{ textAlign: 'right' }}
        >
          {generateTagColor(annualized_return_percentage)}
        </Descriptions.Item>
        <Descriptions.Item
          label="Tỷ lệ rủi ro (Maximum Drawdown)"
          contentStyle={{ textAlign: 'right' }}
        >
          {generateTagColor(maximum_drawdown_percentage)}
        </Descriptions.Item>

        <Descriptions.Item
          label="NAV/CCQ (VNĐ) (*)"
          contentStyle={{ textAlign: 'right' }}
        >
          {formatNav(nav)}
        </Descriptions.Item>
        <Descriptions.Item
          label="Thay đổi so với đầu năm (%)"
          contentStyle={{ textAlign: 'right' }}
        >
          {generateTagColor(change_percentage_ytd)}
        </Descriptions.Item>

        <Descriptions.Item
          label="NAV/CCQ (VNĐ) (*) cách đây 1 năm"
          contentStyle={{ textAlign: 'right' }}
        >
          {formatNav(nav_1y)}
        </Descriptions.Item>
        <Descriptions.Item
          label="Tăng trưởng trong 1 năm (%)"
          contentStyle={{ textAlign: 'right' }}
        >
          {generateTagColor(change_percentage_1y)}
        </Descriptions.Item>

        <Descriptions.Item
          label="NAV/CCQ (VNĐ) (*) cách đây 3 năm"
          contentStyle={{ textAlign: 'right' }}
        >
          {formatNav(nav_3y)}
        </Descriptions.Item>
        <Descriptions.Item
          label="Tăng trưởng trong 3 năm (%)"
          contentStyle={{ textAlign: 'right' }}
        >
          {generateTagColor(change_percentage_3y)}
        </Descriptions.Item>

        <Descriptions.Item
          label="NAV/CCQ (VNĐ) (*) cách đây 5 năm"
          contentStyle={{ textAlign: 'right' }}
        >
          {formatNav(nav_5y)}
        </Descriptions.Item>
        <Descriptions.Item
          label="Tăng trưởng trong 5 năm (%)"
          contentStyle={{ textAlign: 'right' }}
        >
          {generateTagColor(change_percentage_5y)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default AnalyticsDetail;
