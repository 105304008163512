export function getUUIDFootprint(id, length = 0) {
  if (!id) {
    return '';
  }

  // return the footprint of the UUID, which is the last group of characters sperated by '-'
  if (!length) return id.split('-').pop();
  const x = id.split('-').pop();
  return x.slice(-length);
}
