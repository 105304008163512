import apiCall from 'apps/hub/apiCalls/advisories';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import { columnsGenerator } from './config';
import ItemDetail from './detail';
import ItemForm from './Form';

export const AdvisoryListPage = (props, { params }) => (
  <ListPage
    title="All Reports"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="data"
    createUrl={`/hub/advisories/create`}
    {...props}
  />
);

export const AdvisoryDetailPage = props => (
  <DetailPage
    title="Advisory Report"
    apiCall={apiCall}
    listUrl={`/hub/advisories`}
    ItemDetail={ItemDetail}
    resourceName="data"
    options={{ editEnabled: false }}
    {...props}
  />
);

export const AdvisoryCreatePage = props => {
  return (
    <CreatePage
      title="Advisory"
      apiCall={apiCall}
      listUrl={`/hub/advisories`}
      ItemForm={ItemForm}
      resourceName="data"
      {...props}
    />
  );
};

export const AdvisoryEditPage = props => {
  return (
    <EditPage
      title="Advisory Edit"
      apiCall={apiCall}
      listUrl={`/hub/advisories`}
      ItemForm={ItemForm}
      resourceName="data"
      {...props}
    />
  );
};
