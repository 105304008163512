import apiCall from 'apps/education/apiCalls/gameRoom';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import ItemForm from './Form';
import { columnsGenerator } from './config';

export const GameRoomListPage = props => {
  return (
    <ListPage
      title="All Game Rooms"
      apiCall={apiCall}
      createUrl={`/edu/game-rooms/create`}
      columnGenerator={props => columnsGenerator(props)}
      resourceName="items"
      {...props}
    />
  );
};

export const GameRoomEditPage = props => (
  <EditPage
    title="Game Room"
    apiCall={apiCall}
    listUrl={`/edu/game-rooms`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);

export const GameRoomCreatePage = props => (
  <CreatePage
    title="Game Room"
    apiCall={apiCall}
    listUrl={`/edu/game-rooms`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);
