import Cryptos from 'apps/market/components/Cryptos';
import CryptoCreate from 'apps/market/components/Cryptos/create';
import CryptoEdit from 'apps/market/components/Cryptos/edit';
import MetricCreate from 'apps/market/components/Metrics/create';
import MetricEdit from 'apps/market/components/Metrics/edit';
import Metric from 'apps/market/components/Metrics/index';

// CMS

import {
  BankCreatePage,
  BankEditPage,
  BankListPage,
} from 'apps/market/components/Banks/config';
import {
  ETFCreatePage,
  ETFEditPage,
  ETFListPage,
} from 'apps/market/components/Etfs/config';
import {
  FundCreatePage,
  FundDetailPage,
  FundEditPage,
  FundListPage,
} from 'apps/market/components/Funds/pages';
import {
  IndustryCreatePage,
  IndustryEditPage,
  IndustryListPage,
} from 'apps/market/components/Industry/config';
import {
  CollectionCreatePage,
  CollectionEditPage,
  CollectionListPage,
} from 'apps/market/components/InvestingIdeas/config';
import {
  CollectionGroupCreatePage,
  CollectionGroupEditPage,
  CollectionGroupListPage,
} from 'apps/market/components/InvestingIdeasGroup/config';
import MarketPage from 'apps/market/components/MarketPage';
import { NewsListPage } from 'apps/market/components/News/config';
import {
  NewsIndustryCreatePage,
  NewsIndustryEditPage,
  NewsIndustryListPage,
} from 'apps/market/components/NewsIndustry/config';
import { StockEventLogListPage } from 'apps/market/components/StockEventLogs/config';
import {
  StockEventCreatePage,
  StockEventEditPage,
  StockEventListPage,
} from 'apps/market/components/StockEvents/config';
import {
  StockFAEditPage,
  StockFAListPage,
} from 'apps/market/components/StockFA/config';
import {
  StockCreatePage,
  StockEditPage,
  StockListPage,
} from 'apps/market/components/Stocks/config';

const marketRoutes = [
  { path: '/market/overview', element: <MarketPage /> },
  { path: '/market/collections/create', element: <CollectionCreatePage /> },
  { path: '/market/collections/:id/edit', element: <CollectionEditPage /> },
  { path: '/market/collections/:id', element: <CollectionEditPage /> },
  { path: '/market/collections', element: <CollectionListPage /> },
  {
    path: '/market/collection-groups/create',
    element: <CollectionGroupCreatePage />,
  },
  {
    path: '/market/collection-groups/:id/edit',
    element: <CollectionGroupEditPage />,
  },
  {
    path: '/market/collection-groups/:id',
    element: <CollectionGroupEditPage />,
  },
  { path: '/market/collection-groups', element: <CollectionGroupListPage /> },
  { path: '/market/stock-events/create', element: <StockEventCreatePage /> },
  { path: '/market/stock-events/:id/edit', element: <StockEventEditPage /> },
  { path: '/market/stock-events/:id', element: <StockEventEditPage /> },
  { path: '/market/stock-event-logs', element: <StockEventLogListPage /> },
  { path: '/market/stock-events', element: <StockEventListPage /> },
  { path: '/market/stock-fas/:id/edit', element: <StockFAEditPage /> },
  { path: '/market/stock-fas/:id', element: <StockFAEditPage /> },
  { path: '/market/stock-fas', element: <StockFAListPage /> },
  { path: '/market/news', element: <NewsListPage /> },
  { path: '/market/news-industries', element: <NewsIndustryListPage /> },
  {
    path: '/market/news-industries/create',
    element: <NewsIndustryCreatePage />,
  },
  {
    path: '/market/news-industries/:id/edit',
    element: <NewsIndustryEditPage />,
  },
  { path: '/market/stock-fas/:id/edit', element: <StockFAEditPage /> },
  { path: '/market/stock-fas/:id', element: <StockFAEditPage /> },
  { path: '/market/stock-fas', element: <StockFAListPage /> },
  { path: '/market/stocks', element: <StockListPage /> },
  { path: '/market/stocks/create', element: <StockCreatePage /> },
  { path: '/market/stocks/:id', element: <StockEditPage /> },
  { path: '/market/stocks/:id/edit', element: <StockEditPage /> },
  { path: '/market/cryptos', element: <Cryptos /> },
  { path: '/market/cryptos/create', element: <CryptoCreate /> },
  { path: '/market/cryptos/:id/edit', element: <CryptoEdit /> },
  { path: '/market/etfs', element: <ETFListPage /> },
  { path: '/market/etfs/create', element: <ETFCreatePage /> },
  { path: '/market/etfs/:id', element: <ETFEditPage /> },
  { path: '/market/etfs/:id/edit', element: <ETFEditPage /> },
  { path: '/market/industries', element: <IndustryListPage /> },
  { path: '/market/industries/create', element: <IndustryCreatePage /> },
  { path: '/market/industries/:id', element: <IndustryEditPage /> },
  { path: '/market/industries/:id/edit', element: <IndustryEditPage /> },

  { path: '/market/funds', element: <FundListPage /> },
  { path: '/market/funds/create', element: <FundCreatePage /> },
  { path: '/market/funds/:id', element: <FundDetailPage /> },
  { path: '/market/funds/:id/edit', element: <FundEditPage /> },

  { path: '/market/metrics', element: <Metric /> },
  { path: '/market/metrics/create', element: <MetricCreate /> },
  { path: '/market/metrics/:id/edit', element: <MetricEdit /> },

  { path: '/market/banks', element: <BankListPage /> },
  { path: '/market/banks/create', element: <BankCreatePage /> },
  { path: '/market/banks/:id', element: <BankEditPage /> },
  { path: '/market/banks/:id/edit', element: <BankEditPage /> },
];

export default marketRoutes;
