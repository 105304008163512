import { useFetchApiGet } from '@stagapps/redux-utils';
import { Image, Space, Table, Tag } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  compareMatchingLabels,
  compareMatchingColors,
} from 'apps/investment/constants/identification';
import MetaDecorator from 'apps/home/components/MetaDecorator';
import PageTitle from 'apps/home/components/PageTitle';
import ErrorMessage from 'common/ui/ErrorMessage';
import LoadingSpinner from 'common/ui/LoadingSpinner';

const columns = [
  {
    title: 'Tên trường',
    dataIndex: 'field',
    align: 'left',
  },
  {
    title: 'Thông tin hiện tại',
    dataIndex: 'current_value',
    align: 'left',
  },
  {
    title: 'Thông tin mới',
    dataIndex: 'new_value',
    align: 'left',
  },
  {
    title: 'Kết quả so sánh',
    dataIndex: 'result',
    align: 'left',
    render: value =>
      value ? (
        <Tag color={compareMatchingColors[value]}>
          {compareMatchingLabels[value]}
        </Tag>
      ) : null,
  },
];

function ItemIndividualReKYC({ apiCall }) {
  const title = 'ReKYC Submit of User Identification Draft';

  const params = useParams();

  const {
    data: draftReKYC,
    load: loadDraftReKYC,
    isLoading: isLoadingDraftReKYC,
    error: errorDraftReKYC,
  } = useFetchApiGet(apiCall.compareReKYC, { resourceName: 'data' });

  useEffect(() => {
    loadDraftReKYC(params);
  }, []);

  if (isLoadingDraftReKYC) {
    return <LoadingSpinner />;
  }
  if (errorDraftReKYC) {
    return <ErrorMessage errors={errorDraftReKYC} />;
  }

  const { comparison_fields = [] } = draftReKYC || {};

  return (
    <>
      <MetaDecorator title={title} />
      <PageTitle title={title} backUrl={`/investment/users`} />
      <Tag color="red">// TODO: thêm nút confirm để approve hồ sơ này</Tag>
      <Table
        dataSource={comparison_fields}
        columns={columns}
        pagination={false}
      />
    </>
  );
}

export default ItemIndividualReKYC;
