import apiCall from 'apps/investment/apiCalls/employeeProgramOrder';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';

import { Button } from 'antd';
import { employeeProgramOrdersColumnsGenerator } from 'apps/investment/components/EmployeeProgramOrders/config';
import { Link } from 'react-router-dom';
import FilterForm from './FilterForm';
import ItemForm from './Form';
import GenerateForm from './GenerateForm';
import RebundleForm from './RebundleForm';
import ItemDetail from './detail';

export const InvestmentProgramOrderListPage = (props, { params }) => (
  <ListPage
    title="All Flex Orders"
    apiCall={apiCall}
    enableSearch={true}
    FilterForm={FilterForm}
    params={params}
    columnGenerator={props => employeeProgramOrdersColumnsGenerator(props)}
    resourceName="data"
    size="small"
    extra={
      <Button.Group>
        <Button type="primary">
          <Link to={`/investment/program-orders/generate`}>Generate</Link>
        </Button>
        <Button type="default">
          <Link to={`/investment/program-orders/rebundle`}>Rebundle</Link>
        </Button>
      </Button.Group>
    }
    {...props}
  />
);

export const InvestmentProgramOrderEditPage = props => (
  <EditPage
    title="Edit Flex Order"
    apiCall={apiCall}
    listUrl={`/investment/program-orders`}
    ItemForm={ItemForm}
    resourceName="data"
    {...props}
  />
);

export const InvestmentProgramOrderGeneratePage = props => (
  <CreatePage
    title="Generate Flex Orders"
    apiCall={apiCall}
    listUrl={`/investment/program-orders`}
    ItemForm={GenerateForm}
    resourceName="data"
    {...props}
  />
);

export const InvestmentProgramOrderRebundlePage = () => (
  <RebundleForm apiCall={apiCall} />
);

export const InvestmentProgramOrderDetailPage = props => (
  <DetailPage
    title="Flex Order Detail"
    apiCall={apiCall}
    listUrl={`/investment/program-orders`}
    ItemDetail={ItemDetail}
    resourceName="data"
    {...props}
  />
);
