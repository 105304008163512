import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

export const FETCH_CRITERIA_CREATE_API_ID =
  'DASHBOARD/FETCH_CRITERIA_CREATE_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_CRITERIA_CREATE_API_ID,
  ({ payload }) => ({
    endpoint: `${API_BASE_URL}/marketdb-api/admin/v1/criteria`,
    method: 'POST',
    body: payload,
    notify: true,
  })
);

export default apiCall;
