import { useFetchApiList } from '@stagapps/redux-utils';
import { Button, DatePicker, Form, Input, Radio } from 'antd';
import companyApiCall from 'apps/investment/apiCalls/company/list';
import apiUsersCall from 'apps/investment/apiCalls/user/list';
import DebounceSelect from 'common/ui/DebouceSelect';
import { useEffect } from 'react';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { convertDateFormData, ensureDateFields } from 'utils/formData';

const ItemForm = ({ form, submiting, onFinish, initialValues = {}, name }) => {
  const {
    data: users = [],
    load: fetchUserList,
    isLoading: isLoadingUsers,
  } = useFetchApiList(apiUsersCall, { resourceName: 'data' });

  const {
    data: companies = [],
    load: fetchCompanyList,
    isLoading: isLoadingCompanies,
  } = useFetchApiList(companyApiCall, { resourceName: 'data' });

  useEffect(() => {
    if (initialValues.user_id) {
      fetchUserList({ id: initialValues.user_id });
    }
    if (initialValues.company_id) {
      fetchCompanyList({ id: initialValues.company_id });
    }
  }, [initialValues.user_id]);

  useEffect(() => {
    form.setFieldsValue(ensureDateFields(initialValues, ['date_of_birth']));
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'employee_form'}
      onFinish={values => {
        const payload = convertDateFormData(values, ['date_of_birth']);
        return onFinish(payload);
      }}
      scrollToFirstError
    >
      <Form.Item name="company_id" label="Công ty">
        <DebounceSelect
          placeholder="Chọn công ty"
          fetchOptions={fetchCompanyList}
          fetching={isLoadingCompanies}
          options={companies}
          displayFn={o => {
            return {
              value: o.id,
              label: o.name,
            };
          }}
        />
      </Form.Item>
      <Form.Item name="full_name" label="Họ và tên">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="date_of_birth" label="Ngày sinh">
        <DatePicker />
      </Form.Item>
      <Form.Item name="department" label="Phòng ban">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="role" label="Vị trí">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="id_number" label="Số CMND hoặc CCCD">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="email" label="Email làm việc">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="phone" label="Số điện thoại">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="nationality" label="Quốc tịch">
        <Radio.Group>
          <Radio value={'Việt Nam'}>Việt Nam</Radio>
          <Radio value={'Khác'}>Khác</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item name="user_id" label="Stag Account">
        <DebounceSelect
          placeholder="Select Stag Account"
          fetchOptions={fetchUserList}
          fetching={isLoadingUsers}
          options={users}
          displayFn={o => {
            return {
              value: o.id,
              label: o.email,
            };
          }}
        />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
