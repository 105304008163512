import { Button } from 'antd';
import apiCall from 'apps/investment/apiCalls/bank';
import { DetailPage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { Link } from 'react-router-dom';
import ItemForm from './Form';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const InvestmentBankListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    title="All Banks"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="data"
    {...props}
  />
);

export const InvestmentBankDetailPage = props => (
  <DetailPage
    title="Bank Detail"
    apiCall={apiCall}
    listUrl={`/investment/banks`}
    ItemDetail={ItemDetail}
    resourceName="data"
    extraActions={id => (
      <>
        <Button type="primary">
          <Link className="" to={`/investment/banks/${id}/edit`}>
            Edit
          </Link>
        </Button>
      </>
    )}
    {...props}
  />
);

export const InvestmentBankEditPage = props => (
  <EditPage
    title="Bank"
    apiCall={apiCall}
    listUrl={`/investment/banks`}
    ItemForm={ItemForm}
    resourceName="data"
    {...props}
  />
);
