import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Alert, Card, Descriptions, Tag, Tooltip, Typography } from 'antd';
import {
  notificationTemplateColors,
  notificationTemplateLabel,
  notificationTypeColors,
} from 'apps/investment/constants/notification';
const { Title } = Typography;

import { formatDateTime } from 'utils/formatDate';

const ItemDetail = ({ item, apiCall }) => {
  console.log('item', item);

  return (
    <>
      <Card title={<Title level={3}>Notification Log Detail</Title>}>
        <Descriptions column={1} bordered>
          <Descriptions.Item label="UUID">{item.id}</Descriptions.Item>
          <Descriptions.Item label="Type">
            <Tag color={notificationTypeColors[item.type]}>{item.type}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Template">
            <Tag color={notificationTemplateColors[item.template]}>
              {notificationTemplateLabel[item.template]}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Sender">{item.sender}</Descriptions.Item>
          <Descriptions.Item label="Receiver">
            {item.receiver}
          </Descriptions.Item>
          <Descriptions.Item label="Message">
            <Alert message={item.message} type="info" />
          </Descriptions.Item>
          <Descriptions.Item label="Send Success">
            {item.send_success ? (
              <Tooltip title="Success">
                <CheckCircleOutlined
                  style={{ color: 'green', fontSize: '20px' }}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title={item.error_message ? item.error_message : 'Failed'}
              >
                <CloseCircleOutlined
                  style={{ color: 'red', fontSize: '20px' }}
                />
              </Tooltip>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Sent At">
            {formatDateTime(item.created_at)}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
};

export default ItemDetail;
