import { Button, DatePicker, Form, Input, Select } from 'antd';
import { businessCardTypeOptions } from 'apps/investment/constants/user';
import { useEffect } from 'react';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { convertDateFormData, ensureDateFields } from 'utils/formData';

const { Option } = Select;

const dateFields = ['date_of_birth', 'issue_date'];

const ItemForm = ({
  form,
  submiting,
  onFinish,
  initialValues = {},
  name,
  params,
}) => {
  useEffect(() => {
    initialValues.user_id = params.userId;
    form.setFieldsValue(ensureDateFields(initialValues, dateFields));
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'user_form'}
      onFinish={values => {
        // defaule value for business identification
        values.gender = "male"
        onFinish({ ...convertDateFormData(values, dateFields) })
      }}
      scrollToFirstError
    >
      <Form.Item name="user_id" label="Mã người dùng">
        <Input placeholder="" readOnly disabled />
      </Form.Item>

      <Form.Item
        name="card_type"
        label="Loại giấy tờ định danh"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Select>
          {businessCardTypeOptions &&
            businessCardTypeOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="id_number"
        label="Số chứng nhận đăng ký doanh nghiệp / Mã số thuế"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="full_name"
        label="Tên doanh nghiệp"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="date_of_birth"
        label="Ngày đăng ký lần đầu"
      >
        <DatePicker placeholder="" />
      </Form.Item>

      <Form.Item
        name="issue_place"
        label="Nơi cấp"
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="residential_address"
        label="Địa chỉ đăng ký kinh doanh"
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="address"
        label="Địa chỉ liên hệ"
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
