// React Hook Wrapper for Quill.
//
// https://github.com/gtgalone/react-quilljs

import { usePostApi } from '@stagapps/redux-utils';
import imageUploadCall from 'apps/education/apiCalls/editorImage/upload';
import { useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import 'styles/quill/quill.snow.css';
import { convertImageFormData } from 'utils/image';

export default function TextEditor({ value, onChange, ...props }) {
  const { disabled } = props;
  const { quill, quillRef, Quill } = useQuill({
    modules: {
      toolbar: ['bold', 'italic', 'link', 'image', { header: [2, 3, false] }],
      clipboard: { matchers: MSWORD_MATCHERS },
    },
  });

  const {
    post: submitImage,
    isSubmitting: isUploading, // TODO: show loading state when uploading image
    error: submitItemError,
    data,
  } = usePostApi(imageUploadCall);

  useEffect(() => {
    if (quill) {
      if (value) {
        quill.clipboard.dangerouslyPasteHTML(value);
      }

      quill.on('text-change', (delta, oldDelta, source) => {
        onChange(quill.root.innerHTML);
      });
    }
  }, [quill]);

  useEffect(() => {
    if (quill && data && data.photo) {
      const { photo } = data;

      const range = quill?.getSelection();
      quill.insertEmbed(range?.index || 0, 'image', photo);
    }
  }, [data]);

  useEffect(() => {
    if (quill) {
      // Add custom handler for Image Upload
      quill.getModule('toolbar').addHandler('image', imageHandler);
    }
  }, [quill]);

  const imageHandler = e => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        submitImage({ payload: convertImageFormData(file) });
      }
    };
  };

  if (disabled) {
    return <div dangerouslySetInnerHTML={{ __html: value }} />;
  }

  return (
    <>
      <div ref={quillRef} />
    </>
  );
}

function matchMsWordList(node, delta) {
  // Clone the operations
  let ops = delta.ops.map(op => Object.assign({}, op));

  // Trim the front of the first op to remove the bullet/number
  let bulletOp = ops.find(op => op.insert && op.insert.trim().length);
  if (!bulletOp) {
    return delta;
  }

  bulletOp.insert = bulletOp.insert.trimLeft();
  let listPrefix = bulletOp.insert.match(/^.*?(^·|\.)/) || bulletOp.insert[0];
  bulletOp.insert = bulletOp.insert.substring(
    listPrefix[0].length,
    bulletOp.insert.length
  );

  // Trim the newline off the last op
  let last = ops[ops.length - 1];
  last.insert = last.insert.substring(0, last.insert.length - 1);

  // Determine the list type
  let listType = listPrefix[0].length === 1 ? 'bullet' : 'ordered';

  // Determine the list indent
  let style = node.getAttribute('style').replace(/\n+/g, '');
  let levelMatch = style.match(/level(\d+)/);
  let indent = levelMatch ? levelMatch[1] - 1 : 0;

  // Add the list attribute
  ops.push({ insert: '\n', attributes: { list: listType, indent } });
  const Delta = Quill.import('delta');

  return new Delta(ops);
}

function maybeMatchMsWordList(node, delta) {
  if (delta.ops[0].insert.trimLeft()[0] === '·') {
    return matchMsWordList(node, delta);
  }

  return delta;
}

const MSWORD_MATCHERS = [
  ['p.MsoListParagraphCxSpFirst', matchMsWordList],
  ['p.MsoListParagraphCxSpMiddle', matchMsWordList],
  ['p.MsoListParagraphCxSpLast', matchMsWordList],
  ['p.MsoListParagraph', matchMsWordList],
  ['p.msolistparagraph', matchMsWordList],
  ['p.MsoNormal', maybeMatchMsWordList],
];
