import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Space, Tag, Tooltip } from 'antd';
import {
  notificationTemplateColors,
  notificationTemplateLabel,
  notificationTypeColors,
} from 'apps/investment/constants/notification';
import UIIconTooltip from 'common/ui/UIIconTooltip';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/formatDate';

export const notificationLogColumnsGenerator = params => [
  {
    title: 'Code',
    dataIndex: 'code',
    render: (value, record) => (
      <Link to={`/investment/notification-logs/${record.id}`}>{value}</Link>
    ),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: type => {
      return <Tag color={notificationTypeColors[type]}>{type}</Tag>;
    },
  },
  {
    title: 'Template',
    dataIndex: 'template',
    render: template => {
      return (
        <Tag color={notificationTemplateColors[template]}>
          {notificationTemplateLabel[template]}
        </Tag>
      );
    },
  },
  {
    title: 'Sender',
    dataIndex: 'sender',
  },
  {
    title: 'Receiver',
    dataIndex: 'receiver',
  },
  {
    title: 'User',
    dataIndex: 'user',
    render: (text, record) => {
      if (record.user) {
        return (
          <Link to={`/investment/users/${record.user.id}`}>
            {record.user.email}
          </Link>
        );
      }

      return '-';
    },
  },
  {
    title: 'Send Success',
    dataIndex: 'send_success',
    render: (text, record) => {
      if (record.send_success) {
        return (
          <Tooltip title={'Success'}>
            <CheckCircleOutlined style={{ color: 'green', fontSize: '20px' }} />
          </Tooltip>
        );
      }

      return (
        <Tooltip title={record.error_message ? record.error_message : 'Failed'}>
          <CloseCircleOutlined style={{ color: 'red', fontSize: '20px' }} />
        </Tooltip>
      );
    },
  },
  {
    title: 'Sent At',
    dataIndex: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space>
        <Link to={`/investment/notification-logs/${record.id}`}>
          <UIIconTooltip title="Detail" Icon={InfoCircleOutlined} />
        </Link>
      </Space>
    ),
  },
];
