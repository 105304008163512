import { useFetchApiList } from '@stagapps/redux-utils';
import { Button, Form, Input, Switch } from 'antd';
import apiUsersCall from 'apps/investment/apiCalls/user/list';
import FormattedNumberInput from 'common/form/FormattedNumberInput';
import DebounceSelect from 'common/ui/DebouceSelect';
import { useEffect } from 'react';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const CompanyForm = ({
  form,
  submiting,
  onFinish,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  const {
    data: users = [],
    load: fetchUserList,
    isLoading: isLoadingUsers,
  } = useFetchApiList(apiUsersCall, { resourceName: 'data' });

  useEffect(() => {
    if (initialValues.user_id) {
      fetchUserList({ id: initialValues.user_id });
    }

    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'user_form'}
      onFinish={onFinish}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item name="name" label="Name">
        <Input />
      </Form.Item>
      <Form.Item name="name_en" label="Name (English)">
        <Input />
      </Form.Item>
      <Form.Item name="brand_name" label="Brand Name">
        <Input />
      </Form.Item>
      <Form.Item name="tax_number" label="Tax Number">
        <Input />
      </Form.Item>
      <Form.Item name="monthly_order_payment" label="Monthly Order Payment">
        <FormattedNumberInput min={1} max={28} />
      </Form.Item>
      <Form.Item name="email" label="Work Email">
        <Input />
      </Form.Item>
      <Form.Item name="phone" label="Phone">
        <Input />
      </Form.Item>
      <Form.Item name="address" label="Address">
        <Input />
      </Form.Item>
      <Form.Item name="bank_name" label="Bank name">
        <Input />
      </Form.Item>
      <Form.Item name="bank_branch_name" label="Bank branch name">
        <Input />
      </Form.Item>
      <Form.Item name="bank_account_number" label="Bank account number">
        <Input />
      </Form.Item>
      <Form.Item name="bank_account_name" label="Bank account name">
        <Input />
      </Form.Item>

      <Form.Item
        name="enable_employee_payment"
        label="Enable Employee Payment"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item name="user_id" label="Stag Account">
        <DebounceSelect
          placeholder="Select Stag Account"
          fetchOptions={fetchUserList}
          fetching={isLoadingUsers}
          options={users}
          displayFn={o => {
            return {
              value: o.id,
              label: o.email,
            };
          }}
        />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CompanyForm;
