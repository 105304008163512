import { Button, Tag } from 'antd';
import { Link } from 'react-router-dom';

import { mappingLessonType } from 'apps/education/apiCalls/lesson/option';
import { statusColors, statusLabel } from 'utils/constants';
import { formatDateTime } from 'utils/formatDate';
import { cloudflareCdn } from 'utils/image';

export const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Photo',
    dataIndex: 'photo',
    render: value =>
      value && <img width={100} src={cloudflareCdn(value, 100)} />,
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: value => mappingLessonType[value] ?? '-',
  },
  {
    title: 'Ordering',
    dataIndex: 'ranking',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => {
      return <Tag color={statusColors[value]}>{statusLabel[value]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link
            to={`/hub/courses/${params.course_id}/chapters/${params.chapter_id}/lessons/${record.id}/edit`}
          >
            Edit
          </Link>
        </Button>
        {record.type == 'quiz_check_box' && (
          <Button shape="round">
            <Link
              to={`/hub/courses/${params.course_id}/chapters/${params.chapter_id}/lessons/${record.id}/checkbox-questions`}
            >
              All Questions
            </Link>
          </Button>
        )}
        {record.type == 'quiz_multi_check_box' && (
          <Button shape="round">
            <Link
              to={`/hub/courses/${params.course_id}/chapters/${params.chapter_id}/lessons/${record.id}/multicheckbox-questions`}
            >
              All Questions
            </Link>
          </Button>
        )}
        {record.type == 'quiz_type_form' && (
          <Button shape="round">
            <Link
              to={`/hub/courses/${params.course_id}/chapters/${params.chapter_id}/lessons/${record.id}/form-questions`}
            >
              All Questions
            </Link>
          </Button>
        )}
        {record.type == 'poll' && (
          <Button shape="round">
            <Link
              to={`/hub/courses/${params.course_id}/chapters/${params.chapter_id}/lessons/${record.id}/polls`}
            >
              All Polls
            </Link>
          </Button>
        )}
        {record.type == 'video' && (
          <Button shape="round">
            <Link
              to={`/hub/courses/${params.course_id}/chapters/${params.chapter_id}/lessons/${record.id}/videos`}
            >
              All Videos
            </Link>
          </Button>
        )}
      </Button.Group>
    ),
  },
];
