import {
  reducers as apiReducers,
  reduxListApiCallReducer,
} from '@stagapps/redux-utils';
import { combineReducers } from 'redux';
import adminSessionReducer from './adminSession/state';

export default combineReducers({
  ...apiReducers,
  ...reduxListApiCallReducer,
  ...adminSessionReducer,
});
