import { Button, Tag } from 'antd';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useFetchApiList } from '@stagapps/redux-utils';
import pollListApiCall from 'apps/education/apiCalls/poll/list';
import MetaDecorator from 'apps/home/components/MetaDecorator';
import PageTitle from 'apps/home/components/PageTitle';
import DataTable from 'common/ui/DataTable';
import { statusColors, statusLabel } from 'utils/constants';
import { formatISODate } from 'utils/formatDate';
import { cloudflareCdn } from 'utils/image';

const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Photo',
    dataIndex: 'photo',
    render: value =>
      value && <img width={100} src={cloudflareCdn(value, 100)} />,
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    render: value => formatISODate(value),
  },
  {
    title: 'Type',
    dataIndex: 'type',
  },
  {
    title: 'Position',
    dataIndex: 'placeholder',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => {
      return <Tag color={statusColors[value]}>{statusLabel[value]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/edu/polls/${record.id}/edit`}>Edit</Link>
        </Button>
      </Button.Group>
    ),
  },
];

const Polls = () => {
  const title = 'All Polls';
  const params = useParams();

  const { data: items, load: fetchItems, isLoading, paging } = useFetchApiList(
    pollListApiCall,
    {
      resourceName: 'items',
    }
  );

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <>
      <MetaDecorator title={title} />
      <PageTitle
        title={title}
        extra={[
          <Button key="1" type="primary">
            <Link to={`/edu/polls/create`}>Create</Link>
          </Button>,
        ]}
      />

      <DataTable
        rowKey="id"
        columns={columnsGenerator(params)}
        dataSource={items}
        totalItems={paging.count}
        currentPage={paging.page}
        pageSize={paging.page_size}
        dataFetcher={fetchItems}
        loading={isLoading}
      />
    </>
  );
};

export default Polls;
