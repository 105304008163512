export const notificationTemplateOptions = [
  { value: 1001, label: 'OTP Email Verify' },
  { value: 1002, label: 'OTP Phone Verify' },
  { value: 1003, label: 'OTP Sell Order' },
  { value: 1004, label: 'OTP Buy Order' },
  { value: 1005, label: 'OTP Cancel Order' },
  { value: 1006, label: 'Employee Program Invitation' },
];

export const notificationTemplateLabel = {
  1001: 'OTP Email Verify',
  1002: 'OTP Phone Verify',
  1003: 'OTP Sell Order',
  1004: 'OTP Buy Order',
  1005: 'OTP Cancel Order',
  1006: 'Employee Program Invitation',
  1007: 'Buy Order Confirm',
  1008: 'Buy Order Complete',
  1009: 'Buy Order Cancel',
  1010: 'Reset Password',
  1011: 'Remind Payment (Goal Program)',
  1012: 'Remind Payment (Stag Trade)',
  1013: 'Remind Payment (Stag Trade)',
  1014: 'Employee Complete Order (FutureFlex)',
  1015: 'Employee Cancel Order (FutureFlex)',
  1016: 'Company Complete Order (FutureFlex)',
  1017: 'Company Cancel Order (FutureFlex)',
  1018: 'InApp Confirm Order Buy',
  1019: 'InApp Settle Order Buy',
  1020: 'InApp Complete Order Buy',
  1021: 'InApp Remind Paymment (Goal Program)',
  1022: 'InApp Settle Order Sell',
  1023: 'InApp Complete Order Sell',
  1024: 'InApp Warning Cancel Order Buy',
  1025: 'Employee Settle Order (FutureFlex)',
  1026: 'Kick Off Campaign RiskFree',
};

export const notificationTemplateColors = {
  1001: 'yellow',
  1002: 'green',
  1003: 'purple',
  1004: 'cyan',
  1005: 'volcano',
  1006: 'gray',
  1007: 'blue',
  1008: 'yellow',
  1009: 'purple',
  1010: 'cyan',
  1011: 'volcano',
  1012: 'gray',
  1013: 'yellow',
  1014: 'green',
  1015: 'purple',
  1016: 'cyan',
  1017: 'volcano',
  1018: 'gray',
  1019: 'blue',
  1020: 'yellow',
  1021: 'purple',
  1022: 'cyan',
  1023: 'volcano',
  1024: 'purple',
  1025: 'cyan',
  1026: 'volcano',
};

export const notificationTypeColors = {
  'email': 'blue',
  'phone': 'green',
  'inapp': 'cyan',
};