import {
  enhancedMakeFetchAction,
  generateEndpoint,
} from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

const FETCH_INDUSTRY_LIST_API_ID = 'DASHBOARD/FETCH_INDUSTRY_LIST_API';

const apiCall = enhancedMakeFetchAction(FETCH_INDUSTRY_LIST_API_ID, params => ({
  endpoint: generateEndpoint({
    host: `${API_BASE_URL}/marketdb-api/admin/v1/industries`,
    params,
  }),
  method: 'GET',
}));

export default apiCall;
