import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';

const FETCH_INVESTCORE_USER_REQUIRE_CREATE_STOCK_ACCOUNT_API_ID =
  'DASHBOARD/FETCH_INVESTCORE_USER_REQUIRE_CREATE_STOCK_ACCOUNT_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_INVESTCORE_USER_REQUIRE_CREATE_STOCK_ACCOUNT_API_ID,
  () => ({
    endpoint: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/users/required-create-stock-accounts`,
    method: 'GET',
  })
);

export default apiCall;
