// User Program

export const userProgramStatusPending = 1001;
export const userProgramStatusActive = 1002;
export const userProgramStatusCompleted = 1003;
export const userProgramStatusDeleted = 1004;
export const userProgramStatusPaused = 1005;

export const userProgramStatus = {
  1001: 'Chờ tham gia',
  1002: 'Đang tham gia',
  1003: 'Chờ chuyển giao',
  1004: 'Đã chuyển giao',
  1005: 'Chờ hủy',
  1006: 'Đã hủy',
};

export const yesNoColors = {
  true: 'green',
  false: 'red',
};

export const yesNoLabels = {
  true: 'Yes',
  false: 'No',
};

export const userProgramStatusColors = {
  1001: 'yellow',
  1002: 'green',
  1003: 'purple',
  1004: 'grey',
  1005: 'volcano',
};

export const userProgramStatusOptions = [
  { value: 1001, label: 'Chờ tham gia' },
  { value: 1002, label: 'Đang tham gia' },
  { value: 1003, label: 'Đã kết thúc' },
  { value: 1004, label: 'Đã xoá' },
  { value: 1005, label: 'Tạm dừng' },
];

export const userProgramRequiredStartDateEndDateValid = 1002;
export const userProgramRequiredUser = 1003;
export const userProgramRequiredUserVerified = 1004;
export const userProgramRequiredInvestmentPlan = 1005;
export const userProgramRequiredFundAccountReady = 1006;
export const userProgramRequiredCompleteInformation = 1007;
