import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';

const FETCH_INVESTCORE_USER_IDENTIFICATION_IMAGE_LIST_API_ID =
  'DASHBOARD/FETCH_INVESTCORE_USER_IDENTIFICATION_IMAGE_LIST_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_INVESTCORE_USER_IDENTIFICATION_IMAGE_LIST_API_ID,
  ({ userId }) => ({
    endpoint: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/users/${userId}/identification-images`,
    method: 'GET',
  })
);

export default apiCall;
