import React, { useEffect, useState } from 'react';

import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Row,
  Col,
} from 'antd';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { convertDateFormData, ensureDateFields } from 'utils/formData';

const dateFields = ['date'];

const FilterForm = ({
  form,
  initialValues = {},
  onFinish,
  submiting,
  name,
}) => {

  useEffect(() => {
    form.setFieldsValue(ensureDateFields(initialValues, dateFields));
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'filter_form'}
      onFinish={values => onFinish(convertDateFormData(values, dateFields))}
      scrollToFirstError
    >
      <Form.Item name="symbol" label="Symbol">
        <Input placeholder="ACB" />
      </Form.Item>

      <Form.Item name="date" label="Date Crawled">
        <DatePicker />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Filter
        </Button>
      </Form.Item>

    </Form>
  );
};

export default FilterForm;
