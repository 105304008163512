import { Button, Form, Input, Select, Switch } from 'antd';
import { notificationTemplateOptions } from 'apps/education/apiCalls/notification/options';
import { useEffect } from 'react';
import slugify from 'slugify';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  const onBlur = () => {
    const inputValue = form.getFieldsValue();
    !inputValue.slug &&
      form.setFieldsValue({ slug: slugify(inputValue.title).toLowerCase() });
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={onFinish}
      initialValues={initialValues}
      scrollToFirstError
      onBlur={onBlur}
    >
      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Test thông báo" />
      </Form.Item>

      <Form.Item name="description" label="Description">
        <Input.TextArea rows={10} />
      </Form.Item>

      <Form.Item name="template" label="Template">
        <Select>
          {notificationTemplateOptions &&
            notificationTemplateOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item name="is_active" label="Is Active" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item
        name="is_firebase_push_notification"
        label="Notification Badges?"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>

        {onDelete ? (
          <Button
            onClick={onDelete}
            type="primary"
            style={{
              visibility: visibility,
              margin: 10,
              backgroundColor: 'red',
              borderColor: 'red',
            }}
          >
            Delete
          </Button>
        ) : null}
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
