export const orderSideColors = {
  buy: 'green',
  sell: 'blue',
};

export const orderSideLabels = {
  buy: 'Buy',
  sell: 'Sell',
};

export const orderTypeColors = {
  lo: 'green',
  mp: 'blue',
  ato: 'cyan',
  atc: 'lime',
};

export const orderStatusColors = {
  1000: 'red',
  1001: 'yellow',
  1002: 'cyan',
  1003: 'lime',
  1004: 'blue',
  1005: 'green',
  1006: 'grey',
};
