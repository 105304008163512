import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';

const FETCH_INVESTCORE_ORDER_RESYNC_API_ID =
  'DASHBOARD/FETCH_INVESTCORE_ORDER_RESYNC_API_ID';

const apiCall = enhancedMakeFetchAction(
  FETCH_INVESTCORE_ORDER_RESYNC_API_ID,
  ({ id, payload }) => ({
    endpoint: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/orders/${id}/vcam/resync-order-status`,
    method: 'POST',
    body: payload,
    notify: true,
  })
);

export default apiCall;
