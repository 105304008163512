import { ACTIONS } from 'redux-api-call';
import { adminAccessTokenSelector } from 'state/adminSession/state';

const addRequestHeadersMiddleware = ({ getState }) => next => action => {
  const { type, payload } = action;
  const isFormData = payload.body instanceof FormData;

  if (type === ACTIONS.START) {
    const defaultHeaders = !isFormData
      ? { 'Content-Type': 'application/json' }
      : {};

    const state = getState();
    const token = adminAccessTokenSelector(state);
    const authHeaders = token ? { Authorization: `Bearer ${token}` } : {};

    const withHeadersAction = {
      type: ACTIONS.START,
      payload: {
        ...payload,
        body: isFormData ? payload.body : JSON.stringify(payload.body),
        headers: {
          Accept: 'application/json',
          ...defaultHeaders,
          ...payload.headers,
          ...authHeaders,
        },
      },
    };
    next(withHeadersAction);
  } else {
    next(action);
  }
};

export default addRequestHeadersMiddleware;
