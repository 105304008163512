import { Button, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { cloudflareCdn } from 'utils/image';
import { activeColors, activeLabels } from 'utils/constants';

export const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'VCAM Code',
    dataIndex: 'code',
  },
  {
    title: 'Logo',
    dataIndex: 'logo',
    render: value =>
      value && <img width={100} src={cloudflareCdn(value, 100)} />,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: 'Name (en)',
    dataIndex: 'en_name',
    sorter: true,
  },
  {
    title: 'Brand Name',
    dataIndex: 'trading_name',
    sorter: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => {
      const status = value === 1001 ? 'active' : 'inactive';
      return <Tag color={activeColors[status]}>{activeLabels[status]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/investment/banks/${record.id}`}>View</Link>
        </Button>
        <Button
          type="primary"
          shape="round"
          style={{
            visibility: true,
            marginLeft: 10,
          }}
        >
          <Link to={`/investment/banks/${record.id}/edit`}>Edit</Link>
        </Button>
      </Button.Group>
    ),
  },
];