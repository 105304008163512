import { Button, Form, Input, Select, Switch } from 'antd';
import { useEffect } from 'react';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const accountTypeOptions = [
  {
    label: 'Individual',
    value: 'individual',
  },
  {
    label: 'Corporate',
    value: 'business',
  },
];

const UserForm = ({ form, submiting, onFinish, initialValues = {}, name }) => {
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'user_form'}
      onFinish={onFinish}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item name="name" label="Name">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="phone" label="Phone">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="email_verified"
        label="Email Verified?"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        name="phone_verified"
        label="Phone Verified?"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item name="account_type" label="Account Type">
        <Select>
          {accountTypeOptions.map(o => (
            <Select.Option key={o.value} value={o.value}>
              {o.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserForm;
