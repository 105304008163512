import apiCall from 'apps/investment/apiCalls/userIdentificationDraft';
import { EditPage } from 'common/ui/AdminCRUD/page';
import { useParams } from 'react-router-dom';
import ItemIndividualReKYC from './IndividualReview';

export const InvestmentUserIdentificationDraftReKYCPage = props => {
  const params = useParams();

  return <ItemIndividualReKYC apiCall={apiCall} />;
};
