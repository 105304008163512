import { Button } from 'antd';
import apiCall from 'apps/education/apiCalls/userCommunity';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { Link, useParams } from 'react-router-dom';
import ItemForm from './Form';

export const columnsGenerator = params => [
  {
    title: '#',
    dataIndex: 'id',
  },
  {
    title: 'User',
    dataIndex: 'user',
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        {console.log(record)}
        <Button shape="round">
          <Link
            to={`/edu/communities/${params.community_id}/users/${record.id}/edit`}
          >
            Edit
          </Link>
        </Button>
      </Button.Group>
    ),
  },
];

// Pages

export const UserCommunityListPage = props => {
  const params = useParams();
  console.log(params);
  return (
    <ListPage
      title={`All users in the community #${params.community_id}`}
      apiCall={apiCall}
      params={{ community: params.community_id }}
      createUrl={`/edu/communities/${params.community_id}/users/create`}
      columnGenerator={props => columnsGenerator(params)}
      resourceName="items"
      {...props}
    />
  );
};

export const UserCommunityEditPage = props => {
  const params = useParams();
  return (
    <EditPage
      title="Menu Item"
      apiCall={apiCall}
      listUrl={`/edu/communities/${params.community_id}/users`}
      ItemForm={ItemForm}
      resourceName="item"
      {...props}
    />
  );
};

export const UserCommunityCreatePage = props => {
  const params = useParams();
  return (
    <CreatePage
      title="Menu Item"
      apiCall={apiCall}
      listUrl={`/edu/communities/${params.community_id}/users`}
      ItemForm={ItemForm}
      resourceName="item"
      initialValues={{ community: parseInt(props.params.community_id) }}
      {...props}
    />
  );
};
