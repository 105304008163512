export const orderTypeColors = {
  buy: 'green',
  sell: 'blue',
  redistribute: 'cyan',
};

export const orderTypeLabels = {
  buy: 'Buy',
  sell: 'Sell',
  redistribute: 'Redistribute',
};

export const orderStatusCancelled = 6;
export const orderStatusCompleted = 5;

export const orderStatusColors = {
  0: 'red',
  1: 'yellow',
  2: 'cyan',
  3: 'lime',
  4: 'blue',
  5: 'green',
  6: 'grey',
};

export const orderStatusLabels = {
  0: '-',
  1: 'Chờ thanh toán',
  2: 'Chờ xác nhận',
  4: 'Chờ khớp',
  5: 'Đã phân phối',
  6: 'Đã huỷ',
};

export const orderSourceColors = {
  0: 'red',
  1001: 'blue',
  1002: 'orange',
};

export const orderSourceLabels = {
  0: 'Not set',
  1001: 'Stag',
  1002: 'Flex',
  1003: 'Goal',
};

export const orderProgramTypeColors = {
  0: 'red',
  1001: 'blue',
  1002: 'orange',
  1003: 'green',
};

export const orderProgramTypeGeneralInvest = 1001;
export const orderProgramTypeFlex = 1002;
export const orderProgramTypeGoal = 1003;

export const orderProgramTypeLabels = {
  0: 'Not set',
  1001: 'General Invest',
  1002: 'Flex Program',
  1003: 'Goal Program',
};

export const orderFundManagerLabels = {
  '': 'Not set',
  vcam: 'STAG (VCAM)',
  vcamb2b: 'FLEX (VCAM B2B)',
};

export const orderFundManagerColors = {
  '': 'red',
  vcam: 'blue',
  vcamb2b: 'orange',
};

export const fundOrderStatusColors = {
  '': 'red',
  new: 'cyan',
  pending_confirmation: 'yellow-2',
  waiting_admin_approve: 'yellow-4',
  approved: 'blue',
  pending_completion: 'yellow-6',
  completed: 'green',
  cancel: 'grey',
};

export const fundOrderStatusLabels = {
  '': 'Not set',
  new: 'New',
  pending_confirmation: 'Pending Confirmation',
  waiting_admin_approve: 'Waiting for Approval',
  approved: 'Approved',
  pending_completion: 'Pending Completion',
  completed: 'Completed',
  cancel: 'Cancelled',
  system_cancel: 'System Cancelled',
};
