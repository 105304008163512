import React, { useEffect, useState } from 'react';

import {
  Form,
  Input,
  Button,
  Select,
  DatePicker
} from 'antd';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { UploadOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';


const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
}) => {

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={onFinish}
      initialValues={initialValues}
      scrollToFirstError
    >

      <Form.Item
        name="icb_code"
        label="ICB"
        disabled={true}
      >
        <Input placeholder="8000" readOnly disabled />
      </Form.Item>

      <Form.Item
        name="name"
        label="Name"
      >
        <Input placeholder="Tài chính" readOnly disabled />
      </Form.Item>

      <Form.Item
        name="level"
        label="Level"
      >
        <Input placeholder="2" readOnly disabled />
      </Form.Item>

      <Form.Item label="Keywords">
        <Form.List name="keywords">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                  <Form.Item
                    required={false}
                    key={field.key}
                  >
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Please input keyword",
                          },
                        ]}
                        noStyle
                      >
                        <Input placeholder="Tài chính" />
                      </Form.Item>
                      {fields.length >= 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                  </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: '60%' }}
                  icon={<PlusOutlined />}
                >
                  Add field
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
      </Form.Item>

    </Form>
  );
};

export default ItemForm;
