import listApiCall from './list';
import detailApiCall from './detail';
import editApiCall from './edit';

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  create: listApiCall,
  edit: editApiCall,
  delete: editApiCall,
};

export default ApiCall;
