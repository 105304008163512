import { Button, Tag } from 'antd';
import { Link, useParams } from 'react-router-dom';
import {
  CheckCircleOutlined,
} from '@ant-design/icons';

import { statusColors, statusLabel } from 'utils/constants';
import { formatDateTime } from 'utils/formatDate';
import { cloudflareCdn } from 'utils/image';

export const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
  },
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Photo',
    dataIndex: 'photo',
    render: value =>
      value && <img width={100} src={cloudflareCdn(value, 100)} />,
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Ordering',
    dataIndex: 'ranking',
    align: 'center',
  },
  {
    title: 'Available',
    dataIndex: 'is_available',
    align: 'center',
    render: value => {
      if (value) {
        return <CheckCircleOutlined style={{ color: 'green', fontSize: '20px' }} />;
      } else {
        return '-';
      }
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: value => {
      return <Tag color={statusColors[value]}>{statusLabel[value]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => {
      const params = useParams();

      return (
        <Button.Group>
          <Button shape="round">
            <Link
              to={`/hub/courses/${params.course_id}/chapters/${record.id}/edit`}
            >
              Edit
            </Link>
          </Button>
          <Button shape="round">
            <Link
              to={`/hub/courses/${params.course_id}/chapters/${record.id}/lessons`}
            >
              All Lessons
            </Link>
          </Button>
        </Button.Group>
      );
    },
  },
];
