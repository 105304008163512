import cancelApiCall from './cancel';
import createApiCall from './create';
import detailApiCall from './detail';
import editApiCall from './edit';
import listApiCall from './list';
import resyncApiCall from './resync';
import syncApiCall from './sync';

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  create: createApiCall,
  edit: editApiCall,
  delete: editApiCall,
  sync: syncApiCall,
  resync: resyncApiCall,
  cancel: cancelApiCall,
};

export default ApiCall;
