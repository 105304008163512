import { UploadOutlined } from '@ant-design/icons';
import { useFetchApiList } from '@stagapps/redux-utils';
import { Button, DatePicker, Form, Image, Input, Select, Upload } from 'antd';
import apiBanksCall from 'apps/investment/apiCalls/bank/list';
import { productStatusOptions } from 'apps/investment/constants/fundProduct';
import { useEffect } from 'react';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { convertDateFormData, ensureDateFields } from 'utils/formData';

const dateFields = ['inception_date'];

const ItemForm = ({
  form,
  submiting,
  onFinish,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  const { logo } = initialValues;

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const {
    data: banks = [],
    load: fetchBankList,
    isLoading: isLoadingBanks,
  } = useFetchApiList(apiBanksCall, { resourceName: 'data' });

  useEffect(() => {
    if (initialValues.custodian_bank_id) {
      apiBanksCall({ id: initialValues.custodian_bank_id });
    }

    form.setFieldsValue(ensureDateFields(initialValues, dateFields));
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'product_form'}
      onFinish={values =>
        onFinish(convertDateFormData(values, dateFields), ['logo'])
      }
      initialValues={ensureDateFields(initialValues, dateFields)}
      scrollToFirstError
    >
      <Form.Item name="symbol" label="Symbol">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        name="logo"
        label="Logo"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item
        label="Current logo"
        style={{
          visibility: visibility,
        }}
      >
        <Image width={200} src={logo} />
      </Form.Item>

      <Form.Item name="name" label="Name">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input.TextArea rows={10} />
      </Form.Item>

      <Form.Item name="fund_manager_name" label="Fund Manager Name">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="inception_date" label="Inception Date">
        <DatePicker />
      </Form.Item>
      <Form.Item name="policy_url" label="Policy Url">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="prospectus_url" label="Prospectus Url">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        name="registration_certificate"
        label="Registration Certificate of Establishment"
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="strategy" label="Strategy">
        <Input.TextArea rows={20} />
      </Form.Item>

      <Form.Item name="audit_company" label="Audit Company">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="management_fee" label="Management Fee">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="status" label="Status">
        <Select>
          {productStatusOptions &&
            productStatusOptions.map(option => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>

        {onDelete ? (
          <Button
            onClick={onDelete}
            type="primary"
            style={{
              visibility: visibility,
              margin: 10,
              backgroundColor: 'red',
              borderColor: 'red',
            }}
          >
            Delete
          </Button>
        ) : null}
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
