import { useFetchApiList } from '@stagapps/redux-utils';
import { Button, Form, Input, Radio, Select } from 'antd';
import apiBankBranchCall from 'apps/investment/apiCalls/data/bankBranches';
import apiBankCall from 'apps/investment/apiCalls/data/banks';
import {
  genderOptions,
  securityAccountCardTypeOptions,
} from 'apps/investment/constants/user';
import DatePicker from 'common/ui/UIDatePicker';
import { useEffect } from 'react';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { convertDateFormData, ensureDateFields } from 'utils/formData';

const { Option } = Select;

const dateFields = ['date_of_birth', 'id_issue_date'];

const ItemForm = ({
  form,
  submiting,
  onFinish,
  initialValues,
  name,
  params,
}) => {
  const {
    id,
    bank_branch_id,
    bank_name,
    bank_branch_name,
    account_number,
    contract_number,
  } = initialValues || {};

  const { bank_id } = Form.useWatch([], form) || {};

  useEffect(() => {
    if (!!initialValues) {
      initialValues.user_id = params.userId;
      form.setFieldsValue(ensureDateFields(initialValues, dateFields));
    }
  }, [form, initialValues]);

  const { data: banks, load: loadBank } =
    useFetchApiList(apiBankCall, { resourceName: 'data' }) || {};

  const { data: bankBranches, load: loadBankBranch } =
    useFetchApiList(apiBankBranchCall, { resourceName: 'data' }) || {};

  useEffect(() => {
    loadBank();
  }, []);

  useEffect(() => {
    if (bank_id) {
      loadBankBranch({ id: bank_id });
    }
  }, [bank_id]);

  let filteredBanks = banks.filter(bank => bank.nhsv_code != '');
  return (
    <Form
      {...formItemLayout}
      form={form}
      disabled={account_number && contract_number}
      name={name || 'user_form'}
      onFinish={values => {
        if (!id) {
          values.status = 1002;
        }

        onFinish({ ...convertDateFormData(values, dateFields) });
      }}
      scrollToFirstError
    >
      <Form.Item
        name="id_type"
        label="Loại giấy tờ định danh"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Select>
          {securityAccountCardTypeOptions &&
            securityAccountCardTypeOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="id_number"
        label="Số định danh cá nhân"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="phone"
        label="Phone"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="full_name"
        label="Full Name"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="date_of_birth"
        label="Ngày tháng năm sinh"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item name="gender" label="Giới tính">
        <Select>
          {genderOptions &&
            genderOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="id_issue_date"
        label="Ngày cấp"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        name="id_issue_place"
        label="Nơi cấp"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="residential_address"
        label="Địa chỉ thường trú"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="address"
        label="Địa chỉ liên hệ"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="bank_id"
        label="Ngân hàng"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Select
          placeholder="Select a option and change input text above"
          allowClear
          defaultValue={{
            value: bank_id,
            label: bank_name,
          }}
        >
          {filteredBanks?.map(({ id, name }) => (
            <Option value={id}>{name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="bank_branch_id"
        label="Chi Nhánh Ngân hàng"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Select
          placeholder="Select a option and change input text above"
          allowClear
          defaultValue={{
            value: bank_branch_id,
            label: bank_branch_name,
          }}
        >
          {bankBranches?.map(({ id, name }) => (
            <Option value={id}>{name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="bank_account_number"
        label="STK Ngân hàng"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="bank_account_name"
        label="Tên tài khoản ngân hàng"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="tax_number"
        label="Mã số thuế"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="authentication_method"
        label="Phương thức xác thực đăng nhập"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Radio.Group>
          <Radio value={'otp'}>OTP</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="enable_payment_in_advance"
        label="Ứng trước tiền bán chứng khoán"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Radio.Group>
          <Radio value={true}>Có</Radio>
          <Radio value={false}>Không</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="enable_margin_trading_account"
        label="Mở tài khoản giao dịch ký quỹ (margin)"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Radio.Group>
          <Radio value={true}>Có</Radio>
          <Radio value={false}>Không</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="nationality"
        label="Quốc tịch"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
          },
        ]}
      >
        <Radio.Group>
          <Radio value={'Việt Nam'}>Việt Nam</Radio>
          <Radio value={'Khác'}>Khác</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
