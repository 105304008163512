import { usePostApi } from '@stagapps/redux-utils';
import { Form } from 'antd';
import createApiCall from 'apps/education/apiCalls/poll/create';
import PageTitle from 'apps/home/components/PageTitle';
import { useEffect } from 'react';
import { convertFormData } from 'utils/formData.js';
import ItemForm from './Form';

const PollCreate = () => {
  const [form] = Form.useForm();

  const { post: submitItem, isSubmitting, error: submitItemError } = usePostApi(
    createApiCall,
    {
      resourceName: 'item',
    }
  );

  useEffect(() => {}, []);

  const onFinish = values => {
    console.debug('Received values of form: ', values);

    const postData = async () => {
      try {
        let submitData = values;

        submitData.lesson = null;
        submitData.points = 0;
        submitData.is_active = 0;

        if (
          values.photo &&
          values.photo.at(-1) &&
          values.photo.at(-1).originFileObj instanceof File
        ) {
          submitData.photo = values.photo[0].originFileObj;
          submitData.is_active
            ? (submitData.is_active = '1')
            : (submitData.is_active = '0');

          submitData.points = '0';
          submitData = convertFormData(submitData);
        }

        submitItem({ payload: submitData });
      } catch (error) {
        console.error(error.message);
      }
    };

    postData();
  };

  return (
    <>
      <PageTitle title={`Create Poll`} />
      <ItemForm
        submiting={isSubmitting}
        form={form}
        onFinish={onFinish}
        name="poll_create_form"
      />
    </>
  );
};

export default PollCreate;
