import backfillProgramApiCall from './backfill';
import cancelApiCall from './cancel';
import completeApiCall from './complete';
import createApiCall from './create';
import createCompanySellOrderApiCall from './createCompanySellOrder';
import createEmployeeSellOrderApiCall from './createEmployeeSellOrder';
import detailApiCall from './detail';
import editApiCall from './edit';
import estimateApiCall from './estimate';
import inProgressApiCall from './inProgress';
import inviteApiCall from './invite';
import listApiCall from './list';
import pendingApiCall from './pending';
import portfolioApiCall from './portfolio';
import terminateApiCall from './terminate';
import vestingApiCall from './vesting';

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  create: createApiCall,
  edit: editApiCall,
  delete: editApiCall,
  invite: inviteApiCall,
  vesting: vestingApiCall,
  complete: completeApiCall,
  terminate: terminateApiCall,
  cancel: cancelApiCall,
  pending: pendingApiCall,
  inProgress: inProgressApiCall,
  estimate: estimateApiCall,
  createCompanySellOrder: createCompanySellOrderApiCall,
  createEmployeeSellOrder: createEmployeeSellOrderApiCall,
  backfill: backfillProgramApiCall,
  portfolio: portfolioApiCall,
};

export default ApiCall;
