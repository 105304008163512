import { Button, Tag } from 'antd';
import apiCall from 'apps/education/apiCalls/community';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { Link } from 'react-router-dom';
import { statusColors, statusLabel } from 'utils/constants';
import ItemForm from './Form';

const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Photo',
    dataIndex: 'photo',
    render: value => value && <img width={100} src={value} />,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => {
      return <Tag color={statusColors[value]}>{statusLabel[value]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/edu/communities/${record.id}/edit`}>Edit</Link>
        </Button>
        <Button shape="round">
          <Link to={`/edu/communities/${record.id}/users`}>All Users</Link>
        </Button>
      </Button.Group>
    ),
  },
];

// Pages

export const CommunityListPage = (props, { params }) => (
  <ListPage
    title="All Communities"
    apiCall={apiCall}
    params={params}
    createUrl={`/edu/communities/create`}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="items"
    {...props}
  />
);

export const CommunityEditPage = props => (
  <EditPage
    title="Community"
    apiCall={apiCall}
    listUrl={`/edu/communities`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);

export const CommunityCreatePage = props => (
  <CreatePage
    title="Community"
    apiCall={apiCall}
    listUrl={`/edu/communities`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);
