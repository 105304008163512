import { useEffect } from 'react';

import { usePostApi } from '@stagapps/redux-utils';
import { Button, Form, Select, Space } from 'antd';
import apiCall from 'apps/education/apiCalls/gameParticipants';
import { useParams } from 'react-router-dom';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = { status: 1002 },
  name,
}) => {
  const { id, user_id } = useParams();
  useEffect(() => {
    form.setFieldsValue({ ...initialValues });
  }, [form, initialValues]);

  const {
    post: deleteItem,
    isLoading: isDeleting,
    error: deleteItemError,
  } = usePostApi(apiCall.delete);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={onFinish}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item name="status" label="Status">
        <Select>
          <Option key={1001} value={1001}>
            Pending for approval
          </Option>
          <Option key={1002} value={1002}>
            Success
          </Option>
        </Select>
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Space>
          <Button htmlType="submit" type="primary" loading={submiting}>
            Save
          </Button>
          {/* {id && user_id && (
            <Button
              style={{
                color: 'white',
                margin: 10,
                backgroundColor: 'red',
                borderColor: 'red',
              }}
              disabled={isDeleting}
              onClick={() => {
                if (
                  confirm('Are you sure to remove the user out of the room')
                ) {
                  deleteItem({ id, user_id });
                }
              }}
            >
              Remove
            </Button>
          )} */}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
