import { Button, Form, Select } from 'antd';
import { userProgramOrderStatusOptions } from 'apps/investment/constants/programOrder';
import QueryString from 'qs';
import { useSearchParams } from 'react-router-dom';

const FilterForm = ({ form, name }) => {
  let [filterParams, setSearchParams] = useSearchParams();
  let initialValues = QueryString.parse(filterParams.toString());

  const handleSubmit = params => {
    const p = JSON.parse(JSON.stringify(params));
    setSearchParams(p);
  };

  return (
    <Form
      form={form}
      layout="inline"
      name={name || 'filter_form'}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <Form.Item name="status" label="Status">
        <Select style={{ width: 150 }}>
          {userProgramOrderStatusOptions.map(option => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Button type="primary" htmlType="submit">
        Filter
      </Button>
    </Form>
  );
};

export default FilterForm;
