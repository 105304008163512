import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';

const POST_INVESTCORE_COMPANY_CREATE_API_ID =
  'DASHBOARD/POST_INVESTCORE_COMPANY_CREATE_API_ID';

const apiCall = enhancedMakeFetchAction(
  POST_INVESTCORE_COMPANY_CREATE_API_ID,
  ({ payload }) => ({
    endpoint: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/companies`,
    method: 'POST',
    body: payload,
    notify: true,
  })
);

export default apiCall;
