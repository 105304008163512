import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useFetchApiList } from '@stagapps/redux-utils';
import { Button, Form, Input, InputNumber, Select, Spin } from 'antd';
import apiUsersCall from 'apps/investment/apiCalls/user/list';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';

import apiProductsCall from 'apps/investment/apiCalls/product/list';
import FormattedNumberInput from 'common/form/FormattedNumberInput';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { convertDateFormData, ensureDateFields } from 'utils/formData';

const dateFields = []; //['start_date', 'end_date'];
const debounceTimeout = 800;

const UserProgramForm = ({
  form,
  submiting,
  onFinish,
  initialValues = {},
  name,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const editMode = !!initialValues.id;
  const handleChange = (value, selectIndex) => {
    const updatedValues = [...selectedValues];
    updatedValues[selectIndex] = value;
    setSelectedValues(updatedValues);
  };

  const {
    data: products = [],
    load: loadProducts,
    isLoading: isLoadingProducts,
  } = useFetchApiList(apiProductsCall, { resourceName: 'data' });

  const {
    data: users = [],
    load: loadUsers,
    isLoading: isLoadingUsers,
    loadMore: loadMoreUsers,
  } = useFetchApiList(apiUsersCall, { resourceName: 'data' });

  const debounceFetcher = useMemo(() => {
    const loadOptions = value => {
      loadMoreUsers({ search: value });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [loadUsers, debounceTimeout]);

  useEffect(() => {
    form.setFieldsValue(ensureDateFields(initialValues, dateFields));
  }, [form, initialValues]);

  useEffect(() => {
    loadProducts();
    loadUsers();
  }, []);

  const { investment_details = [] } = Form.useWatch([], form) || {};

  if (isLoadingProducts || isLoadingUsers) {
    return <LoadingSpinner />;
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={values => onFinish(convertDateFormData(values, dateFields))}
      name={name || 'user_program_form'}
      scrollToFirstError
    >
      <Form.Item name="user_id" label="User">
        <Select
          onPopupScroll={e => {
            e.persist();
            let target = e.target;
            if (
              target.scrollTop + target.offsetHeight ===
              target.scrollHeight
            ) {
              loadMoreUsers();
            }
          }}
          onSearch={debounceFetcher}
          notFoundContent={isLoadingUsers ? <Spin size="small" /> : null}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          placeholder="Search Email / Full name"
          options={users.map(({ id, email, name }) => ({
            value: id,
            label: name ? name + ' / ' + email : email,
          }))}
        />
      </Form.Item>

      <Form.Item name="program_name" label="Program Name">
        <Input />
      </Form.Item>

      <Form.Item name="end_date" label="End date">
        <Input />
      </Form.Item>
      <Form.Item name="init_contribute_amount" label="Init Contribute Amount">
        <FormattedNumberInput addonAfter={'VND'} min={100000} step={100000} />
      </Form.Item>
      <Form.Item name="investment_interval" label="Investment Interval">
        <FormattedNumberInput min={1} step={1} readOnly disabled />
      </Form.Item>
      <Form.Item name="monthly_order_payment" label="Investment Day">
        <FormattedNumberInput min={1} max={28} step={1} />
      </Form.Item>
      <Form.Item name="target_amount" label="Target amount">
        <FormattedNumberInput addonAfter={'VND'} min={100000} step={100000} />
      </Form.Item>
      <Form.Item
        name="target_return_ratio"
        label="Target Return Ratio (Ex: 0.15)"
      >
        <FormattedNumberInput min={0.01} step={0.01} readOnly disabled />
      </Form.Item>

      {editMode ? null : (
        <Form.Item label="Investment Plan">
          <Form.List name="investment_details">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <div key={key} className="flex flex-row items-center">
                    <Form.Item
                      label="Product"
                      {...restField}
                      name={[name, 'product_id']}
                      labelCol={{ span: 24 }}
                    >
                      <Select
                        style={{ width: '200px' }}
                        placeholder="Chọn quỹ"
                        onChange={value => handleChange(value, index)}
                      >
                        {products.map(innerProduct => (
                          <Option
                            key={innerProduct.id}
                            value={innerProduct.id}
                            disabled={
                              selectedValues.includes(innerProduct.id) &&
                              innerProduct.id !== selectedValues[index]
                            }
                          >
                            {innerProduct.code}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      label="Percentage"
                      labelCol={{ span: 24 }}
                      name={[name, 'percentage']}
                      rules={[
                        {
                          required: true,
                          type: 'number',
                          message: 'Wrong format!',
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: '200px' }}
                        placeholder="Tỉ lệ từ 1-100%"
                        min={1}
                        max={100}
                        step={10}
                        addonAfter={'%'}
                        precision={0}
                      />
                    </Form.Item>

                    <MinusCircleOutlined
                      style={{
                        fontSize: '16px',
                        color: 'red',
                        height: 'fit-content',
                      }}
                      onClick={() => remove(name)}
                    />
                  </div>
                ))}

                {investment_details.length === products.length ? null : (
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add product
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
        </Form.Item>
      )}

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserProgramForm;
