import { useFetchApiList } from '@stagapps/redux-utils';
import { Button, Form, Select, Spin } from 'antd';
import apiCompaniesCall from 'apps/investment/apiCalls/company/list';
import { employeeProgramStatusOptions } from 'apps/investment/constants/employeeProgram';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import debounce from 'lodash/debounce';
import QueryString from 'qs';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const debounceTimeout = 800;
const companyPerPage = 1000;

const FilterForm = ({ form, name }) => {
  let [filterParams, setSearchParams] = useSearchParams();
  let initialValues = QueryString.parse(filterParams.toString());

  const {
    data: companies = [],
    load: loadCompanies,
    isLoading: isLoadingCompanies,
    loadMore: loadMoreCompanies,
  } = useFetchApiList(apiCompaniesCall, { resourceName: 'data' });

  const debounceFetcher = useMemo(() => {
    const loadOptions = value => {
      loadMoreCompanies({ search: value });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [loadCompanies, debounceTimeout]);

  const handleSubmit = params => {
    const p = JSON.parse(JSON.stringify(params));
    setSearchParams(p);
  };

  useEffect(() => {
    // Show all instead of pagination
    loadCompanies({ page_size: companyPerPage });
  }, []);

  if (isLoadingCompanies) {
    return <LoadingSpinner />;
  }

  return (
    <Form
      form={form}
      layout="inline"
      name={name || 'filter_form'}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <Form.Item name="status" label="Status">
        <Select style={{ width: 200 }}>
          {employeeProgramStatusOptions.map(option => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="company_id" label="Company">
        <Select
          style={{ width: 400 }}
          onPopupScroll={e => {
            e.persist();
            let target = e.target;
            if (
              target.scrollTop + target.offsetHeight ===
              target.scrollHeight
            ) {
              loadMoreCompanies();
            }
          }}
          notFoundContent={isLoadingCompanies ? <Spin size="small" /> : null}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={companies.map(({ id, email, name }) => ({
            value: id,
            label: name + ' / ' + email,
          }))}
        />
      </Form.Item>
      <Button type="primary" htmlType="submit">
        Filter
      </Button>
    </Form>
  );
};

export default FilterForm;
