export { default as listApiCall } from './list';
export { default as relevantApiCall } from './relevant';
export { default as irrelevantApiCall } from './irrelevant';
export { default as recommendApiCall } from './recommend';
export { default as disrecommendApiCall } from './disrecommend';
export { default as deleteApiCall } from './delete';

import listApiCall from './list';
import relevantApiCall from './relevant';
import irrelevantApiCall from './irrelevant';
import recommendApiCall from './recommend';
import disrecommendApiCall from './disrecommend';
import deleteApiCall from './delete';

const ApiCall = {
  list: listApiCall,
  relevant: relevantApiCall,
  irrelevant: irrelevantApiCall,
  recommend: recommendApiCall,
  disrecommend: disrecommendApiCall,
  delete: deleteApiCall,
};

export default ApiCall;
