import { usePostApi } from '@stagapps/redux-utils';
import { Form } from 'antd';
import createApiCall from 'apps/education/apiCalls/notification/create';
import PageTitle from 'apps/home/components/PageTitle';
import ItemForm from './Form';

const NotificationCreate = () => {
  const [form] = Form.useForm();

  const { post: submitItem, isSubmitting, error: submitItemError } = usePostApi(
    createApiCall,
    {
      resourceName: 'item',
    }
  );

  const onFinish = values => {
    console.debug('Received values of form: ', values);

    const postData = async () => {
      try {
        let submitData = values;
        submitItem({ payload: submitData });
      } catch (error) {
        console.error(error.message);
      }
    };

    postData();
  };

  return (
    <>
      <PageTitle title={`Create Notification`} />
      <ItemForm
        submiting={isSubmitting}
        form={form}
        onFinish={onFinish}
        name="notification_create_form"
      />
    </>
  );
};

export default NotificationCreate;
