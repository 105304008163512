import { EditOutlined } from '@ant-design/icons';
import { Space, Tag } from 'antd';
import UIIconTooltip from 'common/ui/UIIconTooltip';
import { Link } from 'react-router-dom';
import { statusColors, statusLabel } from 'utils/constants';
import { formatDateTime } from 'utils/formatDate';

export const columnsGenerator = params => [
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Author',
    dataIndex: 'author',
  },
  {
    title: 'Suggestion',
    dataIndex: 'suggestion',
    width: '33%',
    render: value => <div dangerouslySetInnerHTML={{ __html: value }} />,
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Published At',
    dataIndex: 'published_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => {
      return <Tag color={statusColors[value]}>{statusLabel[value]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space>
        <Link to={`/hub/advisories/${record.id}/edit`}>
          <UIIconTooltip title="Edit" Icon={EditOutlined} />
        </Link>
      </Space>
    ),
  },
];
