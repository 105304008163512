import { Button, Tag } from 'antd';
import {
  userStatusColors,
  userStatusLabels,
} from 'apps/education/constants/gameUser';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/formatDate';

export const columnsGenerator = params => [
  {
    title: 'Email',
    dataIndex: 'user',
    render: obj => obj.email,
  },
  {
    title: 'Name',
    dataIndex: 'user',
    render: obj => obj.name,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: value => {
      return (
        <Tag color={userStatusColors[value]}>{userStatusLabels[value]}</Tag>
      );
    },
  },
  {
    title: 'Initial Capital',
    dataIndex: 'initial_capital',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Depository Amount',
    dataIndex: 'depository_amount',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Joined At',
    dataIndex: 'joined_date',
    render: value => formatDateTime(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        {record.user ? (
          <Button shape="round">
            <Link
              to={`/edu/game-rooms/${record.game_room}/users/${record.user.id}/edit`}
            >
              Edit
            </Link>
          </Button>
        ) : null}
      </Button.Group>
    ),
  },
];
