import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

const FETCH_MUTUAL_FUND_HISTORY_API_ID =
  'DASHBOARD/FETCH_MUTUAL_FUND_HISTORY_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_MUTUAL_FUND_HISTORY_API_ID,
  ({ symbol, date_range }) => ({
    endpoint: `${API_BASE_URL}/marketdb-api/admin/v1/funds/${symbol}/prices?date_range=${date_range}`,
    method: 'GET',
  })
);

export default apiCall;
