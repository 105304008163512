import { Button, Form, Select } from 'antd';
import { orderStatusLabels } from 'apps/investment/constants/fundOrder';
import QueryString from 'qs';
import { useSearchParams } from 'react-router-dom';

const FilterForm = ({ form, name }) => {
  let [filterParams, setSearchParams] = useSearchParams();

  const handleSubmit = params => {
    const p = JSON.parse(JSON.stringify(params));
    console.log(p);
    setSearchParams(p);
  };

  let statusOptions = [{ value: '', label: 'All' }];

  for (let value in orderStatusLabels) {
    if (value > 0 && orderStatusLabels.hasOwnProperty(value)) {
      statusOptions.push({ value: value, label: orderStatusLabels[value] });
    }
  }

  return (
    <Form
      form={form}
      layout="inline"
      name={name || 'filter_form'}
      onFinish={handleSubmit}
      initialValues={QueryString.parse(filterParams.toString())}
    >
      <Form.Item name="status" label="Status">
        <Select style={{ width: 200 }} options={statusOptions} />
      </Form.Item>
      <Form.Item name="program_type" label="Program">
        <Select
          style={{ width: 200 }}
          options={[
            { value: 1002, label: 'Flex Program' },
            { value: 1003, label: 'Goal Program' },
          ]}
        />
      </Form.Item>
      <Form.Item name="trading_account_manager" label="Trading Account Manager">
        <Select
          style={{ width: 200 }}
          options={[
            { value: 'nhsv', label: 'NHSV' },
            { value: 'vcam', label: 'VCAM' },
          ]}
        />
      </Form.Item>
      <Button type="primary" htmlType="submit">
        Filter
      </Button>
    </Form>
  );
};

export default FilterForm;
