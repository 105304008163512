import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

export const FETCH_LESSON_DELETE_API_ID = 'HUB/FETCH_LESSON_DELETE_API_ID';

const apiCall = enhancedMakeFetchAction(
  FETCH_LESSON_DELETE_API_ID,
  ({ id }) => ({
    endpoint: `${API_BASE_URL}/investcore-api/admin/edu/lessons/${id}`,
    method: 'DELETE',
    notify: true,
  })
);

export default apiCall;
