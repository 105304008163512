import { Button, Tag } from 'antd';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useFetchApiList } from '@stagapps/redux-utils';
import challengeListApiCall from 'apps/education/apiCalls/challenge/list';
import { renderTypeName } from 'apps/education/apiCalls/challenge/options';
import MetaDecorator from 'apps/home/components/MetaDecorator';
import PageTitle from 'apps/home/components/PageTitle';
import DataTable from 'common/ui/DataTable';
import { activeColors, activeLabels } from 'utils/constants';

const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Reward',
    dataIndex: 'value',
  },
  {
    title: 'Photo',
    dataIndex: 'photo',
    render: value => value && <img width={100} src={value} />,
  },
  {
    title: 'Frequently',
    dataIndex: 'type',
    render: value => renderTypeName(value),
  },
  {
    title: 'Status',
    dataIndex: 'is_active',
    render: value => {
      const status = value === true ? 'active' : 'inactive';
      return <Tag color={activeColors[status]}>{activeLabels[status]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/edu/challenges/${record.id}/edit`}>Edit</Link>
        </Button>
      </Button.Group>
    ),
  },
];

const Challenges = () => {
  const title = 'All Challenges';
  const {
    data: items,
    load: fetchItems,
    isLoading,
    paging,
  } = useFetchApiList(challengeListApiCall, { resourceName: 'items' });

  useEffect(() => {
    fetchItems();
  }, []);

  var formatedItems = items.map(item => {
    if (item.parent && item.parent > 0 && !item.name.startsWith('--')) {
      item.name = `-- ${item.name}`;
    }

    return item;
  });

  return (
    <>
      <MetaDecorator title={title} />
      <PageTitle
        title={title}
        extra={[
          <Button key="1" type="primary">
            <Link to={`/edu/challenges/create`}>Create</Link>
          </Button>,
        ]}
      />

      <DataTable
        rowKey="id"
        columns={columnsGenerator()}
        dataSource={formatedItems}
        totalItems={paging.count}
        currentPage={paging.page}
        pageSize={paging.page_size}
        dataFetcher={fetchItems}
        loading={isLoading}
      />
    </>
  );
};

export default Challenges;
