import {
  AdvisoryCreatePage,
  AdvisoryEditPage,
  AdvisoryListPage,
} from 'apps/hub/components/Advisories';
import {
  ChapterCreatePage,
  ChapterEditPage,
  ChapterListPage,
} from 'apps/hub/components/Chapters';
import {
  CourseCreatePage,
  CourseEditPage,
  CourseListPage,
} from 'apps/hub/components/Courses';
import { FHCDetailPage, FHCListPage } from 'apps/hub/components/FHC';
import {
  PollCreatePage,
  PollEditPage,
  PollListPage,
} from 'apps/hub/components/LessonPolls';
import {
  QuizCheckBoxCreatePage,
  QuizCheckBoxEditPage,
  QuizCheckBoxListPage,
} from 'apps/hub/components/LessonQuizCheckboxes';
import {
  QuizMultiCheckBoxCreatePage,
  QuizMultiCheckBoxEditPage,
  QuizMultiCheckBoxListPage,
} from 'apps/hub/components/LessonQuizMultiCheckboxes';
import {
  QuizTypeFormCreatePage,
  QuizTypeFormEditPage,
  QuizTypeFormListPage,
} from 'apps/hub/components/LessonQuizTypeForms';
import {
  LessonCreatePage,
  LessonEditPage,
  LessonListPage,
} from 'apps/hub/components/Lessons';
import {
  VideoCreatePage,
  VideoEditPage,
  VideoListPage,
} from 'apps/hub/components/LessonVideos';
import {
  TopicCreatePage,
  TopicEditPage,
  TopicListPage,
} from 'apps/hub/components/Topics';
import {
  VideosCreatePage,
  VideosEditPage,
  VideosListPage,
} from 'apps/hub/components/Videos';

const hubRoutes = [
  { path: '/hub/videos/create', element: <VideosCreatePage /> },
  { path: '/hub/videos/:id/edit', element: <VideosEditPage /> },
  { path: '/hub/videos', element: <VideosListPage /> },

  { path: '/hub/advisories/create', element: <AdvisoryCreatePage /> },
  { path: '/hub/advisories/:id/edit', element: <AdvisoryEditPage /> },
  { path: '/hub/advisories', element: <AdvisoryListPage /> },
  { path: '/hub/fhc-reports', element: <FHCListPage /> },
  { path: '/hub/fhc-reports/:id', element: <FHCDetailPage /> },
  { path: '/hub/courses', element: <CourseListPage /> },
  { path: '/hub/courses/create', element: <CourseCreatePage /> },
  { path: '/hub/courses/:id', element: <CourseEditPage /> },
  { path: '/hub/courses/:id/edit', element: <CourseEditPage /> },
  { path: '/hub/courses/:course_id/chapters', element: <ChapterListPage /> },
  {
    path: '/hub/courses/:course_id/chapters/create',
    element: <ChapterCreatePage />,
  },
  {
    path: '/hub/courses/:course_id/chapters/:id',
    element: <ChapterEditPage />,
  },
  {
    path: '/hub/courses/:course_id/chapters/:id/edit',
    element: <ChapterEditPage />,
  },
  { path: '/hub/topics', element: <TopicListPage /> },
  { path: '/hub/topics/create', element: <TopicCreatePage /> },
  { path: '/hub/topics/:id/edit', element: <TopicEditPage /> },
  {
    path: '/hub/courses/:course_id/chapters/:chapter_id/lessons',
    element: <LessonListPage />,
  },
  {
    path: '/hub/courses/:course_id/chapters/:chapter_id/lessons/create',
    element: <LessonCreatePage />,
  },
  {
    path: '/hub/courses/:course_id/chapters/:chapter_id/lessons/:id',
    element: <LessonEditPage />,
  },
  {
    path: '/hub/courses/:course_id/chapters/:chapter_id/lessons/:id/edit',
    element: <LessonEditPage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/polls',
    element: <PollListPage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/polls/create',
    element: <PollCreatePage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/polls/:id',
    element: <PollEditPage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/polls/:id/edit',
    element: <PollEditPage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/videos',
    element: <VideoListPage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/videos/create',
    element: <VideoCreatePage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/videos/:id',
    element: <VideoEditPage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/videos/:id/edit',
    element: <VideoEditPage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/checkbox-questions',
    element: <QuizCheckBoxListPage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/checkbox-questions/create',
    element: <QuizCheckBoxCreatePage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/checkbox-questions/:id',
    element: <QuizCheckBoxEditPage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/checkbox-questions/:id/edit',
    element: <QuizCheckBoxEditPage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/multicheckbox-questions',
    element: <QuizMultiCheckBoxListPage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/multicheckbox-questions/create',
    element: <QuizMultiCheckBoxCreatePage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/multicheckbox-questions/:id',
    element: <QuizMultiCheckBoxEditPage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/multicheckbox-questions/:id/edit',
    element: <QuizMultiCheckBoxEditPage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/form-questions',
    element: <QuizTypeFormListPage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/form-questions/create',
    element: <QuizTypeFormCreatePage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/form-questions/:id',
    element: <QuizTypeFormEditPage />,
  },
  {
    path:
      '/hub/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/form-questions/:id/edit',
    element: <QuizTypeFormEditPage />,
  },
];

export default hubRoutes;
