import { usePostApi } from '@stagapps/redux-utils';
import apiCall from 'apps/investment/apiCalls/employeeProgram';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import FilterForm from './FilterForm';
import ItemForm from './Form';
import { employeeProgramsColumns } from './config';
import ItemDetail from './detail';
import Portfolio from './portfolio';

export const ProgramDetailListPage = (props, { params }) => {
  const { post: backfillPost } = usePostApi(apiCall.backfill);
  return (
    <ListPage
      enableSearch={true}
      FilterForm={FilterForm}
      title="All Flex Programs"
      apiCall={apiCall}
      params={params}
      createUrl={`/investment/employee-programs/create`}
      columnGenerator={props =>
        employeeProgramsColumns({ backfillPost, ...props })
      }
      resourceName="data"
      {...props}
    />
  );
};

export const ProgramDetailDetailPage = (props, { params }) => (
  <DetailPage
    title="Flex Program Detail"
    listUrl={`/investment/employee-programs`}
    apiCall={apiCall}
    ItemDetail={ItemDetail}
    params={params}
    resourceName="data"
    {...props}
  />
);

export const ProgramDetailEditPage = props => (
  <EditPage
    title="Edit Flex Program"
    apiCall={apiCall}
    listUrl={`/investment/employee-programs`}
    ItemForm={ItemForm}
    resourceName="data"
    {...props}
  />
);

export const ProgramDetailCreatePage = props => (
  <CreatePage
    title="Create Flex Program"
    apiCall={apiCall}
    listUrl={`/investment/employee-programs`}
    ItemForm={ItemForm}
    resourceName="data"
    {...props}
  />
);

export const ProgramDetailPortfolioPage = props => (
  <Portfolio apiCall={apiCall} />
);
