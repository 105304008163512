import {
  EditOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
import { Space, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import {
  employeeProgramStatus,
  employeeProgramStatusColors,
  employeeProgramStatusPending,
} from 'apps/investment/constants/employeeProgram';
import UIIconTooltip from 'common/ui/UIIconTooltip';
import { formatDateTime } from 'utils/formatDate';

export const employeeProgramsColumns = () => {
  return [
    {
      title: '',
      dataIndex: 'id',
      align: 'center',
      render: id => (
        <Link to={`/investment/employee-programs/${id}`}>View</Link>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      sorter: true,
      render: value => formatDateTime(value),
    },
    {
      title: 'Employee Name',
      dataIndex: 'employee',
      render: employee => (
        <Link to={`/investment/employees/${employee?.id}`}>
          {employee?.full_name}
        </Link>
      ),
    },
    {
      title: 'Company',
      dataIndex: 'company',
      render: company => (
        <Link to={`/investment/companies/${company?.id}`}>
          {company?.brand_name}
        </Link>
      ),
    },
    {
      title: 'Start',
      dataIndex: 'start_date',
      sorter: true,
      type: 'dateTime',
    },
    {
      title: 'End',
      dataIndex: 'end_date',
      sorter: true,
      type: 'dateTime',
    },
    {
      title: 'Company Match',
      dataIndex: 'company_contribute_amount',
      type: 'formatNumber',
      sorter: true,
    },
    {
      title: 'Employee Match',
      dataIndex: 'employee_contribute_amount',
      type: 'formatNumber',
      sorter: true,
    },
    {
      title: 'Interval',
      dataIndex: 'investment_interval',
      sorter: true,
      align: 'right',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => {
        return (
          <Space>
            <Tag color={employeeProgramStatusColors[record.status]}>
              {employeeProgramStatus[record.status]}
            </Tag>
            {record.status == employeeProgramStatusPending &&
              record.required_active_conditions &&
              record.required_active_conditions.length > 0 && (
                <Tooltip title={'Chưa đủ điều kiện'}>
                  <InfoCircleOutlined
                    style={{ color: '#b8860b', fontSize: '20px' }}
                  />
                </Tooltip>
              )}
          </Space>
        );
      },
    },
    {
      title: 'Accept Invitation',
      dataIndex: 'joined_at',
      sorter: true,
      type: 'dateTime',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space>
          <Link to={`/investment/employee-programs/${record.id}/edit`}>
            <UIIconTooltip title="Edit" Icon={EditOutlined} />
          </Link>

          <Link
            to={`/investment/employee-programs/${record.id}/investment-plans`}
          >
            <UIIconTooltip title="Investment Plan" Icon={LineChartOutlined} />
          </Link>
          <Link to={`/investment/employee-programs/${record.id}/portfolio`}>
            <UIIconTooltip title="View Portfolio" Icon={PieChartOutlined} />
          </Link>
        </Space>
      ),
    },
  ];
};
