import { Button, Tag, Tooltip } from 'antd';
import apiCall from 'apps/education/apiCalls/userDevice';
import { ListPage } from 'common/ui/AdminCRUD/page';
import copyTextToClipboard from 'utils/clipboard';
import { activeColors, activeLabels } from 'utils/constants';
import { formatISODate } from 'utils/formatDate';

const columnsGenerator = params => [
  {
    title: 'User ID',
    dataIndex: 'user',
    render: obj => obj.id,
  },
  {
    title: 'Email',
    dataIndex: 'user',
    render: obj => obj.email,
  },
  {
    title: 'Name',
    dataIndex: 'user',
    render: obj => obj.name,
  },
  {
    title: 'Device Name',
    dataIndex: 'device_name',
  },
  {
    title: 'App version',
    dataIndex: 'app_version',
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    render: value => formatISODate(value),
  },
  {
    title: 'Status',
    dataIndex: 'is_active',
    render: value => {
      const status = value === true ? 'active' : 'inactive';
      return <Tag color={activeColors[status]}>{activeLabels[status]}</Tag>;
    },
  },
  {
    title: 'Notification Token',
    dataIndex: 'fcm_token',
    render: value => {
      // onClick handler function for the copy button
      const handleCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(value)
          .then(() => {
            console.log('copied', value);
          })
          .catch(err => {
            console.log(err);
          });
      };

      return (
        <Tooltip>
          <Button onClick={handleCopyClick}>Copy to clipboard</Button>
        </Tooltip>
      );
    },
  },
];

export const UserDeviceListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    searchOptions={{
      placeHolder: "Enter user's name, email, userID or device name",
    }}
    title="All User Devices"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="items"
    {...props}
  />
);
