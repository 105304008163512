import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';

const VerificationMark = ({ value, isVerified, showNotVerified = true }) => {
  if (!value) {
    return null;
  }

  if (isVerified) {
    return (
      <Space>
        {value}
        <Tooltip title="Verified">
          <CheckCircleOutlined style={{ color: 'green', fontSize: '20px' }} />
        </Tooltip>
      </Space>
    );
  }

  if (showNotVerified) {
    return (
      <Space>
        {value}
        <Tooltip title="Not Verified">
          <InfoCircleOutlined style={{ color: 'red', fontSize: '20px' }} />
        </Tooltip>
      </Space>
    );
  }

  return null;
};

export default VerificationMark;
