import React, { useEffect } from 'react';
import { Form, Input, Button, Upload, DatePicker, Select } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { convertDateFormData, ensureDateFields } from 'utils/formData';
import { statusOptions } from 'utils/constants';
import { listingOptions } from './config';

const dateFields = ['inception_date'];

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    form.setFieldsValue(ensureDateFields(initialValues, dateFields));
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={values => onFinish(convertDateFormData(values, dateFields))}
      initialValues={ensureDateFields(initialValues, dateFields)}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Quỹ ETF DCVFMVN DIAMOND" />
      </Form.Item>

      <Form.Item
        name="type"
        label="Type"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Quỹ ETF" />
      </Form.Item>

      <Form.Item
        name="symbol"
        label="Symbol"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="FUEVFVND" />
      </Form.Item>

      <Form.Item
        name="inav_symbol"
        label="Inav Symbol"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="FUEVFVND" />
      </Form.Item>

      <Form.Item name="management_fee" label="Management Fee">
        <Input placeholder="0.80%" />
      </Form.Item>

      <Form.Item name="redemption_fee" label="Redemption Fee">
        <Input placeholder="0% - 0.15%" />
      </Form.Item>

      <Form.Item name="subscription_fee" label="Subscription Fee">
        <Input placeholder="0%" />
      </Form.Item>

      <Form.Item name="switching_fee" label="Switching Fee">
        <Input placeholder="0%" />
      </Form.Item>

      <Form.Item name="currency" label="Currency">
        <Input placeholder="vnd" />
      </Form.Item>

      <Form.Item
        name="exchange"
        label="Exchange"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="hose" />
      </Form.Item>

      <Form.Item
        name="reference_index"
        label="Reference Index"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Vietnam Diamond Index" />
      </Form.Item>

      <Form.Item name="inception_date" label="Inception Date">
        <DatePicker />
      </Form.Item>

      <Form.Item name="trading_cycle" label="Trading Cycle">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="cut_off_time" label="Cut Off Time">
        <Input placeholder="14h40 hằng ngày" />
      </Form.Item>

      <Form.Item name="trading_time" label="Trading Time">
        <Input placeholder="Hàng ngày" />
      </Form.Item>

      <Form.Item name="fund_manager" label="Fund Manager">
        <Input placeholder="Công ty Cổ phần Quản lý Quỹ Đầu tư Dragon Capital Việt Nam" />
      </Form.Item>

      <Form.Item name="custodian_bank" label="Custodian Bank">
        <Input placeholder="Vietcombank - Chi nhánh TP.HCM" />
      </Form.Item>

      <Form.Item name="transfer_agency" label="Transfer Agency">
        <Input placeholder="Trung tâm lưu ký chứng khoán (VSD)" />
      </Form.Item>

      <Form.Item name="index_provider" label="Index Provider">
        <Input placeholder="Sở giao dịch Chứng khoán Thành phố Hồ Chí Minh (HOSE)" />
      </Form.Item>

      <Form.Item name="dividend" label="Dividend">
        <Input placeholder="Tái đầu tư" />
      </Form.Item>

      <Form.Item name="authorized_participants" label="Authorized Participants">
        <Input placeholder="BVSC, HSC,..." />
      </Form.Item>

      <Form.Item name="market_maker" label="Market Maker">
        <Input placeholder="BVSC, HSC,..." />
      </Form.Item>

      <Form.Item name="bloomberg_symbol" label="Bloomberg Symbol">
        <Input placeholder="FUEVFVND VN EQUITY" />
      </Form.Item>

      <Form.Item name="creation_unit" label="Creation Unit">
        <Input placeholder="1 (một) lô ETF tương ứng với 100.000 chứng chỉ quỹ ETF" />
      </Form.Item>

      <Form.Item name="description" label="Description">
        <Input.TextArea rows={10} />
      </Form.Item>

      <Form.Item name="strategy" label="Strategy">
        <Input.TextArea rows={10} />
      </Form.Item>

      <Form.Item name="investment_objective" label="Investment Objective">
        <Input.TextArea
          rows={10}
          placeholder="Mục tiêu của Quỹ ETF VFMVN DIAMOND là mô phỏng gần nhất có thể về biến động (performance) của chỉ số VN DIAMOND. Quỹ ETF VFMVN DIAMOND sử dụng chiến lược đầu tư thụ động để thực hiện mục tiêu đầu tư đã định trước."
        />
      </Form.Item>

      <Form.Item
        name="photo"
        label="Logo"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item name="exchange_status" label="Listing Status">
        <Select>
          {listingOptions &&
            listingOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item name="status" label="Publishing Status">
        <Select>
          {statusOptions &&
            statusOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>

        {onDelete ? (
          <Button
            onClick={onDelete}
            type="primary"
            style={{
              visibility: visibility,
              margin: 10,
              backgroundColor: 'red',
              borderColor: 'red',
            }}
          >
            Delete
          </Button>
        ) : null}
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
