import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';

const POST_INVESTCORE_EMPLOYEE_PROGRAM_CREATE_COMPANY_SELL_ORDER_API_ID =
  'DASHBOARD/POST_INVESTCORE_EMPLOYEE_PROGRAM_CREATE_COMPANY_SELL_ORDER_API_ID';

const apiCall = enhancedMakeFetchAction(
  POST_INVESTCORE_EMPLOYEE_PROGRAM_CREATE_COMPANY_SELL_ORDER_API_ID,
  ({ id, payload }) => ({
    endpoint: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/employee-programs/${id}/create-company-sell-order`,
    method: 'POST',
    body: payload,
    isStay: true,
    notify: true,
  })
);

export default apiCall;
