import apiCall from 'apps/hub/apiCalls/course';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { columnsGenerator } from './config';
import ItemForm from './Form';

export const CourseListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    title="All courses"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="data"
    createUrl={`/hub/courses/create`}
    {...props}
  />
);

export const CourseEditPage = props => (
  <EditPage
    title="Course Edit"
    apiCall={apiCall}
    listUrl={`/hub/courses`}
    ItemForm={ItemForm}
    resourceName="data"
    {...props}
  />
);

export const CourseCreatePage = props => (
  <CreatePage
    title="Course"
    apiCall={apiCall}
    listUrl={`/hub/courses`}
    ItemForm={ItemForm}
    resourceName="data"
    {...props}
  />
);
