import { useEffect, useState } from 'react';
import slugify from 'slugify';

import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Image, Input, InputNumber, Upload } from 'antd';
import { DRAFT_STATUS, PUBLISH_STATUS } from 'utils/constants';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  const { photo, status } = initialValues;

  const onBlur = () => {
    const inputValue = form.getFieldsValue();
    !inputValue.slug &&
      form.setFieldsValue({ slug: slugify(inputValue.title).toLowerCase() });
  };

  const [isRemoved, setIsRemoved] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const removePhoto = () => {
    setIsRemoved(true);
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={values =>
        onFinish({ ...values, status: isDraft ? DRAFT_STATUS : PUBLISH_STATUS })
      }
      initialValues={initialValues}
      scrollToFirstError
      onBlur={onBlur}
    >
      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Ngân hàng ACB" />
      </Form.Item>

      <Form.Item
        name="symbol"
        label="Symbol"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="ACB" />
      </Form.Item>

      <Form.Item
        name="slug"
        label="URL Slug"
        tooltip="URL Slug"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input value={form.fieldValue} placeholder="ngan-hang-acb" />
      </Form.Item>

      <Form.Item
        name="photo"
        label="Upload a new photo"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      {isRemoved || !photo ? null : (
        <Form.Item
          label="Existing photo"
          style={{
            visibility,
          }}
        >
          <Button
            type="primary"
            shape="circle"
            size="small"
            style={{
              backgroundColor: 'red',
              position: 'absolute',
              zIndex: 99,
              left: 5,
              top: 5,
            }}
            onClick={() => removePhoto()}
          >
            X
          </Button>
          <Image width={200} src={photo}></Image>
        </Form.Item>
      )}

      <Form.Item name="savings_on_demand" label="Savings on demand">
        <InputNumber />
      </Form.Item>

      <Form.Item name="savings_1m" label="Savings 1m">
        <InputNumber />
      </Form.Item>

      <Form.Item name="savings_3m" label="Savings 3m">
        <InputNumber />
      </Form.Item>

      <Form.Item name="savings_6m" label="Savings 6m">
        <InputNumber />
      </Form.Item>

      <Form.Item name="savings_9m" label="Savings 9m">
        <InputNumber />
      </Form.Item>

      <Form.Item name="savings_12m" label="Savings 12m">
        <InputNumber />
      </Form.Item>

      <Form.Item name="savings_13m" label="Savings 13m">
        <InputNumber />
      </Form.Item>

      <Form.Item name="savings_18m" label="Savings 18m">
        <InputNumber />
      </Form.Item>

      <Form.Item name="savings_24m" label="Savings 24m">
        <InputNumber />
      </Form.Item>

      <Form.Item name="savings_36m" label="Savings 36m">
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="online_savings_on_demand"
        label="Online Savings on demand"
      >
        <InputNumber />
      </Form.Item>

      <Form.Item name="online_savings_1m" label="Online Savings 1m">
        <InputNumber />
      </Form.Item>

      <Form.Item name="online_savings_3m" label="Online Savings 3m">
        <InputNumber />
      </Form.Item>

      <Form.Item name="online_savings_6m" label="Online Savings 6m">
        <InputNumber />
      </Form.Item>

      <Form.Item name="online_savings_9m" label="Online Savings 9m">
        <InputNumber />
      </Form.Item>

      <Form.Item name="online_savings_12m" label="Online Savings 12m">
        <InputNumber />
      </Form.Item>

      <Form.Item name="online_savings_13m" label="Online Savings 13m">
        <InputNumber />
      </Form.Item>

      <Form.Item name="online_savings_18m" label="Online Savings 18m">
        <InputNumber />
      </Form.Item>

      <Form.Item name="online_savings_24m" label="Online Savings 24m">
        <InputNumber />
      </Form.Item>

      <Form.Item name="online_savings_36m" label="Online Savings 36m">
        <InputNumber />
      </Form.Item>

      <Form.Item name="loan_to_deposit_ratio" label="Loan to deposit ratio">
        <InputNumber />
      </Form.Item>

      <Form.Item name="market_cap" label="Market cap">
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="non_performing_loan_ratio"
        label="Loan Loss Reserve (LLR)"
      >
        <InputNumber />
      </Form.Item>

      <Form.Item name="capital_adequacy_ratio" label="Capital adequacy ratio">
        <InputNumber />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>

        {onDelete ? (
          <Button
            onClick={onDelete}
            type="primary"
            style={{
              visibility: visibility,
              margin: 10,
              backgroundColor: 'red',
              borderColor: 'red',
            }}
          >
            Delete
          </Button>
        ) : null}
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
