import { get } from 'lodash/fp';
import { createAction, handleActions } from 'redux-actions';

const SET_AUTH_TOKEN = 'admin/SET_AUTH_TOKEN';
const RESET_AUTH_TOKEN = 'admin/RESET_AUTH_TOKEN';

const setAdminAuthTokenAC = createAction(SET_AUTH_TOKEN);
const resetAdminAuthTokenAC = createAction(RESET_AUTH_TOKEN);

const adminSessionSelector = get('adminSession');
const adminUserSelector = get('adminSession.user');
const adminAccessTokenSelector = get('adminSession.accessToken.token');
const adminRefreshTokenSelector = get('adminSession.refreshToken.token');

const authTokenReducer = handleActions(
  {
    [SET_AUTH_TOKEN]: (state, { payload }) => payload,
    [RESET_AUTH_TOKEN]: () => null,
  },
  null
);

export {
  adminAccessTokenSelector,
  adminRefreshTokenSelector,
  adminSessionSelector,
  adminUserSelector,
  resetAdminAuthTokenAC,
  setAdminAuthTokenAC,
};

export default {
  adminSession: authTokenReducer,
};
