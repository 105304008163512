import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { INVESTCORE_API_BASE_URL } from 'config/env.config';

const GET_INVESTCORE_EMPLOYEE_PROGRAM_ESTIMATE_API_ID =
  'DASHBOARD/GET_INVESTCORE_EMPLOYEE_PROGRAM_ESTIMATE_API_ID';

const apiCall = enhancedMakeFetchAction(
  GET_INVESTCORE_EMPLOYEE_PROGRAM_ESTIMATE_API_ID,
  ({ id }) => ({
    endpoint: `${INVESTCORE_API_BASE_URL}/investcore-api/admin/employee-programs/${id}/estimate-vesting`,
    method: 'GET',
    isStay: true,
    notify: true,
  })
);

export default apiCall;
