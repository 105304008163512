import { Button } from 'antd';
import apiCall from 'apps/investcore/apiCalls/etfProduct';
import { DetailPage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { Link } from 'react-router-dom';
import { formatNumber } from 'utils/formatAmount';
import { formatISODate } from 'utils/formatDate';
import { cloudflareCdn } from 'utils/image';
import ItemForm from './Form';
import ItemDetail from './detail';

const columnsGenerator = params => [
  {
    title: 'Code',
    dataIndex: 'symbol',
  },
  {
    title: 'Logo',
    dataIndex: 'logo',
    render: value =>
      value && <img width={100} src={cloudflareCdn(value, 100)} />,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: 'Fund Manager',
    dataIndex: 'fund_manager_name',
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    sorter: true,
    render: value => formatISODate(value),
  },
  {
    title: 'Volume',
    dataIndex: 'volume',
    align: 'right',
    render: value => formatNumber(value),
  },
  {
    title: 'Price',
    dataIndex: 'last',
    align: 'right',
    render: value => formatNumber(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/investcore/products/etfs/${record.id}`}>View</Link>
        </Button>
      </Button.Group>
    ),
  },
];

export const InvestmentStockListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    title="All ETF Products"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="data"
    {...props}
  />
);

export const InvestmentStockEditPage = props => (
  <EditPage
    title="ETF Product"
    apiCall={apiCall}
    listUrl={`/investcore/products/etfs`}
    ItemForm={ItemForm}
    resourceName="data"
    {...props}
  />
);

export const InvestmentStockDetailPage = props => (
  <DetailPage
    title="ETF Product Detail"
    apiCall={apiCall}
    listUrl={`/investcore/products/etfs`}
    ItemDetail={ItemDetail}
    resourceName="data"
    options={{ editEnabled: false }}
    {...props}
  />
);
