import React, { useEffect } from 'react';
import slugify from 'slugify';
import { Form, Input, Button, Tag, InputNumber, Select, Image, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { statusOptions, statusColors, statusLabel } from 'utils/constants';

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  const { photo } = initialValues;

  const onBlur = () => {
    const inputValue = form.getFieldsValue();
    !inputValue.slug &&
      form.setFieldsValue({ slug: slugify(inputValue.title).toLowerCase() });
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={onFinish}
      initialValues={initialValues}
      scrollToFirstError
      onBlur={onBlur}
    >
      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Chứng khoán" />
      </Form.Item>

      <Form.Item
        name="slug"
        label="URL Slug"
        tooltip="URL Slug"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input value={form.fieldValue} placeholder="chung-khoan" />
      </Form.Item>

      <Form.Item name="excerpt" label="Short Description">
        <Input.TextArea rows={3} />
      </Form.Item>

      <Form.Item name="description" label="Description">
        <Input.TextArea rows={10} />
      </Form.Item>

      <Form.Item
        name="photo"
        label="Upload a new photo"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item
        label="Existing photo"
        style={{
          visibility: visibility,
        }}
      >
        <Image width={200} src={photo} />
      </Form.Item>

      <Form.Item
        name="ranking"
        label="Topic Number"
        tooltip="Smallest number will be on top: 1,2,3.."
      >
        <InputNumber />
      </Form.Item>

      <Form.Item name="status" label="Status">
        <Select>
          {statusOptions &&
            statusOptions.map(option => (
              <Option key={option.value} value={option.value}>
                <Tag color={statusColors[option.value]}>{statusLabel[option.value]}</Tag>
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>

        {onDelete ? (
          <Button
            onClick={onDelete}
            type="primary"
            style={{
              visibility: visibility,
              margin: 10,
              backgroundColor: 'red',
              borderColor: 'red',
            }}
          >
            Delete
          </Button>
        ) : null}
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
