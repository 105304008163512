import { useFetchApiGet, useFetchApiList } from '@stagapps/redux-utils';
import { LineChart } from '@tremor/react';
import { Col, Layout, Progress, Row, Spin, Statistic, Table } from 'antd';
import apiCall from 'apps/investment/apiCalls/userProgram';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getChangeValueColor } from 'utils/color';
import { formatNumber } from 'utils/formatAmount';

const duplicateColumn = [
  {
    title: 'Product Code',
    dataIndex: 'product_code',
  },
];

const portColums = [
  ...duplicateColumn,
  {
    title: 'Total Contributed Amount',
    dataIndex: 'total_contributed_amount',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Quantity Free to Sell',
    dataIndex: 'available_quantity',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Locking Quantity',
    dataIndex: 'lock_quantity',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'AVG Price',
    dataIndex: 'avg_buy_price',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Cert Subsciption Amount',
    dataIndex: 'cert_subscription_amount',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Market Value',
    dataIndex: 'market_value',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Unrealized Gain Loss',
    dataIndex: 'unrealized_gain_loss',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Unrealized Gain Loss Percentage',
    dataIndex: 'unrealized_gain_loss_percentage',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
];

const InvestmentUserProgramPortfolio = () => {
  const params = useParams();

  const {
    data: userProgramPortfolio,
    load: fetchUserProgramPortfolio,
    isLoading: isLoadingUserProgramPortfolio,
  } = useFetchApiGet(apiCall.portfolio, { resourceName: 'data' });

  const {
    data: userProgramPortfolioAnalytics,
    load: fetchUserProgramPortfolioAnalytics,
    isLoading: isLoadingUserProgramPortfolioAnalytics,
  } = useFetchApiList(apiCall.analytics, { resourceName: 'data' });

  useEffect(() => {
    fetchUserProgramPortfolio({ id: params.id });
    fetchUserProgramPortfolioAnalytics({ id: params.id });
  }, []);

  const isLoading =
    isLoadingUserProgramPortfolio || isLoadingUserProgramPortfolioAnalytics;

  if (isLoading) {
    return (
      <Spin tip="Loading" size="large">
        <div className="content" />
      </Spin>
    );
  }

  const formatter = value => <Statistic value={formatNumber(value)} />;

  return (
    <>
      <Row gutter={32}>
        <Col span={6}>
          <Progress
            type="circle"
            percent={userProgramPortfolio.user_program_progress}
            precision={2}
            formatter={formatter}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Target Amount"
            value={userProgramPortfolio.user_program.target_amount}
            precision={2}
            formatter={formatter}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Total Contributed Amount"
            value={userProgramPortfolio.total_contributed_amount}
            precision={2}
            formatter={formatter}
          />
        </Col>
        <Col span={6}>
          <Statistic
            valueStyle={{
              color: getChangeValueColor(
                userProgramPortfolio.unrealized_gain_loss
              ),
            }}
            title="Market Value"
            value={userProgramPortfolio.market_value}
            precision={2}
            formatter={formatter}
            suffix={`(${formatNumber(
              userProgramPortfolio.unrealized_gain_loss_percentage
            )}%)`}
          />
        </Col>
      </Row>
      <Table
        rowKey="id"
        columns={portColums}
        dataSource={userProgramPortfolio.items}
        pagination={false}
      />

      <Layout>
        <LineChart
          data={userProgramPortfolioAnalytics}
          index="order_date"
          className="mt-4 h-72"
          categories={[
            'accumulated_capital_amount',
            'accumulated_contributed_amount',
            'accumulated_market_value',
            'accumulated_projected_market_value',
          ]}
          yAxisWidth={65}
          colors={['blue', 'red', 'green', 'yellow']}
          valueFormatter={formatNumber}
        />
      </Layout>
    </>
  );
};

export default InvestmentUserProgramPortfolio;
