import React from 'react';
import SelectField from './SelectField';

export default function MarketIndexSelectField(props) {
  // TODO: Call api
  let options = [
    { value: 'VNINDEX', label: 'VNINDEX' },
    { value: 'HNXIndex', label: 'HNXIndex' },
    { value: 'VN30', label: 'VN30' },
    { value: 'VNXALL', label: 'VNXALL' },
    { value: 'VNALL', label: 'VNALL' },
    { value: 'VNDIAMOND', label: 'VNDIAMOND' },
    { value: 'UpcomIndex', label: 'UpcomIndex' },
    { value: 'HNX30', label: 'HNX30' },
    { value: 'HNXUpcomIndex', label: 'HNXUpcomIndex' },
    { value: 'VN100', label: 'VN100' },
  ];

  return (
    <SelectField placeholder="Select Indexes" mode="multiple" style={{ width: '100%' }} {...props} options={options} />
  );
}