export { default as listApiCall } from './list';
export { default as detailApiCall } from './detail';
export { default as editApiCall } from './edit';

import listApiCall from './list';
import detailApiCall from './detail';
import editApiCall from './edit';

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  create: null,
  edit: editApiCall,
  delete: null,
};

export default ApiCall;
