import { usePostApi } from '@stagapps/redux-utils';
import { Button, Form, Input } from 'antd';
import apiCall from 'apps/education/apiCalls/gameParticipants/import';
import { useParams } from 'react-router-dom';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ImportForm = () => {
  const { id } = useParams();
  const { post: importItems, submiting, error: importError } = usePostApi(
    apiCall
  );

  return (
    <Form
      {...formItemLayout}
      name={'import_form'}
      onFinish={v => {
        const values = { emails: v.emails.split(',').map(e => e.trim()) };
        importItems({ id, payload: values });
      }}
      scrollToFirstError
    >
      <Form.Item
        name="emails"
        label="Emails"
        tooltip="List of emails to import, separated by comma. The users are already in the room will be skipped."
      >
        <Input.TextArea
          rows={10}
          placeholder="hai@stag.vn,tan@stag.vn,hieu@stag.vn"
        />
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button htmlType="submit" type="primary" loading={submiting}>
          Import
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ImportForm;
