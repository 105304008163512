import { useEffect, useState } from 'react';
import slugify from 'slugify';

import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { useFetchApiList } from '@stagapps/redux-utils';
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
  Tag,
  Upload,
} from 'antd';
import imageUploadCall from 'apps/education/apiCalls/editorImage/upload';
import getMetricApiCall from 'apps/market/apiCalls/collection/metric';
import getOperatorApiCall from 'apps/market/apiCalls/collection/operator';
import {
  contentTypeOptions,
  productTypeOptions,
  sortOptions,
} from 'apps/market/apiCalls/collection/option';
import collectionGroupApiCall from 'apps/market/apiCalls/collectionGroup';
import TextEditor from 'common/form/TextEditor';
import {
  DRAFT_STATUS,
  PUBLISH_STATUS,
  statusColors,
  statusLabel,
  statusOptions,
} from 'utils/constants';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  const { photo, status, preview_list } = initialValues;

  const onBlur = () => {
    const inputValue = form.getFieldsValue();
    !inputValue.slug &&
      form.setFieldsValue({ slug: slugify(inputValue.title).toLowerCase() });
  };

  const [isRemoved, setIsRemoved] = useState(false);
  const [isDraft, setIsDraft] = useState(false);

  const removePhoto = () => {
    setIsRemoved(true);
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const changeProductType = e => {
    fetchMetrics({ product_type: e });
    fetchOperators({ product_type: e });
  };

  useEffect(() => {
    console.log('form edit');
    fetchMetrics({ product_type: initialValues.product_type || 'stock' });
    fetchOperators({ product_type: initialValues.product_type || 'stock' });
    fetchCollectionGroups();
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const {
    data: metrics,
    load: fetchMetrics,
    isLoadingMetrics,
    pagingMetric,
  } = useFetchApiList(getMetricApiCall, { resourceName: 'items' });

  const {
    data: operators,
    load: fetchOperators,
    isLoadingOperators,
    pagingOperator,
  } = useFetchApiList(getOperatorApiCall, { resourceName: 'items' });

  const {
    data: collectionGroups,
    load: fetchCollectionGroups,
    isLoadingCollectionGroups,
    pagingCollectionGroups,
  } = useFetchApiList(collectionGroupApiCall.list, { resourceName: 'items' });

  var previewColumns = [
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  var previewDataSource =
    preview_list &&
    preview_list.map(item => ({
      key: item.id,
      symbol: item.symbol,
      name: item.name,
    }));

  if (initialValues.field_params && initialValues.field_params.length > 0) {
    previewDataSource = [];
    previewColumns = initialValues.field_params.map(item => ({
      title: item
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.substr(1))
        .join(' '),
      dataIndex: item,
      key: item,
    }));

    var previewListLength = preview_list ? preview_list.length : 0;
    for (let i = 0; i < previewListLength; i++) {
      if (!preview_list[i]) {
        continue;
      }

      var row = { key: preview_list[i].id };
      for (let j = 0; j < previewColumns.length; j++) {
        var columnName = previewColumns[j]['dataIndex'];
        row[columnName] = preview_list[i][columnName];
      }
      previewDataSource.push(row);
    }
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={values =>
        onFinish({ ...values, status: isDraft ? DRAFT_STATUS : PUBLISH_STATUS })
      }
      initialValues={initialValues}
      scrollToFirstError
      onBlur={onBlur}
    >
      <Form.Item name="content_type" label="Content Type">
        <Select>
          {contentTypeOptions &&
            contentTypeOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Ngân hàng ACB" />
      </Form.Item>

      <Form.Item
        name="slug"
        label="URL Slug"
        tooltip="URL Slug"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input value={form.fieldValue} placeholder="ngan-hang-acb" />
      </Form.Item>

      <Form.Item
        name="photo"
        label="Upload a new photo"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      {isRemoved || !photo ? null : (
        <Form.Item
          label="Existing photo"
          style={{
            visibility,
          }}
        >
          <Button
            type="primary"
            shape="circle"
            size="small"
            style={{
              backgroundColor: 'red',
              position: 'absolute',
              zIndex: 99,
              left: 5,
              top: 5,
            }}
            onClick={() => removePhoto()}
          >
            X
          </Button>
          <Image width={200} src={photo}></Image>
        </Form.Item>
      )}

      <Form.Item name="excerpt" label="Excerpt">
        <Input.TextArea rows={10} />
      </Form.Item>

      <Form.Item name="description" label="Description">
        <TextEditor uploadCallback={imageUploadCall} value={form.fieldValue} />
      </Form.Item>

      <Form.Item name="order" label="Order">
        <InputNumber />
      </Form.Item>

      <Form.Item name="limit" label="Limit">
        <InputNumber />
      </Form.Item>

      <Form.Item name="status" label="Status">
        <Select>
          {statusOptions &&
            statusOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="product_type"
        label="Type"
        getValueFromEvent={changeProductType}
      >
        <Select>
          {productTypeOptions &&
            productTypeOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item name="group" label="Group">
        <Select>
          {collectionGroups &&
            collectionGroups.map(option => (
              <Option key={option.id} value={option.id}>
                {option.title}{' '}
                <Tag color={statusColors[option.status]}>
                  {statusLabel[option.status]}
                </Tag>
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="Filter">
        <Form.List name="filter_params">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[{ required: true, message: 'Missing name' }]}
                    >
                      <Select
                        allowClear
                        showSearch
                        filterOption={(input, option) =>
                          option.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          option.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          slugify(option.children)
                            .replaceAll('-', ' ')
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          slugify(option.children)
                            .replaceAll('-', ' ')
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {metrics &&
                          metrics.map(option => (
                            <Option key={option.value} value={option.value}>
                              {option.label}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      {...restField}
                      name={[name, 'operator']}
                      rules={[{ required: true, message: 'Missing operator' }]}
                    >
                      <Select>
                        {operators &&
                          operators.map(option => (
                            <Option key={option.value} value={option.value}>
                              {option.label}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      {...restField}
                      name={[name, 'value']}
                      rules={[{ required: true, message: 'Missing value' }]}
                    >
                      <Input placeholder="Value" />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add filter
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>

      <Form.Item label="Sort">
        <Form.List name="sort_params">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[{ required: true, message: 'Missing name' }]}
                    >
                      <Select
                        allowClear
                        showSearch
                        filterOption={(input, option) =>
                          option.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          option.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          slugify(option.children)
                            .replaceAll('-', ' ')
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          slugify(option.children)
                            .replaceAll('-', ' ')
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {metrics &&
                          metrics.map(option => (
                            <Option key={option.value} value={option.value}>
                              {option.label}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <Form.Item
                      {...restField}
                      name={[name, 'type']}
                      rules={[{ required: true, message: 'Missing type' }]}
                    >
                      <Select>
                        {sortOptions &&
                          sortOptions.map(option => (
                            <Option key={option.value} value={option.value}>
                              {option.label}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add sort
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>

      <Form.Item label="Fields">
        <Form.List name="field_params">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item required={false} key={field.key}>
                  <Form.Item
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Please select field name',
                      },
                    ]}
                    noStyle
                  >
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        slugify(option.children)
                          .replaceAll('-', ' ')
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        slugify(option.children)
                          .replaceAll('-', ' ')
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {metrics &&
                        metrics.map(option => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  {fields.length >= 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: '60%' }}
                  icon={<PlusOutlined />}
                >
                  Add field
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>

      <Form.Item label="Results">
        <Table columns={previewColumns} dataSource={previewDataSource} />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>

        {onDelete ? (
          <Button
            onClick={onDelete}
            type="primary"
            style={{
              visibility: visibility,
              margin: 10,
              backgroundColor: 'red',
              borderColor: 'red',
            }}
          >
            Delete
          </Button>
        ) : null}
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
