import { Descriptions } from 'antd';
import { formatDateTime } from 'utils/formatDate';

const ItemDetail = ({ item }) => {
  return (
    <>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="Name">{item.user_name}</Descriptions.Item>
        <Descriptions.Item label="Email">{item.email}</Descriptions.Item>
        <Descriptions.Item label="Company">
          {item.company_name}
        </Descriptions.Item>
        <Descriptions.Item label="Result">
          {item.code ? (
            <a
              target="_blank"
              href={`https://stag.vn/suc-khoe-tai-chinh/${item.code}`}
            >
              View report
            </a>
          ) : (
            '-'
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Metadata">{item.metadata}</Descriptions.Item>
        <Descriptions.Item label="Created At">
          {formatDateTime(item.created_at)}
        </Descriptions.Item>
        <Descriptions.Item label="Updated At">
          {formatDateTime(item.updated_at)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default ItemDetail;
